import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const Toast1 = () => {

    const [data, setData] = useState([]);
    // const [resultArray, setResultArray] = useState([]);

    // useEffect(() => {
    //     DemographicsApi1()
    // }, []);


    const DemographicsApi1 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_count&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log(response.data[0].values[0])
                console.log("   PAGING DATA      ", response.data.paging.previous)
                console.log("     DATA DATA    ", response.data.data)

                test(response.data.paging.previous)


            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }



    const test = (uarel) => {
        for (let i = 0; i > 6; i++) {
            axios.get(`${uarel}`)
                .then((resp) => {
                    console.log("   PAGING DATA     ", i, resp.data.paging.previous)
                    test(resp.data.paging.previous)
                }).catch((error) => {
                    console.log("The Inside Api 3 error - ", error)
                });
        }

    }


    const [only_reach_dates, setOnly_reach_dates] = useState([]);
    const [only_reach_values, setOnly_reach_values] = useState([]);
 
    const [isloader, setLoader] = useState(false);

    const [resultArray, setResultArray] = useState([]);

    useEffect(() => {
        setLoader(true)
        // fetchData("https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_count&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC", 3);



        fetchDataReach("https://graph.facebook.com/v18.0/17841433401221135/insights?metric=reach&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC", 4);

        // setTimeout(() => {

        //     showey();
        // }, 10000);
    }, []);

    // const only_values = [0,0,1,0];



    const Tymm = () => {


        const dateString = "Tue, 23 Jan 2024 08:00:00 GMT";

        // Parse the date string
        const date = new Date(dateString);

        // Get the day and month parts
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });

        // Construct the new date string
        const newDateString = `${day} ${month}`;

        console.log(newDateString); // Output: 23 Jan

    }



    const [only_dates, setOnly_dates] = useState([]);
    const [only_values, setOnly_values] = useState([]);
    let newSortArray;
    const fetchData = (url, remainingIterations) => {
        if (remainingIterations <= 0) {
            return;
        }

        fetch(url)
            .then(response => response.json())
            .then(responseData => {
                const values = responseData.data[0].values;

                // Extracting value and end_time and saving them in the resultArray
                const extractedData = values.map(item => ({
                    value: item.value,
                    end_time: item.end_time
                }));
                setResultArray(prevResult => [...prevResult, ...extractedData]);

                // Fetch previous data recursively
                if (responseData.paging && responseData.paging.previous) {
                    fetchData(responseData.paging.previous, remainingIterations - 1);
                }
            }

            )
            .catch(error => console.error('Error fetching data:', error));

    };

    const addChartData = () => {

        newSortArray = resultArray.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        // Iterate through the sorted dash array
        newSortArray.forEach(item => {
            // Extract date in the format "25 January"
            const date = new Date(item.end_time).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
            only_values.push(item.value);
            only_dates.push(date);
            console.log(item.value)
            // Extract value

        });

        console.log("herer", only_dates);
        console.log("herer", only_values);

    }




    // --------------- //
    const [resultReachArray, setResultReachArray] = useState([]);
    let newSortReachArray;
    const fetchDataReach = (url, remainingIterations) => {
        if (remainingIterations <= 0) {
            return;
        }

        fetch(url)
            .then(response => response.json())
            .then(responseData => {
                const values = responseData.data[0].values;

                // Extracting value and end_time and saving them in the resultArray
                const extractedData = values.map(item => ({
                    value: item.value,
                    end_time: item.end_time
                }));
                setResultReachArray(prevResult => [...prevResult, ...extractedData]);

                // Fetch previous data recursively
                if (responseData.paging && responseData.paging.previous) {
                    fetchDataReach(responseData.paging.previous, remainingIterations - 1);
                }
            }

            )
            .catch(error => console.error('Error fetching data:', error));

    };

    const addChartReachData = () => {

        newSortReachArray = resultReachArray.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        // Iterate through the sorted dash array
        newSortReachArray.forEach(item => {
            // Extract date in the format "25 January"
            const date = new Date(item.end_time).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
            only_reach_dates.push(date);

            // Extract value
            only_reach_values.push(item.value);
        });

        console.log("reach dates ", only_reach_dates);
        console.log("reach values", only_reach_values);

    }




    // ------------
    const options = {

        title: {
            text: 'Line Chart'
        },
        xAxis: {
            // categories: ["23 jan", "24 jan", "25 jan", "26 jan", "27 jan", "28 jan"]
            categories: only_reach_dates
        },
        yAxis: {
            title: {
                text: 'Value'
            }
        },
        series: [{
            name: 'Series 1',
            // data: [0, 0, 0, 1, 0, 0]
            data: only_reach_values
        },
        {
            name: 'Series 2',
            data: [0, 0, 3, 2, 0, 0]
            // data: [only_values]
        },
        ]
    };
    // ------------
    const showey = () => {
        // console.log("The fanny :", resultArray)
        // resultArray.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        // console.log(resultReachArray);
        // console.log(resultArray);
        // addChartReachData()
        addChartData()
        console.log("called", options)
    }

    return (
        <>
            <div>
                {/* Displaying extracted result */}
                <h2 onClick={() => { addChartReachData(); setLoader(false) }}>Extracted Result:</h2>
                <ul>
                    {resultArray.map((item, index) => (
                        <li key={index}>Value: {item.value}, End Time:
                            {/* {dayjs(item.end_time).format("DD-MM-YYYY hh:mm A")} */}
                            {dayjs(item.end_time).toString()}
                        </li>
                    ))}
                </ul>
            </div>

            <div>

                {
                    isloader == false
                        ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                        />
                        :
                        <>
                            <h3>Not yet ...</h3>
                        </>

                }
            </div>

        </>
    );


}

export default Toast1


















