import './Styles/landingPage.css'
import './Styles/Hover.css'
import Routey from './Routez/Routey'
function App() {
  return (
    <>
      <Routey />
    </>
  )
}
export default App
