import React from 'react'
import banner from '../../../../Imagez/Dashboard/All/Upgrade Card.png'
import { useState } from 'react';
import GRaph from "../../../../Imagez/Dashboard/All/Pie Chart.png";
import Pepsi from "../../../../Imagez/Dashboard/All/Pepsico.png";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import numeral from 'numeral';
import { baseUrl2 } from '../../../../Routez/Routey';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ProgressBar } from 'react-bootstrap-v5';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const ProfileYoutube = () => {


    const location = useLocation();
    const navigate = useNavigate();

    const [engagementPaid, setEngagementPaid] = useState([]);
    const [growth1, setGrowth1] = useState([]);

    const [viewsGrowth, setViewsGrowth] = useState([]);
    const [subscribersGrowth, setSubscribersGrowth] = useState([]);


    const [adMentions, setAdMentions] = useState([]);
    const [hashPer, setHashPer] = useState([]);
    const [paidAds, setPaidAds] = useState([]);
    const [chanMen, setChanMen] = useState([]);
    const [mentionzArray, setMentionzArray] = useState([]);
    const [mentionsData, setMentionsData] = useState();
    const [freque, setFreque] = useState()
    const [percentages, setPercentages] = useState([]);
    const [likesRcomments, setLikesRcomments] = useState("0");
    const [viewsRlikes, setViewsRlikes] = useState("0");

    useEffect(() => {


        ProfileDetails();
        // 2
        HashPercentage();
        // 3
        AdzMentions();
        ChannMention();
        // 4
        PaidPartner();
        // 5
        ChanGrowth();
        // 6
        FreQ();

        setTimeout(() => {
        }, 10000);
    }, 2000)


    const [actionValue, setActionValue] = useState("info");
    const [dbeeScore, setDbeeScore] = useState(35);
    const [profileDataY, setProfileDataY] = useState([]);

    // 1
    // YOUTUBE 
    const ProfileDetails = () => {
        // console.log("the end ",location.state.keyWord)
        axios.post(`${baseUrl2}/youtube/profile-details`, {
            name: localStorage.getItem("UserName")

        }).then((res) => {
            // console.log("the datttaa i wananat", res.data)
            setProfileDataY(res.data)


            const x = (res.data.avgVideoViews / res.data.avgLikes)

            setLikesRcomments(x)


            const y = (res.data.avgLikes / res.data.avgVideoComments)
            setViewsRlikes(y)

            // console.log("the datttaa i wananat", res.data.qualityScore)
            setDbeeScore((res.data.qualityScore * 100).toFixed(0))
        }).catch((err) => {
            console.log("the utube channel api error - ", err)
        })
    }


    // 2.2
    //hashtag-percentage 
    const HashPercentage = () => {
        axios.post(`${baseUrl2}/brand/hashtag-percentage`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {
            setHashPer(res.data)

        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }


    // 3.1
    // Ad Mentions and Mentions Per Day
    const AdzMentions = () => {
        axios.post(`${baseUrl2}/brand/ads`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {
            setAdMentions(res.data)

            const paidData = res.data.dailyAds;
            const paidArray = paidData.map(item => Math.round(parseFloat(item.count)));
            setPaidAds(paidArray);



            // console.log("ohh laah laa response : ", res.data)
            // console.log("ohh laah laa : ", paidData)
            // console.log("dennna  : ", res.data.dailyAds[0].count)
        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }
    // 3.2
    //channel mentions------------------------------------------
    const ChannMention = () => {
        axios.post(`${baseUrl2}/brand/channel-mentions`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {
            setChanMen(res.data)
            setMentionsData(res.data.dailyMentions)
            // console.log("DAILY MENTIONS DATA", res.data)
            calculateMentions(res.data.dailyMentions);

            // setTimeout(() => {
            // }, 1500);


            // console.log("The OH LAh LAahh -- >", res.data.dailyMentions)
        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }

    const calculateMentions = (arrayVal) => {
        const last7DaysMentions = [];
        const currentDate = new Date();
        // console.log(`Current date: ${currentDate.toISOString().split('T')[0]}`);

        for (let i = 0; i < 7; i++) {
            const dateToCheck = new Date(currentDate);
            dateToCheck.setDate(currentDate.getDate() - i);
            const dateString = dateToCheck.toISOString().split('T')[0];
            // console.log(`Checking data for date: ${dateString}`);

            const mention = arrayVal.find(m => m.date === dateString);
            if (mention) {
                console.log(`Data found for ${dateString}: ${mention.mentions}`);
            } else {
                // console.log(`No data found for ${dateString}, defaulting to 0.`);
            }
            last7DaysMentions.push(mention ? mention.mentions : 0);
        }

        console.log("Final calculated mentions array:", last7DaysMentions);
        setMentionzArray(last7DaysMentions);
    };

    // HIGHCHARTS -BAR CHARTS
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: [' Mon', 'Tue ', 'Wed ', 'Thur ', 'Fri ', 'Sat', 'Sun'],
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        series: [
            {
                name: 'Mentions',
                // data: [3, 6, 9, 12, 15, 12, 43], // Replace with your data for series A
                data: mentionzArray, // Replace with your data for series A
            },
            {
                name: 'Ad Mentions',
                // data: [1, 2, 3, 4, 6, 7], // Replace with your data for series B
                data: paidAds, // Replace with your data for series B
            },
        ],
    };


    // HIGHCHARTS -LINE CHARTS
    const options1 = {
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: 'Values'
            }
        },
        xAxis: {
            categories: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
        },
        series: [
            {
                name: 'Total Views',
                data: viewsGrowth,
                // data: [3, 6, 9, 12, 15, 12, 43],

            },
            {
                name: 'New Subscribers Gained',
                data: subscribersGrowth
                // data: [25, 9, 12, 1, 12, 33, 5]
            }
        ]
    };

    // ---------------------------------------------------------

    const FreQ = () => {
        axios.post(`${baseUrl2}/brand/frequency/${localStorage.getItem("channelId")}`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {

            // console.log("FRE-Q DATA", res.data)
            setFreque(res.data.postFrequencyPercentage)


            // console.log("The OH LAh LAahh -- >", res.data.dailyMentions)
        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }



    // 4 
    // Paid Partnership Engagement & Views 
    const PaidPartner = () => {
        axios.post(`${baseUrl2}/brand/engagementPaid`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {
            // console.log("The channel Id data -- >>", res.data)
            setEngagementPaid(res.data)
            // console.log("the paid part", res.data)

            // const paidData = res.data.dailyAds[0];
            // const paidArray = paidData.map(item => Math.round(parseFloat(item.paidAds)));
            // setPaidAds(paidArray);
        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }



    // 5
    ///Brand Growth
    const ChanGrowth = () => {

        axios.post(`${baseUrl2}/brand/growth`, {
            channelId: localStorage.getItem("channelId")
        }).then((res) => {
            setGrowth1(res.data)


            // console.log("The GROWGROW -- >>", res.data.channelStats.viewCount)
            // console.log("The GROWGROW -- >>", res.data.channelStats.subscriberCount)
            // console.log("The GROWGROW -- >>", res.data.dailyData)
            // setGrowth1(res.data)

            const dailyDData1 = res.data.dailyData;
            const dailyDataArray1 = dailyDData1.map(item => Math.round(parseFloat(item.viewsGrowth)));
            setViewsGrowth(dailyDataArray1);

            const dailyDData2 = res.data.dailyData;
            const dailyDataArray2 = dailyDData2.map(item => Math.round(parseFloat(item.subscribersGrowth)));
            setSubscribersGrowth(dailyDataArray2);

        }).catch((err) => {
            console.log("The channel Id data error - ", err)
        })
    }




    // EXTRAZZ 

    // 1-dreabee score ppie
    const dataaa = {
        labels: ["Dreabee Score : " + dbeeScore + "%"],
        datasets: [
            {
                data: [dbeeScore, 100 - dbeeScore],
                backgroundColor: ['#2D59F5', "gray"],
                borderWidth: 0.5,
            },
        ],
    };

    // 2- gender pie 
    const dataaaG = {
        labels: ["Male : 35%", "Female : 65%"],
        datasets: [
            {
                data: [35, 100 - 35],
                backgroundColor: ['#2D59F5', "#BCBCBC"],
                borderWidth: 0.5,
            },
        ],
    };

    const colors = ["success", "info", "warning", "danger", "primary", "secondary"]

    return (
        <>

            <div className='row bg- '>

                {/* Left NAvbar  */}
                <div className='col-2 '>
                    <div className='ms-2  pt-2 StickSide' style={{
                        height: "70vh",
                        position: "fixed",
                        top: "13rem",
                        // left: "9rem",
                        width: "17.5rem",
                        overflow: "scroll",
                        overflowX: "hidden",
                    }} >
                        {/* <div> */}

                        <ul className="nav-list" style={{ "listStyleType": "none" }}>

                            <li className={actionValue == "info" ? "act_btn  ms-0  d-flex align-items-center" : ' ms-0  d-flex align-items-center navHovTextNoBg'} onClick={() => { document.getElementById("Info").scrollIntoView({ behavior: "smooth" }); setActionValue("info") }} style={{ "marginTop": "1rem" }}>
                                <i class='bx bxs-shapes ' style={{ "fontWeight": "600", "fontSize": "26px", "color": "" }}></i> &nbsp;   &nbsp; <span className=" " style={{ "fontSize": "18px", "fontWeight": "600", "color": "" }}>Info</span>
                            </li>
                            {/* <li className={actionValue == "Insight" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 bg- d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Insight").scrollIntoView({ behavior: "smooth" }); setActionValue("Insight") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Insight</span>
                            </li>
                            <li className={actionValue == "Performance" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-layer' style={{ "fontWeight": "600", "fontSize": "26px" }}></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("Performance").scrollIntoView({ behavior: "smooth" }); setActionValue("Performance") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Performance</span>
                            </li>

                            <li className={actionValue == "Engagement" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Engagement").scrollIntoView({ behavior: "smooth" }); setActionValue("Engagement") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Engagement</span>
                            </li>
                            <li className={actionValue == "PaidEngagement" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("PaidEngagement").scrollIntoView({ behavior: "smooth" }); setActionValue("PaidEngagement") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Paid Engagement</span>
                            </li>
                            <li className={actionValue == "AudienceMetrics" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("AudienceMetrics").scrollIntoView({ behavior: "smooth" }); setActionValue("AudienceMetrics") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Audience Metrics</span>
                            </li>
                            <li className={actionValue == "Growth" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Growth").scrollIntoView({ behavior: "smooth" }); setActionValue("Growth") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Growth </span>
                            </li>
                            <li className={actionValue == "Brands" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("Brands").scrollIntoView({ behavior: "smooth" }); setActionValue("Brands") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Brands </span>
                            </li>
                            <li className={actionValue == "VisitInstagram" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("VisitInstagram").scrollIntoView({ behavior: "smooth" }); setActionValue("VisitInstagram") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Visit Instagram </span>
                            </li> */}

                        </ul>
                    </div>
                </div>


                {/* rIGHT section  */}
                <div className='col-10 bg- mt-5 '>
                    <div
                        className=" bg- ms-5 mb-2 ps-4"
                        style={{
                            marginBottom: "2rem",
                            border: "0px solid black",
                            height: "70vh",
                            position: "fixed",
                            top: "13rem",
                            // left: "24.7rem",
                            width: "71vw",
                            overflow: "scroll",
                            overflowX: "hidden",

                        }}>

                        {/* <div> */}

                        <div className="" style={{ height: "200vh", width: "100%" }}>
                            {/* SECTION 1  */}
                            <div className="row mt-2 p-2" id="Info">
                                {/* Card 0  */}
                                <div
                                    className=" mx-2 py-3 KardzProf"
                                    style={{
                                        backgroundColor: "white",
                                        width: "65rem ",
                                        height: "153px",
                                        borderRadius: "10px",
                                    }}>




                                    <div>
                                        <table className='InstaProfileTab1'>
                                            <tr>
                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Subscribers
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {(numeral(profileDataY?.usersCount).format('0.0a'))}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Total Video
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        566
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Likes
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {(numeral(profileDataY?.avgLikes).format('0.0a'))}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Comments
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {(numeral(profileDataY?.avgVideoComments).format('0.0a'))}

                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Views
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {(numeral(profileDataY?.avgVideoViews).format('0.0a'))}
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>




                                </div>






                            </div>

                            {/* SECTION 2  */}
                            {/* <div className="row my-2  py-2 " id="Insight">
                         
                                <div className="KardzProf mx-2" style={{
                                    width: "312px ",
                                    // width: "20rem ",
                                    height: "409px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg py-4  h-100 '>
                                        <span className='mx-2' style={{ "fontSize": "24px", fontWeight: "600" }}>Dreabee Score</span>
                                        <section
                                            src={GRaph}
                                            alt="img"
                                            className=" mt-4 pe-2 ">

                                            <section style={{ height: "300px", width: "300px" }}>
                                                <Doughnut data={dataaa} />
                                            </section>
                                        </section>
                                    </section>

                                </div>

                                <div className="KardzProf mx-2" style={{
                                    width: "713px ",
                                    height: "409px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>

                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "24px", fontWeight: "600" }}>Insights</span>


                                        <div className="row">

                                            {
                                                hashPer.slice(0, 8).map((data, index) => (
                                                    <div className="col-lg-6 ">
                                                        <div className='row px-2 my-3 py-2 bg- '>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >  {data.hashtag}</div>
                                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>{data.percentage} %</div>
                                           

                                                            <div className='col-12 text-end mt-1 '>
                                                                <ProgressBar variant="success" now={data.percentage} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </section>
                                </div>
                            </div> */}

                            {/* SECTION 3 --- Performance */}
                            {/* <div className="row my-2 p-2" id="Performance">
                                <div className="KardzProf mx-2" style={{
                                    width: "1027px ",
                                    minHeight: "600px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Content Performance & Benchmark</span>

                                        <div className='row my-3 ' >
                               
                                            <div className='col-lg'>
                                                <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E" }}> Mentions per day </h4>
                                                <h4 style={{ "fontSize": "26px", "fontWeight": "600" }}>
                                                    {(chanMen?.totalMentionsPerDay)}
                                                </h4>

                                            </div>
                                           
                                            <div className='col-lg ps-4' style={{ borderLeft: "1px solid black" }}>
                                                <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E" }}>Ads per day </h4>
                                                <h4 style={{ "fontSize": "26px", "fontWeight": "600" }}> {adMentions.totalAdsPerDay} </h4>

                                            </div>
                                        
                                            <div className='col-lg '></div>
                                          
                                            <div className='col-lg-4 pt-1'>
                                                <section className='d-flex justify-content-start'>
                                                    <span className=' bg-success mt-1' style={{ "borderRadius": "50%", "height": "14px", width: "14px" }} ></span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E", "lineHeight": "25px" }}>Mentions</h4>
                                                </section>
                                                <section className='d-flex  justify-content-start'>
                                                    <span className=' mt-1' style={{ "borderRadius": "50%", "height": "14px", width: "14px", background: "#2D59F5" }} ></span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E", "lineHeight": "25px" }}>Ad Mentions</h4>
                                                </section>

                                            </div>


                                        </div>


                                        <div className='row my-4'>
                                            <div style={{ "height": "50px", width: "1000px" }}>


                                                <HighchartsReact highcharts={Highcharts} options={options} />


                                            </div>
                                        </div>


                                    </section>


                                </div>
                            </div> */}

                            {/* SECTION 4  Overall Engagement & Views */}
                            {/* <div className="row mt-2 p-2 pb-1" id="Engagement">
                                <div className="KardzProf mx-2" style={{
                                    width: "1027px ",
                                    height: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 pt-3 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Overall Engagement & Views</span>

                                      
                                        <div className='w-100 row bg- py-2 mt-3' >

                                            <div className='col-lg-2 bg- text-center'>
                                                <img width="30" height="30" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" id="icon3" alt="youtube-play" />
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Videos</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Subscribers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>

                                                            {(numeral(profileDataY?.usersCount).format('0.0a'))}

                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Total Video</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>

                                                        
                                                            266
                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>

                                                            {(numeral(profileDataY?.avgLikes).format('0.0a'))}

                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>

                                                            {(numeral(profileDataY?.avgVideoComments).format('0.0a'))}
                                                        </h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Views</h4>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>

                                                    {(numeral(profileDataY?.avgVideoViews).format('0.0a'))}

                                                </h4>

                                            </div>
                                        </div>



                                    </section>


                                </div>

                            </div> */}


                            {/* SECTION 5  Likes to comments Ratio   /  Reels View to Like Ratio  /  Post Frequency */}
                            <div className="row p-2 pt-1 mb-2   " id="">


                                <div className="KardzProf ms-2 my-2" style={{ width: "508px ", height: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Likes to comments Ratio</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>

                                                {(numeral(likesRcomments).format('0.0a'))}%
                                                {/* {likesRcomments} % */}
                                                {/* {likesRcomments.toFixed(2)} % */}

                                            </div>

                                            <div className='col-12 text-end mt-1 '>
                                                <ProgressBar variant="success" now={likesRcomments} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>
                                            {/* <input type='range' className='mt-1' /> */}
                                        </div>
                                    </section>
                                </div>



                                <div className="KardzProf mx-2 my-2" style={{ width: "508px ", height: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Video View to Like Ratio</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>

                                                {(numeral(viewsRlikes).format('0.0a'))} %

                                                {/* {viewsRlikes} % */}
                                                {/* {viewsRlikes.toFixed(2)} % */}
                                            </div>

                                            <div className='col-12 text-end mt-1 '>
                                                <ProgressBar variant="warning" now={viewsRlikes} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>
                                        </div>
                                    </section>
                                </div>



                                <div className="KardzProf mx-2 my-2" style={{ width: "508px ", minHeight: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Post Frequency</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>
                                                {/* {freque} */}
                                                52 %

                                            </div>
                                            <div className='col-12 text-end mt-1 '>
                                                <ProgressBar variant="warning" now={52} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>
                                        </div>
                                    </section>
                                </div>



                            </div>


                            {/* SECTION 6  Paid Partnership Engagement Vies */}
                            {/* <div className="row p-2 pt-1 my-2" id="PaidEngagement">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    height: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Paid Partnership Engagement & Views</span>

                                      
                                        <div className='w-100 row bg- py-2 mt-2' >

                                            <div className='col-lg-2 bg- text-center'>
                                                <img width="30" height="30" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" id="icon3" alt="youtube-play" />
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Videos</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Subscribers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>


                                                            {(numeral(engagementPaid?.subscribers).format('0.0a'))}

                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Total Video</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.totalVideos}
                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.avgLikes}
                                                        </h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.avgComments}
                                                        </h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Views</h4>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                    {engagementPaid?.avgViews}
                                                </h4>

                                            </div>
                                        </div>



                                    </section>






                                </div>
                            </div> */}


                            {/* SECTION 7  Audience Engagement */}
                            {/* <div className="row p-2 pt-1 my-2" id="AudienceMetrics">
                                <div className="KardzProf ms-2 py-au" style={{
                                    width: "1027px ",
                                    height: "307px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>

                                    <section className='bg- p-4 h-100'>
                                        <h4 style={{ "fontSize": "18px", fontWeight: "600" }}>Audience Engagement</h4>
                                        <div className='w-100 row bg-ary mx' >
                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>


                                                    <div className='col-lg-6 bg-ndary '>



                                                        <div className='row my-4  px-2 '>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top City </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Mumbai</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>

                                                            <div className='col-12 text-end mt-1 '>
                                                                <ProgressBar variant="success" now={likesRcomments} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>


                                                        <div className='row my-5 mb-4 px-2 '>

                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top State </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Maharashtra</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>

                                                            <div className='col-12 text-end mt-1 '>
                                                                <ProgressBar variant="info" now={likesRcomments} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>


                                                    </div>



                                                    <div className='col-lg-6  bg-ing '>

                                                        <div className='row my-4 px-2'>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top Country </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >India</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>

                                                            <div className='col-12 text-end mt-1 '>
                                                                <ProgressBar variant="primary" now={likesRcomments} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>

                                                        </div>

                                                        <div className='row my-5 mb-4 px-2'>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top Age Group </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >20-25</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>

                                                            <div className='col-12 text-end mt-1 '>
                                                                <ProgressBar variant="warning" now={likesRcomments} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </section>

                                            </div>
                                            <div className='col-lg-4   '>
                                                <section className='row d-flex justify-content-center'>

                                                    <section className='col-lg-12 ' style={{ height: "200px", width: "200px" }}>
                                                        <Doughnut data={dataaaG} />


                                                        <section className='text-center  col-lg-12' style={{ fontWeight: "500", "fontSize": "16px" }}>
                                                            Genders
                                                        </section>
                                                    </section>



                                                </section>
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div> */}



                            {/* SECTION 8  Audience Geography */}
                            {/* <div className="row p-2 pt-1 my-2" >
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    height: "313px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Audience Geography </span>




                                        <div className='w-100 row bg-ary mx' >

                                            <div className='col-lg-6 bg-'>
                                                <section className='row ' >



                                                    <div className='col-lg-12  py-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>United States (USA)</div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}> 41.25 %</div>
                                                        </section>
                                                        <ProgressBar variant="primary" now={viewsRlikes} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                    </div>




                                                    <div className='col-lg-12 py-2  pb-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>United States (USA)</div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}> 41.25 %</div>
                                                        </section>

                                                        <ProgressBar variant="warning" now={viewsRlikes} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />


                                                    </div>


                                                    <div className='col-lg-12 py-2 pb-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>United States (USA)</div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}> 41.25 %</div>
                                                        </section>

                                                        <ProgressBar variant="success" now={viewsRlikes} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                    </div>



                                                    <div className='col-lg-12 py-3' >

                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>United States (USA)</div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}> 41.25 %</div>
                                                        </section>

                                                        <ProgressBar variant="info" now={viewsRlikes} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />

                                                    </div>


                                                </section>



                                            </div>




                                            <div className='col-lg-6 text-center'>
                                                <img src={require('../../../../Imagez/Dashboard/Cards/WMap.png')} width={400} />
                                            </div>
                                        </div>



                                    </section>

                                </div>
                            </div> */}




                            {/* SECTION Location based on country --------  Location based on city`  */}
                            {/* <div className="row p-2 pt-1 my-2" id="NotableFollowers">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    minHeight: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <div className=' my-3' style={{ "fontSize": "18px", fontWeight: "600", paddingLeft: "15px" }}>  Audience Engagement    </div>

                                    <section className='bg-  mb-2  row  rounded pe-3' >

                                  
                                        <div className='col-lg-8 col-md-12'>
                                            <section className='mx-2 px-3 py-2 w-100 h-100 rounded border'>
                                             
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Age</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Last 28 days(delayed day) Views
                                                    </span>
                                                </span>

                                              
                                                <span>

                                                </span>


                                            </section>
                                        </div>
                                       
                                        <div className='col-lg-4 col-md-12 rounded py-2 border'>


                                            <section className='mx-2 '>
                                                
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Audience Details</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Followers Types
                                                    </span>
                                                </span>

                                                <section className='row'>

                                                    
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                  
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </section>


                                        </div>

                                    </section>


                                   
                                    <section className='bg-  my-2 row  rounded px-3' >
                                     
                                        <div className='col-lg-4 col-md-12 rounded py-2 border'>


                                            <section className='mx-2 '>
                                                
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Audience Details</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Followers Types
                                                    </span>
                                                </span>

                                                <section className='row'>

                                                    
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                   
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                  
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                   
                                                    <div className='col-lg-12 col-md-12 h-100  rounded border my-2'>
                                                        <div className='row  h-100 '>
                                                            <div className='col-2  py-3 h-100'>
                                                                <img width="35" height="35" style={{ borderRadius: "50%", backgroundColor: "#E2FBD7" }} className='p-2' src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />


                                                            </div>
                                                            <div className='col-10  h-100 py-3'>
                                                                <div className='row bg'>
                                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Real Followers</div>
                                                                    <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>70%</div>
                                                                    <div className='col-12 text-end mt-1 '>
                                                                        <ProgressBar variant="success" now={70} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>



                                                </section>



                                                <span>

                                                </span>
                                            </section>


                                        </div>
                                      
                                        <div className='col-lg-8 col-md-12'>
                                            <section className='mx-2 px-3 py-2 w-100 h-100 rounded border'>
                                           
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Age</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Last 28 days(delayed day) Views
                                                    </span>
                                                </span>

                                              
                                                <span>

                                                </span>


                                            </section>
                                        </div>
                                    </section>






                                    <section className='bg- m-1 my-2 row border rounded  p-2' >
                                       
                                        <div className='col-lg-6 col-md-12  py-2' style={{ borderRight: "2px solid #E7E7E7" }}>
                                            <section className='mx-2 '>
                                               
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Location based on country</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Last 28 days(delayed 1day) watch time (minutes)
                                                    </span>
                                                </span>

                                               
                                                <span>
                                                   
                                                    <div className='row mt-2 mb-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >India</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>15%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="info" now={15} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Germany</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="danger" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Italy</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>13%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="success" now={13} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Zambia</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="warning" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >India</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>15%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="info" now={15} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Germany</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="danger" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>




                                                </span>
                                            </section>
                                        </div>

                                       
                                        <div className='col-lg-6 col-md-12 py-2'>
                                            <section className='mx-2 px-3 '>
                                               
                                                <span>
                                                    <span style={{ "fontSize": "15px", fontWeight: "600" }}>Location based on city</span>
                                                    <span className='d-flex align-items-center ' style={{ "fontSize": "12px ", fontWeight: "500" }}>
                                                        Last 28 days(delayed 1day) watch time (minutes)
                                                    </span>
                                                </span>

                                                <span className=''>
                                                  
                                                    <div className='row mt-2 mb-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Chennai</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>15%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="info" now={15} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                  
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Patna</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="danger" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Indore</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>13%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="success" now={13} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Pune</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="warning" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Jaipur</div>
                                                        <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>15%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="info" now={15} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className='row my-4 mb-4 px-1 '>
                                                        <div className='col-8 d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "14px" }} >Kochi</div>
                                                        <div className='col-4 text-end  d-flex align-items-end justify-content-end' style={{ fontWeight: "400", fontSize: "14px" }}>46%</div>
                                                        <div className='col-12 text-end mt-1 '>
                                                            <ProgressBar variant="danger" now={46} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                        </div>
                                                    </div>




                                                </span>
                                            </section>
                                        </div>
                                    </section>

                                </div>
                            </div> */}



                            {/* SECTION Notable followers  */}
                            {/* <div className="row p-2 pt-1 my-2" id="NotableFollowers">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    minHeight: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- p-4 h-100'>
                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Notable Followers</span>
                                      
                                        <div className='w-100 row bg- py-2 mt-2' >


                                            <div className='col-lg-12 '>
                                                <section className='row px-2 d-flex align-items-center mb-2'>
                                                    <div className='col-lg-4 text-start p-1 d-flex align-items-center'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="15" height="15" src="https://img.icons8.com/puffy/64/star.png" alt="star" />
                                                            &nbsp;
                                                            INFLUENCER
                                                        </span>

                                                    </div>
                                                    <div className='col-lg-4 text-start p-1 d-flex align-items-center '>
                                                        <span className='d-fle align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="22" height="22" src="https://img.icons8.com/sf-ultralight/25/youtube-play.png" alt="youtube-play" />
                                                            &nbsp; USERNAME
                                                        </span>

                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.totalVideos}
                                                        </h4>--commment----

                                                    </div>
                                                    <div className='col-lg-4 text-start p-1 d-flex align-items-center'>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="16" height="16" src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />
                                                            &nbsp; FOLLOWERS
                                                        </span>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.avgLikes}
                                                        </h4>--commment----

                                                    </div>
                                                </section>


                                                <section className='row px-2 '>
                                                
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>
                                                <section className='row px-2 '>
                                                
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>
                                                <section className='row px-2 '>
                                                
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>
                                                <section className='row px-2 '>
                                                
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>
                                                <section className='row px-2 '>
                                                
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>




                                            </div>


                                        </div>
                                    </section>






                                </div>
                            </div> */}

                            {/* SECTION Creators look alike */}
                            {/* <div className="row p-2 pt-1 my-2" id="CreatorsLookAlike ">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    minHeight: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- p-4 h-100'>
                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Creators Look alike </span>
                                      
                                        <div className='w-100 row bg- py-2 mt-2' >
                                            <div className='col-lg-12 '>
                                               
                                                <section className='row px-2 d-flex align-items-center mb-2'>
                                                    <div className='col-lg-4 text-start p-1 d-flex align-items-center'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="15" height="15" src="https://img.icons8.com/puffy/64/star.png" alt="star" />
                                                            &nbsp;
                                                            INFLUENCER
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4  p-1 d-flex align-items-center justify-content-start '>
                                                        <span className='d-fle align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="22" height="22" src="https://img.icons8.com/sf-ultralight/25/youtube-play.png" alt="youtube-play" />
                                                            &nbsp; USERNAME
                                                        </span>

                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.totalVideos}
                                                        </h4>-----------------commment------

                                                    </div>

                                                    <div className='col-lg-4  p-1 d-flex align-items-center justify-content-start'>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="16" height="16" src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />
                                                            &nbsp; FOLLOWERS
                                                        </span>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.avgLikes}
                                                        </h4> --commment----

                                                    </div>
                                                </section>

                                            
                                                <section className='row px-2 '>
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>

                                                <span>
                                                    <section className='row px-2 '>
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                       
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                               
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                       
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                       
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                               
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                       
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                               
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                </span>

                                            </div>
                                        </div>
                                    </section>






                                </div>
                            </div> */}


                            {/* SECTION Followers lookalike  */}
                            {/* <div className="row p-2 pt-1 my-2" id="FollowersLookalike">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    minHeight: "148px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- p-4 h-100'>
                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Followers lookalike</span>
                                     
                                        <div className='w-100 row bg- py-2 mt-2' >
                                            <div className='col-lg-12 '>
                                               
                                                <section className='row px-2 d-flex align-items-center mb-2'>
                                                    <div className='col-lg-4 text-start p-1 d-flex align-items-center'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="15" height="15" src="https://img.icons8.com/puffy/64/star.png" alt="star" />
                                                            &nbsp;
                                                            INFLUENCER
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4  p-1 d-flex align-items-center justify-content-start '>
                                                        <span className='d-fle align-items-center ' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="22" height="22" src="https://img.icons8.com/sf-ultralight/25/youtube-play.png" alt="youtube-play" />
                                                            &nbsp; USERNAME
                                                        </span>

                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.totalVideos}
                                                        </h4>----------------commment -------------

                                                    </div>

                                                    <div className='col-lg-4  p-1 d-flex align-items-center justify-content-start'>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "16px ", fontWeight: "500" }}>
                                                            <img width="16" height="16" src="https://img.icons8.com/pastel-glyph/64/user-male-circle.png" alt="user-male-circle" />
                                                            &nbsp; FOLLOWERS
                                                        </span>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>
                                                            {engagementPaid?.avgLikes}
                                                        </h4>----------------comment-----------------------

                                                    </div>
                                                </section>

                                               
                                                <section className='row px-2 '>
                                                    <div className='col-lg-4 text-start px-1'>
                                                        <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                            </SkeletonTheme>
                                                            &nbsp;
                                                            priyakachopra
                                                        </span>
                                                    </div>
                                                    <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                        <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                            @priyakachopra
                                                        </span>



                                                    </div>
                                                    <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                        <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                            90.27 M
                                                        </span>
                                                    </div>
                                                </section>

                                                <span>
                                                    <section className='row px-2 '>
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                    <section className='row px-2 '>
                                                        <div className='col-lg-4 text-start px-1'>
                                                            <span className='d-flex align-items-center ' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                                                                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={25} height={25} />
                                                                </SkeletonTheme>
                                                                &nbsp;
                                                                priyakachopra
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-4 text-start px-1 d-flex align-items-center justify-content-start'>
                                                            <span className='' style={{ "fontSize": "15px ", fontWeight: "500", color: "#5B9DFF" }}>
                                                                @priyakachopra
                                                            </span>



                                                        </div>
                                                        <div className='col-lg-4 text-start px-2 d-flex align-items-center justify-content-start '>
                                                            <span className='d-flex align-items-center' style={{ "fontSize": "15px ", fontWeight: "500" }}>
                                                                90.27 M
                                                            </span>
                                                        </div>
                                                    </section>
                                                </span>

                                            </div>
                                        </div>
                                    </section>






                                </div>
                            </div> */}





                            {/* SECTION BRANDS MENTION  */}
                            {/* Mention dynamics on YouTube */}
                            {/* <div className='monthly_report_section row  ms-2 Kardz my-4 py-5' id="Growth" style={{
                                width: "1027px",
                                height: "470px",
                                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"
                            }}>
                                <div className='col-lg-4'>
                                    <label className='Xplore_Card_Topic'>Growth In Last 30 Days</label> &nbsp;
                                    <i class='bx bx-info-circle' style={{ "position": "relative", "top": " 3px" }}></i>
                                    <div>

                                        <span className='d-flex flex-row align-items-center '>
                                            <section className='Xplore_rectab' />
                                            <section className='d-flex flex-column ms-3' style={{ "borderLeft": "" }}>
                                                <label className='three' style={{ "position": "relative", top: "32px" }}><p>Total Reach</p></label>
                                                <label className='two' style={{ "position": "relative", top: "18px", "fontSize": "2.375rem" }}><p>

                                                    {growth1?.channelStats?.viewCount}

                                                </p></label> &nbsp;
                                            </section>
                                        </span>
                                        <span className='d-flex flex-row align-items-center '>
                                            <section className='Xplore_rectag' />
                                            <section className='d-flex flex-column ms-3' style={{ "borderLeft": "" }}>
                                                <label className='three' style={{ "position": "relative", top: "30px" }}><p>New Followers Gained</p></label>
                                                <label className='two' style={{ "position": "relative", top: "20px" }}><p style={{ "fontSize": "2.375rem" }}>

                                                    {growth1?.channelStats?.subscriberCount}
                                                </p></label> &nbsp;
                                            </section>
                                        </span>





                                    </div>
                                </div>

                                <div className='col-lg-8'>
                                    <img src={require('../../../../Imagez/Dashboard/All/chart.png')} className='my-5 me-4' width={650} /> ----------comment-----------
                                    <div style={{ "height": "250px", width: "650px" }}>
                                        <HighchartsReact Highcharts={Highcharts} options={options1} />
                                    </div>
                                </div>
                            </div> */}


                            {/* SECTION 9   */}
                            {/* <div className="row my-2 p-2" id="Brands">
                                Card 1 -------------comment--------------
                                <div
                                    className="KardzProf mx-2"
                                    style={{
                                        width: "1027px ",
                                        height: "30rem",
                                        borderRadius: "10px",
                                        background: "white",
                                    }}
                                >
                                    ABOVE ROW --------comment-----------
                                    <div className="row bg- mt-5">
                                        <div className="col-12 bg-">
                                            <i
                                                className="bx bx-star"
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "26px",
                                                    marginLeft: "2rem",
                                                }}
                                            ></i>{" "}
                                            &nbsp;{" "}
                                            <span
                                                className=""
                                                style={{ fontWeight: "700", fontSize: "24px" }}
                                            >
                                                Brands
                                            </span>
                                            <i class='bx bxl-instagram-alt' style={{ "fontSize": "28px", "fontWeight": "600" }} ></i>
                                            <span style={{ "fontWeight": "700", "fontSize": "24px" }}>Content</span> -----------omment---------------------------------------------------------
                                        </div>
                                    </div>
                                    BOTTOM ROW ---------coment------------
                                    <div className="row bg- mt-4">
                                        <div className="col d-flex">
                                            <span
                                                style={{
                                                    paddingLeft: "1rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#C83B93",
                                                }}
                                            >
                                                All
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Automobile
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Device
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Telecommunication
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Watches And Eyewear
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Non-Alcoholic Beverages
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                E-Commerce
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                FMCG
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Applications
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div
                                                className="bg  d-flex justify-content-evenly p-1 py-3 mt-5"
                                                style={{ width: "100%", backgroundColor: "#F8F5FF" }}
                                            >
                                                <section
                                                    className="bg text-center  ms-2 p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}



                            {/* SECTION 4  Overall Engagement & Views */}
                            {/* <div className="row mt-2 p-2 " id="VisitInstagram">
                                <div className="KardzProf mx-2 Faqz " style={{
                                    width: "1027px ",
                                    height: "100px",
                                    borderRadius: "10px",
                                    background: ""
                                }}>
                                    <section className='bg- p-4 h-100 text-center '>
                                        <span className='bg- d-flex align-items-center justify-content-center  ' style={{ "fontSize": "30px", fontWeight: "700" }}>Visit YouTube  &nbsp;
                                            <img width="40" height="40" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" id="icon3" alt="youtube-play" />
                                        </span>
                                    </section>

                                </div>

                            </div> */}


                            {/* <div className=''>.</div> */}
                        </div>

                    </div>
                </div>
            </div>



        </>
    )
}

export default ProfileYoutube