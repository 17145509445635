import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { baseUrl } from '../../Routez/Routey';
const InfluencersLogInForm = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errow, setErrow] = useState(false);
    const [ming, setMing] = useState("");



    const glogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse)

            window.localStorage.setItem("isLoggedIn", true);
            window.localStorage.setItem("Gmail-Auth", tokenResponse.access_token)
            navigate("/Dreabee/Dashboard");

            // swal("You are successfully Logged In", "👍", "success");

        }
    })

    const [profVal, setProfVal] = useState("");

    // const [handled, setHandled] = useState();

    const ProfileNow = (handled) => {
        // console.log("the handled ", handled)
        // console.log("this is the value of the proofile username --->>>", handled)

        // navigate("/Dreabee/Dashboard");
        // ProfileNow((data.url).split("@")[1])

        let data = { email, password };
        axios.post(`${baseUrl}api/Influencer/influencerLogin`, {

            email: data.email,
            password: data.password

        }).then((res) => {
            // console.log("login response - ", res.data.Instagram_detail)
            console.log("login response - ", res.data.Instagram_detail[0].Instagram_username)
            window.localStorage.setItem("isLoggedIn", true);

            window.localStorage.setItem("Access-Id", res.data.Id)
            localStorage.setItem("UserName", res.data.Instagram_detail[0].Instagram_username);

            navigate(`/Dreabee/Profile`, { state: { userIn: "influencer" } })
            swal("You are successfully Logged In", "👍", "success");

            
            // navigate(`/Dreabee/Dashboard/Profile/Summary/${"@" + handled}`, { state: { keyWord: res.data.Instagram_detail[0].Instagram_username } })
            // /${res.data.Instagram_detail[0].Instagram_username}`, { state: { userIn: "influencer" } })

        }).catch((error) => {
            console.log("error", error);
            swal("Oops!", "Maybe a wrong email or password! Try Again.", "error");
        })

    }

    const jump = () => {
        navigate("/Creators_LandingPage")
    }
    const go2 = () => {
        navigate("/LogInAgency");
    }
    const go3 = () => {
        navigate("/LogInInfluencer");
    }
    const go1 = () => {
        navigate("/LogIn");
    }








    useEffect(() => {
        window.localStorage.setItem("userIn", "influencer");
        setMing(localStorage.getItem("UserName"))
        // console.log("the one any oiny : ", localStorage.getItem("UserName"))

        setTimeout(() => {
            // ProfileDetailsI();
            // console.log("the ratatatat descroiption", profileDataI?.description)
            console.log("In-System : ", ming)
        }, 4000);
    }, []);






    const AcessTok = (tokhen) => {

        axios.get('https://graph.facebook.com/v14.0/me/accounts', {
            params: {
                fields: 'id,name,access_token',
                access_token: tokhen
            }
        }).then((response) => {
            console.log("GRAPH API 1 RESPONSE ::-->", response);
            console.log("THE RES_PON_SE :", response.data.data[1].access_token);
            setTimeout(() => {
                console.log("fine")
                GraphApi2(response.data.data[1].id, response.data.data[1].access_token)
            }, 1000);

        }).catch((error) => {
            console.log("the Access Token error - ", error)
        });

    }



    const [tokkhen, setTokkhen] = useState("EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC");

    const GraphApi2 = (id) => {
        console.log("ID HERE :", id)
        // console.log("ID HERE :",id)
        axios.get(`https://graph.facebook.com/v18.0/${id}?fields=instagram_business_account&access_token=${tokkhen}`)
            .then((response) => {
                console.log("GRAPH API 2 RESPONSE :: --> ", response.data.instagram_business_account.id);

                window.localStorage.setItem("isLoggedIn", true);
                window.localStorage.setItem("AccessTokken", tokkhen);
                window.localStorage.setItem("TheGraphId", response.data.instagram_business_account.id);
                localStorage.setItem("UserName", "billy");
                // window.localStorage.setItem("Access-Id", res.data.Id)

                setTimeout(() => {
                    if (response.data.instagram_business_account.id) {
                        navigate(`/Dreabee/Profile`)
                    } else {
                        swal("Oops!", "Something went wrong.Try again", "info");

                    }
                }, 1000);
            })
            .catch((error) => {
                console.log("Graph Api - 2 - error - ", error)
                swal("Oops!", "Something went wrong.", "error");
            });
    }


    return (
        <>
            <section className='row bg-ifo'>


                <section className='text-start bg-inf'>



                    <h5 className='sub'>Login As</h5>

                    <div className='row'>

                        <div className='col'>
                            <button onClick={go1} className='batton2 col-md p-2' style={{ "fontSize": "" }}>Brands</button>
                        </div>


                        <div className='col'>
                            <button onClick={go2} className='batton2 col-md '>Agency</button>
                        </div>

                        <div className='col'>
                            <button onClick={go3} className=' activeButton col-md '>Influencers</button>
                        </div>
                        <div className='col-12'>
                            <FacebookLogin
                                appId="196789807939487"
                                onSuccess={(response) => {
                                    // convertaccesstoken(response.accessToken)
                                    console.log("The accesstoken I Got :", response.accessToken)
                                    AcessTok(response.accessToken)


                                }}
                                onFail={(error) => {
                                    console.log('Login Failed!', error);
                                }}
                                onProfileSuccess={(response) => {
                                    console.log('Get Profile Success!', response);

                                }}

                                render={({ onClick, logout }) => (
                                    <button className=' batton2 mt-3 w-100 d-flex align-items-center justify-content-center'
                                        onClick={onClick} onLogoutClick={logout}>
                                        <img src={require('../../Imagez/Common/fblogo.png')} width={30} />

                                    </button>
                                )}
                            />
                        </div>





                        {/* <div className='col-11 text-center'>
                            <button id='google' onClick={() => { glogin() }} className='batton2 col-md mt-3' style={{ width: "100%" }}>   <img src={require('../../Imagez/Common/Gologo.png')} width={30} /></button>
                        </div> */}
                    </div>




                </section>




                <section className='text-start'>
                    <h5 className='sub mt-4'>Business Email</h5>
                    <input type='email' value={email} name='Email' onChange={(e) => { setEmail(e.target.value) }} placeholder='example@examplemail.com' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                    {errow && email.length <= 0 ?
                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px", "marginTop": "2px" }}>Email can not be empty. &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                        : ""
                    }

                    <h5 className='sub mt-3'>Password</h5>
                    <input type='email' value={password} name='password' onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                    {errow && password.length <= 0 ?
                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px", "marginTop": "2px" }}>Password cannot be empty. &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                        : ""
                    }
                    {/* <span style={{"position":"relative" ,"left":"-8%","color":"aqua","fontWeight":"600"}}>OTP</span> */}

                    <br />
                    <input className="ms-1 " type="checkbox" checked value="" id="" /><label className='rme ps-2'>Remember Me</label>
                    <br />
                    {/* <label className='rme ' style={{"marginLeft":"4.5rem"}}>Didn't recieved OTP , <span style={{"color":"aqua","fontWeight":"600"}}>Send Again .</span></label> */}

                    <button className='bigB col-md mt-4'


                        onClick={() => {
                            ProfileNow(("@miridawn").split("@")[1]);
                            setProfVal(("@miridawn").split("@")[1]);
                        }}


                    > <span className='bigBt'>LOG IN</span></button><br />
                    <label className='rme ps-2'>Didn't Registered yet ? <span onClick={jump} style={{ "color": "aqua", "cursor": "pointer" }}>JOIN NOW</span></label>




                </section>
            </section>
        </>
    )
}

export default InfluencersLogInForm