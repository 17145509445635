import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Brand_explore from './Brand_expore';
import Video_analysis from './Video_analysis';
import BrandsInfluencer from './BrandsInfluencer';
import BrandVideoCompResearch from './BrandVideoCompResearch';

const BrandCompetitor = () => {
    const [active, setActive] = useState('Videos')
    const [platform, setPlatform] = useState('Utube')
    const navigate = useNavigate();
    return (
        <div className="pt-1">

            <section>


                <div className='row  d-flex align-items-center  py-3 px-3'>
                    <div className='px-3 py-0 h-100  d-flex align-items-center col-lg-6'>
                        <h3>
                            <b>  Competitor Research</b>
                        </h3>
                    </div>

                    <div className='col-lg-6 '>
                        {/* <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ objectFit: 'cover', width: '44vw' }} /> */}

                        <div className='d-flex d-flex justify-content-end '>

                            <button onClick={() => { setPlatform("Utube") }} className={platform == "Utube" ? 'border-info Hovly btn align-items-center d-flex  mx-4' : 'Hovly btn align-items-center d-flex border mx-4'}>
                                {/* <img className='mx-1 ' style={{ "cursor": "pointer" }} width={25} src={require('../../../../Imagez/Dashboard/All/simple-line-icons_social-youtube.png')} /> */}
                                <img width="25" height="25" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" alt="youtube-play"/>
                      
                                &nbsp;
                                <b>
                                    YouTube
                                </b>
                                &nbsp;
                                <span>2.3M</span>
                            </button>


                            <button onClick={() => { setPlatform("Insta") }} className={platform == "Insta" ? 'Hovly border-info btn align-items-center d-flex  mx-4' : 'Hovly btn align-items-center d-flex border mx-4'}>
                                <img className='mx-1  ' style={{ "cursor": "pointer" }} src={require('../../../../Imagez/Dashboard/All/Insta.png')} width={20} />
                                &nbsp;
                                <b>
                                    Instagram
                                </b>
                                &nbsp;
                                <span>10.7M</span>
                            </button>




                        </div>




                        {/* <span className=' bg p-3 Hovly' style={{ " border": "1px solid red", "borderRadius": "20px", "cursor": "pointer", height: "2rem", width: "15rem" }}>
                            <img className='mx-1  ' style={{ "cursor": "pointer" }} src={require('../../../../Imagez/Dashboard/All/Insta.png')} width={10} />

                            <label className='mx-1 pt-3'><b>Instagram</b></label>
                            <span>10.7M</span>
                        </span>

                        <span className='me-2  bg p-3 Hovly' style={{ " border": "1px solid red", "borderRadius": "20px", "cursor": "pointer" }}>
                            <img className='mx-1 ' style={{ "cursor": "pointer" }} src={require('../../../../Imagez/Dashboard/All/simple-line-icons_social-youtube.png')} />
                            <label className='mx-1 pt-3'>
                                <b  > Youtube</b>
                            </label>
                            <span   >2.58M</span>
                        </span> */}



                    </div>
                </div>

            </section>





            <section>
                {/* 3 Buttons for the page routes  */}
                <div className='mt-1'>

                    <button onClick={() => { setActive('Videos') }} className={active == 'Videos' ? 'tabs_btn brand_active_tab' : 'tabs_btn'}>
                        Videos
                    </button>

                    <button onClick={() => { setActive('Influencers') }} className={active == 'Influencers' ? 'tabs_btn brand_active_tab' : 'tabs_btn'}>
                        Influencers
                    </button>
                </div>

                {/* <Explore / Videos /Influencers /> */}
                <div>
                    {
                        active == "Videos"
                            ?
                            
                            <BrandVideoCompResearch/>
                            :
                            <BrandsInfluencer/>
                    }
                </div>
            </section>
        </div>
    )
}

export default BrandCompetitor