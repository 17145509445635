import React from 'react'
import { useNavigate } from 'react-router-dom'

import CommFoot from '../CommonComponents/CommFoot'
import FlowerPhoto1 from './LandinPg_Sub/FlowerPhoto1'
import FlowerPhoto2 from './LandinPg_Sub/FlowerPhoto2'


const LandinFoot = () => {
    const navigate = useNavigate();
    const InflueReg = () => {
        navigate("/Phone_Verification_Influencers")
    }



    return (
        <>
            {/* <div className='w-100' style={{ "minHeight": "80rem", "backgroundColor": "black", "zIndex": "-5", "overflow": "hidden" }}> */}
            <section className=' bgFooter' style={{ height: "85vh" }} >
                <div className='row  h-100 d-flex align-items-center'>


                    <div className='col-6'>
                        <FlowerPhoto1 />
                    </div>


                    <div className='col-6  '>
                        <FlowerPhoto2 />
                    </div>
                </div>



            </section>
            <CommFoot />

            {/* </div> */}

        </>
    )
}

export default LandinFoot