import React, { useState } from 'react'
import '../../../../../node_modules/react-date-picker/dist/DatePicker.css'
import InputRange from 'react-input-range'

const BrandsInfluencer = () => {

    const [filfil, setFilfil] = useState(false)



    const [btnAct, setBtnAct] = useState(true)
    const [inputs, setInputs] = useState({ ViewzFilter: { min: 0, max: 10 } });
    const [minDrebScore, setMinDrebScore] = useState("");
    const [maxDrebScore, setMaxDrebScore] = useState("");

    const [inputsPrice, setInputsPrice] = useState({ PrizzFilter: { min: 0, max: 10 } });
    const [minPricePoint, setMinPricePoint] = useState("");
    const [maxPricePoint, setMaxPricePoint] = useState("");


    return (
        <div className='bg-light rounded px-0 pt-3 mt-3 mb-5 ' style={{ "paddingBottom": "15rem" }}>
            {/* search box */}
            <div className='d-flex flex-content-between align-items-center w-100'>
                <div className='brand_search'>
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type='text' />
                </div>



                <div className='mx-1'>
                    <button onClick={() => { setFilfil(!filfil) }} className='brand_filter_btn   d-flex align-items-center justify-content-evenly py-3'>
                        Filters&nbsp;<i class='bx bx-filter text-light' ></i>
                    </button>
                </div>




            </div>


            {/* Filter section */}
            {
                filfil
                    ?
                    <div className={filfil ? "fill-pop" : ""}>

                        <section className=' d-flex mt-3 w-100' style={{ flexDirection: "row-reverse", flexWrap: "wrap" }}>
                            {/* 1 - View */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "Viewz" ? 'active-btn ' : 'inactive-btn'} onClick={() => { btnAct == "Viewz" ? setBtnAct("") : setBtnAct("Viewz"); }} >
                                    Views &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "Viewz"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "350px", "height": "200px" }}>
                                                <div className='row h-100 p-1 '>


                                                    <label className='mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }}>
                                                        VIEWS
                                                    </label>




                                                    <div className='col-lg-12'>
                                                        <section className='px-4'>
                                                            <InputRange
                                                                maxValue={10}
                                                                minValue={0}
                                                                value={inputs?.ViewzFilter} onChange={(value) => setInputs((prev) => ({ ...prev, ViewzFilter: value }))}
                                                            />
                                                        </section>
                                                    </div>


                                                    <div className='col-lg-12'>
                                                        <div className='row bg-light ms-0' style={{ width: "100%" }}>
                                                            <div className='col-lg-6'>
                                                                <div className='py-1   my-1 hovBorder ' style={{ width: "100%", padding: "5px 10px 5px 10px" }}>
                                                                    <text className='' style={{ "fontSize": "10px", color: "" }}>Min. Score</text>
                                                                    <br />
                                                                    <input type='text'
                                                                        value={inputs.ViewzFilter.min} onChange={() => { setMinDrebScore((inputs.ViewzFilter.min) / 10) }}
                                                                        className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>




                                                            </div>
                                                            <div className='col-lg-6'>


                                                                <div className='py-1  my-1 hovBorder ' style={{ width: "100%", padding: "5px 10px 5px 10px" }}>
                                                                    <text className='' style={{ "fontSize": "10px" }}>Max. Score</text>
                                                                    <br />
                                                                    <input type='text'
                                                                        value={inputs.ViewzFilter.max} onChange={(e) => { setMaxDrebScore((inputs.ViewzFilter.max) / 10) }}
                                                                        className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>





                                                </div>

                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </span> */}

                            {/* Publicationdate  */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "Publicationdate" ? 'active-btn ' : 'inactive-btn'} onClick={() => { btnAct == "Publicationdate" ? setBtnAct("") : setBtnAct("Publicationdate"); }} >
                                    Publication Date &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>
                                {
                                    btnAct == "Publicationdate"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative" }}>
                                            <div className='bg-info'>
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </span> */}

                            {/* 4 - Video Type  */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "VideoType" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "VideoType" ? setBtnAct("") : setBtnAct("VideoType");
                                }} >
                                    Video Type &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "VideoType"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "height": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > VIDE0 TYPE</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Any</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>Paid</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Free</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span> */}

                            {/* 5 -Influencers country */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "InfluencersCountry" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "InfluencersCountry" ? setBtnAct("") : setBtnAct("InfluencersCountry");
                                }} >
                                    Influencers Country &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>
                                {
                                    btnAct == "InfluencersCountry"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "height": "250px", overflowY: "scroll", overflowX: "hidden" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > SELECT COUNTRY</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <input type='checkbox' />  &nbsp; <text className='Filter-text'>Any</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <input type='checkbox' />&nbsp; 🏳️‍⚧️ &nbsp;<text className='Filter-text'>India</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' /> &nbsp;🏁  &nbsp;<text className='Filter-text'>Japan</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' /> &nbsp;🏳️‍⚧️&nbsp;  <text className='Filter-text'>Korea</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' /> &nbsp;🏁&nbsp; <text className='Filter-text'>Sweden</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' />&nbsp; 🏳️‍⚧️&nbsp;<text className='Filter-text'>Pakistan</text>
                                                        </section>





                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span> */}

                            {/* 6 -Broadcasting lAnguage */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "BroadcastLanguage" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "BroadcastLanguage" ? setBtnAct("") : setBtnAct("BroadcastLanguage");

                                }} >
                                    Broadcast Language &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>


                                {
                                    btnAct == "BroadcastLanguage"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "height": "250px", overflowY: "scroll", overflowX: "hidden" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > SELECT LANGUAGES</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <input type='checkbox' />  &nbsp; <text className='Filter-text'>Any</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <input type='checkbox' />    &nbsp; <text className='Filter-text'>Hindi</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' />    &nbsp; <text className='Filter-text'>English</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' />    &nbsp; <text className='Filter-text'>Arabic</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' />    &nbsp;<text className='Filter-text'>Korean</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <input type='checkbox' />   &nbsp; <text className='Filter-text'>Japanese</text>
                                                        </section>





                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}



                            </span> */}

                            {/* 7-Search by  */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "SearchBy" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "SearchBy" ? setBtnAct("") : setBtnAct("SearchBy");
                                }} >
                                    Search By &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "SearchBy"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "height": "230px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > SEARCH TYPE</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Any</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>Title</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Description </text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Pinned Comments </text>
                                                        </section>

                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span> */}




                            {/* <button className={btnAct == "VideoBy" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                btnAct == "VideoBy" ? setBtnAct("") : setBtnAct("VideoBy");
                            }} >
                                Video By &nbsp; <i class='bx bx-chevron-down'></i>
                            </button> */}



                            {/* 9 -Gender */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "Gender" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "Gender" ? setBtnAct("") : setBtnAct("Gender");
                                }} >
                                    Gender &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>


                                {
                                    btnAct == "Gender"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "10px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "190px", "height": "150px" }}>
                                                <div className='row  p-1 '>


                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Any</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>Male</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Female</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span> */}


                            {/* 3- Video Label  */}
                            {/* <span className='my-2'>
                                <button className={btnAct == "Price" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "VideoLabel" ? setBtnAct("") : setBtnAct("VideoLabel");
                                }} >
                                    Video Label &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>
                                {
                                    btnAct == "VideoLabel"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "300px", "height": "90px" }}>
                                                <div className='row  p-1 '>

                                                    <label className='mx-3 mt-3 mb-0' style={{ "fontWeight": "700", "fontSize": "14px" }}>
                                                        VIDEO LABEL
                                                    </label>


                                                    <div className='col-lg-8 text-center w-100 d-flex align-items-center bg- mt-1 ms-3'>
                                                        <input type='checkbox' /> &nbsp;
                                                        <text className='text-bold' style={{ "fontWeight": "600", color: "gray" }}>Includes Paid Promotions</text>
                                                    </div>





                                                </div>

                                            </div>
                                        </div>
                                        :
                                        null
                                }


                            </span> */}






                            {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
                            {/* 5 */}
                            <span className='my-2'>
                                <button className={btnAct == "SortBy" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "SortBy" ? setBtnAct("") : setBtnAct("SortBy");
                                }} >
                                    Sort By &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "SortBy"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "minHeight": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} >Sort By </section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Relevance </text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>Uploaded Date</text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>View Count </text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Rating</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span>
                            {/* 4 */}
                            <span className='my-2'>
                                <button className={btnAct == "Features" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "Features" ? setBtnAct("") : setBtnAct("Features");
                                }} >
                                    Features &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "Features"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "minHeight": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > Features</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Live</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>4K</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>HD</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Subtitles/CC</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Creative Commons</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>360 &deg; </text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>VR180</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>3D</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>HDR</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Location</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Purchased</text>
                                                        </section>



                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span>
                            {/* 3 */}
                            <span className='my-2'>
                                <button className={btnAct == "Duration" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "Duration" ? setBtnAct("") : setBtnAct("Duration");
                                }} >
                                    Duration &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "Duration"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "minHeight": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} >Duration </section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Under 4 minutes</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>4 - 20 minutes</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Over 20 minutes</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span>
                            {/* 2 */}
                            <span className='my-2'>
                                <button className={btnAct == "Type" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "Type" ? setBtnAct("") : setBtnAct("Type");
                                }} >
                                    Type &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "Type"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "minHeight": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > Type</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Video </text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>channel</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Playlist</text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>Film</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span>
                            {/* 1 */}
                            <span className='my-2'>
                                <button className={btnAct == "Update" ? 'active-btn ' : 'inactive-btn'} onClick={() => {
                                    btnAct == "Update" ? setBtnAct("") : setBtnAct("Update");
                                }} >
                                    Upload Date &nbsp; <i class='bx bx-chevron-down'></i>
                                </button>

                                {
                                    btnAct == "Update"
                                        ?
                                        <div style={{ height: "0px", width: "0px", position: "relative", left: "5px", top: "10px", zIndex: "28" }}>
                                            <div className='filterBox ' style={{ "width": "250px", "minHeight": "200px" }}>
                                                <div className='row  p-1 '>


                                                    <section className=' mx-3 mt-3 mb-2' style={{ "fontWeight": "700", "fontSize": "14px" }} > Upload Date</section>

                                                    <section className='row m-0 px-3'>
                                                        <section className='col-12  hovhov  py-2 '>
                                                            <text className='Filter-text'>Last Hour </text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov  py-2 '>
                                                            <text className='Filter-text'>Today</text>
                                                        </section>


                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>This Week</text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>This Month</text>
                                                        </section>
                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                        <section className='col-12 hovhov   py-2 '>
                                                            <text className='Filter-text'>This Year</text>
                                                        </section>

                                                        <hr className='m-0 mx-auto px-3   ' style={{ opacity: "0.06" }} />
                                                    </section>




                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null}
                            </span>
                            {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}



                        </section>

                        <section className=' Filterz-on-bg d-flex justify-content-start mt-3'>
                            <div className='text-start'>
                                <div className='Filterz-on '>
                                    Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                                </div>
                            </div>
                        </section>





                    </div>
                    :
                    null}

            {/* Filter section */}




            {/* Table Row Section */}
            <div>
                <table className='application_table mt-3'>
                    <thead>
                        <tr>
                            <th>
                                Influencer
                            </th>

                            <th>
                                Subscribers
                            </th>

                            <th>
                                Views
                            </th>

                            <th>
                                The Last Video
                            </th>

                            <th>
                                Broadcast Language
                            </th>

                            <th>
                                Price
                            </th>

                            <th>
                                Number
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}

                        <tr>
                            <td>
                                <div className='d-flex  my-4 ps-3'>
                                    <img src={require('../../../../Imagez/Dashboard/All/camp_user.png')} width={50} />

                                    <div className='d-flex flex-column mx-4'>
                                        <label style={{ fontSize: '18px' }}>
                                            Guru
                                        </label>

                                        <label style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            🇮🇳 India
                                        </label>
                                    </div>

                                    <div>
                                        <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                34.5k
                            </td>

                            <td>
                                2.72 k
                            </td>

                            <td>
                                06.08.2023
                            </td>

                            <td>
                                English
                            </td>

                            <td>
                                <div className='d-flex flex-column mx-4'>
                                    <label style={{ fontSize: '16px' }}>
                                        $45.57 - $120.64
                                    </label>

                                    <label style={{ fontSize: '12px', color: '#7E7E7E' }}>

                                        $15 - $45
                                    </label>
                                </div>

                            </td>

                            <td className=''>
                                6658
                            </td>
                        </tr>
                        {/* ----------------------------------------- */}




                    </tbody>
                </table>



                {/* <div class="bg-image"></div> */}
                {/* <div class="bg-text text-center   mb-4">
                    <img src={require('../../../../Imagez/Dashboard/All/lock.png')} width={100} />
                    <h5 style={{ "font-size": '20px', "marginTop": "1rem" }}>Apps list Is limited</h5>
                </div> */}

            </div>
        </div>
    )
}

export default BrandsInfluencer