import React, { useEffect, useRef, useState } from 'react'
import logo from '../../Imagez/Common/logo.png'


import trapBox from '../../Imagez/signIn_LogIn/Login_pattern2.png'
import FadeLoader from "react-spinners/FadeLoader";
import AgencySignUpForm from './AgencySignUpForm';
import SignUpHead from '../Brands_Login_SignUp/SignUp/SignUpHead';

import phone from '../../Imagez/LandinPgImg/phone.png'
import { useInView } from 'react-intersection-observer';


const AgencySignUp = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])


  const textRef = useRef(null);
  const [ref1, inView1] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.8, // Trigger when at least 50% of the element is in view

  });
  const [ref2, inView2] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.8, // Trigger when at least 50% of the element is in view

  });
  const [ref3, inView3] = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.8, // Trigger when at least 50% of the element is in view
    delay: 100
  });
  return (
    <>
      <div className='conatiner-fluid'>
        {
          loading ? (
            <section className='' style={{ "height": "100vh", "width": "100vw", "background": "black" }} >
              <div className=' d-flex justify-content-center align-items-center  h-100'>

                <FadeLoader
                  color={"aqua"}
                  loading={loading}
                  // cssOverride={override}
                  size={50}
                // aria-label="Loading Spinner"
                // data-testid="loader"
                />

              </div>
            </section>
          ) : (





            <div className='bgLogIn' style={{ "height": "", "backgroundColor": "", "zIndex": "-5" }}>
              <div className='row '>
                <div className='col-lg-12 text-center mt-' style={{ "marginTop": "" }}>


                  <section className='row  d-flex justify-content-center'>
                    {/* lEFT  */}
                    <section className='col-lg-6 ps-5'>
                      <div className='ps-5 '>

                        {/* LOGO-SECTION */}
                        <div className='col-lg-12 mb-2 pt-2 bg-'>
                          <img src={logo} alt='hehe' style={{ "width": "120px", }} />
                        </div>
                        {/* HEADEING-SECTION  */}
                        <div>
                          <h4 style={{ "fontSize": "42px", "fontWeight": "700", "color": "#FFFFFF", "lineHeight": "60px" }}>Welcome Back , Lets get in ..</h4>
                        </div>


                        {/* FORM-SECTION */}
                        <section className='row d-flex justify-content-center'>
                          <section className='col-lg-9 '>
                            <div className='py-0'>
                              <section >
                                <AgencySignUpForm />
                              </section>
                            </div>
                          </section>
                        </section>




                      </div>
                    </section>



                    {/* RIGHT  */}

                    <section className='col-lg-6 bg-'>
                      <div>

                        {/* SIGN-UP-HEADER  */}
                        <section className='row bg-'>
                          <div className='col-lg-12 mb bg-' >
                            <SignUpHead />
                          </div>
                        </section>


                        {/* TRANSPARENT-CARD */}
                        <section className='row d-flex justify-content-start'>
                          <section className='col-lg-12 text-center bg-inf'>
                            <div className='py-3' style={{ border: "" }}>
                              <section className='d-flex justify-content-center'>
                                <div className=' boxxy Kardz'>

                                  <section className='row text-start' >
                                    <div className={`fade-in-text ${inView3 ? 'fade-in col-md-6 d-flex flex-column  ' : 'col-md-6 d-flex flex-column '}`} ref={ref3} style={{ "height": "580px" }}>
                                      <h4 className='' style={{ "marginTop": "5rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "24px", "lineHeight": "31px", "color": "#FFFFFF" }}>Experience a whole new REALM OF INFLUENCER</h4>
                                      <p className='' style={{ "marginTop": "1rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "18px", "lineHeight": "20px", "color": "#FFFFFF" }}>Become a DREABEE Member & Get</p>
                                      <ul className='ulli' style={{ "marginTop": "0rem", "marginLeft": "1rem", "fontWeight": "400", "fontSize": "15px", "lineHeight": "20px", "color": "#FFFFFF" }}>
                                        <li className=''>300000+ Unique Influencers across Platforms</li>
                                        <li className='mt-2'>Super Flexible Search like #tags, bio, profession and more </li>
                                        <li className='mt-2'>Robust Scoring system for every Influencer & every Profile</li>
                                        <li className='mt-2'>Advanced Search filters with extensive filters</li>
                                      </ul>
                                      <p id='tangs' style={{ "marginLeft": "3rem", "marginTop": "0.5rem", "fontStyle": "italic", "fontSize": "18px", "fontWeight": "600", "lineHeight": "24px", "color": "#FFFFFF" }}>And best of all ,<br />FREE FOREVER</p>
                                    </div>


                                    <div className=' col-md-6 d-flex justify-content-center align-items-center ' style={{ "height": "580px" }}>  <img src={phone} className={`fade-in-text ${inView2 ? 'fade-in phone' : 'phone'}`} ref={ref2} style={{ "width": "280px", "zIndex": "" }} alt='logo' /> </div>
                                  </section>

                                </div>
                              </section>
                            </div>
                          </section>
                        </section>



                      </div>
                    </section>






                  </section>


                </div>
              </div>
            </div>








          )}
      </div>
    </>
  )
}

export default AgencySignUp