import React, { useEffect, useState } from 'react'
import banner from '../../../../../Imagez/Dashboard/All/Upgrade Card.png'
import { useLocation, useNavigate } from 'react-router-dom';
import GRaph from "../../../../../Imagez/Dashboard/All/Pie Chart.png";
import Pepsi from "../../../../../Imagez/Dashboard/All/Pepsico.png";
import numeral from 'numeral';
import axios from 'axios';
import { baseUrl2 } from '../../../../../Routez/Routey';
import { Doughnut } from 'react-chartjs-2';
import { ProgressBar } from 'react-bootstrap-v5';
import AgencyLogIn from '../../../../../Pages/Agency_Login_SignUp/AgencyLogIn';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const InfluencerInstagramSummary = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [actionValue, setActionValue] = useState("info");
    const [profileDataI, setProfileDataI] = useState([]);
    useEffect(() => {
        // console.log("this pages locations state", location)
        console.log("-----------=================--------------========")
        // console.log("----location--INSTAPROFF--", location.state);
        // navigate("Dreabee/Dashboard/Profile/Instagram", { state: { tag: Key } })
        // ProfileDetailsI();

        // Api1();
        // Api21();
        // Api22();
        // Api23();

        DemographicsApi1();
        DemographicsApi2();
        DemographicsApi3();
        DemographicsApi4();
        DemographicsApi5();

        setLoader(true)

        fetchData("https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_count&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC", 4);


        fetchDataReach("https://graph.facebook.com/v18.0/17841433401221135/insights?metric=reach&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC", 4);

    }, [])


    // INSTAGRAM 
    const ProfileDetailsI = () => {
        // console.log("the insta end ", location.state.keyWord)
        axios.post(`${baseUrl2}/instagram/profile-details`, {
            name: localStorage.getItem("UserName")
        }).then((res) => {
            console.log("THE Instagram API PROFILE DATA -", res.data)
            setProfileDataI(res.data)
        }).catch((err) => {
            console.log("the profile insta page errorr - ", err)
        })
    }

    // 1-dreabee score ppie
    const dataaa = {
        labels: ["Dreabee score"],
        // labels: ["Dreabee Score : " + dbeeScore + "%"],
        datasets: [
            {
                data: [70, 30],
                // data: [dbeeScore, 100 - dbeeScore],
                backgroundColor: ['#2D59F5', "gray"],
                borderWidth: 0.5,
            },
        ],
    };




    // --------------Graph Apis---------------

    // https://graph.facebook.com/v19.0/17841433401221135/insights?metric=impressions,reach,profile_views&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC


    const [profileData3, setProfileData3] = useState([])
    const Api1 = () => {
        axios.get('https://graph.facebook.com/v19.0/17841433401221135? fields=name%2Cfollows_count%2Cfollowers_count%2Cbiography%2Cid%2Cusername%2Cwebsite%2Cmedia_count%2Cprofile_picture_url&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                console.log("THE RESPONSE INSIDE API #3", response.data);
                setProfileData3(response.data)

                setTimeout(() => {
                    console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }

    const [profileData21, setProfileData21] = useState()
    const Api21 = () => {
        axios.get(`https://graph.facebook.com/v19.0/17841433401221135/insights?metric=email_contacts,follower_count,get_directions_clicks,phone_call_clicks,profile_views,text_message_clicks,website_clicks,&period=day&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC`)
            .then((response) => {
                console.log("THE RESPONSE  API #21", response.data.data);
                setProfileData21(response.data.data)

                setTimeout(() => {
                    // console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }

    // day,week,days_28
    const [profileData22, setProfileData22] = useState()
    const [timeLine22, setTimeLine22] = useState("day")
    const Api22 = () => {
        axios.get(`https://graph.facebook.com/v19.0/17841433401221135/insights?metric=impressions,reach&period=${timeLine22}&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC`)
            .then((response) => {
                console.log("THE RESPONSE INSIDE API #22", response.data.data);
                setProfileData22(response.data.data)

                setTimeout(() => {
                    // console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 2.2 error - ", error)
            });

    }
    // lifetime
    const [profileData23, setProfileData23] = useState()
    const Api23 = () => {
        axios.get(`https://graph.facebook.com/v19.0/17841433401221135/insights?metric=online_followers&period=lifetime&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC`)
            .then((response) => {
                console.log("THE RESPONSE INSIDE API #23", response.data.data);
                setProfileData23(response.data.data[0])

                setTimeout(() => {
                    // console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 2.3 error - ", error)
            });

    }


    const Api3 = () => {
        axios.get('https://graph.facebook.com/v19.0/17841433401221135? fields=name%2Cfollows_count%2Cfollowers_count%2Cbiography%2Cid%2Cusername%2Cwebsite%2Cmedia_count%2Cprofile_picture_url&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                console.log("THE RESPONSE INSIDE API #3", response.data);
                setProfileData3(response.data)

                setTimeout(() => {
                    console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }




    // __________DEMOGRAPHICS GRAPH____________________

    const [topCityName, setTopCityName] = useState("")
    const [topCityPercent, setTopCityPercent] = useState("")

    const [topCountryName, setTopCountryName] = useState()
    const [topCountryPercentage, setTopCountryPercentage] = useState()

    const [topStatePercentage, setTopStatePercentage] = useState()


    const [topAgeGrp, setTopAgeGrp] = useState()
    const [topAgePercent, setTopAgePercent] = useState()

    const [topGenderPercent, setTopGenderPercent] = useState()
    const [topGenderName, setTopGenderName] = useState()


    // -------------Top City-----------------------
    const [demograph1, setDemograph1] = useState()
    const DemographicsApi1 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_demographics&period=lifetime&metric_type=total_value&breakdown=city&timeframe=this_month&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:CITY)", response.data.data[0].total_value.breakdowns[0].results);
                // addAllValuez(response.data.data[0].total_value.breakdowns[0].results)

                setDemograph1(response.data.data[0].total_value.breakdowns[0].results)
                findCityData(response.data.data[0].total_value.breakdowns[0].results)
                Top4CityStates(response.data.data[0].total_value.breakdowns[0].results)
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }
    // name
    const findCityData = (demography) => {
        const highestValueObject = demography.reduce((acc, cur) => {
            return cur.value > acc.value ? cur : acc;
        });
        // console.log("Highest Value:", highestValueObject.value);
        // console.log("Dimension Value:", highestValueObject.dimension_values[0]);
        const [city, state] = highestValueObject.dimension_values[0].split(', ');
        addAllValuez(demography, highestValueObject.value)
        setTopCityName(city)
        console.log("thedity :", city)
    }
    // %
    const addAllValuez = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100;
        }, 0);
        console.log("Sum of all values:", sum * y);
        setTopCityPercent((sum * y).toFixed(2))

    };

    // -------------Top City-----------------------


    // -------------Top Country-----------------------
    //2-COUNTRY 
    const DemographicsApi2 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_demographics&period=lifetime&metric_type=total_value&breakdown=country&timeframe=this_month&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:COUNTRY)", response.data.data);
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:COUNTRY)", response.data.data[0].total_value.breakdowns[0].results);
                findCountryData(response.data.data[0].total_value.breakdowns[0].results)
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }

    const findCountryData = (demography) => {
        const highestValueObject = demography.reduce((acc, cur) => {
            return cur.value > acc.value ? cur : acc;
        });
        // console.log("Highest Value:", highestValueObject.value);
        // console.log("Dimension Value:", highestValueObject.dimension_values[0]);
        const country = highestValueObject.dimension_values[0];
        addAllCountry(demography, highestValueObject.value)
        setTopCountryName(country)
        console.log("thedonntry :", country)
    }
    // %
    const addAllCountry = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100;
        }, 0);
        console.log("Sum of all values:", sum * y);
        setTopCountryPercentage((sum * y).toFixed(2))

    };

    // -------------Top Country-----------------------

    // -------------Top State-----------------------
    //3-State
    const DemographicsApi3 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_demographics&period=lifetime&metric_type=total_value&breakdown=city&timeframe=this_month&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:COUNTRY)", response.data.data);
                console.log("THE DEMOGRAPHIC API (THIS-MONTH:STATE)", response.data.data[0].total_value.breakdowns[0].results);
                findMostFrequentState(response.data.data[0].total_value.breakdowns[0].results)
                // findStateData(response.data.data[0].total_value.breakdowns[0].results)
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }


    const findStateData = (demography) => {
        const highestValueObject = demography.reduce((acc, cur) => {
            return cur.value > acc.value ? cur : acc;
        });
        // console.log("Highest Value:", highestValueObject.value);
        // console.log("Dimension Value:", highestValueObject.dimension_values[0]);
        const [country, state] = highestValueObject.dimension_values[0].split(', ');
        addAllCountry(demography, highestValueObject.value)
        setTopCountryName(country)
        console.log("thedonntry :", country)
    }
    // %
    const addAllState = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100;
        }, 0);
        console.log("Sum of all values:", sum * y);
        setTopCityPercent((sum * y).toFixed(2))

    };

    const [topStateName, setTopStateName] = useState()

    // ---Different--
    const findMostFrequentState = (x) => {
        const statesCount = {};
        x.forEach(item => {
            const [city, state] = item.dimension_values[0].split(', ');
            statesCount[state] = (statesCount[state] || 0) + 1;
        });
        const mostFrequent = Object.keys(statesCount).reduce((a, b) => statesCount[a] > statesCount[b] ? a : b);

        // console.log("Most Frequently Occurred State:", mostFrequent);
        setTopStateName(mostFrequent)
    };

    // -------------Top State-----------------------


    // -------------Top Age-----------------------
    //4-Age 
    const DemographicsApi4 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_demographics&period=lifetime&metric_type=total_value&breakdown=age&timeframe=this_month&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:AGE)", response.data.data);
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:COUNTRY)", response.data.data[0].total_value.breakdowns[0].results);

                findAgeData(response.data.data[0].total_value.breakdowns[0].results)
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }
    const findAgeData = (demography) => {
        const highestValueObject = demography.reduce((acc, cur) => {
            return cur.value > acc.value ? cur : acc;
        });
        // console.log("Highest Value:", highestValueObject.value);
        // console.log("Dimension Value:", highestValueObject.dimension_values[0]);
        const Age = highestValueObject.dimension_values[0];
        addAllAge(demography, highestValueObject.value)
        setTopAgeGrp(Age)
        console.log("theAgeyy :", Age)
    }
    // %
    const addAllAge = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100;
        }, 0);
        console.log("Sum of all age values:", sum * y);
        setTopAgePercent((sum * y).toFixed(2))

    };

    // -------------Top Age-----------------------



    // -------------Top GENDER-----------------------
    //4-GENDER 
    const [gengen, setGengen] = useState();
    const DemographicsApi5 = () => {
        axios.get('https://graph.facebook.com/v18.0/17841433401221135/insights?metric=follower_demographics&period=lifetime&metric_type=total_value&breakdown=gender&timeframe=this_month&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE DEMOGRAPHIC API (THIS-MONTH:GENDER)", response.data.data);
                console.log("THE DEMOGRAPHIC API (THIS-MONTH:Gender)", response.data.data[0].total_value.breakdowns[0].results);
                setGengen(response.data.data[0].total_value.breakdowns[0].results)
                // GenChart(response.data.data[0].total_value.breakdowns[0].results)
                // findGenderData(response.data.data[0].total_value.breakdowns[0].results)
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }
    const findGenderData = (demography) => {
        const highestValueObject = demography.reduce((acc, cur) => {
            return cur.value > acc.value ? cur : acc;
        });
        // console.log("Highest Value:", highestValueObject.value);
        // console.log("Dimension Value:", highestValueObject.dimension_values[0]);
        const gender = highestValueObject.dimension_values[0];
        addAllGender(demography, highestValueObject.value)
        setTopGenderName(gender)
        console.log("thegendry :", gender)
    }
    // %
    const addAllGender = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100000;
        }, 0);
        console.log("Sum of all values:", sum * y);
        setTopGenderPercent((sum * y).toFixed(2))

    };

    // 2- gender pie 
    let dataaaG
    if (gengen) {
        dataaaG = {
            labels: ["Female: 5%", "Male : 3%", "Unknown:92%"],
            datasets: [
                {
                    data: [gengen[0].value, gengen[1].value, gengen[2].value],
                    // data: [5, 90, 5],
                    backgroundColor: ['#2D59F5', "#BCBCBC", "pink"],
                    borderWidth: 0.5,
                },
            ],
        };

    } else {
        dataaaG = {
            labels: ["Female: 35%", "Male : 63%", "Unknown:2%"],
            datasets: [
                {
                    // data: [gengen[0].value, gengen[1].value, gengen[2].value],
                    data: [20, 50, 30],
                    backgroundColor: ['#2D59F5', "#BCBCBC", "pink"],
                    borderWidth: 0.5,
                },
            ],
        };
    }



    // --------------Audience Geography-----------------
    const [audienceGeo, setAudienceGeo] = useState();
    const Top4CityStates = (x) => {
        const sortedData = [...x].sort((a, b) => b.value - a.value);
        const topFour = sortedData.slice(0, 4).map(item => {
            const [city, state] = item.dimension_values[0].split(', ');
            return { city, state, value: item.value };
        });
        addAllAudPercent(x, topFour)
        setTopValues(topFour);
        setAudienceGeo(topFour)
        console.log("Top 4 Values:", topFour);
    }

    const [sumVal, setSumVal] = useState()
    const addAllAudPercent = (x, y) => {
        const sum = x.reduce((acc, cur) => {
            return (acc + cur.value) * 1 / 100;
        }, 0);

        console.log("Sum of all values:", sum * y);
        setSumVal(sum)
    };


    const [topValues, setTopValues] = useState([]);
    const findLowestValue = () => {
        const lowestValueObject = demograph1.reduce((acc, cur) => {
            return cur.value < acc.value ? cur : acc;
        });
        console.log("lowest Value:", lowestValueObject.value);
        console.log("Dimension Value:", lowestValueObject.dimension_values[0]);
    }
    const showww = () => {
        // DemographicsApi1();
    }
    const showwww = () => {
        // findHighestValue();
    }
    const showwwww = () => {
        // findLowestValue();
        // addAllValues();
        // Top4CityStates();
        // console.log(audienceGeo[0].value);
        // findMostFrequentState();
    }

    // ADD GROWTH  DATA -REACH DATA
    const [isloader, setLoader] = useState(false);
    // ---------ADD CHART GROWTH DATA -GROWTH IN 7 DAYS---------------
    const [only_dates, setOnly_dates] = useState([]);
    const [only_values, setOnly_values] = useState([]);
    const [resultArray, setResultArray] = useState([]);

    let newSortArray;
    const fetchData = (url, remainingIterations) => {
        if (remainingIterations <= 0) {
            return;
        }

        fetch(url)
            .then(response => response.json())
            .then(responseData => {
                const values = responseData.data[0].values;

                // Extracting value and end_time and saving them in the resultArray
                const extractedData = values.map(item => ({
                    value: item.value,
                    end_time: item.end_time
                }));
                setResultArray(prevResult => [...prevResult, ...extractedData]);

                // Fetch previous data recursively
                if (responseData.paging && responseData.paging.previous) {
                    fetchData(responseData.paging.previous, remainingIterations - 1);
                }
            }

            )
            .catch(error => console.error('Error fetching data:', error));

    };
    const addChartData = () => {

        newSortArray = resultArray.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        // Iterate through the sorted dash array
        newSortArray.forEach(item => {
            // Extract date in the format "25 January"
            const date = new Date(item.end_time).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
            only_values.push(item.value);
            only_dates.push(date);
            console.log(item.value)
            // Extract value

        });

        console.log("GROWTH DATES ", only_dates);
        console.log("GROWTH VALUES", only_values);

    }


    // ----------ADD CHART RRRREACH DATA - 7 DAYS ---------------
    const [only_reach_dates, setOnly_reach_dates] = useState([]);
    const [only_reach_values, setOnly_reach_values] = useState([]);
    const [resultReachArray, setResultReachArray] = useState([]);

    let newSortReachArray;
    const fetchDataReach = (url, remainingIterations) => {
        if (remainingIterations <= 0) {
            return;
        }

        fetch(url)
            .then(response => response.json())
            .then(responseData => {
                const values = responseData.data[0].values;

                // Extracting value and end_time and saving them in the resultArray
                const extractedData = values.map(item => ({
                    value: item.value,
                    end_time: item.end_time
                }));
                setResultReachArray(prevResult => [...prevResult, ...extractedData]);

                // Fetch previous data recursively
                if (responseData.paging && responseData.paging.previous) {
                    fetchDataReach(responseData.paging.previous, remainingIterations - 1);
                }
            }

            )
            .catch(error => console.error('Error fetching data:', error));

    };

    const addChartReachData = () => {

        newSortReachArray = resultReachArray.sort((a, b) => new Date(a.end_time) - new Date(b.end_time));
        // Iterate through the sorted dash array
        newSortReachArray.forEach(item => {
            // Extract date in the format "25 January"
            const date = new Date(item.end_time).toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
            only_reach_dates.push(date);

            // Extract value
            only_reach_values.push(item.value);
        });

        console.log("reach dates ", only_reach_dates);
        console.log("reach values", only_reach_values);

    }

    // ------------------
    // linechart 1 
    const options = {
        title: {
            text: ''
        },
        xAxis: {
            // categories: ["23 jan", "24 jan"]
            categories: only_dates
        },
        yAxis: {
            title: {
                text: 'People Count'
            }
        },
        series: [
            {
                name: 'Reaches',
                // data: [0, 0, 1, 2, 0, 0]
                data: only_reach_values
            },

            {
                name: 'Followers gained',
                data: only_values
            },



        ]
    };

    const showey = () => {

        addChartData();

        addChartReachData();


        setTimeout(() => {
            console.log("CHINNED")
        }, 2000);
    }
    // -----------------
    // linechart 2 
    const optionss = {
        title: {
            text: ''
        },
        xAxis: {
            // categories: ["23 jan", "24 jan"]
            categories: only_dates
        },
        yAxis: {
            title: {
                text: 'People Count'
            }
        },
        series: [
            {
                name: 'Mentions per day',
                data: [0, 0, 0, 0, 0, 0]

            },

            {
                name: 'Ads per day',
                data: [0, 0, 0, 0, 0, 0]

            },



        ]
    };



    // ------
    // barchart 1
    const baroptions1 = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: [' Mon', 'Tue ', 'Wed ', 'Thur ', 'Fri ', 'Sat', 'Sun'],
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        series: [
            {
                name: 'Mentions',
                data: [3, 6, 9, 12, 15, 12, 43], // Replace with your data for series A
                // data: mentionzArray, // Replace with your data for series A
            },
            {
                name: 'Ad Mentions',
                data: [1, 2, 3, 4, 6, 7], // Replace with your data for series B
                // data: paidAds, // Replace with your data for series B
            },
        ],
    };

    return (
        <>


            <div className='row'>

                {/* Left NAvbar  */}
                <div className='col-2  '>

                    <div className='pt-2 ' style={{
                        height: "58vh",
                        position: "",
                        top: "13rem",
                        // left: "9rem",
                        // width: "17.5rem",
                        overflow: "scroll",
                        overflowX: "hidden",
                    }} >


                        <ul className="nav-list  text-start" style={{ "listStyleType": "none" }}>
                            {/* <li style={{ "cursor": "pointer" }} onClick={() => { showww() }}   >bunbun</li>
                            <li style={{ "cursor": "pointer" }} onClick={() => { showwww() }}   >tumtum</li>
                            <li style={{ "cursor": "pointer" }} onClick={() => { showwwww() }}   >dumdum</li> */}
                            <li className={actionValue == "info" ? "act_btn  ms-0 d-flex align-items-start" : ' ms-0  d-flex align-items-center navHovTextNoBg'} onClick={() => { document.getElementById("Info").scrollIntoView({ behavior: "smooth" }); setActionValue("info") }} style={{ "marginTop": "1rem" }}>
                                <i class='bx bxs-shapes ' style={{ "fontWeight": "600", "fontSize": "26px", "color": "" }}></i> &nbsp;   &nbsp; <span className=" " style={{ "fontSize": "18px", "fontWeight": "600", "color": "" }}>Info</span>
                            </li>
                            <li className={actionValue == "Insight" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 bg- d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Insight").scrollIntoView({ behavior: "smooth" }); setActionValue("Insight") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Insight</span>
                            </li>
                            <li className={actionValue == "Performance" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-layer' style={{ "fontWeight": "600", "fontSize": "26px" }}></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("Performance").scrollIntoView({ behavior: "smooth" }); setActionValue("Performance") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Performance</span>
                            </li>

                            <li className={actionValue == "Engagement" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Engagement").scrollIntoView({ behavior: "smooth" }); setActionValue("Engagement") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Engagement</span>
                            </li>
                            <li className={actionValue == "PaidEngagement" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("PaidEngagement").scrollIntoView({ behavior: "smooth" }); setActionValue("PaidEngagement") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Paid Engagement</span>
                            </li>
                            <li className={actionValue == "AudienceMetrics" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("AudienceMetrics").scrollIntoView({ behavior: "smooth" }); setActionValue("AudienceMetrics") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Audience Metrics</span>
                            </li>
                            <li className={actionValue == "Growth" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp;  &nbsp; <span className="" onClick={() => { document.getElementById("Growth").scrollIntoView({ behavior: "smooth" }); setActionValue("Growth") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Growth </span>
                            </li>
                            <li className={actionValue == "Brands" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("Brands").scrollIntoView({ behavior: "smooth" }); setActionValue("Brands") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Brands </span>
                            </li>
                            <li className={actionValue == "VisitInstagram" ? "act_btn  mt-4 d-flex align-items-center" : 'mt-4 ms-0 d-flex align-items-center navHovTextNoBg'} style={{ "marginTop": "" }}>
                                <i class='bx bx-star' style={{ "fontWeight": "600", "fontSize": "26px" }} ></i> &nbsp; &nbsp;  <span className="" onClick={() => { document.getElementById("VisitInstagram").scrollIntoView({ behavior: "smooth" }); setActionValue("VisitInstagram") }} style={{ "fontSize": "18px", "fontWeight": "600" }}>Visit Instagram </span>
                            </li>

                        </ul>
                    </div>
                </div>



                {/* Right SEcTion  */}
                {/* <div className='col-10 bg-' style={{ "border": "0px solid black", "height": "62.9vh", "position": "fixed", "top": "16.5rem", "left": "24.5rem", "width": "67.5rem", "overflow": "scroll", "overflowX": "hidden" }} > */}
                {/* <div className='bg-' style={{ "height": "60vh", "width": "100%" }}>
                        <span>
                            <img src={banner} alt='img' style={{ "width": "55rem", "marginTop": "5rem", "marginLeft": "5rem" }} />
                            <span style={{ "position": "relative", "bottom": "3.5rem", "left": "50rem" }}>    <button className='text-center p-1 pt-0  ' style={{
                                "width": "8.2rem", "height": "2.5rem",
                                "fontWeight": "600",
                                "fontSize": "0.8rem",
                                "borderRadius": "10px",
                                "color": "white",
                                "backgroundColor": "#C83B93", "border": "2px solid #C83B93",
                                "marginLeft": "0rem"
                            }}><span style={{ "position": "relative", "top": "2px" }}> Contact Sales</span></button>
                            </span>
                        </span>

                    </div> */}
                {/* Right SEcTion  */}
                <div className='col-10 mt-1 '>
                    <div
                        className="ms-5 mb-5 ps-4 "
                        style={{
                            marginBottom: "2rem",
                            border: "0px solid black",
                            height: "62vh",
                            position: "",
                            top: "13rem",
                            // left: "24.7rem",
                            // width: "71vw",
                            overflow: "scroll",
                            overflowX: "hidden",
                        }}>
                        <div className="bg-" style={{ height: "200vh", width: "100%" }}>
                            {/* SECTION 1  */}
                            <div className="row mt-2 p-2" id="Info">
                                {/* Card 0  */}
                                <div
                                    className=" mx-2 py-3 KardzProf"
                                    style={{
                                        backgroundColor: "white",
                                        width: "65rem ",
                                        height: "153px",
                                        borderRadius: "10px",
                                    }}>


                                    <div>
                                        <table className='InstaProfileTab1'>
                                            <tr>
                                                <td>
                                                    <div className='instaCard1txt1' >
                                                        Followers
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {/* 34.2 M */}
                                                        {(numeral(profileDataI?.usersCount).format('0.0a'))}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Avg Likes
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {/* 566 */}
                                                        {(numeral(profileDataI?.avgLikes).format('0.0a'))}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Avg Comments
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {/* 559 */}
                                                        {(numeral(profileDataI?.avgVideoComments).format('0.0a'))} %
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        Avg Reel Views
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {/* 30.65M */}
                                                        {(numeral(profileDataI?.avgVideoViews).format('0.0a'))}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='instaCard1txt1'>
                                                        ER
                                                    </div>

                                                    <div className='instaCard1txt2'>
                                                        {/* $748.51k */}
                                                        {((profileDataI?.avgER) * 100).toFixed(2)}%
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>




                                </div>






                            </div>

                            {/* SECTION 2  */}
                            <div className="row my-2  p-2" id="Insight">
                                <div className="KardzProf mx-2" style={{
                                    width: "312px ",
                                    height: "409px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- ps-0 p-4 h-100'>
                                        <span style={{ "fontSize": "24px", fontWeight: "600" }}>Dreabee Score</span>

                                        <section
                                            src={GRaph}
                                            alt="img"
                                            className=" mt-4 pe-2 ">

                                            <section style={{ height: "300px", width: "300px" }}>
                                                <Doughnut data={dataaa} />
                                            </section>
                                        </section>


                                        {/* <img src={GRaph} alt="img" className="mt-4 me-5" style={{ width: "16rem" }} /> */}




                                    </section>

                                </div>


                                <div className="KardzProf mx-2" style={{
                                    width: "713px ",
                                    height: "409px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>

                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "24px", fontWeight: "600" }}>Insights</span>


                                        <div className="row">
                                            <div className="col">
                                                {/* <ul
                                                className="ms-2"
                                                style={{
                                                    listStyle: "none",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                    ✈ Travel
                                                </li>
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                    🎨 Art & Entertainment
                                                </li>
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                    🦾 Fitness
                                                </li>
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                    🦾 Health & Fitness
                                                </li>
                                            </ul> */}

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >  ✈ Travel</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} > 🎨 Art & Entertainment</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >  🦾 Fitness</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} > 🦾 Health & Fitness</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>





                                            </div>
                                            <div className="col">
                                                {/* <ul
                                                className="ms-2"
                                                style={{
                                                    listStyle: "none",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                 
                                                </li>
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                 
                                                </li>
                                                <li style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                 
                                                </li>
                                                <li className='d-flex flex-column' style={{ marginTop: "", marginBottom: "3.2rem" }}>
                                                    Others
                                                    <input type='range' className='mt-2' />
                                                </li>
                                            </ul> */}



                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >     👚 Fashion</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >    👪 Family & Parentingt</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >     🎓 Education</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                                <div className='row px-2 my-3 py-2 bg- '>
                                                    <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} > 🦾 Others</div>
                                                    <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                                    <input type='range' className='mt-1' />
                                                </div>

                                            </div>
                                        </div>



                                    </section>
                                </div>






                            </div>

                            {/* SECTION 3 --- Performance */}
                            <div className="row my-2 p-2" id="Performance">
                                <div className="KardzProf mx-2" style={{
                                    width: "1027px ",
                                    height: "600px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Content Performance & Benchmark</span>

                                        <div className='row my-3' >
                                            {/* 1 */}
                                            <div className='col-lg'>
                                                <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E" }}> Mentions per day </h4>
                                                <h4 style={{ "fontSize": "26px", "fontWeight": "600" }}> 0.19 </h4>

                                            </div>
                                            {/* 2 */}
                                            <div className='col-lg ps-4' style={{ borderLeft: "1px solid black" }}>
                                                <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E" }}>Ads per day </h4>
                                                <h4 style={{ "fontSize": "26px", "fontWeight": "600" }}>17.38 </h4>

                                            </div>
                                            {/* 3 */}
                                            <div className='col-lg'></div>
                                            {/* 4 */}
                                            <div className='col-lg-4 pt-1'>
                                                <section className='d-flex justify-content-start'>
                                                    <span className=' bg-success mt-1' style={{ "borderRadius": "50%", "height": "14px", width: "14px" }} ></span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E", "lineHeight": "25px" }}>Mentions</h4>
                                                </section>
                                                <section className='d-flex  justify-content-start'>
                                                    <span className=' mt-1' style={{ "borderRadius": "50%", "height": "14px", width: "14px", background: "#2D59F5" }} ></span>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <h4 style={{ "fontSize": "16px", "fontWeight": "400", "color": "#7E7E7E", "lineHeight": "25px" }}>Ad Mentions</h4>
                                                </section>






                                            </div>





                                        </div>

                                        <div className='row '>

                                            <div className=' col-lg-12 '>

                                                {/* <HighchartsReact
                                                    width={450}
                                                    highcharts={Highcharts}
                                                    options={optionss}
                                                /> */}

                                                <HighchartsReact highcharts={Highcharts} options={baroptions1} />
                                            </div>
                                        </div>



                                    </section>


                                </div>
                            </div>


                            {/* SECTION 4  Overall Engagement & Views */}
                            <div className="row mt-2 p-2 pb-1" id="Engagement">
                                <div className="KardzProf mx-2" style={{
                                    width: "1027px ",
                                    height: "241px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Overall Engagement & Views</span>

                                        {/* 1 */}
                                        <div className='w-100 row bg-prmary py-2  mt-3' style={{ "borderBottom": "1px solid #DADADA" }} >

                                            <div className='col-lg-2 bg- text-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6516C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6516 17.6516C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6516 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="#C83B93" />
                                                </svg>
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Posts</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Followers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>34.2 M</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>10K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>ER</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>1.25 %</h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>


                                            </div>
                                        </div>

                                        {/* 2 */}
                                        <div className='w-100 row bg- py-2 mt-2' >

                                            <div className='col-lg-2 bg- text-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6516C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6516 17.6516C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6516 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="#C83B93" />
                                                </svg>
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Reels</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Followers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>34.2 M</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>10K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>Avg Reel Views</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "700" }}>ER</h4>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>1.25 %</h4>

                                            </div>
                                        </div>



                                    </section>


                                </div>

                            </div>
                            {/* SECTION 5  Likes to comments Ratio   /  Reels View to Like Ratio  /  Post Frequency */}
                            <div className="row p-2 pt-1 mb-2   " id="">
                                <div className="KardzProf ms-2" style={{ width: "508px ", height: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Likes to comments Ratio</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>41.25 %</div>
                                            {/* <input type='range' className='mt-1' /> */}

                                            <div className='px-2 my-2'>
                                                <ProgressBar variant="success" now={41} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>

                                        </div>
                                    </section>
                                </div>



                                <div className="KardzProf mx-2" style={{ width: "508px ", height: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Reels View to Like Ratio</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>47.25 %</div>
                                            <div className='px-2 my-2'>
                                                <ProgressBar variant="danger" now={47} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>
                                        </div>
                                    </section>
                                </div>



                                <div className="KardzProf mx-2 mt-2" style={{ width: "508px ", height: "80px", borderRadius: "10px", background: "white" }}>
                                    <section className='w-100 h-100 bg- py-2'>
                                        <div className='row px-4 py-2 bg- '>
                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Post Frequency</div>
                                            <div className='col-4 text-end   bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>
                                            <div className='px-2 my-2'>
                                                <ProgressBar variant="info" now={28} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                            </div>
                                        </div>
                                    </section>
                                </div>



                            </div>

                            {/* SECTION 6  Paid Partnership Engagement Vies */}
                            <div className="row p-2 pt-1 my-2" id="PaidEngagement">
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    height: "241px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>



                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Paid Partnership Engagement Vies</span>

                                        {/* 1 */}
                                        <div className='w-100 row bg-prmary py-2  mt-3' style={{ "borderBottom": "1px solid #DADADA" }} >

                                            <div className='col-lg-2 bg- text-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6516C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6516 17.6516C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6516 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="#C83B93" />
                                                </svg>
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Posts</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Followers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>34.2 M</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>10K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>ER</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>1.25 %</h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>


                                            </div>
                                        </div>

                                        {/* 2 */}
                                        <div className='w-100 row bg- py-2 mt-2' >

                                            <div className='col-lg-2 bg- text-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <path d="M9.75 2.5H20.25C24.25 2.5 27.5 5.75 27.5 9.75V20.25C27.5 22.1728 26.7362 24.0169 25.3765 25.3765C24.0169 26.7362 22.1728 27.5 20.25 27.5H9.75C5.75 27.5 2.5 24.25 2.5 20.25V9.75C2.5 7.82718 3.26384 5.98311 4.62348 4.62348C5.98311 3.26384 7.82718 2.5 9.75 2.5ZM9.5 5C8.30653 5 7.16193 5.47411 6.31802 6.31802C5.47411 7.16193 5 8.30653 5 9.5V20.5C5 22.9875 7.0125 25 9.5 25H20.5C21.6935 25 22.8381 24.5259 23.682 23.682C24.5259 22.8381 25 21.6935 25 20.5V9.5C25 7.0125 22.9875 5 20.5 5H9.5ZM21.5625 6.875C21.9769 6.875 22.3743 7.03962 22.6674 7.33265C22.9604 7.62567 23.125 8.0231 23.125 8.4375C23.125 8.8519 22.9604 9.24933 22.6674 9.54235C22.3743 9.83538 21.9769 10 21.5625 10C21.1481 10 20.7507 9.83538 20.4576 9.54235C20.1646 9.24933 20 8.8519 20 8.4375C20 8.0231 20.1646 7.62567 20.4576 7.33265C20.7507 7.03962 21.1481 6.875 21.5625 6.875ZM15 8.75C16.6576 8.75 18.2473 9.40848 19.4194 10.5806C20.5915 11.7527 21.25 13.3424 21.25 15C21.25 16.6576 20.5915 18.2473 19.4194 19.4194C18.2473 20.5915 16.6576 21.25 15 21.25C13.3424 21.25 11.7527 20.5915 10.5806 19.4194C9.40848 18.2473 8.75 16.6576 8.75 15C8.75 13.3424 9.40848 11.7527 10.5806 10.5806C11.7527 9.40848 13.3424 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6516C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6516 17.6516C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6516 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z" fill="#C83B93" />
                                                </svg>
                                                <h4 style={{ "fontSize": "18px", fontWeight: "600", "color": "#C83B93" }}>Reels</h4>
                                            </div>

                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Followers</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>34.2 M</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Avg Likes</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Avg Comments</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>10K</h4>

                                                    </div>
                                                    <div className='col-lg-3 text-center p-1'>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>Avg Reel Views</h4>
                                                        <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>424.1 K</h4>

                                                    </div>
                                                </section>
                                            </div>

                                            <div className='col-lg-2 text-center p-1'>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "600" }}>ER</h4>
                                                <h4 style={{ "fontSize": "14px ", fontWeight: "400" }}>1.25 %</h4>

                                            </div>
                                        </div>



                                    </section>






                                </div>
                            </div>

                            {/* SECTION 7  Audience Engagement */}
                            <div className="row p-2 pt-1 my-2" id="AudienceMetrics">
                                <div className="KardzProf ms-2 py-au" style={{
                                    width: "1027px ",
                                    height: "307px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>

                                    <section className='bg- p-4 h-100'>
                                        <h4 style={{ "fontSize": "18px", fontWeight: "600" }}>Audience Engagement</h4>
                                        <div className='w-100 row bg-ary mx' >
                                            <div className='col-lg-8 bg-'>
                                                <section className='row px-2'>


                                                    <div className='col-lg-6 bg-ndary '>
                                                        {/* city */}
                                                        <div className='row my-4  px-2 '>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top City </h5>
                                                            {/* <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >Nuh</div> */}
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >{topCityName}</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>{topCityPercent}%</div>
                                                            {/* <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>20%</div> */}
                                                            <div className='px-2 my-2'>
                                                                <ProgressBar variant="primary" now={topCityPercent} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                                {/* <ProgressBar variant="primary" now={20} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} /> */}
                                                            </div>
                                                            {/* <input type='range' className='mt-2' /> */}
                                                            {/* <ProgressBar variant="warning" now={41} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} /> */}
                                                        </div>

                                                        {/* State  */}
                                                        <div className='row my-5 mb-4 px-2 '>

                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top State </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >{topStateName}</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>28.9%</div>
                                                            <div className='px-2 my-2'>
                                                                <ProgressBar variant="info" now={30} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className='col-lg-6  bg-ing '>
                                                        {/* country  */}
                                                        <div className='row my-4 px-2'>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top Country </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >{topCountryName}</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>{topCountryPercentage}%</div>


                                                            <div className='px-2 my-2'>
                                                                <ProgressBar variant="warning" now={topCountryPercentage} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>

                                                        {/* age  */}
                                                        <div className='row my-5 mb-4 px-2'>
                                                            <h5 style={{ fontWeight: "400 ", fontSize: "16px", "lineHeight": "10px" }} className=''>Top Age Group </h5>
                                                            <div className='col-8 bg-er d-flex align-items-end ' style={{ fontWeight: "400 ", fontSize: "16px" }} >{topAgeGrp}</div>
                                                            <div className='col-4 text-end     bg- d-flex align-items-end justify-content-end' style={{ fontWeight: "600" }}>{topAgePercent}%</div>
                                                            <div className='px-2 my-2'>
                                                                <ProgressBar variant="danger" now={topAgePercent} style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                            </div>
                                                        </div>


                                                    </div>

                                                </section>

                                            </div>
                                            <div className='col-lg-4 '>

                                                <section className='row d-flex justify-content-center'>

                                                    <section className='col-lg-12 ' style={{ height: "200px", width: "200px" }}>
                                                        <Doughnut data={dataaaG} />


                                                        <section className='text-center  col-lg-12' style={{ fontWeight: "500", "fontSize": "16px" }}>
                                                            Genders
                                                        </section>
                                                    </section>



                                                </section>


                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>












                            {/* SECTION 8  Audience Geography */}
                            <div className="row p-2 pt-1 my-2" >
                                <div className="KardzProf ms-2" style={{
                                    width: "1027px ",
                                    height: "313px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}>


                                    <section className='bg- p-4 h-100'>

                                        <span style={{ "fontSize": "18px", fontWeight: "600" }}>Audience Geography </span>





                                        <div className='w-100 row bg-ary mx' >

                                            <div className='col-lg-6 bg-'>
                                                <section className='row ' >



                                                    <div className='col-lg-12  py-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>
                                                                {
                                                                    audienceGeo ?
                                                                        <>
                                                                            {audienceGeo[0]?.city}
                                                                            &nbsp;
                                                                            ({audienceGeo[0]?.state})
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}>

                                                                {
                                                                    audienceGeo && sumVal ?
                                                                        <>
                                                                            {((audienceGeo[0]?.value) * sumVal).toFixed(2)}
                                                                            %
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </section>
                                                        <ProgressBar variant="primary" now={
                                                            audienceGeo && sumVal ?
                                                                ((audienceGeo[0]?.value) * sumVal).toFixed(2)
                                                                :
                                                                null
                                                        } style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                    </div>




                                                    <div className='col-lg-12 py-2  pb-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}> {
                                                                audienceGeo ?
                                                                    <>
                                                                        {audienceGeo[1]?.city}
                                                                        &nbsp;
                                                                        ({audienceGeo[1]?.state})
                                                                    </>
                                                                    :
                                                                    null
                                                            }</div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}>   {
                                                                audienceGeo && sumVal ?
                                                                    <>
                                                                        {((audienceGeo[1]?.value) * sumVal).toFixed(2)}
                                                                        %
                                                                    </>
                                                                    :
                                                                    null
                                                            }</div>
                                                        </section>

                                                        <ProgressBar variant="warning" now={
                                                            audienceGeo && sumVal ?
                                                                ((audienceGeo[1]?.value) * sumVal).toFixed(2)
                                                                :
                                                                null
                                                        } style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />


                                                    </div>


                                                    <div className='col-lg-12 py-2 pb-3' >
                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>
                                                                {
                                                                    audienceGeo ?
                                                                        <>
                                                                            {audienceGeo[2]?.city}
                                                                            &nbsp;
                                                                            ({audienceGeo[2]?.state})
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}>   {
                                                                audienceGeo && sumVal ?
                                                                    <>
                                                                        {((audienceGeo[2]?.value) * sumVal).toFixed(2)}
                                                                        %
                                                                    </>
                                                                    :
                                                                    null
                                                            }</div>
                                                        </section>

                                                        <ProgressBar variant="success" now={
                                                            audienceGeo && sumVal ?
                                                                ((audienceGeo[2]?.value) * sumVal).toFixed(2)
                                                                :
                                                                null
                                                        } style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />
                                                    </div>



                                                    <div className='col-lg-12 py-3' >

                                                        <section className='row' >

                                                            <div className='col text-start' style={{ "fontWeight": "500", fontSize: "16px" }}>
                                                                {
                                                                    audienceGeo ?
                                                                        <>
                                                                            {audienceGeo[3]?.city}
                                                                            &nbsp;
                                                                            ({audienceGeo[3]?.state})
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className='col text-end' style={{ "fontWeight": "500", fontSize: "16px" }}>
                                                                {
                                                                    audienceGeo && sumVal ?
                                                                        <>
                                                                            {((audienceGeo[3]?.value) * sumVal).toFixed(2)}
                                                                            %
                                                                        </>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                        </section>

                                                        <ProgressBar variant="info" now={
                                                            audienceGeo && sumVal ?
                                                                ((audienceGeo[3]?.value) * sumVal).toFixed(2)
                                                                :
                                                                null
                                                        } style={{ "height": "8px", boxShadow: "0px 1px 8px 1px #888888" }} />

                                                    </div>


                                                </section>



                                            </div>




                                            <div className='col-lg-6 text-center'>
                                                <img src={require('../../../../../Imagez/Dashboard/Cards/WMap.png')} width={400} />
                                            </div>
                                        </div>
                                    </section>

                                </div>
                            </div>







                            {/* SECTION BRANDS MENTION  */}
                            {/* Mention dynamics on YouTube */}
                            <div className='monthly_report_section row  ms-2 Kardz my-4' id="Growth" style={{
                                width: "1027px",
                                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"
                            }} >
                                <div className='col-lg-12'>
                                    <label className='Xplore_Card_Topic'   >Growth In Last 30 Days</label> &nbsp;
                                    <i class='bx bx-info-circle' style={{ "position": "relative", "top": " 3px" }}></i>
                                    <div className='d-flex   '>

                                        <span className='d-flex flex-row align-items-center me-5 '>
                                            <section className='Xplore_rectab' />
                                            <section className='d-flex flex-column ms-3' style={{ "borderLeft": "" }}>
                                                <label className='three' style={{ "position": "relative", top: "32px" }}><p>Total Reach</p></label>
                                                <label className='two' style={{ "position": "relative", top: "18px", "fontSize": "2.375rem" }}><p>30.65M</p></label> &nbsp;
                                            </section>

                                        </span>


                                        <span className='d-flex flex-row align-items-center  '>
                                            <section className='Xplore_rectag' />
                                            <section className='d-flex flex-column ms-3 w-100 ' style={{ "borderLeft": "" }}>
                                                <label className='three' style={{ "position": "relative", top: "30px" }}><p>New Followers Gained</p></label>
                                                <label className='two' style={{ "position": "relative", top: "15px" }}><p style={{ "fontSize": "2.375rem" }}>566</p></label> &nbsp;
                                            </section>
                                        </span>





                                    </div>
                                </div>

                                <div className=''>
                                    {/* <img src={require('../../../../../Imagez/Dashboard/All/chart.png')} className='my-5 me-4' width={650} /> */}
                                    {/* {
                                        only_values && only_dates
                                            ? */}
                                    <div className='my-5 '>
                                        {
                                            isloader == false
                                                ?
                                                <HighchartsReact
                                                    width={650}
                                                    height={300}
                                                    highcharts={Highcharts}
                                                    options={options}
                                                />
                                                :
                                                <h4 style={{ "cursor": "pointer" }} onClick={() => { setLoader(false); showey(); }}>Click to open chart.</h4>

                                        }
                                    </div>

                                </div>
                            </div>

                            {/* SECTION 9   */}
                            <div className="row my-2 p-2" id="Brands">
                                {/* Card 1  */}
                                <div
                                    className="KardzProf mx-2"
                                    style={{
                                        width: "1027px ",
                                        height: "30rem",
                                        borderRadius: "10px",
                                        background: "white",
                                    }}
                                >
                                    {/* ABOVE ROW  */}
                                    <div className="row bg- mt-5">
                                        <div className="col-12 bg-">
                                            <i
                                                className="bx bx-star"
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "26px",
                                                    marginLeft: "2rem",
                                                }}
                                            ></i>{" "}
                                            &nbsp;{" "}
                                            <span
                                                className=""
                                                style={{ fontWeight: "700", fontSize: "24px" }}
                                            >
                                                Brands
                                            </span>
                                            {/* <i class='bx bxl-instagram-alt' style={{ "fontSize": "28px", "fontWeight": "600" }} ></i>
            <span style={{ "fontWeight": "700", "fontSize": "24px" }}>Content</span> */}
                                        </div>
                                    </div>
                                    {/* BOTTOM ROW  */}
                                    <div className="row bg- mt-4">
                                        <div className="col d-flex">
                                            <span
                                                style={{
                                                    paddingLeft: "1rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#C83B93",
                                                }}
                                            >
                                                All
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Automobile
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Device
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Telecommunication
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Watches And Eyewear
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Non-Alcoholic Beverages
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                E-Commerce
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                FMCG
                                            </span>
                                            <span
                                                style={{
                                                    paddingLeft: "2rem",
                                                    fontSize: "13.5px",
                                                    fontWeight: "600",
                                                    color: "#7E7E7E",
                                                }}
                                            >
                                                Applications
                                            </span>
                                            {/* <span style={{"paddingLeft":"2rem"}} >hi10</span> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div
                                                className="bg  d-flex justify-content-evenly p-1 py-3 mt-5"
                                                style={{ width: "100%", backgroundColor: "#F8F5FF" }}
                                            >
                                                <section
                                                    className="bg text-center  ms-2 p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                <section
                                                    className="bg text-center  p-2"
                                                    style={{
                                                        backgroundColor: "whitesmoke",
                                                        boxShadow: " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                                                        height: "12rem",
                                                        width: "12rem",
                                                    }}
                                                >
                                                    <span
                                                        style={{ fontSize: "14px", paddingBottom: "2px" }}
                                                    >
                                                        {" "}
                                                        <u>15 Posts </u>{" "}
                                                    </span>
                                                    <section>
                                                        {" "}
                                                        <img
                                                            src={Pepsi}
                                                            alt="img"
                                                            style={{ width: "7rem" }}
                                                        ></img>{" "}
                                                    </section>
                                                    <section>Pepsi</section>
                                                    <section style={{ fontSize: "12px" }}>@Pepsi</section>
                                                </section>
                                                {/* <section className='bg text-center mx-1 p-2' style={{ "backgroundColor": "whitesmoke", "boxShadow": " 0px 5px 30px 0px rgba(0, 0, 0, 0.10)", "height": "12rem", "width": "12rem" }}>
                <span style={{ "fontSize": "14px", "paddingBottom": "2px" }}> <u>15 Posts </u> </span>
                <section>   <img src={Pepsi} alt='img' style={{ "width": "7rem" }}></img>  </section>
                <section>Pepsi</section>
                <section style={{ "fontSize": "12px" }}>@Pepsi</section>
              </section> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* SECTION 4  Overall Engagement & Views */}
                            <div className="row mt-2 p-2 pb-1" id="VisitInstagram">
                                <div className="KardzProf mx-2 Faqz btnz" style={{
                                    width: "1027px ",
                                    height: "100px",
                                    borderRadius: "10px",
                                    background: ""
                                }}>


                                    <section className='bg- p-4 h-100 text-center '>

                                        <span className='bg- d-flex align-items-center justify-content-center  ' style={{ "fontSize": "30px", fontWeight: "700" }}>Visit Instagram  &nbsp; <i class='bx bxl-instagram' style={{ color: "", fontSize: "40px", fontWeight: "700" }}  ></i></span>







                                    </section>


                                </div>

                            </div>


                        </div>
                    </div>
                </div>



                {/* </div> */}
            </div>
        </>
    )
}

export default InfluencerInstagramSummary

