import React, { useState } from 'react'
import logo from '../../../Imagez/Common/logo.png'
import { useNavigate } from 'react-router-dom';

const LandinNav = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const InfluencersJump = () => {
        navigate("/Creators_LandingPage")
    }
    const showw = () => {
        setShow(true);
    }
    const notshoww = () => {
        setShow(false);
    }
    const buzz1 = () => {
        navigate("/Business_LandingPage")
        notshoww();
    }
    const buzz2 = () => {
        navigate("/Paid_Collabs_LandingPage")
        notshoww();
    }

    const SignUp = () => {
        navigate("/SignUp")
    }
    const LogIn = () => {
        navigate("/LogIn")
    }
    const Landed = () => {
        navigate("/")
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row  py-2'
                    // style={{ "zIndex": "5", "position":"fixed", width: "100%", background: "#0000002c" }} >
                    style={{ "zIndex": "5", "position": "", width: "100%" }} >


                    <section className='col-4 d-flex align-items-center justify-content-evenly'>

                        <img src={logo} className='Kardz' onClick={Landed} alt='logo' style={{ "width": "6rem", "position": "", "marginLeft": "2rem", cursor: "pointer" }} />

                        <section className=' texty navHovText' onClick={InfluencersJump} style={{ "fontWeight": "600", "fontSize": "16px", "color": "#FFFFFF", "position": "", "left": "-20%", "cursor": "pointer" }}>For Influencers</section>



                        <section className=' texty  navHovText d-flex align-items-center' onMouseOver={showw} style={{ "fontWeight": "600", "fontSize": "16px", "color": "#FFFFFF", "position": "", "left": "-120%", "cursor": "pointer" }}>For Brands
                            {
                                show
                                    ?
                                    <i class='bx bx-chevron-up' style={{ "fontSize": "20px" }}></i>
                                    :
                                    <i class='bx bx-chevron-down' style={{ "fontSize": "20px" }}></i>
                            }



                            {show ?
                                <span onMouseEnter={showw} onMouseLeave={notshoww} style={{ "height": "0px", "width": "0px", position: "relative", "zIndex": "5", top: "25px", left: "-155px" }} >
                            <section className='py-1' style={{ "zIndex": "5", "height": "", "width": "200px", "backgroundColor": "black", "border": "aqua 1px solid ", "borderRadius": "10px" }}>
                                <h1 className='py-1 hoverpe text-center text-light' onClick={buzz1} style={{ "fontSize": "14px", "cursor": "pointer" }}>DREABEE Business Suite   </h1>
                                <h1 className='pt-1 hoverpe text-center text-light' onClick={buzz2} style={{ "fontSize": "14px", "cursor": "pointer" }}>DREABEE Paid Collabs </h1>
                            </section>
                        </span>
                        : null
                            }

                    </section>

                </section>


                <section className='col-4 bg- d-flex align-items-center justify-content-evenly'>
                    {/* <section className='col-md texty navHovText' onClick={InfluencersJump} style={{ "fontWeight": "600", "fontSize": "16px", "color": "#FFFFFF", "position": "", "left": "-20%", "cursor": "pointer" }}>For Influencers</section> */}
                    {/* <input type='text' placeholder='enter your search here ' disabled width={60} /> */}

                    <section>
                        <input placeholder='Search creators & categories'
                            className=''
                            style={{
                                "boxSizing": "borderbox", "maxWidth": "", minWidth: "500px", "height": "40px",
                                "background": "#FFFFFF", "border": "2px solid #D12C8B", "borderRadius": "10px",
                                "fontWeight": "600", "fontSize": "14px", "paddingLeft": "1rem"
                            }} />
                    </section>
                    <span style={{ position: "relative", top: "-10px", left: "-30px", height: "0px", width: "0px" }}>
                        {/* <i className="fa-brands fa-instagram" style={{ "color": "#df20a3", "position": "", "left": "-5%", "top": "5%" }}></i> */}
                        <i class='bx bx-search-alt btnz' style={{ "color": "#df20a3" }}></i>
                    </span>
                </section>


                <section className='col-4 d-flex align-items-center justify-content-evenly bg-'>

                    {/* <section className='texty navHovText1 ' style={{ "fontSize": "16px", "fontWeight": "700", "color": "#C83B93", "cursor": "pointer" }} >
                            <u>
                                Sign in
                            </u>
                        </section> */}



                    <span className=' d-flex justify-content-center align-items-center navHovText2 ' onClick={LogIn}
                        style={{ "border": "0px solid #FFFFFF", "borderRadius": "200px", "width": "80px", "height": "35px", "cursor": "pointer" }}>

                        <p className='text1 '>Log in</p>
                    </span>
 
                    <span className=' d-flex justify-content-center align-items-center navHovText2 ' onClick={SignUp}
                        style={{ "border": "0px solid #FFFFFF", "borderRadius": "200px", "width": "80px", "height": "35px", "cursor": "pointer" }}>

                        <p className='text1 '>Sign up</p>
                    </span>

                    <span className='bg-light d-flex justify-content-center align-items-center btnz' style={{
                        "border": "2px solid #FFFFFF", "borderRadius": "200px",
                        "width": "200px", "height": "40px", "cursor": "pointer"
                    }}>


                        <text className=' d-flex align-items-center justify-content-center' style={{ "color": "#C83B93",fontWeight :"600" }}>

                            Post a Campaign Brief &nbsp;<i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i>

                        </text>
                    </span>
                </section>

            </div>
        </div>
        </>
    )
}

export default LandinNav