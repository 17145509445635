import React from 'react'

import CommFoot from '../CommonComponents/CommFoot'
import FlowerPhoto2 from '../Landinpg/LandinPg_Sub/FlowerPhoto2'

import line from '../../Imagez/BusinessLandinPgImg/Radio_Lined Up.png'
import bar1 from '../../Imagez/BusinessLandinPgImg/Bars1.png'
import bar2 from '../../Imagez/BusinessLandinPgImg/Bars2.png'
import left from '../../Imagez/BusinessLandinPgImg/left.png'
import right from '../../Imagez/BusinessLandinPgImg/right.png'
import trapbox from '../../Imagez/BusinessLandinPgImg/Credits1.png'
import BussCard1 from './BussCard1'
import { BussCard2 } from './BussCard2'
import Kard1 from '../Landinpg/LandinPg_Sub/kard1'
import Slider from 'react-slick'
import Kard2 from '../Landinpg/LandinPg_Sub/Kard2'
import RangeBar1 from './RangeBar1'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import BussLandinFoot from './BussLandinFoot'



const BussLandinSec2 = () => {

    function SquarePrevArrow(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "absolute",
                    top: "80%", right: "50%",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50% 0 0 50%",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"

                }
                }
                onClick={onClick} >
                <i class='bx bx-chevron-left' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    function SquareNextArrow(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "absolute",
                    top: "80%", left: "50%",

                    height: "60px",
                    width: "60px",
                    borderRadius: " 0 50% 50% 0",


                }}
                onClick={onClick}
            >
                <i class='bx bx-chevron-right' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    const settings2 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: false,
        nextArrow: false,
        nextArrow: <SquareNextArrow />,
        prevArrow: <SquarePrevArrow />
    };



    function SquarePrevArrow2(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "relative",
                    left: "42.8rem", top: "25rem",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50% 0 0 50%",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"

                }
                }
                onClick={onClick} >
                <i class='bx bx-chevron-left' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    function SquareNextArrow2(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "relative",
                    top: "-8.7rem", left: "48.7rem",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: " 0 50% 50% 0",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"


                }}
                onClick={onClick}
            >
                <i class='bx bx-chevron-right' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    const settings3 = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        // nextArrow: <SquareNextArrow2 />,
        // prevArrow: <SquarePrevArrow2 />
    };





    const textRef = useRef(null);

    // REFFF 1
    const [ref1, inView1] = useInView({
        triggerOnce: true,
        threshold: 0.4
    });



    // REFFF 2
    const [ref2, inView2] = useInView({
        // triggerOnce: true, 
        threshold: 0.4
    });
    // REFFF 3
    const [ref3, inView3] = useInView({
        // triggerOnce: true, 
        threshold: 0.4
    });


    // REFFF 4
    const [ref4, inView4] = useInView({
        triggerOnce: true,
        threshold: 0.4
    });
    // REFFF 5
    const [ref5, inView5] = useInView({
        triggerOnce: true,
        threshold: 0.4
    });


    // REFFF 6
    const [ref6, inView6] = useInView({
        // triggerOnce: true, 
        threshold: 0.4
    });
    // REFFF 7
    const [ref7, inView7] = useInView({
        triggerOnce: true,
        threshold: 0.04, delay: 500
    });




    // REFFF 8
    const [ref8, inView8] = useInView({
        triggerOnce: true,
        threshold: 0.04,
        delay: 500
    });
    // REFFF 9
    const [ref9, inView9] = useInView({
        // triggerOnce: true, 
        threshold: 0.5,
        delay: 500
    });
    // REFFF 10
    const [ref10, inView10] = useInView({
        // triggerOnce: true, 
        threshold: 0.02,
        delay: 500
    });


    // REFFF 11
    const [ref11, inView11] = useInView({
        // triggerOnce: true, 
        threshold: 0.05,
        delay: 500
    });
    // REFFF 12
    const [ref12, inView12] = useInView({
        // triggerOnce: true, 
        threshold: 0.05,
        delay: 500
    });



    // REFFF 13
    const [ref13, inView13] = useInView({
        // triggerOnce: true, 
        threshold: 0.05,
        delay: 500
    });
    // REFFF 14
    const [ref14, inView14] = useInView({
        // triggerOnce: true, 
        threshold: 0.05,
        delay: 500
    });








    const navigate = useNavigate();

    const signIn = () => {
        navigate("/SignUp")
    }


    return (
        <>
            <section className='row h-25'>
                {/* part 1 -logoz box */}
                <section className='bgWhytS '  style={{ "height": "20rem"}}>
                    <section style={{ "position": "relative", "top": "-0.899rem" }}>
                        <div className='col-10 '  >
                            <section className={`fade-in-text-up ${inView1 ? 'fade-in-up huhuL Kardz' : 'huhuL Kardz'}`} ref={ref1} style={{ "boxSizing": "borderBox", "position": "relative", "width": "1320px", "height": "122px", "left": "5rem", "top": "-6.8rem", zIndex: "10" }}>
                                {/* logo row  */}
                                <div className='row d-flex justify-evenly pt-4 ' style={{ zIndex: "10" }}>
                                    <div className='col-1'></div>
                                    <div className='col '>
                                        <i className=" GlowOne fa-brands fa-apple mx-3" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>

                                    <div className='col '>
                                        <i className="GlowOne fa-brands fa-amazon me-3" style={{ "fontSize": "5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col '>
                                        <i className="GlowOne fa-brands fa-google mx-3" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "6rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col '>
                                        <i className="GlowOne fa-brands fa-youtube mx-3" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "8rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col '>
                                        <i className="GlowOne fa-solid fa-hippo mx-3" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col '>
                                        <i className="GlowOne fa-brands fa-wordpress mx-3" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col '>
                                        <i className="GlowOne fa-brands fa-windows" style={{ "fontSize": "4rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col-1'></div>
                                </div>
                            </section>
                        </div>


                        <section style={{ "backgroundColor": "none", "position": "relative", "top": "-3rem" }}>
                            <div className='  d-flex justify-content-center'>
                                <section>
                                    <div className={`fade-in-text-right ${inView2 ? 'fade-in-right  text-center' : ' col'}`} ref={ref2} >
                                        <h3 style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "" }}>The difference</h3>
                                        <h3 style={{ "fontSize": "3.2rem", "fontWeight": "700", "color": "#C83B93" }}>DREABEE makes</h3>

                                    </div>

                                    <div className={`fade-in-text-right ${inView3 ? 'fade-in-right  text-center' : ' col'}`} ref={ref3} >
                                        <h3 style={{ "fontSize": "1.2rem", "fontWeight": "700", "width": "37rem", "marginTop": "0.5rem", "lineHeight": "30px" }}>DREABEE gives a simplified experience at every step of your journey to run measurable influencer campaigns. Even with low bandwidth, no expertise & limited budget.</h3>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </section>
                </section>

                {/* part 2 */}
                <section className='bgWhytS ' style={{ "height": "40rem", "paddingTop": "2rem" }} id='BusSuite'>
                    <div className='row'>
                        <div className={`fade-in-text-up ${inView4 ? 'fade-in-up  col-6' : ' col-6'}`} ref={ref4}>
                            {/* <span style={{ "height": "0", "width": "0", "position": "relative" }}>
                                <div className='bg-info p-3' style={{ "height": "", "width": "px" }}> hehe</div>
                            </span> */}
                            <span style={{ "position": "relative", "left": "12rem", "top": "5.5rem" }}>
                                <div className='row' style={{ "width": "28rem" }}>
                                    <p style={{ "fontSize": "1.2rem", "fontWeight": "600" }}> Discover relevant creators from our extensive database of over 300,000. Filter them by category, location, follower range, and more.  </p>
                                </div>
                                <div className='row'>
                                    <h2 style={{ "fontWeight": "700", "fontSize": "2.2rem", "marginTop": "2rem" }}>Request to Collaborate</h2>
                                </div>
                                <div className='row'>
                                    <h2 style={{ "fontWeight": "700", "fontSize": "2.2rem", "marginTop": "4rem", "paddingTop": "" }}>Negotiate Cost</h2>
                                </div>
                                <div className='row'>
                                    <h2 style={{ "fontWeight": "700", "fontSize": "2.2rem", "marginTop": "4rem" }}>Measure Result</h2>
                                </div>
                                <img src={line} alt='line' style={{ "height": "30rem", "position": "relative", "left": "-6rem", "top": "-30rem" }} />
                            </span>
                        </div>
                        <div className={`fade-in-text-up ${inView5 ? 'fade-in-up  col-6' : ' col-6'}`} ref={ref5}>
                            <span style={{ "position": "relative", "top": "-2rem", "left": "0rem" }}><FlowerPhoto2 style={{ "width": "12rem" }} /></span>
                        </div>
                    </div>

                    {/* <section style={{ "position": "relative", "top": "" }}>
                        <span style={{ "width": "0rem", "position": "relative", "top": "-80rem", "left": "10rem", "zIndex": "1" }}> <section className='Ellipse4' style={{ "width": "15rem", "height": "10rem", "zIndex": "1", }} />   </span> 
                        <span style={{ "width": "0rem", "position": "relative", "top": "10rem", "left": "20rem", "zIndex": "-11" }}> <section className='Ellipse3' style={{ "width": "10rem", "height": "2rem", "zIndex": "1" }} />   </span> 
                        <span style={{ "width": "20rem", "position": "relative", "top": "-95rem", "left": "50rem", "zIndex": "1" }}> <section className='Ellipse3' style={{ "width": "10rem", "height": "15rem", "zIndex": "1" }} />  </span>
                    </section> */}

                </section>

                {/* part 3 SQUARE CARDS  */}
                <div className='row d-flex align-items-center  bgWhytS' style={{ "height": "40rem", paddingTop: "5rem", paddingBottom: "30rem" }} >

                    {/* HEADING  */}
                    <section className={`fade-in-text-right ${inView6 ? 'fade-in-right ' : ''}`} ref={ref6}>
                        {/* <h1 className='text-center' style={{ "fontSize": "2.2rem", "fontWeight": "700", "color": "#D461A8" }}> Key Features</h1> */}
                        {/* <h1 className='text-center' style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "black", "position": "relative", "top": "" }}> You are all covered with DREABEE, from finding influencers, and collaborating to measuring the campaign ROI. We have every tool required to run a successful influencer campaign.</h1> */}

                        <div className={`fade-in-text-right ${inView6 ? 'fade-in-right col-12 text-center' : 'col-12 text-center'}`} ref={ref6} >
                            <h1 style={{ "fontWeight": "700", "fontSize": "3rem", "color": "", "zIndex": "4" }}>Key Features</h1>
                            <hr className=' mx-auto' style={{ "width": "10%", "height": "6px", "color": "none", "backgroundColor": "#D42E90", "borderRadius": "20rem", "zIndex": "4" }} />
                        </div>

                        <div className={`fade-in-text-right ${inView6 ? 'fade-in-right col-12  mt-4' : 'col-12  mt-4'}`} ref={ref6}>
                            <h1 className='text-center' style={{ "lineHeight": "35px", "fontSize": "1.2rem", "fontWeight": "700", "marginLeft": "15rem", "width": "60rem" }}>
                                You are all covered with DREABEE, from finding influencers, and collaborating to measuring the campaign ROI.
                                We have every tool required to run a successful influencer campaign.
                            </h1>
                        </div>


                    </section>

                    <section>
                        <Slider className='roundImg ' {...settings2} style={{ "paddingBottom": "5rem", marginLeft: "-10px", marginRight: "-30px" }}>

                            {/* 1  */}
                            <div className=''>
                                <section className=' d-flex justify-content-center' style={{ "top": "1rem" }}>
                                    <Kard1 />
                                </section>

                            </div>
                            {/* 2 */}
                            {/* 1  */}
                            <div className=''>
                                <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                    <Kard1 />
                                </section>

                            </div>
                            {/* 2 */}
                            {/* 1  */}
                            <div className=''>
                                <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                    <Kard1 />
                                </section>

                            </div>
                            {/* 2 */}
                            {/* 1  */}
                            <div className=''>
                                <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                    <Kard1 />
                                </section>

                            </div>
                            {/* 2 */}
                            {/* 1  */}
                            <div className=''>
                                <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                    <Kard1 />
                                </section>

                            </div>
                            {/* 2 */}

                        </Slider>
                    </section>
                </div>



                {/* part 4 */}
                <section className='bgWhytS' style={{ "height": "52rem", "paddingTop": "5rem", "zIndex": "2" }
                }>
                    <div className='row' style={{ "zIndex": "2" }}>

                        <div className='col-4 text-center' style={{ "zIndex": "2" }}>
                            <img src={left} className={`pop-in ${inView10 ? 'pop pt-5' : 'pt-5'}`} ref={ref10} alt='img1' style={{ "width": "22rem", "zIndex": "2" }} />
                        </div>


                        <div className='col-4' >


                            <section className={`fade-in-text-up ${inView7 ? 'fade-in-up ' : ''}`} ref={ref7} >

                                <h1 style={{ "width": "50rem", "position": "relative", "left": "-6rem", "top": "7rem" }}> <span style={{ "fontWeight": "700", "fontSize": "3rem", "color": "#7E7E7E", }}>Low to No experience running </span> <br />
                                </h1>

                                <h1 style={{ "width": "50rem", "position": "relative", "top": "6rem", "left": "1rem", "fontWeight": "700", "fontSize": "3rem", }}>
                                    influencer campaigns?
                                </h1>

                                <hr className={`fade-in-text-right ${inView9 ? 'fade-in-right mx-auto' : 'mx-auto'}`} ref={ref9} style={{ "width": "30%", "height": "6px", "position": "relative", "top": "5.5rem", "color": "none", "backgroundColor": "#D42E90", "borderRadius": "20rem" }} />
                            </section>


                            <section className={`fade-in-text-up ${inView8 ? 'fade-in-up ' : ''}`} ref={ref8} >
                                <h1 style={{ "width": "50rem", "position": "relative", "top": "12rem", "left": "2rem", "fontWeight": "700", "fontSize": "3rem", "color": "#C83B93" }}>
                                    DREABEE for you!
                                </h1>

                                {/* button  */}
                                <button className='UnEvenBtnz' onClick={signIn} style={{ "zIndex": "2", "width": "310px", "height": "64px", "backgroundColor": "transparent", "border": "2px solid #D12C8B", "borderRadius": "500px", "marginTop": "13rem", "marginLeft": "4rem" }}>
                                    <span style={{ "fontWeight": "700", "fontSize": "24px", "lineHeight": "34px", "textAlign": "center", "color": "#C83B93" }}>Get Started for free <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i> {/* <i className="fa-solid fa-arrow-up-right-from-square" style={{"color":"white"}}></i> */} </span>
                                </button>
                            </section>



                        </div>

                        <div className='col-4 text-center'>
                            <img src={right} alt='img2' className={`pop-in ${inView10 ? 'pop pt-5' : 'pt-5'}`} ref={ref10} style={{ "width": "20rem", "zIndex": "1" }} />
                        </div>

                    </div>

                    <section style={{ "position": "relative", "top": "" }}>
                        <span style={{ "width": "0rem", "position": "relative", "top": "-40rem", "left": "30rem", "zIndex": "1" }}> <section className='Ellipse4' style={{ "width": "10rem", "height": "10rem" }} />   </span>
                        <span style={{ "width": "20rem", "position": "relative", "top": "-40rem", "left": "" }}> <section className='Ellipse3' style={{ "width": "15rem", "height": "15rem", "zIndex": "1" }} />  </span>
                        <span style={{ "width": "20rem", "position": "relative", "top": "-50rem", "left": "50rem", "zIndex": "1" }}> <section className='Ellipse3' style={{ "width": "10rem", "height": "15rem", "zIndex": "1" }} />  </span>

                    </section>
                </section>

                {/* part 4 LONG CARDS RECTANGLE   */}
                <div className='row  bgWhytS align-items-center' style={{ "height": "30rem" }} id='Testimonialz'>

                    {/* HEADING  */}
                    <section className={`fade-in-text-right ${inView11 ? 'fade-in-right ' : ''}`} ref={ref11} style={{ position: "relative", top: "20px", "marginBottom": "0rem" }}>
                        <h1 className='text-center' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "#D461A8" }}> What Make us special -</h1>
                        <h1 className='text-center' style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "black", "position": "relative", "top": "" }}>Trusted by All business</h1>
                    </section>


                    {/* CARDS  */}
                    <section>
                        <Slider {...settings3} className=' my-0 ' style={{ height: "15rem", "paddingBottom": "1rem", marginLeft: "-10px", marginRight: "-35px", zIndex: "2" }}>


                            <div className='d-flex justify-content-center align-items-center'>
                                <Kard2 />
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <Kard2 />
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <Kard2 />
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <Kard2 />
                            </div>

                            <div className='d-flex justify-content-center align-items-center'>
                                <Kard2 />
                            </div>


                        </Slider>
                    </section>

                    <section style={{ "position": "relative", "top": "-30rem", height: "0px", zIndex: "1" }}>
                        <span><img src={bar2} style={{ "width": "40rem", "position": "absolute", "top": "2rem", "left": "70rem", "transform": "rotate(135deg)", "zIndex": "1 " }} alt='bar2' /></span>
                        <span><img src={bar1} style={{ "width": "40rem", "position": "absolute", "top": "-4rem", "left": "-20rem", "transform": "rotate(45deg)", "zIndex": "1", "marginTop": "6rem" }} alt='bar1' /></span>
                    </section>


                </div>


                {/* part 5 footers */}
                <div className='bgFooter' style={{ "height": "", "paddeingTop": "10rem", "zIndex": "-5", "overflow": "hidden" }} id='Pricing' >
                    <BussLandinFoot />
                </div>


            </section>
        </>
    )
}

export default BussLandinSec2