import React, { useState } from 'react'
import pho1 from '../../Imagez/LandinPgImg/photo1.png'
import pho2 from '../../Imagez/LandinPgImg/photo2.png'
import img1 from '../../Imagez/LandinPgImg/img-1.png'
import img2 from '../../Imagez/LandinPgImg/img-2.png'
import graph from '../../Imagez/LandinPgImg/Graph.png'
import Kard1 from './LandinPg_Sub/kard1'
import Kard2 from './LandinPg_Sub/Kard2'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { isVisible } from '@testing-library/user-event/dist/utils'
import { $CombinedState } from 'redux'


const LandinSec2 = () => {


    function RoundPrevArrow(props) {
        const { RoundArrowP, style, onClick } = props;

        return (
            <div
                className={RoundArrowP}
                style={{

                    // marginTop: "5rem",
                    display: "flex", justifyContent: "center", alignItems: "center",
                    position: "absolute",
                    top: "11rem", left: "15px",
                    zIndex: "2",
                    background: "white",
                    border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "0 50% 50% 0",
                    marginLeft: "-2rem",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"

                }
                }
                onClick={onClick} >
                <i class='bx bx-chevron-left' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    function RoundNextArrow(props) {
        const { RoundArrowN, style, onClick } = props;
        return (
            <div
                className={RoundArrowN}
                style={{
                    zIndex: "2",
                    display: "flex", justifyContent: "center", alignItems: "center",
                    position: "absolute",
                    top: "11rem", right: "0px",
                    right: "10px",
                    background: "white",
                    border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50% 0 0 50%",
                    marginLeft: "2rem",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"
                }}
                onClick={onClick}

            >
                <i class='bx bx-chevron-right' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    const settings1 = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 3,
        prevArrow: false,
        nextArrow: false,
        nextArrow: <RoundNextArrow />,
        prevArrow: <RoundPrevArrow />
    };
    function SquarePrevArrow(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "absolute",
                    top: "90%", right: "50%",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50% 0 0 50%",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"

                }
                }
                onClick={onClick} >
                <i class='bx bx-chevron-left' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    function SquareNextArrow(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "absolute",
                    top: "90%", left: "50%",

                    height: "60px",
                    width: "60px",
                    borderRadius: " 0 50% 50% 0",


                }}
                onClick={onClick}
            >
                <i class='bx bx-chevron-right' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    const settings2 = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: false,
        nextArrow: false,
        nextArrow: <SquareNextArrow />,
        prevArrow: <SquarePrevArrow />
    };

    function SquarePrevArrow2(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "relative",
                    left: "42.8rem", top: "25rem",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50% 0 0 50%",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"

                }
                }
                onClick={onClick} >
                <i class='bx bx-chevron-left' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    function SquareNextArrow2(props) {
        const { btnz, style, onClick } = props;
        return (
            <div
                className="btnz"
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center",
                    zIndex: "2",
                    position: "relative",
                    top: "-8.7rem", left: "48.7rem",
                    // background: "white",
                    // border: "1px solid gray",
                    height: "60px",
                    width: "60px",
                    borderRadius: " 0 50% 50% 0",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)"


                }}
                onClick={onClick}
            >
                <i class='bx bx-chevron-right' style={{ fontSize: "32px" }}></i>
            </div>
        );
    }
    const settings3 = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        // nextArrow: <SquareNextArrow2 />,
        // prevArrow: <SquarePrevArrow2 />
    };

    const navigate = useNavigate();
    const GoToLogin = () => {
        navigate("/LogIn")
    }


    // animation section start


    const textRef = useRef(null);
    // REFFF 1
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.04, // Trigger when at least 50% of the element is in view
        
        delay: 500
    });
    // REFFF 2
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
    
        delay: 500
    });
    // REFFF 3
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        
        delay: 500
    });
    // REFFF 4
    const [ref4, inView4] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.5, // Trigger when at least 50% of the element is in view
        
        delay: 500
    });
    // REFFF 5
    const [ref5, inView5] = useInView({
        // triggerOnce: true, // Only trigger the animation once
        threshold: 0.2, // Trigger when at least 50% of the element is in view
    
        delay: 500
    });
    // REFFF 6
    const [ref6, inView6] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.5, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF 
    const [ref7, inView7] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF 8
    const [ref8, inView8] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF 2
    const [ref9, inView9] = useInView({
        triggerOnce: false, // Only trigger the animation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF REFF REFFREFF REFF

    document.addEventListener("DOMContentLoaded", function () {
        const flipElement = document.querySelector(".flip-element");

        if (flipElement) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            flipElement.classList.add("flip-start");
                            observer.unobserve(flipElement); // Stop observing once it's in view
                        }
                    });
                },
                {
                    threshold: 0.5, // Trigger when at least 50% of the element is in view
                }
            );

            observer.observe(flipElement);
        }
    });

    // animation section end



    const [active, setActive] = useState(false)





    return (
        <>
            <div className='' style={{ "height": "100%", "backgroundColor": "white", "zIndex": "-5" }}>
                <section className='container-fluid'>


                    {/*1-LOGO-BANNER-SECTION --- BACKGROUND-BARS */}
                    <div className='row bg-' style={{ "height": "rem" }}>

                        <div className='col-12 bg-success d-flex justify-content-center' style={{ "height": "0px" }} >

                            <section
                                className={`fade-in-text-up ${inView ? 'fade-in-up huhu Kardz d-flex  justify-content-evenly flex-column ' : 'huhu Kardz'}`} ref={ref}

                                style={{
                                    "boxSizing": "borderBox", "position": "relative",
                                    "width": "1220px", "height": "250px", "left": "", "top": "-6.8rem"
                                }}>

                                {/* LOGO-TOP  */}
                                <div className='row d-flex  align-items-center pt-4 '>

                                    <div className='col bg- text-center'>
                                        <i className=" GlowOne fa-brands fa-apple " style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col  text-center'>
                                        <i className=" GlowOne fa-brands fa-amazon " style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col  text-center'>
                                        <i className=" GlowOne fa-brands fa-google" style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "6rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col  text-center'>
                                        <i className=" GlowOne fa-brands fa-youtube" style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "8rem", "top": "1.5rem" }}></i>
                                    </div>
                                    <div className='col  text-center'>
                                        <i className=" GlowOne fa-solid fa-hippo " style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col  text-center'>
                                        <i className=" GlowOne fa-brands fa-wordpress" style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>
                                    <div className='col text-center'>
                                        <i className=" GlowOne fa-brands fa-windows" style={{ "fontSize": "3.5rem", "color": "#FFFFFF", "position": "", "left": "4rem", "top": "" }}></i>
                                    </div>

                                </div>

                                {/* TEXT-BOTTOM */}
                                <div className='row  mt-auto d-flex justify-content-center' style={{ "marginBottom": "3.8rem" }}>



                                    <div className='col-3'>

                                        <section className='row d-flex justify-content-center '>


                                            <section className='col-2 text-end'>
                                                <i className="fa-solid fa-user p-3 mt-1 text-center"
                                                    style={{ "fontSize": "1.2rem", "backgroundColor": "black", "color": "white", "borderRadius": "50%" }}></i>
                                            </section>


                                            <section className='col-8 ' style={{ "position": "relative", "left": "10px" }}>

                                                <h4 className='my-0' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "black" }}>375K</h4>

                                                <text className='my-0' style={{ "fontSize": "1rem", "fontWeight": "700", "color": "#7E7E7E", "marginLeft": "0px" }}> Creators & Influencers</text>

                                            </section>


                                        </section>
                                    </div>



                                    <div className='col-3'>
                                        <section className='row   d-flex justify-content-center '>

                                            <section className='col-2 text-end'>
                                                <i className="fa-solid fa-user p-3 mt-1 text-center"
                                                    style={{ "fontSize": "1.2rem", "backgroundColor": "black", "color": "white", "borderRadius": "50%" }}></i>
                                            </section>


                                            <section className='col-8 ' style={{ "position": "relative", "left": "10px" }}>
                                                <h4 className='my-0' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "black" }}>189K</h4>
                                                <p className='my-0' style={{ "fontSize": "1rem", "fontWeight": "700", "color": "#7E7E7E", "marginLeft": "4px" }}>Creators Created</p>
                                            </section>

                                        </section>
                                    </div>



                                    <div className='col-3'>
                                        <section className='row  d-flex justify-content-center  '>

                                            <section className='col-2 text-end'>
                                                <i className="fa-solid fa-user p-3 mt-1 text-center"
                                                    style={{ "fontSize": "1.2rem", "backgroundColor": "black", "color": "white", "borderRadius": "50%" }}></i>
                                            </section>


                                            <section className='col-8 ' style={{ "position": "relative", "left": "10px" }}>
                                                <h4 className='my-0' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "black" }}>$89.7cr</h4>
                                                <p className='my-0' style={{ "fontSize": "1rem", "fontWeight": "700", "color": "#7E7E7E", "marginLeft": "4px" }}>Marketing Budget saved</p>
                                            </section>

                                        </section>
                                    </div>


                                </div>
                            </section>
                        </div>
                    </div>


                    {/*2-BUTTONS-THREE-CARDS-SECTION*/}
                    <div className='row bg-' style={{ "zIndex": "2", "paddingTop": "15rem", "paddingBottom": "0rem" }}>




                        {/* BUTTONS  */}
                        <div className='col-6  '>
                            <section style={{ "marginLeft": "8rem" }} className={`fade-in-text-up ${inView1 ? 'fade-in-up  ' : ' '}`} ref={ref1}>

                                {/* Heading  */}
                                <span className=''
                                    style={{
                                        "position": "relative", "left": "2px", "top": "-2rem",
                                        "fontWeight": "700", "fontSize": "48px", "lineHeight": "60px", "color": "#C83B93"
                                    }}>
                                    Powerful tools to find,
                                    <br />
                                    connect, and<br />
                                    collaborate
                                </span>

                                {/* Buttons List  */}
                                <section>

                                    <button className='' style={{
                                        "width": "500px", "height": "70px", "border": "none",
                                        "background": "#FFFFFF", "boxShadow": "0px 5px 20px rgba(0, 0, 0, 0.15)", "borderRadius": "500px"
                                    }}>

                                        <div className='row'>
                                            <div className='col-2'><span className='hex'></span> </div>
                                            <div className='col-10 text-start '>
                                                <span className='bg-' style={{
                                                    position: "relative", top: "10px", "width": "290px", "height": "34px", "fontWeight": "600", "fontSize": "16px", "color": "#7E7E7E"
                                                }}>Explore our powerful tools</span>
                                                <br />
                                                <span className='bg- py-0' style={{
                                                    "width": "455px", "height": "50px", "fontWeight": "600", "fontSize": "28px", "color": "#000000"
                                                }}>DREABEE Business Suite</span>
                                            </div>
                                        </div>
                                    </button>


                                    <section className=''>
                                        {/* 1 */}
                                        <button className={`fade-in-text-up ${inView1 ? 'fade-in-up  mula-hov' : ' mula-hov'}`} ref={ref1} style={{ "boxShadow": "0px 5px 20px rgba(0, 0, 0, 0.15)" }}>
                                            <div className='row'>
                                                <div className='col-2'><span className='mulaHex'></span> </div>
                                                <div className='col-10'>
                                                    <div className='mulaText text-start'>Creator Search</div>
                                                </div>
                                            </div>
                                        </button>
                                        {/* 2 */}
                                        <button className={`fade-in-text-up ${inView1 ? 'fade-in-up  mula-hov' : ' mula-hov'}`}
                                            ref={ref1} style={{ "boxShadow": "0px 5px 20px rgba(0, 0, 0, 0.15)" }} >
                                            <div className='row'>
                                                <div className='col-2'><span className='mulaHex'></span> </div>
                                                <div className='col-10'>
                                                    <div className='  mulaText  text-start' >Creator Profile</div>
                                                </div>
                                            </div>
                                        </button>
                                        {/* 3 */}
                                        <button className={`fade-in-text-up ${inView1 ? 'fade-in-up  mula-hov' : ' mula-hov'}`} ref={ref1} style={{ "boxShadow": "0px 5px 20px rgba(0, 0, 0, 0.15)" }}   >
                                            <div className='row'>
                                                <div className='col-2'><span className='mulaHex'></span> </div>
                                                <div className='col-10'>
                                                    <div className=' mulaText text-start' >Creator Outreach</div>
                                                </div>
                                            </div>
                                        </button>
                                        {/* 4 */}
                                        <button className={`fade-in-text-up ${inView1 ? 'fade-in-up  mula-hov' : ' mula-hov'}`} ref={ref1} style={{ "boxShadow": "0px 5px 20px rgba(0, 0, 0, 0.15)" }}  >
                                            <div className='row bg-'>
                                                <div className='col-2 bg-'><span className=' mulaHex'></span> </div>
                                                <div className='col-10'>
                                                    <div className='  mulaText text-start'>Creator Reporting</div>
                                                </div>
                                            </div>
                                        </button>

                                    </section>

                                </section>
                            </section>
                        </div>

                        {/* CARDS  */}
                        <div className='col-6 '>
                            <div className='row' style={{ "marginTop": "-3rem", zIndex: "2" }}>
                                <div className='col-12' >
                                    <section className={`fade-in-text-up ${inView1 ? 'fade-in-up  ' : ' '}`} ref={ref1}><img src={img2} alt='img' style={{ "width": "32rem", "position": "relative", "left": "4rem" }} /></section>
                                </div>

                                <div className='col-6'>
                                    <section className={`fade-in-text-up ${inView2 ? 'fade-in-up  ' : ' '}`} ref={ref2} style={{ "height": "1px", width: "1px" }}>
                                        <img src={img1} alt='img1' style={{ "width": "14rem", "position": "relative", "top": "-32rem", "left": "-6rem", zIndex: "2" }} />
                                    </section>
                                </div>

                                <div className='col-6'>
                                    <section className={`fade-in-text-up ${inView2 ? 'fade-in-up  ' : ' '}`} ref={ref2} style={{ "height": "1px", width: "1px" }}>
                                        <img src={img1} alt='img1' style={{ "width": "14rem", "position": "relative", "top": "-18rem", "left": "8rem", zIndex: "2" }} />
                                    </section>
                                </div>
                            </div>




                            {/* <div className='col-12 bg-info text-start'> */}
                            {/* <section className=' bg-success' style={{ "height": "0px", width: "0px", "position": "relative", "top": "-30rem", "left": "-45rem" }}>
                                        <section className='rect ' style={{zIndex:"1"}}></section>
                                        <section className='rect2 ' style={{zIndex:"1"}}></section>
                                        <section className='rect3 ' style={{zIndex:"1"}}></section>
                                    </section> */}

                            {/* </div> */}
                        </div>






                    </div>


                    {/*3-ROUND-CARDS-SLIDERS */}
                    <div className='  my-1 '>

                        {/* HEADING-SECTION  */}
                        <section>
                            <span>
                                <span style={{ "position": "relative", height: "0px", width: "0px" }}>
                                    <span className='Ellipse3' style={{ "height": "10rem", "width": "20rem" }}></span>
                                </span>
                            </span>

                            {/* HEAD  */}
                            <div className='row '>
                                <section className='col'>
                                    <section className={`fade-in-text-right ${inView4 ? 'fade-in-right ' : ''}`} ref={ref4} style={{ "position": "relative", "top": "2rem" }}>
                                        <span className='text-light text-center'>  <div className='txt1'>Collaborate with
                                            <div className='txt2'> top creators  </div> <hr className=' mx-auto' style={{ "width": "10%", "height": "6px", "color": "none", "backgroundColor": "#D42E90", "borderRadius": "20rem" }} /> </div>  </span>
                                    </section>
                                </section>
                            </div>
                        </section>

                        {/* SLIDER-SECTION  */}
                        <section>
                            <Slider className='bg- ' style={{ "paddingBottom": "1rem", marginLeft: "-10px" }} {...settings1} >
                                {/* 1 */}
                                <div className='RoundSliders bg-  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "", "top": "0rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 2 */}
                                <div className='RoundSliders  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "relative", "top": "5rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 1 */}
                                <div className='RoundSliders bg-  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "", "top": "0rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 2 */}
                                <div className='RoundSliders  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "relative", "top": "5rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 1 */}
                                <div className='RoundSliders bg-  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "", "top": "0rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 2 */}
                                <div className='RoundSliders  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "relative", "top": "5rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 1 */}
                                <div className='RoundSliders bg-  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "", "top": "0rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>

                                {/* 2 */}
                                <div className='RoundSliders  d-flex justify-content-center'>
                                    <section className='bg- align-items-center ' style={{ "position": "relative", "top": "5rem" }}>
                                        <img src={pho1} className='Kardz' alt='img1' style={{ "width": "10rem", "border": "2px aqua solid", "borderRadius": "600px", "boxShadow": "red 2px 6px 2px 2px " }} />
                                        {/* <span style={{ "position": "", "top": "-6rem", "left": "3rem", "WebkitTextStroke": "1px black" }}> <h6 className='name1 navHovText0'>Shirisha</h6><h5 className='name2'>LifeStyle</h5></span> */}
                                    </section>
                                </div>










                            </Slider>
                        </section>


                        {/* <div className='row' style={{ "height": "10rem", "width": "20rem", "position": "", "top": "0px", "left": "25rem" }}>
                            <span className='Ellipse3'></span>
                        </div> */}
                    </div>




                    {/*4-GRAPH-IMAGE-SECTION */}
                    <div className='row d-flex align-items-center mb-4 ' style={{ "height": "40rem" }}>

                        <div className={`fade-in-text-up ${inView5 ? 'fade-in-up col-6 text-center' : 'col-6 text-center'}`} ref={ref5}>
                            <img src={graph} alt='graph' style={{ "width": "26rem", "marginTop": "3rem" }} />
                            <section className='text-start ' style={{ "marginLeft": "10rem" }}>
                                <h1 style={{ "color": "black", "fontWeight": "700", "fontsize": "15rem" }}>3x more effective</h1>
                                <h3 style={{ "color": " #7E7E7E", "fontWeight": "700" }}>than traditional advertising.</h3>
                            </section>
                        </div>

                        <div className={`fade-in-text-up ${inView5 ? 'fade-in-up col-6 ' : 'col-6 '}`} ref={ref5}>

                            <div className='row' style={{ "marginTop": "-3rem", zIndex: "2" }}>
                                <div className='col-12' >
                                    <section className={`fade-in-text-up ${inView9 ? 'fade-in-up  ' : ' '}`} ref={ref9}>
                                        <img src={img2} alt='img' style={{ "width": "32rem", "position": "relative", "left": "4rem" }} />
                                    </section>
                                </div>

                                <div className='col-6'>
                                    <section className={`fade-in-text-up ${inView8 ? 'fade-in-up  ' : ' '}`} ref={ref8} style={{ "height": "1px", width: "1px" }}>
                                        <img src={img1} alt='img1' style={{ "width": "14rem", "position": "relative", "top": "-18rem", "right": "6rem", zIndex: "2" }} />
                                    </section>
                                </div>

                                <div className='col-6'>
                                    <section className={`fade-in-text-up ${inView8 ? 'fade-in-up  ' : ' '}`} ref={ref8} style={{ "height": "1px", width: "1px" }}>
                                        <img src={img1} alt='img1' style={{ "width": "14rem", "position": "relative", "top": "-35rem", "left": "8rem", zIndex: "2" }} />
                                    </section>
                                </div>



                            </div>
                        </div>
                    </div>


 



                    {/* 5-SQUARE-SLIDES  */}
                    <div className='row d-flex align-items-center  ' style={{ "height": "32rem" }}>

                        {/* HEADING  */}
                        <section className={`fade-in-text-right ${inView6 ? 'fade-in-right ' : ''}`} ref={ref6}>
                            <h1 className='text-center' style={{ "fontSize": "2.2rem", "fontWeight": "700", "color": "#D461A8" }}> What do you want to achieve?</h1>
                            <h1 className='text-center' style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "black", "position": "relative", "top": "" }}> Get to your business goals faster with<br /> DRAEBEE.</h1>
                        </section>
                        {/* CARDS  */}
                        <section>
                            <Slider className='roundImg ' {...settings2} style={{ "paddingBottom": "1rem", marginLeft: "-10px" }}>

                                {/* 1  */}
                                <div className=''>
                                    <section className=' d-flex justify-content-center' style={{ "top": "1rem" }}>
                                        <Kard1 />
                                    </section>

                                </div>
                                {/* 2 */}
                                {/* 1  */}
                                <div className=''>
                                    <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                        <Kard1 />
                                    </section>

                                </div>
                                {/* 2 */}
                                {/* 1  */}
                                <div className=''>
                                    <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                        <Kard1 />
                                    </section>

                                </div>
                                {/* 2 */}
                                {/* 1  */}
                                <div className=''>
                                    <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                        <Kard1 />
                                    </section>

                                </div>
                                {/* 2 */}
                                {/* 1  */}
                                <div className=''>
                                    <section className='d-flex justify-content-center' style={{ "top": "1rem" }}>
                                        <Kard1 />
                                    </section>

                                </div>
                                {/* 2 */}

                            </Slider>
                        </section>
                    </div>




                    {/* Testimonials  */}
                    <div className='row  align-items-center' style={{ "height": "30rem" }}>

                        {/* HEADING  */}
                        <section className={`fade-in-text-right ${inView7 ? 'fade-in-right ' : ''}`} ref={ref7} style={{ position: "relative", top: "20px", "marginBottom": "0rem" }}>
                            <h1 className='text-center' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "#D461A8" }}> What Make us special -</h1>
                            <h1 className='text-center' style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "black", "position": "relative", "top": "" }}>Trusted by All business</h1>
                        </section>


                        {/* CARDS  */}
                        <section>
                            <Slider {...settings3} className=' my-0 ' style={{ height: "15rem", "paddingBottom": "1rem", marginLeft: "-10px" }}>


                                <div className='d-flex justify-content-center align-items-center'>
                                    <Kard2 />
                                </div>

                                <div className='d-flex justify-content-center align-items-center'>
                                    <Kard2 />
                                </div>

                                <div className='d-flex justify-content-center align-items-center'>
                                    <Kard2 />
                                </div>

                                <div className='d-flex justify-content-center align-items-center'>
                                    <Kard2 />
                                </div>

                                <div className='d-flex justify-content-center align-items-center'>
                                    <Kard2 />
                                </div>


                            </Slider>
                        </section>




                    </div>

                </section>

            </div>


        </>
    )
}

export default LandinSec2