import ProgressBar from '@ramonak/react-progress-bar'
import { Line } from 'rc-progress'
import React from 'react'

const YoutubeVideoDetails = () => {
    return (


        <>
            {/* Section 1  */}
            <div className='row mt-2 px-3 pb-0 pt-0  '>

                <div className='col-lg-7 p-3 bg-'  >
                    {/* <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ objectFit: 'cover', width: '49vw', height: "20vw", borderRadius: "10px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.7)" }} /> */}
                    <div className='' style={{ objectFit: 'cover', width: '49vw', height: "20vw", borderRadius: "10px", boxShadow: "0 3px 10px rgb(0 0 0 / 0.7)" }}>
                        <iframe style={{ width: "100%", height: "100%", borderRadius: "10px" }} src="https://www.youtube.com/embed/UX2Xx4LIKSA?si=9WoO4F8TT8PJ2uj4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>



                    {/* Section 2 */}
                    <section className='my-2 bg-'>
                        <section className='row  w-100 bg-'>
                            {/* Card 1 */}
                            <section className='col-lg-4 bg- p-1'>
                                <div className='brands_features_box py-2' style={{ width: "100%" }}>

                                    {/* line1 */}
                                    <div className='d-flex justify-content-between'>
                                        <text style={{ fontSize: '16px', fontWeight: 'bold' }}>Views</text>

                                        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                            1,442,640
                                        </label>
                                    </div>
                                    {/* line2  */}
                                    <div className='mt-1  text-start'>
                                        <label style={{ fontSize: '14px', fontWeight: '600', color: "gray" }}>Published </label>
                                        <br />
                                        <label className='d-flex align-items-center' style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            2024.01.18
                                        </label>
                                    </div>

                                </div>
                            </section>

                            {/* Card 2 */}
                            <section className='col-lg-4  p-1'>
                                <div className='brands_features_box py-2' style={{ width: "100%" }}>

                                    {/* line1  */}
                                    <div className='d-flex justify-content-between'>
                                        <text style={{ fontSize: '16px', fontWeight: 'bold' }}>Likes</text>

                                        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                            96,148
                                        </label>
                                    </div>
                                    {/* line 2  */}
                                    <div className='mt-1  text-start'>
                                        <label style={{ fontSize: '14px', fontWeight: '600', color: "gray" }}>Per Views</label>
                                        <br />

                                        <label className='d-flex align-items-center' style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            6.5% &nbsp;
                                            <ProgressBar completed={60} bgColor={`#c83b93`} height={12} style={{ fontSize: "12px" }} borderRadius={0} width={160} />
                                        </label>
                                    </div>

                                </div>
                            </section>

                            {/* Card 3 */}
                            <section className='col-lg-4 p-1'>
                                <div className='brands_features_box py-2' style={{ width: "100%" }}>
                                    {/* line1  */}
                                    <div className='d-flex justify-content-between'>
                                        <text style={{ fontSize: '16px', fontWeight: 'bold' }}>Comments</text>


                                        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                            7,100
                                        </label>
                                    </div>
                                    {/* line2  */}
                                    <div className='mt-1  text-start'>
                                        <label style={{ fontSize: '14px', fontWeight: '600', color: "gray" }}>Per Views</label>
                                        <br />
                                        <label className='d-flex align-items-center' style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            0.5% &nbsp;
                                            <ProgressBar completed={30} bgColor={`#c83b93`} height={12} borderRadius={0} width={160} />
                                        </label>
                                    </div>

                                </div>
                            </section>

                        </section>


                    </section>
                </div>

                {/* brand_desc */}
                <div className='p-3 pt-0 brand_desc col-lg-5'>

                    <div className='row bg-'>
                        <div className='col-lg-6 mt-0 pt-0 ps-0 bg-'  >

                            <button className='bg-transparent mt-2' >
                                <div className='d-flex  1 '>
                                    <img src='https://yt3.googleusercontent.com/5PzLmbagsD5EKdIzADcnsYlWNR-5asxCLv22_FKCl7P3t5seJXEwXArynPAUxRxU633N78B9Ig=s176-c-k-c0x00ffffff-no-rj' style={{ borderRadius: "50%", border: "2px solid aqua" }} width={60} />

                                    <div className='d-flex flex-column mx-3 mt-2'>
                                        <label className=' text-start text-dark' style={{ fontSize: '18px' }}>
                                            Zaeden&nbsp;  <img src={require('../../../../Imagez/Dashboard/All/mdi_approve.png')} />
                                        </label>

                                        <label className='' style={{ fontSize: '12px', color: '#7E7E7E' }}>
                                            262K subscribers
                                        </label>
                                    </div>


                                </div>

                            </button>

                        </div>
                        <div className='col-lg-6 bg-'></div>
                    </div>

                    <h3>
                        <b>tere bina - Zaeden | ft. Amyra Dastur | Kunaal Vermaa | VYRLOriginals </b>
                    </h3>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <span className=" linlin">More..</span>
                    </p>


                </div>


            </div>




            <hr className='mx-4' style={{ "opacity": "0.09" }} />
            {/* Saction 3 */}
            <section className='mb-5'>
                <div className='mt-4 mb-2'>
                    <label className=' ms-4 text-dark' style={{ fontSize: '18px', fontWeight: "600" }}>
                        <b>
                            Popular video in this channel
                        </b>
                    </label>
                </div>

                <div className=' row mt-2 text-center'>
                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>


                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>



                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                </div>
                {/* _________________________________________________________________________ */}
                <div className=' row mt-4 text-center'>
                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>


                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>



                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                </div>
                {/* _________________________________________________________________________ */}
                <div className=' row mt-4 text-center'>
                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>


                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>



                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/Qwm6BSGrOq0?si=RGOwnKQhKgXfPENW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                    <div className='col-lg-3'>

                        <div className='' style={{ objectFit: 'cover', width: '280px', borderRadius: "15px" }}>
                            <iframe style={{ width: '280px', borderRadius: "15px", height: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.4)" }} src="https://www.youtube.com/embed/_kUrW9SEaJc?si=2XA_7MfVLYB7h1n2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <div>
                            Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                        </div>

                        <div>
                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />{" "}
                                    1.2 M
                                </span>

                            </button>

                            <button className='btn text-secondary'>
                                <span className='d-flex align-items-center'>
                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" /> {" "}
                                    7.9 K
                                </span>

                            </button>
                        </div>
                    </div>

                </div>
                {/* _________________________________________________________________________ */}

            </section>
        </>



    )
}

export default YoutubeVideoDetails