// Enable the utube apis 

import React, { useState } from 'react'
import Social_dropdown from './component/social-dropdown';
import LocationDrop from './component/location-dropdown';
import { Modal } from 'react-bootstrap-v5';
import '../../Styles/Slyider.css';
import { useEffect } from 'react';
import Imagg from '../../Imagez/Common/User.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FadeLoader } from 'react-spinners';
import DbeeScore from './component/SearchSection/DbeeScore';
import numeral from 'numeral';
import Followers from './component/SearchSection/Followers';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import dayjs, { Dayjs } from 'dayjs';
import CountryDropz from './searching-influencers/CountryDropz';
import { baseUrl2 } from '../../Routez/Routey';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SearchBarMain from './component/SearchSection/SearchBarMain';

export default function YoutubeSearch2() {
    const [planModal, setplanModal] = useState(false);
    const [youtubeActive, setYoutubeactive] = useState('channel');
    const [blow, setBlow] = useState(false);
    const [catShow, setCatShow] = useState(false)
    const [allChannel, setAllChannel] = useState([]);


    // SEARCH-BAR-THING 
    const [searchNow, setSearchNow] = useState(false);
    const [optionz, setOptionz] = useState("Post Descriptions")
    const [showHash, setShowHash] = useState(false)
    const [activeNow, setActiveNow] = useState("all")
    const [serrch, setSerrch] = useState(false)
    const [socials, setSocials] = useState("insta")
    const [openDrops, setOpenDrops] = useState(false);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            // GoFor();
            console.log("Hitted the Go!!")
        }
    };
    const location = useLocation();
    // const [loading, setLoading] = useState(false);
    const [DeepSearch, setDeepSearch] = useState(false);
    const [DeepSearchVideo, setDeepSearchVideo] = useState(false);
    const [View, setView] = useState(0);

    const dada = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [ting, setTing] = useState("");





    const toggleSlide = () => {
        setBlow(!blow);
    };
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
        console.log(" the whole state : ", location.state);
        console.log(" the thing keyword : ", location.state.tag);
        setTing(location.state.tag)
        CatIdz();



        setTimeout(() => {
            ChannelDetailz();
        }, 2000);
        setTimeout(() => {
            videoDetails();
        }, 4000);
        setTimeout(() => {
            setShowPager(true)
        }, 5000);


        localStorage.removeItem("UserName");
        localStorage.removeItem("channelId");
    }, [])


    // _____________________  MAIN APIs START__________________________________



    // 1.Channel Api 
    const ChannelDetailz = (geny, sity, minFoll, maxFoll, minDb, maxDb, avgViewsMin, avgViewsMax, minLikez, maxLikez) => {
        let tagg;
        if (ting != "") {
            tagg = ting
        }
        else {
            tagg = location.state.tag
        }
        axios.post(`${baseUrl2}/youtube/channel-details`, {
            tags: profileLink,
            q: tagg,
            page: 1,

            locations: sity,

            minUsersCount: minFoll,
            maxUsersCount: maxFoll,

            genders: geny,


            minLikes: minLikez,
            maxLikes: maxLikez,

            minViews: avgViewsMin,
            maxViews: avgViewsMax,

            minComments: minComments,
            maxComments: maxComments,


            minQualityScore: minDb,
            maxQualityScore: maxDb,

            minER: minER,
            maxER: maxER,




        }).then((res) => {
            // console.log(" channels youtube #x# : ", res.data)
            // chan.push(res.data)
            console.log("CHAN DATA----- : ", res.data)
            setTotalUser(res.data.total_result)
            setChan(res.data.data)
        }).catch((err) => {
            console.log("CHAN ERROR ", err)
        })
    }

    // 2.Videos Api
    const videoDetails = (typee, durationn, advertisementt, publishDateFilterr, categoryy, countryy, minView, maxView) => {
        let keykey;
        if (ting != "") {
            keykey = ting
        }
        else {
            keykey = location.state.tag
        }
        console.log("MINIMUMVIEWS FORM TH API : ", minView)
        axios.post(`${baseUrl2}/youtube/video-id`, {
            keyword: keykey,
            type: typee,
            duration: durationn,
            advertisement: advertisementt,
            category: categoryy,
            countryCode: countryy,
            publishDateFilter: publishDateFilterr,
            minViews: minView,
            maxViews: maxView,

        }).then(async (response) => {

            // console.log("the first api channel id: ", response.data.data[0].channel_id)
            // console.log("the first api only id : ", response.data.data[0].id)
            console.log("hitted now")
            const x = response.data.data[0].id;
            const y = response.data.data[0].channel_id;

            // setDataFromFirstApi(response.data.data);
            // setXValue(x);
            // setYValue(y);


            const fetchResult = await Promise.all(
                (response.data.data).map(async (elem) => {
                    const res = await axios.post(`${baseUrl2}/youtube/video-details`,
                        { channel_id: elem.channel_id, id: elem.id })
                    return res.data;
                })
            );
            // console.log("the resulttttt :", fetchResult)
            setAllVideo(fetchResult)

            // VIDEO DETAILS =================================>
            // console.log("the second api VIDEO DETAILS THUMBNAIL: ", fetchResult[0].video_details[0].snippet.thumbnails.maxres.url)
            // console.log("the second api VIDEO DETAILS TAGS: ", fetchResult[0].video_details[0].snippet.title)
            // console.log("the second api VIDEO DETAILS PUBLISHED DATE: ", fetchResult[0].video_details[0].snippet.publishedAt)
            // console.log("the second api VIDEO DETAILS VIEW-COUNT: ", fetchResult[0].video_details[0].statistics.viewCount)
            // console.log("the second api VIDEO DETAILS LIKE-COUNT: ", fetchResult[0].video_details[0].statistics.likeCount)
            // console.log("the second api VIDEO DETAILS COMMENT-COUNT: ", fetchResult[0].video_details[0].statistics.commentCount)

            // console.log("the second api VIDEO DETAILS TAGS: ", fetchResult[0].video_details[0].snippet.tags[1])


            // CHANNEL DETAILS ================================>
            // console.log("the second api CHANNEL THUMBNAIL: ", fetchResult[0].channel_details[0].snippet.thumbnails.high.url);
            // console.log("the second api CHANNEL NAME: ", fetchResult[0].channel_details[0].snippet.title);
            // console.log("the second api CHANNEL SUBSCRIBERS : ", fetchResult[0].channel_details[0].statistics.subscriberCount);

        }).catch((error) => {
            console.error('VIDEO API ERROR', error);
        });
    }

    // 3. Categories api
    const CatIdz = () => {
        axios.post(`${baseUrl2}/youtube/catId`).then((res) => {
            // console.log("All the Categories : ", res.data)
            setAllCats(res.data);
        }).catch((err) => {
            console.log("Category eRROR- ", err)
        })
    }

    // 4. Country api
    const CountryIdz = () => {
        axios.post(`${baseUrl2}/youtube/country`).then((res) => {
            // console.log("All the Categories : ", res.data)
            setAllCountry(res.data);
        }).catch((err) => {
            console.log("COUNTRY ERROR- ", err)
        })
    }
    // _____________________  MAIN APIs END__________________________________





    const OpenInsta = () => {
        navigate("/Dreabee/Dashboard/search-instagram", { state: { tag: ting } })
    }



    const [profileLink, setProfileLink] = useState("");
    const [pageNo, setPageNo] = useState("1");


    const FilterzIn = () => {
        // setGen(e.target.value);
        ChannelDetailz();
    }




    //    ________________________________     VIDEO APIs    ________________________________      VIDEO APIs    ________________________________     
    const [videoIdz, setvideoIdz] = useState([]);
    const [allvideo, setAllVideo] = useState([]);
    const [tabShow, setTabShow] = useState(false);
    const [allCats, setAllCats] = useState([]);
    const [allCountry, setAllCountry] = useState([]);
    const [catsIdz, setCatsIdz] = useState([]);


    const [videoViews, setVideoViews] = useState("");
    const [videoDuration, setVideoDuration] = useState(""); //any
    const [videoType, setVideoType] = useState(""); //completed
    const [videoAds, setVideoAds] = useState("");   //any
    const [videoPublished, setVideoPublished] = useState("");   //any
    const [videoCategory, setVideoCategory] = useState("1");
    const [videoCountry, setVideoCountry] = useState("IN");
    const [videoMinView, setVideoMinView] = useState("");
    const [videoMaxView, setVideoMaxView] = useState("");

    const [videoCatDrop, setVideoCatDrop] = useState(false)
    const [videoCountryDrop, setVideoCountryDrop] = useState(false)



    // const VideoIdz = (chanId) => {
    //     axios.post(`${baseUrl2}/youtube/video-channel`, {
    //         channelId: chanId
    //     }).then((res) => {
    //         console.log("ThIS IS FROM cHANNEL dETAILS aaapiiiss :", res)
    //     }).catch((err) => {
    //         console.log("the video  api error - ", err)
    //     })
    // }

    const ItsOnn = () => {
        setCatShow(true)
    }
    const ItsOff = () => {
        setCatShow(false)
    }


    // _____________________DROPDOWNZ_____________________________
    // DROPDOWN SOCIAL MEDIA
    const [openDrop, setDrop] = useState(false);
    const [selected, setSelelcted] = useState('Youtube');
    const navigate = useNavigate();

    // _____________________DROPDOWNZ_____________________________




    // 1-- DREABEE SCORE DROPDOWN 
    const [dreabeeDrop, setDreabeeDrop] = useState(false);

    const [minimum, setMinimum] = useState("")
    const [maximum, setMaximum] = useState("")

    const [inputs, setInputs] = useState({ DbScore: { min: 0, max: 10 } });

    const ClearDb = () => {
        setMinDrebScore("");
        setMaxDrebScore("");
        ChannelDetailz();
    }

    //2--LOCATION FILTERS
    const [openCityDrop, setCityDrop] = useState(false);
    const [selectedCity, setSelelctedCity] = useState('Location')

    // 3 -- FOLLOWERS COUNT  FILTER 
    const [minimumF, setMinimumF] = useState();
    const [maximumF, setMaximumF] = useState();
    const [fDropz, setFDropz] = useState(false);
    const [more, setMore] = useState(false)
    const [inputsFollow, setInputsFollow] = useState({ Followerz: { min: 0, max: 1000000 } });

    // 4 --GENDER
    const [gender, setGender] = useState("");
    const [genGen, setGenGen] = useState(false);

    //  ________________________________    CHANNEL APIs  ________________________________ CHANNEL APIs  ________________________________
    const [chan, setChan] = useState([]);
    const [totalUser, setTotalUser] = useState();





    // ---------------FILTERS STATES----------------
    // 1ST
    const [chanType, setChanType] = useState("");

    const [minViews, setMinViews] = useState("");
    const [maxViews, setMaxViews] = useState("");

    const [videoDurationMin, setVideoDurationMin] = useState("");
    const [videoDurationMax, setVideoDurationMax] = useState("");

    const [city, setCity] = useState("");
    const [countryDrop, setCountryDrop] = useState(false);

    const [minER, setMinER] = useState("");
    const [maxER, setMaxER] = useState("");

    const [minLikes, setMinLikes] = useState("");
    const [maxLikes, setMaxLikes] = useState("");

    const [minComments, setMinComments] = useState("");
    const [maxComments, setMaxComments] = useState("");

    const [minSupChat, setMinSupChat] = useState("");
    const [maxSupChat, setMaxSupChat] = useState("");





    // 2ND
    const [minFollowers, setMinFollowers] = useState("");
    const [maxFollowers, setMaxFollowers] = useState("");

    const [minDrebScore, setMinDrebScore] = useState("");
    const [maxDrebScore, setMaxDrebScore] = useState("");


    // Clear all Filters 
    const EmptyFill = () => {


        setCity("");
        setMinER("");
        setMaxER("");
        setMinLikes("");
        setMaxLikes("");
        setMinComments("");
        setMaxComments("");
        setMinViews("");
        setMaxViews("");


    }
    // ---------------FILTERS STATES----------------
    const [hovrd, setHovrd] = useState(false);
    const [profVal, setProfVal] = useState("");

    // const [handled, setHandled] = useState();
    const ProfileNow = (handled) => {
        console.log("the handled ", handled)
        localStorage.setItem("UserName", handled);
        // localStorage.setItem("UserInstaName", "abhiya_vibes");
        // localStorage.setItem("UserInstaName", "sunar_ki_dukaan");


        console.log("this is the value of the proofile username", handled)

        // ProfileNow((data.url).split("@")[1])
        // navigate(`/Dreabee/Dashboard/Profile/Summary/${"@" + handled}`, { state: { keyWord: handled } })
        navigate(`/Dreabee/Dashboard/Profile`)
    }

    //---------------------------------------------------------------------------
    const [pageNow, setPageNow] = useState(1)
    const [pageAll, setPageAll] = useState(10)
    const DecNo = () => {
        let x = pageNow - 1;
        if (x <= 0) {
            x = 0;
        } else {
            setPageNow(pageNow - 1)
            // InstaAccount(minDrebScore, maxDrebScore, minimumF, maximumF, gender, city, (pageNow - 1))
        }


    }
    const IncNo = () => {
        let x = pageNow + 1;
        if (x > pageAll) {
            x = pageAll;
        } else {
            setPageNow(pageNow + 1)
            // InstaAccount(minDrebScore, maxDrebScore, minimumF, maximumF, gender, city, (pageNow + 1))

        }
    }

    const [showPager, setShowPager] = useState(false);


    const [beta, setBeta] = useState([]);
    const [Morey, setMorey] = useState(
        ["Type", "Avg. Views", "Avg. Likes", "Avg. comments", "E.R %", "Duration", "Views/7days", "Views/15days", "Views/30days", "Super Chat", "Weekly update", "Views/Subs", "Cmt./Views", "Like/Views", "Live Viewers", "Categories", "Last Promotion", "Last Update", "Publish Date", "Advertisement", "Country"])

    const handleClick = (item) => {
        // Remove the item from Morey
        const updatedMorey = Morey.filter((element) => element !== item);
        setMorey(updatedMorey);

        // Add the item to beta
        setBeta((prevBeta) => [...prevBeta, item]);
        console.log("THE ARRAY ADDED ", beta)
    };

    const handleClickReverse = (item) => {
        // Remove the item from beta
        const updatedBeta = beta.filter((element) => element !== item);
        setBeta(updatedBeta);

        // Add the item back to Morey
        setMorey((prevMorey) => [...prevMorey, item]);
        console.log("THE ARRAY ADDED ", Morey)
    };

    const checkValueInBeta = (value) => {
        return beta.includes(value);
    };

    const myFunction = () => {
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
    }

    const [loading, setLoading] = useState(false);

    const ShowCon = () => {
        console.log("The length of Ting : ", ting.length)
    }

    const [choicez, setChoicez] = useState(false);
    return (
        <>
            <div className='row' id='TableTop'>
                <div className='col-lg-12'>


                    {/* Search Field */}


                    {/* <SearchBarMain /> */}
                    <div className=' d-flex  justify-content-betweeen align-items-center' style={{ zIndex: "1" }}>


                        <div className='d-flex mx=0' style={{ width: '70%' }}>



                            <div className='search-Main'>

                                <div className='' onClick={() => { setShowHash(!showHash) }}>
                                    <text className='ps-1' style={{
                                        // overflowX: "hidden",
                                        // whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "220px"

                                    }}>
                                        {optionz}
                                    </text>
                                    {
                                        showHash
                                            ?
                                            <i class='bx bx-chevron-up' />
                                            :
                                            <i class='bx bx-chevron-down' />
                                    }



                                </div>

                                {
                                    showHash
                                        ?
                                        <section className='dropDown-Tags bg-light ' style={{ "top": "" }}>
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Bio Keyword"); }}
                                            >Bio Keyword</p>
                                            <hr />
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Post Descriptions"); }}
                                            >Post Descriptions</p>
                                            <hr />
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Similar Followers"); }}
                                            >Similar Followers</p>
                                            <hr />
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Similar Niche"); }}
                                            >Similar Niche</p>
                                            <hr />
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Hashtags"); }}
                                            >Hashtags</p>
                                            <hr />
                                            <p className=' h-100 '
                                                onClick={() => { setShowHash(!showHash); setOptionz("Mentions"); }}
                                            >Mentions</p>
                                            <hr />
                                        </section>
                                        :
                                        null
                                }


                                <input type="text" className={serrch ? 'tallInput' : ''} value={ting}
                                    // onKeyPress={handleKeyPress}
                                    style={{ outline: "none", paddingLeft: "200px" }}
                                    // onChange={(e) => { setTing(e.target.value); }}
                                    onChange={(e) => { setTing(e.target.value); setSearchNow(true); setShowHash(false) }}
                                />

                                {

                                    searchNow && (ting.length != 0)
                                        ?
                                        <>
                                            <section className='bg-light sugg-box' style={{ "minWidth": "750px", minHeight: "400px" }}>
                                                {/* section-1  */}
                                                <section className='py-2 px-3 d-flex justify-content-between'>
                                                    <text style={{ fontSize: "1.125rem", fontWeight: "700", textTransform: "uppercase", color: "#7E7E7E", }} >Search Suggestions </text>
                                                    <span>  <i className="bx bx-x   RoundBtnz" style={{ fontSize: "28px", "borderRadius": "50%", color: "#7E7E7E" }} onClick={() => { setSearchNow(false); }} ></i></span>
                                                </section>

                                                {/* section-2 */}
                                                <section className='w-100 d-flex justify-content-center'>
                                                    <section className='social-tabs'>
                                                        <text onClick={() => { setActiveNow("all") }} className={activeNow == "all" ? 'active-social-tab  ' : ""}>All</text>
                                                        <text onClick={() => { setActiveNow("utube") }} className={activeNow == "utube" ? 'active-social-tab ' : ""}><i class='bx bxl-youtube' /> &nbsp;  </text>
                                                        <text onClick={() => { setActiveNow("insta") }} className={activeNow == "insta" ? ' active-social-tab  text-center' : " text-center"}>
                                                            <i class='bx bxl-instagram' /> &nbsp;   </text>
                                                    </section>
                                                </section>

                                                {/* section-3 */}
                                                <section className='my-3  mb-0 pt-1 ' style={{ overflow: "hidden", overflowY: "scroll", height: "290px" }}>
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}
                                                    <section className='d-flex align-items-center justify-content-between hoverpey ps-4'>

                                                        <section className='d-flex align-items-center py-2'>
                                                            <img src={require('../../Imagez/Common/TayTay.jpg')} width={50} height={50} style={{ "border": "1px solid black", borderRadius: "50%" }} /> &nbsp; &nbsp;
                                                            <section>


                                                                <section>
                                                                    <text className='' style={{ "fontSize": "16px", "fontWeight": "700" }} >Disha Patani </text>
                                                                    <br />
                                                                    <text className='text-secondary' style={{ "fontSize": "14px", "fontweigh": "600" }}>@dishapatani21</text>
                                                                </section>


                                                            </section>
                                                        </section>

                                                        <section className=' me-5'>
                                                            {/* <button className='btnz'><i class="fa-solid fa-arrow-trend-up p-1" style={{"border" :"1px solid black", borderRadius: "50%" }}></i></button> */}
                                                            <img src={require('../../Imagez/Common/Redirect.png')} width={40} height={40} /> &nbsp; &nbsp;
                                                        </section>

                                                    </section>
                                                    {/* ====================================================================================== */}







                                                </section>

                                            </section>
                                        </>
                                        :
                                        null
                                }

                            </div>

                            <span className='btnz' style={{ height: "0px", width: "0px", position: "relative", "right": "35px", top: "12px", zIndex: "2" }}>
                                <i className="fa fa-search fa-lg " style={{ color: "#c83b93" }} onClick={() => { setSerrch(!serrch) }}   ></i>
                            </span>

                        </div>



                        <div className='' style={{ width: "10%" }}>



                            {/* <button className=' btn social-btns-drop py-2 mx-1 ' onClick={() => { setOpenDrops(!openDrops) }}>
                        <section className='d-flex justify-content-evenly lalign-items-center'>

                            <label>
                                {selected}
                            </label>

                            {
                                openDrop
                                    ?
                                    <label>
                                        <i className="fa fa-angle-up"></i>
                                    </label>
                                    :
                                    <label>
                                        <i className="fa fa-angle-down"></i>
                                    </label>

                            }
                        </section>
                    </button> */}

                            <div className='d-flex  justify-content-evenly  '>

                                <button className={socials === "insta" ? 'icon-btn-active   p-3 px-2' : 'icon-btn   p-3 px-2'} onClick={() => { setSocials("insta"); ShowCon(); }}>
                                    {/* <img src={require('../../Imagez/Dashboard/All/mdi_instagram.png')} /> */}
                                    <img width="40" height="40" src="https://img.icons8.com/arcade/64/instagram-new.png" alt="instagram-new" />
                                </button>

                                <button className={socials === "utube" ? 'icon-btn-active   p-3 px-2' : 'icon-btn   p-3 px-2'} onClick={() => { setSocials("utube") }}>
                                    {/* <img className="" src={require('../../Imagez/Dashboard/All/simple-line-icons_social-youtube.png')} /> */}
                                    <img width="40" height="40" src="https://img.icons8.com/arcade/64/youtube-play.png" alt="youtube-play" />
                                </button>


                            </div>

                            {
                                openDrops
                                    ?

                                    <>
                                        <section className='mt-1' style={{ "height": "0px", "width": "0px", position: "relative" }}>

                                            <ul className='dropDownzz bg-light mt- px' style={{ width: "275px", right: "0px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)" }}>
                                                <li>
                                                    <button className=' btn hoverpe' onClick={() => { setSelelcted('Youtube'); setOpenDrops(false); }}>
                                                        <img src={require('../../Imagez/Dashboard/All/simple-line-icons_social-youtube.png')} /> &nbsp;
                                                        Youtube
                                                    </button>
                                                </li>

                                                <li style={{ border: "none" }}>
                                                    <button className=' btn hoverpe' onClick={() => { setOpenDrops(false); setSelelcted('Instagram'); }}>
                                                        {/* <img src={require('../../Imagez/Dashboard/All/mdi_instagram.png')} /> &nbsp; */}
                                                        Instagram
                                                    </button>
                                                </li>

                                            </ul>
                                        </section>
                                    </>
                                    :
                                    null
                            }
                        </div>



                        <div className='' style={{ width: "20%" }}>
                            <div className='d-flex align-items-center justify-content-evenly'>
                                {/* <input type="checkbox" className='checkbox_section mx-2' /> */}
                                <section>
                                    <img alt='img' src={require("../../Imagez/Dashboard/All/mdi_approve.png")} />
                                    <label className='labels'>Verified influencers</label>
                                </section>

                                <label class="switchSmall2 m5">
                                    <input type="checkbox"
                                    // onClick={toggleSlide} 

                                    />
                                    <small></small>
                                </label>
                            </div>
                        </div>


                    </div>


                    {/* Youtube CHANNELS & VIDEOS Option */}
                    <div className='mt-3 d-flex justify-content-between'>
                        <div className='d-flex'>
                            {/* <button onClick={wow}>showe me </button>/ */}

                            <button className={youtubeActive == 'channel' ? 'channel_tab active_channel_tab' : 'channel_tab'} onClick={() => { setYoutubeactive('channel') }}>
                                Channel
                            </button>

                            <button className={youtubeActive == 'videos' ? 'channel_tab active_channel_tab' : 'channel_tab'} onClick={() => { setYoutubeactive('videos'); }}>
                                Videos
                            </button>
                        </div>

                        <div className='d-flex youtube_filter_head_label justify-content-between'>
                            <label>
                                About 2 channels for "cinema landing page" (filtered)
                            </label>

                            <label>
                                Searched among <span style={{ color: '#C83B93' }}>986,223,232</span> Youtube video is global.
                            </label>
                        </div>
                    </div>


                    {/* Youtube Search channel filter */}

                    {
                        youtubeActive != 'channel'
                            ?
                            <div className='deep_search_section'>
                                <button className='Deep_search_btn' onClick={() => setDeepSearchVideo(!DeepSearchVideo)}>
                                    <img src={require("../../Imagez/Dashboard/All/chart_icon.png")} style={{ width: '20px', marginRight: '10px' }} />
                                    Deep Search Video
                                </button>
                            </div>
                            :
                            null
                    }


                    {
                        youtubeActive == 'channel'
                            ?
                            null
                            :
                            null
                    }
                    {/* Youtube Search videos filter */}
                    {/*  ======BLACK BUTTON FILTER SECTION  STARTS====== */}
                    {/*  ======BLACK BUTTON FILTER SECTION  STARTS====== */}
                    {/*  ======BLACK BUTTON FILTER SECTION  STARTS====== */}
                    {
                        youtubeActive == 'channel'
                            ?
                            <div className='mt-0 d-flex  justify-content-between'>
                                <label className='labels ms-3  d-flex justify-content-content align-items-center ' style={{ 'fontSize': '18px' }}>
                                    {totalUser} Profiles
                                </label>

                                <div className='plan_filter '>

                                    {/* <div className='mx-2'>
                                        <div>
                                            <button className={openCityDrop ? 'BtnBlK btn  ' : "btn BtnWy "} onClick={() => {
                                                setCityDrop(!openCityDrop)
                                                setMore(false);
                                                setDreabeeDrop(false);
                                                setGenGen(false);
                                                setFDropz(false);
                                            }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <label style={{ "fontWeight": "700", "fontSize": "14px" }}>
                                                        {selectedCity}
                                                    </label>
                                                    &nbsp;
                                                    &nbsp;
                                                    {
                                                        openCityDrop
                                                            ?
                                                            <label className='d-flex align-items-center'>
                                                                <i className="fa fa-angle-up"></i>
                                                            </label>
                                                            :
                                                            <label className='d-flex align-items-center'>
                                                                <i className="fa fa-angle-down"></i>
                                                            </label>

                                                    }
                                                </div>
                                            </button>

                                            {
                                                openCityDrop
                                                    ?
                                                    <div className='location_drop p-4 mt-1' style={{ boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)" }}>
                                                        <label>
                                                            <b> Select Location</b>
                                                        </label>



                                                        <div className='d-flex flex-column city_search_section'>
                                                            <label>
                                                            </label>

                                                            <div className='city_search'>
                                                                <input type="text" onchange={(e) => { setCity(e.target.value) }} />
                                                                <i className='fa fa-search' />
                                                            </div>


                                                        </div>

                                                        <div className='loaction_footer mt-4 pt-3' style={{ boxShadow: " rgb(0 0 0 / 50%) 0px -6px 6px -6px " }}>
                                                            <button className='btn'
                                                                onClick={() => {
                                                                    ChannelDetailz(gender, "", minFollowers, maxFollowers, minDrebScore, maxDrebScore)
                                                                }}>
                                                                Clear
                                                            </button>

                                                            <button className='btn'
                                                                onClick={() => {
                                                                    setCityDrop(false)
                                                                    ChannelDetailz(gender, city, minFollowers, maxFollowers, minDrebScore, maxDrebScore)


                                                                }}>
                                                                Apply Filters
                                                            </button>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>


                                    </div> */}

                                    {/* GENDER */}
                                    {/* <div className='mx-2'>
                                        <div>
                                            <button className={genGen ? ' btn  BtnBlK' : "btn BtnWy "} onClick={() => {
                                                setGenGen(!genGen);
                                                setDreabeeDrop(false)
                                                setCityDrop(false)
                                                setMore(false)
                                                setFDropz(false);
                                            }}>
                                                <div style={{ display: "flex", alignItems: "center", "justifyContent": "center", "fontWeight": "700", "fontSize": "14px" }}>

                                                    <div style={{ display: "flex", alignItems: "center" }}>


                                                        <label className=' d-flex align-items-center'>
                                                            Gender &nbsp;

                                                            {
                                                                genGen
                                                                    ?
                                                                    <i className="fa fa-angle-up" style={{ "fontWeight": "700" }}></i>
                                                                    :
                                                                    <i className="fa fa-angle-down" style={{ "fontWeight": "700" }}></i>

                                                            }

                                                        </label>
                                                    </div>

                                                </div>
                                            </button>

                                            {
                                                genGen
                                                    ?
                                                    <section className='location_drop social_drop_list' style={{ "height": "0px", width: "0px" }}>
                                                        <ul className='social_drop_list mt-1 bg-light' style={{ position: "relative", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)", width: "140px" }}>


                                                            <li>
                                                                <button className='social_drop btn hoverpe' onClick={() => {

                                                                    setGender('m');
                                                                    setDrop(false);
                                                                    setGenGen(false);
                                                                    ChannelDetailz("m", city, minFollowers, maxFollowers, minDrebScore, maxDrebScore)
                                                                }}>
                                                                    Male
                                                                </button>
                                                            </li>

                                                            <li style={{ border: "" }}>
                                                                <button className='social_drop btn hoverpe' onClick={() => {

                                                                    setGender('f');
                                                                    setDrop(false);
                                                                    setGenGen(false);
                                                                    ChannelDetailz("f", city, minFollowers, maxFollowers, minDrebScore, maxDrebScore)


                                                                }}>
                                                                    Female
                                                                </button>
                                                            </li>
                                                            <li style={{ border: "none" }}>

                                                               
                                                            </li>
                                                        </ul>
                                                    </section>
                                                    :
                                                    null
                                            }
                                        </div>



                                    </div> */}


                                    {/* Followers  */}
                                    {/* <div className='mx-2'>
                                        <div >
                                            <button className={fDropz ? ' btn BtnBlK ' : "btn  BtnWy"} onClick={() => {
                                                setFDropz(!fDropz);
                                                setCityDrop(false);
                                                setMore(false);
                                                setDreabeeDrop(false);
                                                setGenGen(false);
                                            }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <label style={{ "fontWeight": "700", "fontSize": "14px" }}>
                                                        Followers
                                                    </label>&nbsp;&nbsp;
                                                    {
                                                        fDropz
                                                            ?
                                                            <label className='bg-io d-flex align-items-center'>
                                                                <i className="fa fa-angle-up"></i>
                                                            </label>
                                                            :
                                                            <label className='d-flex align-items-center'>
                                                                <i className="fa fa-angle-down"></i>
                                                            </label>
                                                    }
                                                </div>
                                            </button>



                                            {
                                                fDropz
                                                    ?
                                                    <div className='Score_drop Hovely p-4 pb-3 mt-1  ' style={{ right: "120px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)" }}>
                                                        <label>
                                                            <b> SELECT FOLLOWERS</b>
                                                        </label>

                                                        <section className='mt-4 mb-2  pe-3 mx-2'>
                                                            <InputRange
                                                                maxValue={1000000}
                                                                minValue={0}
                                                                value={inputsFollow?.Followerz}
                                                                onChange={(value) =>
                                                                    setInputsFollow((prev) => ({ ...prev, Followerz: value }))
                                                                }
                                                            />
                                                        </section>



                                                        <section className='container my-3 '>

                                                            <div className='row d-flex align-items-center justify-content-evenly my-3 mb-0 mt-2'>

                                                                <div className='py-1 px-5 my-1 btnz col-md-5 ' style={{ "border": "1px solid #0E0E0E", "borderRadius": "10px" }}>
                                                                    <section className='' style={{ "fontSize": "10px", color: "" }}>Min. Score</section>
                                                                    <input type='text'
                                                                        value={(numeral(inputsFollow.Followerz.min).format('0.0a'))}
                                                                        onchange={() => { setMinimumF(inputsFollow.Followerz.min) }}
                                                                        className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>




                                                                <div className='py-1 px-5  my-1 btnz col-md-5' style={{ "border": "1px solid #0E0E0E", "borderRadius": "10px" }}>
                                                                    <section className='b' style={{ "fontSize": "10px", color: "" }}>Max. Score</section>
                                                                    <input type='text'
                                                                        value={(numeral(inputsFollow.Followerz.max).format('0.0a'))}
                                                                        onchange={() => { setMaximumF(inputsFollow.Followerz.max) }}
                                                                        className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>
                                                            </div>



                                                        </section>





                                                        <div className='loaction_footer pt-3' style={{ boxShadow: " rgb(0 0 0 / 50%) 0px -6px 6px -6px " }}>
                                                            <button className='btn btnz me-2' onClick={() => {

                                                                setFDropz(false);
                                                                setMinimumF("")
                                                                setMaximumF("")
                                                                ChannelDetailz(gender, city, "", "", minDrebScore, maxDrebScore)


                                                            }}>
                                                                Clear Filters
                                                            </button>

                                                            <button className='btn btnz ms-2'
                                                                onClick={() => {
                                                                    setMinimumF(inputsFollow.Followerz.min)
                                                                    setMaximumF(inputsFollow.Followerz.max)

                                                                    console.log("the folower count min youtue ", minimumF)
                                                                    console.log("the folower count max youtue ", maximumF)
                                                                    ChannelDetailz(gender, city, minimumF, maximumF, minDrebScore, maxDrebScore)
                                                                    setFDropz(false);


                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div> */}



                                    {/* DREABEESCORE  */}
                                    {/* <div className='mx-2'>
                                        <div >
                                            <button className={dreabeeDrop ? ' btn BtnBlK ' : "btn BtnWy "} onClick={() => {
                                                setDreabeeDrop(!dreabeeDrop);
                                                setGenGen(false)
                                                setMore(false)
                                                setCityDrop(false)
                                                setFDropz(false);
                                            }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <label style={{ "fontWeight": "700", "fontSize": "14px" }}>
                                                        QUALITY SCORE
                                                    </label>&nbsp;&nbsp;
                                                    {
                                                        dreabeeDrop
                                                            ?
                                                            <label className='bg-io d-flex align-items-center'>
                                                                <i className="fa fa-angle-up"></i>
                                                            </label>
                                                            :
                                                            <label className='d-flex align-items-center'>
                                                                <i className="fa fa-angle-down"></i>
                                                            </label>
                                                    }
                                                </div>
                                            </button>



                                            {
                                                dreabeeDrop
                                                    ?
                                                    <div className='Score_drop Hovely p-4 mt-1' style={{ right: "40px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)" }}>
                                                        <label>
                                                            <b> SELECT QUALITY SCORE</b>
                                                        </label>



                                                        <section className='mt-4 mb-2 mx-3'>
                                                            <InputRange
                                                                maxValue={10}
                                                                minValue={0}
                                                                value={inputs?.DbScore}
                                                                onChange={(value) =>
                                                                    setInputs((prev) => ({ ...prev, DbScore: value }))
                                                                }
                                                            />
                                                        </section>



                                                        <section className='container '>

                                                            <div className='row d-flex align-items-center justify-content-between ps-1 my-3 mb-3 mt-4'>

                                                                <div className='py-1 px-5 my-1 btnz col-md-5' style={{ "border": "1px solid #0E0E0E", "borderRadius": "10px" }}>
                                                                    <section className='' style={{ "fontSize": "10px", color: "" }}>Min. Score</section>
                                                                    <input type='text' value={inputs.DbScore.min} onChange={() => { setMinDrebScore(inputs.DbScore.min) }} className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>




                                                                <div className='py-1 px-5  my-1 btnz col-md-5' style={{ "border": "1px solid #0E0E0E", "borderRadius": "10px" }}>
                                                                    <section className='b' style={{ "fontSize": "10px", color: "" }}>Max. Score</section>
                                                                    <input type='text' value={inputs.DbScore.max} onChange={(e) => { setMaxDrebScore(e.target.value) }} className='bg-transparent' style={{ width: "100px", outline: "none", border: "none", "fontSize": "16px", fontWeight: "600", color: "#7e7e7e" }} />
                                                                </div>
                                                            </div>

                                                        </section>



                                                        <div className='loaction_footer mt- pt-3' style={{ boxShadow: " rgb(0 0 0 / 50%) 0px -6px 6px -6px " }}>
                                                            <button className='btn btnz me-2'
                                                                onClick={() => {
                                                                    ClearDb();
                                                                    setMinDrebScore((0 / 10));
                                                                    setMaxDrebScore((0 / 10));
                                                                    setDreabeeDrop(false);
                                                                    ChannelDetailz(gender, city, minFollowers, maxFollowers, minDrebScore, maxDrebScore)
                                                                }}>
                                                                Clear Filters
                                                            </button>

                                                            <button className='btn btnz ms-2' onClick={() => {
                                                                setMinDrebScore((inputs.DbScore.min) / 10);
                                                                setMaxDrebScore((inputs.DbScore.max / 10));

                                                                setDreabeeDrop(false);
                                                                console.log("the max score : " + maxDrebScore)
                                                                console.log("the min score : " + minDrebScore)

                                                                ChannelDetailz(gender, city, minFollowers, maxFollowers, (inputs.DbScore.min) / 10, (inputs.DbScore.max) / 10)
                                                          

                                                            }}>
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div> */}


                                    {/* More */}
                                    <div className='mx-2'>
                                        <div className='deep_search_section'>
                                            <button className='Deep_search_btn' onClick={() => setDeepSearch(!DeepSearch)}>
                                                <img src={require("../../Imagez/Dashboard/All/chart_icon.png")} style={{ width: '20px', marginRight: '10px' }} />
                                                Deep Search
                                            </button>
                                        </div>

                                    </div>




                                </div>
                            </div>
                            :
                            null
                    }
                    {/*  ======BLACK BUTTON FILTER SECTION ENDS====== */}
                    {/*  ======BLACK BUTTON FILTER SECTION ENDS====== */}
                    {/*  ======BLACK BUTTON FILTER SECTION ENDS====== */}

                    {
                        choicez
                            ?

                            <section className=' Filterz-on-bg d-flex justify-content-start mt-3' style={{ overflowX: "scroll" }}>

                                <div className=' d-flex justify-content-evenly'>


                                    <div className='Filterz-on mx-2'>
                                        Type : {chanType} &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                                    </div>
                                    <div className='Filterz-on mx-2'>
                                        Avg Views : {View} &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                                    </div>

                                    {/* ---------------------------------------  */}
                                    {/* <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>  <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>  
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>  
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>  
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>  
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div>
                            <div className='Filterz-on mx-2'>
                                Gender : Male &nbsp; <i class='bx bx-x btnz' style={{ cursor: "pointer" }}></i>

                            </div> */}




                                </div>
                            </section>
                            :
                            null
                    }

                    {/* ______________________________________________________________________________YOUTUBE VIDEO / CHANNEL SECTION ______________________________________________________________________________ */}
                    {
                        youtubeActive == 'channel'
                            ?
                            <>
                                {/* channel  table section */}
                                <div id='Top' className='table-responsive' style={{ overflowX: "hidden" }}>
                                    <table className='plan_table ' style={{ marginBottom: "10rem" }}>


                                        <thead>
                                            <tr>
                                                <th className=' '>
                                                    <input type="checkbox" disabled className='checkbox_section mx-2' />
                                                </th>

                                                <th >
                                                    Youtube Profile
                                                </th>

                                                {/* <th>
                                                    Score <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                </th> */}

                                                {/* <th className='bg- text-center'>
                                                    Quality Score
                                                </th> */}
                                                <th className='bg- text-center'>
                                                    Followers
                                                </th>

                                                <th className='bg- text-center'>
                                                    Likes
                                                </th>

                                                <th className='bg- text-center'>
                                                    Views
                                                </th>

                                                <th className='bg- text-center'>
                                                    ER %
                                                    {/* <i class="fa fa-info-circle" aria-hidden="true"></i> */}
                                                </th>

                                                <th className='bg- text-center' >
                                                    Categories
                                                </th>
                                            </tr>


                                        </thead>



                                        {/* chan */}

                                        {
                                            chan.length != 0
                                                ?
                                                chan.slice(1,).map((data) => {

                                                    return (
                                                        <>
                                                            <tbody >
                                                                <tr className='' >
                                                                    <td className=' ' colSpan={0}>
                                                                        <input type="checkbox" className='checkbox_section mx-2' />
                                                                    </td>
                                                                    <td className=''>
                                                                        <div className='d-flex ' style={{ "width": "200px" }} >

                                                                            {
                                                                                data.image != ""
                                                                                    ?
                                                                                    <img src={data.image} className='btnz ' alt={Imagg} width={55} height={55} style={{ border: "2px solid #C83B93", "borderRadius": "50%" }} />
                                                                                    :
                                                                                    <img src={require('../../Imagez/Common/User.jpg')} className='btnz ' width={55} height={55} style={{ border: "2px solid #C83B93", "borderRadius": "50%" }} />
                                                                            }
                                                                            <div style={{ textAlign: 'initial', marginLeft: '5px' }}>
                                                                                <div className=' d-flex  align-items-center'>
                                                                                    <label className='navHovTextNoBg ' style={{
                                                                                        wordWrap: "breakWord", "zIndex": "", overflow: "hidden",
                                                                                        textOverflow: "ellipsis", width: "130px",
                                                                                    }} onClick={() => {
                                                                                        ProfileNow((data.url).split("@")[1]);

                                                                                        setProfVal((data.url).split("@")[1])

                                                                                    }}>
                                                                                        {data.name}
                                                                                    </label>
                                                                                    <img className='' src={require('../../Imagez/Dashboard/All/mdi_approve.png')} />
                                                                                </div>

                                                                                <label className='social_handle'>

                                                                                    {(data.url).split(".com/")[1]}

                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>


                                                                    {/* <td className=''>
                                                                        {((data.quality_score) * 10).toFixed(0)}
                                                                    </td> */}


                                                                    <td className='text-uppercase'>
                                                                        {(numeral(data.user_count).format('0.0a'))}
                                                                    </td>



                                                                    <td className='text-uppercase'>

                                                                        {(numeral(data.average_likes).format('0.0a'))}

                                                                    </td>

                                                                    <td className='text-uppercase'>

                                                                        {(numeral(data.average_video_views).format('0.0a'))}

                                                                    </td>


                                                                    <td className=''>

                                                                        {((data.average_ER) * 100).toFixed(2)}
                                                                        %
                                                                    </td>


                                                                    <td className=' py-2' colSpan={3}

                                                                        style={{ boxShadow: "0 0 0 0 " }}
                                                                    >






                                                                        <div className='categories_row py-2   w-100 ' >
                                                                            <div className='mx-auto bg- w-75 '>
                                                                                <section className='row d-flex justify-content-center bg- '>

                                                                                    <section className='col'>
                                                                                        <div className='rounded border' style={{
                                                                                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
                                                                                            //  width: "100px",
                                                                                            maxWidth: "90px", minWidth: "60px",
                                                                                            textOverflow: "ellipsis", "textTransform": "capitalize",
                                                                                            height: "30px"
                                                                                        }} >
                                                                                            <text style={{ fontSize: "13px", fontweigh: "500" }}> #
                                                                                                {
                                                                                                    data.suggested_tags
                                                                                                        ?
                                                                                                        data.suggested_tags[1]
                                                                                                        :
                                                                                                        <span> Tag1 </span>
                                                                                                }

                                                                                            </text>
                                                                                        </div>
                                                                                    </section>


                                                                                    <section className='col'>
                                                                                        <div className='rounded border' style={{
                                                                                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
                                                                                            //  width: "100px",
                                                                                            maxWidth: "90px", minWidth: "60px",
                                                                                            textOverflow: "ellipsis", "textTransform": "capitalize",
                                                                                            height: "30px"
                                                                                        }} >
                                                                                            <text style={{ fontSize: "13px", fontweigh: "500" }}>#
                                                                                                {/* {
                                                                                                    data.suggested_tags
                                                                                                        ?
                                                                                                        data.suggested_tags[1]
                                                                                                        :
                                                                                                        <span> Tag1 </span>
                                                                                                } */}
                                                                                                {location.state.tag}
                                                                                            </text>
                                                                                        </div>
                                                                                    </section>







                                                                                </section>



                                                                                <section className='row d-flex justify-content-start  mt-1'>










                                                                                </section>




                                                                            </div>

                                                                        </div>

                                                                    </td>


                                                                </tr>

                                                                {/* <tr className='why 2nd one' >
                                                                    <td className=' ' colSpan={0}>
                                                                        <input type="checkbox" className='checkbox_section mx-2' />
                                                                    </td>
                                                                    <td className=''>
                                                                        <div className='d-flex ' style={{ "width": "200px" }} >

                                                                            {
                                                                                data.image != ""
                                                                                    ?
                                                                                    <img src={data.image} className='btnz ' alt={Imagg} width={55} height={55} style={{ border: "2px solid #C83B93", "borderRadius": "50%" }} />
                                                                                    :
                                                                                    <img src={require('../../Imagez/Common/User.jpg')} className='btnz ' width={55} height={55} style={{ border: "2px solid #C83B93", "borderRadius": "50%" }} />
                                                                            }
                                                                            <div style={{ textAlign: 'initial', marginLeft: '5px' }}>
                                                                                <div className=' d-flex  align-items-center'>
                                                                                    <label className='navHovTextNoBg ' style={{
                                                                                        wordWrap: "breakWord", "zIndex": "", overflow: "hidden",
                                                                                        textOverflow: "ellipsis", width: "130px",
                                                                                    }} onClick={() => {
                                                                                        ProfileNow((data.url).split("@")[1]);

                                                                                        setProfVal((data.url).split("@")[1])

                                                                                    }}>
                                                                                        {data.name}
                                                                                    </label>
                                                                                    <img className='' src={require('../../Imagez/Dashboard/All/mdi_approve.png')} />
                                                                                </div>

                                                                                <label className='social_handle'>
                                                                                    {(data.url).split(".com/")[1]}

                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>


                                                                    <td className=''>
                                                                        {((data.quality_score) * 10).toFixed(0)}
                                                                    </td>


                                                                    <td className='text-uppercase'>


                                                                        {(numeral(data.user_count).format('0.0a'))}

                                                                    </td>

                                                                    <td className='text-uppercase'>

                                                                        {(numeral(data.average_likes).format('0.0a'))}

                                                                    </td>

                                                                    <td className='text-uppercase'>
                                                                        {(numeral(data.average_video_views).format('0.0a'))}

                                                                    </td>

                                                                    <td className=''>
                                                                        {((data.average_ER) * 100).toFixed(2)}%
                                                                    </td>


                                                                    <td className=' py-2' colSpan={3}
                                                                        onMouseEnter={ItsOnn} onMouseLeave={ItsOff}
                                                                        style={{ boxShadow: "0 0 0 0 " }}
                                                                    >



                                                                        {
                                                                            <div className='categories_row py-2  bg- w-100 ' >
                                                                                <div className='my- bg- w-100'>
                                                                                    <section className='row d-flex justify-content-center ps-6 bg-'>

                                                                                        <span className='plan_categories my-1 mx-1 col-lg-5 rounded' style={{
                                                                                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)", " border ": "1px solid grey",
                                                                                            whiteSpace: "nowrap",  //remove
                                                                                            overflow: "hidden",
                                                                                            width: "160px", "textTransform": "capitalize",
                                                                                            textOverflow: "ellipsis"
                                                                                        }}> # {ting}
                                                                                        </span>

                                                                                        <span className='plan_categories my-1 mx-1 col-lg-5 rounded' style={{
                                                                                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)", " border ": "1px solid grey",
                                                                                            whiteSpace: "nowrap",   //remove
                                                                                            overflow: "hidden",
                                                                                            width: "160px", "textTransform": "capitalize",
                                                                                            textOverflow: "ellipsis"
                                                                                        }}>#




                                                                                            {
                                                                                                data.suggested_tags
                                                                                                    ?
                                                                                                    data.suggested_tags[1]
                                                                                                    :
                                                                                                    <span> Tag1 </span>


                                                                                            }
                                                                                        </span>



                                                                                    </section>

                                                                                    <section className='row d-flex justify-content-start ps-5 bg-'>
                                                                                        <span className='plan_categories my-1 col-lg-3 rounded ms-3' style={{
                                                                                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)", " border ": "1px solid grey",
                                                                                            whiteSpace: "nowrap",  //remove
                                                                                            overflow: "hidden",
                                                                                            width: "160px",
                                                                                            textOverflow: "ellipsis"
                                                                                        }}>
                                                                                            More
                                                                                            +
                                                                                        </span>

                                                                                    </section>


                                                                                </div>

                                                                            </div>


                                                                        }


                                                                    </td>
                                                                </tr> */}

                                                            </tbody>
                                                        </>

                                                    );
                                                })


                                                :



                                                <>
                                                    <tbody className='mt-2 bg-transparent'  >
                                                        <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#E5E4E2" borderRadius={5}>

                                                            {

                                                                dada.slice(0, 10).map((data) => {

                                                                    return (
                                                                        <tr id={data.id} >
                                                                            <td className=' ' colSpan={0}>
                                                                                <input type="checkbox" className='checkbox_section mx-2' />
                                                                            </td>

                                                                            <td className=''>
                                                                                <div className='d-flex ' style={{ "width": "200px" }}
                                                                                    onClick={() => {
                                                                                        ProfileNow(("@miridawn").split("@")[1]);

                                                                                        setProfVal(("@miridawn").split("@")[1])

                                                                                    }} >

                                                                                    <Skeleton circle={true} style={{ "borderRadius": "50%" }} width={50} height={50}

                                                                                    />


                                                                                    <div className='' style={{ textAlign: 'initial', marginLeft: '10px' }}>
                                                                                        <div className=' d-flex  align-items-center'>
                                                                                            <Skeleton className='navHovTextNoBg  ' style={{ width: "130px", height: "20px" }} />
                                                                                        </div>

                                                                                        <Skeleton className='social_handle  ' style={{ width: "60px", height: "15px" }} />


                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td className=''>
                                                                                <Skeleton style={{ width: "60px", height: "25px" }} />
                                                                            </td>


                                                                            <td className='text-uppercase'>
                                                                                <Skeleton style={{ width: "60px", height: "25px" }} />

                                                                            </td>

                                                                            <td className='text-uppercase'>
                                                                                <Skeleton style={{ width: "60px", height: "25px" }} />
                                                                            </td>

                                                                            <td className='text-uppercase'>
                                                                                <Skeleton style={{ width: "60px", height: "25px" }} />
                                                                            </td>

                                                                            {/* <td className=''>
                                                                                <Skeleton style={{ width: "60px", height: "25px" }} />
                                                                            </td> */}


                                                                            <td className=' py-2' colSpan={2}
                                                                                onMouseEnter={ItsOnn} onMouseLeave={ItsOff}
                                                                                style={{ boxShadow: "0 0 0 0 " }}
                                                                            >



                                                                                <div className='categories_row py-2   w-100 ' >
                                                                                    <div className='mx-auto bg- w-75 '>
                                                                                        <section className='row d-flex justify-content-center bg- '>

                                                                                            <section className='col'>

                                                                                                <Skeleton style={{
                                                                                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
                                                                                                    //  width: "100px",
                                                                                                    maxWidth: "90px", minWidth: "60px",

                                                                                                    height: "35px"
                                                                                                }} />
                                                                                            </section>

                                                                                            <section className='col'>
                                                                                                <Skeleton style={{
                                                                                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
                                                                                                    // width: "100px",
                                                                                                    maxWidth: "90px", minWidth: "60px",
                                                                                                    height: "35px"

                                                                                                }} />
                                                                                            </section>

                                                                                            <section className='col'>
                                                                                                <Skeleton style={{
                                                                                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
                                                                                                    // width: "100px",
                                                                                                    maxWidth: "90px", minWidth: "60px",
                                                                                                    height: "35px"
                                                                                                }} />

                                                                                            </section>





                                                                                        </section>



                                                                                        <section className='row d-flex justify-content-start  mt-1'>










                                                                                        </section>




                                                                                    </div>

                                                                                </div>





                                                                            </td>


                                                                        </tr>
                                                                    )
                                                                })

                                                            }

                                                        </SkeletonTheme>

                                                    </tbody>
                                                </>
                                        }
                                        {/* LOADING SKELETON SECTION CHANNEL SECTION 🔺 */}



                                    </table>
                                </div>

                            </>

                            :

                            <>

                                {/* VIDEO TABLE SECTION */}
                                {
                                    allvideo.length != 0
                                        ?
                                        allvideo.map((data) => {
                                            return (
                                                <div className='bg-light d-flex justify-content-between w-100 my-3 p-4   ' style={{ borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)" }}>
                                                    <div className='d-flex '>
                                                        <div className='bg d-flex align-items-center'>
                                                            {
                                                                data.video_details[0].snippet.thumbnails.high === undefined
                                                                    ?
                                                                    <img className='border rounded' src={require('../../Imagez/Dashboard/All/blank.png')} width={300} height={150} />
                                                                    :
                                                                    <img className='' src={data.video_details[0].snippet.thumbnails.high.url} width={300} height={150} style={{ "objectFit": "scaleDown", borderRadius: "10px", border: "5px solid #C83B93" }} alt='thumb' />
                                                            }
                                                        </div>




                                                        {/* 2---VEDIO DETAIL SECTION-------- */}
                                                        <div className='mx-3 d-flex flex-column'>
                                                            <div>
                                                                <label className='font-weight-bold my-1' style={{ fontSize: "16px" }}>
                                                                    {data.video_details[0].snippet.title}
                                                                </label>
                                                            </div>


                                                            <div>
                                                                {
                                                                    data.video_details[0].snippet.tags
                                                                        ?
                                                                        <>

                                                                            <label className='font-weight-bold  mx-1 py-1 px-2 text-center rounded' style={{ fontSize: "16px", minWidth: "60px", backgroundColor: "whitesmoke", borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", textTransform: "capitalize" }}>
                                                                                # {data.video_details[0].snippet.tags[0]}
                                                                            </label>

                                                                            <label className='font-weight-bold  mx-1 py-1 px-2 text-center rounded' style={{ fontSize: "16px", minWidth: "60px", backgroundColor: "whitesmoke", borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", textTransform: "capitalize" }}>
                                                                                # {data.video_details[0].snippet.tags[1]}
                                                                            </label>

                                                                            <label className='font-weight-bold  mx-1 py-1 px-2 text-center rounded' style={{ fontSize: "16px", minWidth: "60px", backgroundColor: "whitesmoke", borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", textTransform: "capitalize" }}>
                                                                                # {data.video_details[0].snippet.tags[2]}
                                                                            </label>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <label className='font-weight-bold  mx-1 py-1 px-2 text-center rounded' style={{ fontSize: "16px", minWidth: "60px", backgroundColor: "whitesmoke", borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)", textTransform: "capitalize" }}>
                                                                                #{ting}
                                                                            </label>
                                                                        </>
                                                                }
                                                            </div>



                                                            <div className='mt-3'>
                                                                <label className='ps-1 mt-3' style={{ fontSize: "14px" }}>
                                                                    Published On : {dayjs(data.video_details[0].snippet.publishedAt).format("DD-MM-YYYY")}
                                                                </label>
                                                            </div>


                                                            <div className='mt-1 d-flex'>
                                                                <span className=' text-secondary '>
                                                                    <span className='d-flex align-items-center'>
                                                                        <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                                                        &nbsp;
                                                                        {(numeral(data.video_details[0].statistics.viewCount).format('0.0a'))}
                                                                    </span>
                                                                </span>

                                                                <span className=' text-secondary mx-2'>
                                                                    <span className='d-flex align-items-center'>
                                                                        <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#7E7E7E" }} />
                                                                        &nbsp;
                                                                        {(numeral(data.video_details[0].statistics.likeCount).format('0.0a'))}
                                                                    </span>
                                                                </span>

                                                                <span className=' text-secondary'>
                                                                    <span className='d-flex align-items-center'>
                                                                        <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                                                                        &nbsp;
                                                                        {(numeral(data.video_details[0].statistics.commentCount).format('0.0a'))}
                                                                    </span>
                                                                </span>

                                                            </div>


                                                        </div>
                                                    </div>





                                                    {/* Right section  */}
                                                    <div className='text-center  me-4'>

                                                        <div>
                                                            {
                                                                data.channel_details[0].snippet.thumbnails.high != undefined
                                                                    ?

                                                                    <img src={data.channel_details[0].snippet.thumbnails.high.url} onClick={() => {

                                                                    }} className='btnz' style={{ "borderRadius": "50%" }} width={100} />
                                                                    :
                                                                    <img src={require('../../Imagez/Dashboard/All/camp_user.png')} width={100} className='p-1 btnz' style={{ borderRadius: "50%", cursor: "grab", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)" }} />
                                                            }


                                                        </div>

                                                        <div className='mt-1'>
                                                            <label style={{ fontSize: "16px", textOverflow: "ellipsis" }}>
                                                                {data.channel_details[0].snippet.title}

                                                            </label>
                                                        </div>

                                                        <label className='text-secondary' style={{ fontSize: "14px" }}>
                                                            <i class="fa fa-user mx-2" aria-hidden="true"></i>

                                                            {data.channel_details[0].statistics.subscriberCount}
                                                        </label>
                                                    </div>
                                                </div>
                                            )

                                        })
                                        :
                                        <>
                                            {/* LOADING VIDEOS SECTION  */}
                                            <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={5}>

                                                {
                                                    dada.slice(0, 4).map((data) => {
                                                        return (
                                                            <div className='bg-light d-flex justify-content-between w-100 my-3 p-4   ' style={{ borderRadius: "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)" }}>
                                                                <div className='d-flex '>
                                                                    <div className='bg d-flex align-items-center'>
                                                                        <Skeleton className='border rounded' width={300} height={150} />
                                                                        {/* <img className='border rounded' src={require('../../Imagez/Dashboard/All/blank.png')} width={300} height={150} /> */}
                                                                    </div>
                                                                    {/* 2----------- */}
                                                                    <div className='mx-3 d-flex flex-column'>
                                                                        <div>
                                                                            <label className='font-weight-bold my-1' style={{ fontSize: "16px" }}>
                                                                                <Skeleton className='my-1' style={{ width: "360px", height: "25px" }} />
                                                                            </label>
                                                                        </div>


                                                                        <div>

                                                                            <label className='font-weight-bold  mx-1 py-1  text-center rounded'>
                                                                                <Skeleton className='me-2' style={{ width: "60px", height: "25px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)" }} />
                                                                            </label>

                                                                            <label className='font-weight-bold  mx-1 py-1  text-center rounded'>
                                                                                <Skeleton className='me-2' style={{ width: "60px", height: "25px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)" }} />
                                                                            </label>


                                                                        </div>



                                                                        <div className='mt-3'>
                                                                            <label className='mx-1'>
                                                                                {/* 2023.06.03 */}
                                                                                <Skeleton className='ps-1 mt-3' style={{ width: "120px", height: "20px" }} />
                                                                            </label>
                                                                        </div>

                                                                        <div className='mt-1 d-flex  w-100 bg'>


                                                                            <span className=' text-secondary ms-1 me-3 bg- '>
                                                                                {/* <i class="fa fa-play-circle " aria-hidden="true"></i>  */}
                                                                                <span className='d-flex align-items-center'>
                                                                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                                                                    &nbsp;
                                                                                    <Skeleton className='' style={{ width: "40px", height: "20px" }} />
                                                                                </span>

                                                                            </span>

                                                                            <span className=' text-secondary me-3 bg-'>
                                                                                {/* <i class="fa fa-thumbs-up"></i> */}
                                                                                <span className='d-flex align-items-center'>
                                                                                    <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />
                                                                                    &nbsp;
                                                                                    <Skeleton className='' style={{ width: "40px", height: "20px" }} />
                                                                                </span>
                                                                            </span>

                                                                            <span className=' text-secondary me-3 bg-'>
                                                                                {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                                                                                <span className='d-flex align-items-center'>
                                                                                    <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                                                                                    &nbsp;
                                                                                    <Skeleton className='' style={{ width: "40px", height: "20px" }} />
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                {/* Right section  */}
                                                                <div className='text-center me-4'>
                                                                    {/* 6 */}
                                                                    <div>
                                                                        {/* <img src={require('../../Imagez/Dashboard/All/camp_user.png')} width={100} className='p-1 btnz' style={{ borderRadius: "50%", cursor: "grab", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)" }} /> */}
                                                                        <Skeleton circle={true} height={100} width={100} className='btnz' style={{ borderRadius: "", cursor: "grab", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)" }} />
                                                                    </div>
                                                                    {/* 4 */}
                                                                    <div className='mt-1'>
                                                                        <label style={{ fontSize: "16px", textOverflow: "ellipsis" }}>
                                                                            <Skeleton className='mx-2' style={{ width: "150px", height: "25px" }} />
                                                                        </label>
                                                                    </div>
                                                                    {/* 5 */}
                                                                    <label className='text-secondary' style={{ fontSize: "14px" }}>
                                                                        <Skeleton className='mx-2' style={{ width: "60px", height: "20px" }} />

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </SkeletonTheme>
                                        </>
                                }

                            </>

                    }
                    {/* ______________________________________________________________________________YOUTUBE VIDEO / CHANNEL SECTION ______________________________________________________________________________ */}
                </div>

                <Modal
                    show={planModal}
                    onHide={() => { setplanModal(false) }}
                >
                    <div className='plan_modal'>

                        <div className='create_plan_head'>
                            <label>
                                Create Plan
                            </label>

                            <hr className='tips_line' />
                        </div>
                        <div className='d-flex flex-column mt-3'>
                            <label className='camp_label'>Name of the Plan</label>
                            <input type='text' className='camp_input' placeholder='Enter Plan Name' />
                        </div>

                        <div className='d-flex flex-column mt-3'>
                            <label className='camp_label'>Brand</label>
                            <input type='text' className='camp_input' placeholder='Enter Plan Name' />
                        </div>

                        <div className='d-flex flex-column mt-3'>
                            <label className='camp_label'>I want to add influencers by</label>
                            <div className='d-flex flex-column'>
                                <div>
                                    <input type='radio' className='mx-2' />
                                    <label>
                                        Searching Influencers From Dreabee
                                    </label>
                                </div>

                                <div>
                                    <input type='radio' className='mx-2' />
                                    <label>
                                        Adding Influencers Manually
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='create_plan_btn mt-4'>
                            <button className='btn'>
                                Create Plan
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>


            {/* SlideSection */}
            <div className={`slide-container ${blow ? 'slide-in' : 'slide-out'}`
            }>
                <div className="slide-content" >
                    {/* Your content goes here */}
                    <div className='row' >
                        <div className='col-lg-12 pt-3 m-0 add_plan_setion'>
                            <label>
                                Kindly select a plan to<br /> add influencers
                            </label>

                            <div className='add_plans_inner'>
                                <button className='btn plan_add_btn' onClick={() => { setplanModal(true) }}>
                                    <img src={require('../../Imagez/Dashboard/All/Invite.png')} />
                                    New Plan
                                </button>

                                <div className='added_plan mt-5'>
                                    <label>
                                        New Plan 1
                                    </label>

                                    <div className='list_footer'>
                                        <label>
                                            List 1
                                        </label>

                                        <label>
                                            4th Aug, 2023
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='View_plan_section '>
                                <button className='View_plan_btn btn me-3'>
                                    View Plan
                                </button>
                                {

                                    blow
                                        ?
                                        <button onClick={toggleSlide} className='ps-2 py-2 pb-3'
                                            style={{ "border": "none", background: "white", "position": "relative", "top": "-18.2rem", "left": "-13.5rem", "opacity": "0.6", "borderRadius": " 20px 0 0 20px" }}>
                                            <i class='bx bxs-chevrons-right bx-fade-right' style={{ "fontSize": "3rem" }} ></i>
                                            <span style={{ "position": "relative", "left": "-45px", "fontWeight": "700", "top": "12px", "fontSize": "13px" }}> Close Plans </span></button>
                                        :
                                        <button onClick={toggleSlide} className='ps-2 py-2 pb-3'
                                            style={{ "border": "none", background: "white", "position": "relative", "top": "-38.8rem", "left": "-13.5rem", "opacity": "0.6", "borderRadius": " 20px 0 0 20px" }}>
                                            <i class='bx bxs-chevrons-left bx-fade-right' style={{ "fontSize": "3rem" }} ></i>
                                            <span style={{ "position": "relative", "left": "-45px", "fontWeight": "700", "top": "12px", "fontSize": "13px" }}> View Plans </span></button>

                                }
                            </div>



                        </div>
                    </div>
                </div>
            </div>



            {/* Deep Search Slide */}
            <div className={`slide-container-deep ${DeepSearch ? 'slide-in-deep' : 'slide-out-deep'}`
            }>
                <div className="slide-content  mx-0" style={{ "width": "" }} >
                    {/* Your content goes here */}
                    <div style={{ padding: '15px', backgroundColor: "#fff", width: '100%', height: '100vh', overflowY: 'scroll', overflowX: "hidden" }}>
                        <div className='filter_header'>
                            <button className='deep_search_cross' onClick={() => setDeepSearch(false)}>
                                <i class="fa fa-angle-left"></i>
                            </button>

                            <label className='Filter_name'>
                                Deep Search Filter
                            </label>
                        </div>
                        <div>




                            {/*("type") */}

                            {/* beta.includes("Type") */}

                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Type&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Type")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between'>
                                                <button onClick={() => { setChanType("Upload"); setChoicez(true) }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Upload
                                                </button>

                                                <button onClick={() => { setChanType("Live"); setChoicez(true) }} className={chanType == "Live" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Live Stream
                                                </button>

                                                <button onClick={() => { setChanType("Premiere"); setChoicez(true) }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Premiere
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}


                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* ("Avg. Views") */}
                            {/* beta.includes("Avg. Views") */}

                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Avg. Views")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>0 - 10k </option>
                                                <option className='text-center'>10k - 50k </option>
                                                <option className='text-center'>50k - 100k  </option>
                                                <option className='text-center'>100k - 200k </option>
                                                <option className='text-center'>200k - 500k  </option>
                                                <option className='text-center'>500k - 1M </option>
                                                <option className='text-center'>1M - 2M </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}




                            {/* ("Avg. Likes") */}
                            {/* beta.includes("Avg. Likes") */}

                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Likes&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Avg. Likes")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>

                                                <option className='text-center'>1k - 3k </option>
                                                <option className='text-center'>3k - 5k  </option>
                                                <option className='text-center'>5k - 10k </option>
                                                <option className='text-center'>10k - 20k  </option>
                                                <option className='text-center'>20k - 30k </option>
                                                <option className='text-center'>30k + </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}


                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* ("Avg. comments") */}
                            {/* beta.includes("Avg. comments") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Live Viewers&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Avg. comments")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>1k - 3k </option>
                                                <option className='text-center'>3k - 5k  </option>
                                                <option className='text-center'>5k - 10k </option>
                                                <option className='text-center'>10k - 20k  </option>
                                                <option className='text-center'>20k - 30k </option>
                                                <option className='text-center'>30k + </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}






                            {/* beta.includes("E.R %") */}
                            <>
                                {/* <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            E.R %&nbsp;<i className='bx bx-info-circle text-secondary btnz popup' onClick={() => { myFunction(); }} style={{ fontSize: "16px" }}></i>
                                   
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("E.R %")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>0 - 5</option>
                                                <option className='text-center'>5 - 10</option>
                                                <option className='text-center'>10 - 20</option>
                                                <option className='text-center'>20 - 50</option>
                                                <option className='text-center'>50+</option>

                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </>


                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* beta.includes("Duration") ------------------*/}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Duration&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Duration</option>
                                                <option className='text-center'>Short (60 sec or less) </option>
                                                <option className='text-center'>61 sec+ </option>
                                                <option className='text-center'>3 min+ </option>
                                                <option className='text-center'>10 min+ </option>
                                                <option className='text-center'>1 hr+ </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* beta.includes("Views/7days") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views/7 days&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Views/7days")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>10k - 50k</option>
                                                <option className='text-center'>50k - 100k</option>
                                                <option className='text-center'>100k - 200k</option>
                                                <option className='text-center'>200k - 500k</option>
                                                <option className='text-center'>500k - 1M</option>
                                                <option className='text-center'>1M +</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}


                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* beta.includes("Views/15days") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views/15 days&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Views/15days")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>10k - 50k</option>
                                                <option className='text-center'>50k - 100k</option>
                                                <option className='text-center'>100k - 200k</option>
                                                <option className='text-center'>200k - 500k</option>
                                                <option className='text-center'>500k - 1M</option>
                                                <option className='text-center'>1M +</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}


                            {/* beta.includes("Views/30days") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views/30 days&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Views/30days")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>10k - 50k</option>
                                                <option className='text-center'>50k - 100k</option>
                                                <option className='text-center'>100k - 200k</option>
                                                <option className='text-center'>200k - 500k</option>
                                                <option className='text-center'>500k - 1M</option>
                                                <option className='text-center'>1M +</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}

                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* beta.includes("Super Chat") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Super Chat&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Super Chat")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>$100 +</option>
                                                <option className='text-center'>$1,000 +</option>
                                                <option className='text-center'>$5,000 +</option>
                                                <option className='text-center'>$10,000 +</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* beta.includes("Weekly update") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Weekly update&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Weekly update")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>1 - 3</option>
                                                <option className='text-center'>3 - 5</option>
                                                <option className='text-center'>7+ </option>
                                                <option className='text-center'>10+  </option>
                                                <option className='text-center'>15+</option>
                                                <option className='text-center'>20+ </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}

                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* beta.includes("Views/Subs") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views/Subs&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Views/Subs")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>10% +</option>
                                                <option className='text-center'>30% +</option>
                                                <option className='text-center'>50% +</option>
                                                <option className='text-center'>100% +</option>
                                                <option className='text-center'>200% +</option>
                                                <option className='text-center'>300% +</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* beta.includes("Cmt./Views") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Cmt./Views&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Cmt./Views")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>1% +</option>
                                                <option className='text-center'>3% +</option>
                                                <option className='text-center'>5% +</option>
                                                <option className='text-center'>10% +</option>
                                                <option className='text-center'>15% +</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}

                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* beta.includes("Like/Views") -------------------- */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Like/Views&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Like/Views")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>1% +</option>
                                                <option className='text-center'>3% +</option>
                                                <option className='text-center'>5% +</option>
                                                <option className='text-center'>10% +</option>
                                                <option className='text-center'>15% +</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* beta.includes("Live Viewers") */}
                            {/* ? */}
                            <>
                                {/* <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Live Viewers&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Live Viewers")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>1k - 3k </option>
                                                <option className='text-center'>3k - 5k  </option>
                                                <option className='text-center'>5k - 10k </option>
                                                <option className='text-center'>10k - 20k  </option>
                                                <option className='text-center'>20k - 30k </option>
                                                <option className='text-center'>30k + </option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* beta.includes("Categories") */}
                            {/* <div className='filter_menu'>
                                <div className=' d-flex justify-content-between'>
                                    <label className='filter_label d-flex align-items-center'>
                                        Categories&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                    </label>

                                    <label className='filter_label ' onClick={() => handleClickReverse("Categories")}  >
                                        <i className='bx bx-x btnz'></i>
                                    </label>
                                </div>

                                <div className='row py-3'>
                                    <div className='col-lg-12'>
                                        <section className='d-flex justify-content-between' style={{ flexWrap: "wrap" }}>
                                            {
                                                allCats.length != 0

                                                    ?
                                                    allCats.slice(0, 8).map((data, index) => {
                                                        return (
                                                            <>
                                                                <button id={data.id} onClick={() => { setCatsIdz() }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                                    {data.title}
                                                                </button>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    null
                                            }

                                        </section>
                                    </div>
                                </div>
                            </div> */}

                            {/* beta.includes("Last Promotion") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Last Promotion&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Last Promotion")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>
                                                <button onClick={() => { setChanType("Upload") }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 7 days
                                                </button>

                                                <button onClick={() => { setChanType("Live") }} className={chanType == "Live" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Past 15 days
                                                </button>

                                                <button onClick={() => { setChanType("Premiere") }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Past 30 days
                                                </button>

                                                <button onClick={() => { setChanType("Upload1") }} className={chanType == "Upload1" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 180 days
                                                </button>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* beta.includes("Last Update") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Last Update&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Last Update")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>
                                                <button onClick={() => { setChanType("Upload") }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 24 hours
                                                </button>

                                                <button onClick={() => { setChanType("Uplooad") }} className={chanType == "Uplooad" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 7 days
                                                </button>

                                                <button onClick={() => { setChanType("Live") }} className={chanType == "Live" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Past 15 days
                                                </button>

                                                <button onClick={() => { setChanType("Premiere") }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Past 30 days
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}



                            {/* beta.includes("Publish Date")--------------------------- */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Publish Date&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Publish Date")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>
                                                <button onClick={() => { setChanType("Upload") }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 24 hours
                                                </button>

                                                <button onClick={() => { setChanType("Uplooad") }} className={chanType == "Uplooad" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Past 7 days
                                                </button>

                                                <button onClick={() => { setChanType("Live") }} className={chanType == "Live" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Past 15 days
                                                </button>

                                                <button onClick={() => { setChanType("Premiere") }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Past 30 days
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}

                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* beta.includes("Advertisement") */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Advertisement&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Advertisement")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>
                                                <button onClick={() => { setChanType("Upload") }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Includes Paid Promotion
                                                </button>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}


                            {/* beta.includes("Country") */}
                            <>
                                {/* <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Country&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Country")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                       





                                        <div className='d-flex  justify-content-end '>

                                            <button className={city ? 'youtube_filter_btn RoundBtnz px-3' : 'youtube_filter_btn RoundBtnz px-3'}
                                                onClick={() => {
                                                    setVideoCountryDrop(!videoCountryDrop)
                                                }}>
                                                {
                                                    videoCountryDrop
                                                        ?
                                                        <i class="fa fa-xmark" aria-hidden="true"></i>
                                                        :
                                                        <i class='bx bx-dots-horizontal-rounded'></i>
                                                }
                                            </button>

                                            {
                                                videoCountryDrop
                                                    ?
                                                    <span className='bg-light' style={{
                                                        "height": "0px", "width": "0px", "position": "relative", left: "-5rem", "zIndex": "10000"
                                                    }}>
                                                        <CountryDropz />
                                                    </span>
                                                    :
                                                    null
                                            }


                                            {

                                                videoCountry
                                                    ?

                                                    <button className='youtube_filter_btn RoundBtnz' onClick={() => {
                                                        setVideoCountry("")
                                                    }}>
                                                        <i class="fa fa-xmark" aria-hidden="true"></i>
                                                    </button>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </div> */}
                            </>



                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* Publish Date */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Upload Date&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Publish Date")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("hour") }} className={chanType == "hour" ? "activeButtonFilter btn " : "blck_btn   btn"}>

                                                    Last Hour
                                                </button>

                                                <button onClick={() => { setChanType("today") }} className={chanType == "today" ? "activeButtonFilter btn " : "blck_btn   btn"}>

                                                    Today
                                                </button>

                                                <button onClick={() => { setChanType("week") }} className={chanType == "week" ? "activeButtonFilter btn " : "  blck_btn btn"}>

                                                    This week
                                                </button>

                                                <button onClick={() => { setChanType("month") }} className={chanType == "month" ? "activeButtonFilter btn " : "blck_btn  btn  "}>

                                                    This Month
                                                </button>

                                                <button onClick={() => { setChanType("year") }} className={chanType == "year" ? "activeButtonFilter btn " : "blck_btn  btn  "}>

                                                    This Year
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </>

                            {/* Type */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Type&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Type")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between'>
                                                <button onClick={() => { setChanType("Video") }} className={chanType == "Video" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Video
                                                </button>

                                                <button onClick={() => { setChanType("Channel") }} className={chanType == "Channel" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Channel
                                                </button>

                                                <button onClick={() => { setChanType("Playlist") }} className={chanType == "Playlist" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Playlist
                                                </button>
                                                <button onClick={() => { setChanType("Film") }} className={chanType == "Film" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Film
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </>

                            {/* Duration  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Duration&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>




                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("4min") }} className={chanType == "4min" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Under 4 min
                                                </button>

                                                <button onClick={() => { setChanType("4-20min") }} className={chanType == "4-20min" ? "activeButtonFilter btn " : "blck_btn   btn"}>

                                                    4-20 min
                                                </button>

                                                <button onClick={() => { setChanType("20+") }} className={chanType == "20+" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Over 20 min
                                                </button>



                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>

                            {/* Feature  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Feature &nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("Live") }} className={chanType == "Live" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Live
                                                </button>

                                                <button onClick={() => { setChanType("4K") }} className={chanType == "4K" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    4K
                                                </button>

                                                <button onClick={() => { setChanType("HD") }} className={chanType == "HD" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    HD
                                                </button>

                                                <button onClick={() => { setChanType("cc") }} className={chanType == "cc" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Subtitles/CC
                                                </button>

                                                <button onClick={() => { setChanType("createCommons") }} className={chanType == "createCommons" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Creative Commons
                                                </button>

                                                <button onClick={() => { setChanType("360") }} className={chanType == "360" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    360
                                                </button>

                                                <button onClick={() => { setChanType("VR") }} className={chanType == "VR" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    VR180
                                                </button>

                                                <button onClick={() => { setChanType("3D") }} className={chanType == "3D" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    3D
                                                </button>

                                                <button onClick={() => { setChanType("HDR") }} className={chanType == "HDR" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    HDR
                                                </button>

                                                <button onClick={() => { setChanType("Location") }} className={chanType == "Location" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Location
                                                </button>

                                                <button onClick={() => { setChanType("Purchased") }} className={chanType == "Purchased" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Purchased
                                                </button>



                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>

                            {/* Sort By  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Sort By &nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("Relevance") }} className={chanType == "Relevance" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Relevance
                                                </button>

                                                <button onClick={() => { setChanType("UploadedDate") }} className={chanType == "UploadedDate" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Uploaded Date
                                                </button>

                                                <button onClick={() => { setChanType("ViewCount") }} className={chanType == "ViewCount" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    View Count
                                                </button>

                                                <button onClick={() => { setChanType("Rating") }} className={chanType == "Rating" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Rating
                                                </button>





                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>


                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            <div>
                                <button className='deep_search_btn'>
                                    Search
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {/* Deep Search Slide Video */}
            <div className={`slide-container-deep-video ${DeepSearchVideo ? 'slide-in-deep-video' : 'slide-out-deep-video'}`
            }>
                <div className="slide-content  mx-0" style={{ "width": "" }} >
                    {/* Your content goes here */}
                    <div style={{ padding: '15px', backgroundColor: "#fff", width: '100%', height: '100vh', overflowY: 'scroll', overflowX: "hidden" }}>
                        <div className='filter_header'>
                            <button className='deep_search_cross' onClick={() => setDeepSearchVideo(false)}>
                                <i class="fa fa-angle-left"></i>
                            </button>

                            <label className='Filter_name'>
                                Deep Search Filter
                            </label>
                        </div>
                        <div>
                            {/* ---------------------------------- */}
                            {/* Publish Date */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Upload Date&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Publish Date")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("hour") }} className={chanType == "hour" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    {/* Past 24 hours */}
                                                    Last Hour
                                                </button>

                                                <button onClick={() => { setChanType("today") }} className={chanType == "today" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    {/* Past 7 days */}
                                                    Today
                                                </button>

                                                <button onClick={() => { setChanType("week") }} className={chanType == "week" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    {/* Past 15 days */}
                                                    This week
                                                </button>

                                                <button onClick={() => { setChanType("month") }} className={chanType == "month" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    {/* Past 30 days */}
                                                    This Month
                                                </button>

                                                <button onClick={() => { setChanType("year") }} className={chanType == "year" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    {/* Past 30 days */}
                                                    This Year
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </>
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* Type */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Type&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Type")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between'>
                                                <button onClick={() => { setChanType("Video") }} className={chanType == "Video" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Video
                                                </button>

                                                <button onClick={() => { setChanType("Channel") }} className={chanType == "Channel" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Channel
                                                </button>

                                                <button onClick={() => { setChanType("Playlist") }} className={chanType == "Playlist" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Playlist
                                                </button>
                                                <button onClick={() => { setChanType("Film") }} className={chanType == "Film" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                    Film
                                                </button>

                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </>
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* Duration  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Duration&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    {/* <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Duration</option>
                                                <option className='text-center'>Short</option>
                                                <option className='text-center'>Medium</option>
                                                <option className='text-center'>Long</option>

                                            </select>
                                        </div>
                                    </div> */}


                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("4min") }} className={chanType == "4min" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Under 4 min
                                                </button>

                                                <button onClick={() => { setChanType("4-20min") }} className={chanType == "4-20min" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    {/* Past 7 days */}
                                                    4-20 min
                                                </button>

                                                <button onClick={() => { setChanType("20+") }} className={chanType == "20+" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Over 20 min
                                                </button>



                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}

                            {/* ---------------------------------- */}
                            {/* Feature  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Feature &nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("Live") }} className={chanType == "Live" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Live
                                                </button>

                                                <button onClick={() => { setChanType("4K") }} className={chanType == "4K" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    4K
                                                </button>

                                                <button onClick={() => { setChanType("HD") }} className={chanType == "HD" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    HD
                                                </button>

                                                <button onClick={() => { setChanType("cc") }} className={chanType == "cc" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Subtitles/CC
                                                </button>

                                                <button onClick={() => { setChanType("createCommons") }} className={chanType == "createCommons" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Creative Commons
                                                </button>

                                                <button onClick={() => { setChanType("360") }} className={chanType == "360" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    360
                                                </button>

                                                <button onClick={() => { setChanType("VR") }} className={chanType == "VR" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    VR180
                                                </button>

                                                <button onClick={() => { setChanType("3D") }} className={chanType == "3D" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    3D
                                                </button>

                                                <button onClick={() => { setChanType("HDR") }} className={chanType == "HDR" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    HDR
                                                </button>

                                                <button onClick={() => { setChanType("Location") }} className={chanType == "Location" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Location
                                                </button>

                                                <button onClick={() => { setChanType("Purchased") }} className={chanType == "Purchased" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Purchased
                                                </button>



                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* Sort By  */}
                            <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Sort By &nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Duration")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>

                                                <button onClick={() => { setChanType("Relevance") }} className={chanType == "Relevance" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Relevance
                                                </button>

                                                <button onClick={() => { setChanType("UploadedDate") }} className={chanType == "UploadedDate" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Uploaded Date
                                                </button>

                                                <button onClick={() => { setChanType("ViewCount") }} className={chanType == "ViewCount" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    View Count
                                                </button>

                                                <button onClick={() => { setChanType("Rating") }} className={chanType == "Rating" ? "activeButtonFilter btn " : "  blck_btn btn"}>
                                                    Rating
                                                </button>





                                            </section>
                                        </div>
                                    </div>


                                </div>
                            </>
                            {/* ---------------------------------- */}






                            {/* Views */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Views&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Views")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 '
                                                onChange={(e) => {
                                                    setVideoMinView(e.target.value);
                                                    videoDetails(videoType, videoDuration, videoAds, videoPublished, videoCategory, videoCountry, e.target.value, videoMaxView)
                                                }}
                                                style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center' value={10000} >10k - 50k</option>
                                                <option className='text-center' value={50000}>50k - 100k</option>
                                                <option className='text-center'>100k - 200k</option>
                                                <option className='text-center'>200k - 500k</option>
                                                <option className='text-center'>500k - 1M</option>
                                                <option className='text-center'>1M+</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}
                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* Super Chat */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label '>
                                            Super Chat&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Super Chat")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-7 py-2'>
                                            <InputRange
                                                maxValue={100}
                                                minValue={0}
                                                value={View}

                                                onChange={value => setView(value)}
                                            />
                                        </div>

                                        <div className='col-lg-5 bg- text-end'>
                                            <select className=' p-2 ' style={{ borderRadius: "7px", fontWeight: "600" }}>
                                                <option className='text-center text-secondary' >Select Range</option>
                                                <option className='text-center'>$100 +</option>
                                                <option className='text-center'>$1,000 +</option>
                                                <option className='text-center'>$5,000 +</option>
                                                <option className='text-center'>$10,000 +</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </> */}
                            {/* ---------------------------------- */}


                            {/* ---------------------------------- */}

                            {/* Advertisement */}
                            {/* <>
                                <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Advertisement&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Advertisement")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ "flexWrap": "wrap" }}>
                                                <button onClick={() => { setChanType("Upload") }} className={chanType == "Upload" ? "activeButtonFilter btn " : "blck_btn   btn"}>
                                                    Includes Paid Promotion
                                                </button>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </> */}

                            {/* ---------------------------------- */}
                            {/*Categories  */}
                            <>
                                {/* <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Categories&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Categories")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                        <div className='col-lg-12'>
                                            <section className='d-flex justify-content-between' style={{ flexWrap: "wrap" }}>
                                                {
                                                    allCats.length != 0

                                                        ?
                                                        allCats.slice(0, 8).map((data, index) => {
                                                            return (
                                                                <>
                                                                    <button id={data.id} onClick={() => { setCatsIdz() }} className={chanType == "Premiere" ? "activeButtonFilter btn " : "blck_btn  btn  "}>
                                                                        {data.title}
                                                                    </button>
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }

                                            </section>
                                        </div>
                                    </div>
                                </div> */}
                            </>

                            {/* ---------------------------------- */}
                            {/* ---------------------------------- */}
                            {/* Country */}
                            <>
                                {/* <div className='filter_menu'>
                                    <div className=' d-flex justify-content-between'>
                                        <label className='filter_label d-flex align-items-center'>
                                            Country&nbsp;<i className='bx bx-info-circle text-secondary btnz' style={{ fontSize: "16px" }}></i>
                                        </label>

                                        <label className='filter_label ' onClick={() => handleClickReverse("Country")}  >
                                            <i className='bx bx-x btnz'></i>
                                        </label>
                                    </div>

                                    <div className='row py-3'>
                                      





                                        <div className='d-flex  justify-content-end '>

                                            <button className={city ? 'youtube_filter_btn RoundBtnz px-3' : 'youtube_filter_btn RoundBtnz px-3'}
                                                onClick={() => {
                                                    setVideoCountryDrop(!videoCountryDrop)
                                                }}>
                                                {
                                                    videoCountryDrop
                                                        ?
                                                        <i class="fa fa-xmark" aria-hidden="true"></i>
                                                        :
                                                        <i class='bx bx-dots-horizontal-rounded'></i>
                                                }
                                            </button>

                                            {
                                                videoCountryDrop
                                                    ?
                                                    <span className='bg-light' style={{
                                                        "height": "0px", "width": "0px", "position": "relative", left: "-5rem", "zIndex": "10000"
                                                    }}>
                                                        <CountryDropz />
                                                    </span>
                                                    :
                                                    null
                                            }


                                            {

                                                videoCountry
                                                    ?

                                                    <button className='youtube_filter_btn RoundBtnz' onClick={() => {
                                                        setVideoCountry("")
                                                    }}>
                                                        <i class="fa fa-xmark" aria-hidden="true"></i>
                                                    </button>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </div> */}
                            </>

                            {/* ---------------------------------- */}

















                            <div>
                                <button className='deep_search_btn'>
                                    Search
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
