import React from 'react'
import SignUpHead from '../Brands_Login_SignUp/SignUp/SignUpHead'
import LoginForm from '../Brands_Login_SignUp/SignUp/LoginForm'
import { FadeLoader } from 'react-spinners'
import { baseUrl } from '../../Routez/Routey';

import logo from '../../Imagez/Common/logo.png'
import trapBox from '../../Imagez/signIn_LogIn/Login_pattern2.png'

import { useState } from 'react';
import { useEffect } from 'react';
import { TransparentCard } from '../../PagesComponent/CommonComponents/TransparentCard'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import FacebookLogin from '@greatsumini/react-facebook-login';

import phone from '../../Imagez/LandinPgImg/phone.png'
import { useInView } from 'react-intersection-observer';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import axios from 'axios';

const AllInOneLogin = () => {
    useEffect(() => {
        window.localStorage.removeItem("UserName");
        window.localStorage.removeItem("userIn");
        setLoading(false)
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1000)
    }, [])

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);







    const textRef = useRef(null);
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view

    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view

    });
    const [ref3, inView3] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view
        delay: 100
    });


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errow, setErrow] = useState(false);
    const [rememberr, setRememberr] = useState(true)




    const glogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse)
            window.localStorage.setItem("isLoggedIn", true);
            window.localStorage.setItem("Gmail-Auth", tokenResponse.access_token)
            navigate("/Dreabee/Dashboard");
            // swal("You are successfully Logged In", "👍", "success");


            // setTimeout(() => {
            //     // swal.close()
            // }, 1000);



        }
    })
    // -------------------

    const BrandsLogin = () => {
        // console.log({ name, email, password, repass, mobile });
        // console.log("name");
        let data = { email, password };
        if (email.length === 0 || password.length === 0 || errow.length === 0) {
            setErrow(true)
        } else {
            axios.post(`${baseUrl}api/Brands/BrandLogin`, {
                email: data.email,
                password: data.password
            }).then((res) => {
                console.log("login response - ", res.data.Id)
                navigate("/Dreabee/Dashboard");
                swal("You are successfully Logged In", "👍", "success");


                window.localStorage.setItem("userIn", "brand");
                window.localStorage.setItem("isLoggedIn", true);
                window.localStorage.setItem("Access-Id", res.data.Id)


            }).catch((error) => {
                console.log("error", error);
                swal("Oops!", "Maybe a wrong email or password! Try Again.", "error");
            })

        }
    }
    // +++++++++++++++++++++


    const AgencyLogin = () => {
        // console.log({ name, email, password, repass, mobile });
        // console.log("name");
        let data = { email, password };
        if (email.length == 0 || password.length == 0 || errow.length == 0) {
            setErrow(true)
        } else {
            axios.post(`${baseUrl}api/agency/AgencyLogin`, {
                email: data.email,
                password: data.password
            }).then((res) => {
                window.localStorage.setItem("isLoggedIn", true);
                window.localStorage.setItem("userIn", "agency");
                navigate("/Dreabee/Dashboard");
                swal("You are successfully Signed Up", "Try Login", "success");
            }).catch((error) => {
                console.log("error", error);
                swal("Oops!", "Something went wrong! Try Again.", "error");
            })

        }
    }


    // +++++++++++++++++++++
    const [profVal, setProfVal] = useState("");
    // NORMAL-LOGIN 
    const InfluencerLogin = () => {
        let data = { email, password };
        axios.post(`${baseUrl}api/Influencer/influencerLogin`, {

            email: data.email,
            password: data.password

        }).then((res) => {
            // console.log("login response - ", res.data.Instagram_detail)
            console.log("login response - ", res.data.Instagram_detail[0].Instagram_username)
            window.localStorage.setItem("isLoggedIn", true);
            window.localStorage.setItem("userIn", "influencer");

            window.localStorage.setItem("Access-Id", res.data.Id)
            localStorage.setItem("UserName", res.data.Instagram_detail[0].Instagram_username);

            // swal("You are successfully Logged In", "👍", "success");
            // navigate(`/Dreabee/Profile/${res.data.Instagram_detail[0].Instagram_username}`, { state: { userIn: "influencer" } })


            navigate("/Dreabee/Profile")

        }).catch((error) => {
            console.log("error", error);
            swal("Oops!", "Maybe a wrong email or password! Try Again.", "error");
        })

    }


    // GRAPH-API-LOIGIN 
    const GraphApi1 = (tokhen) => {

        axios.get('https://graph.facebook.com/v14.0/me/accounts', {
            params: {
                fields: 'id,name,access_token',
                access_token: tokhen
            }
        }).then((response) => {
            console.log("GRAPH API 1 RESPONSE ::-->", response);
            console.log("THE RES_PON_SE :", response.data.data[1].access_token);
            setTimeout(() => {
                // console.log("fine")
                GraphApi2(response.data.data[1].id, response.data.data[1].access_token)
            }, 1000);

        }).catch((error) => {
            console.log("the Access Token error - ", error)
        });

    }
    const [tokkhen, setTokkhen] = useState("EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC");

    const GraphApi2 = (id) => {
        console.log("ID HERE :", id)
        // console.log("ID HERE :",id)
        axios.get(`https://graph.facebook.com/v18.0/${id}?fields=instagram_business_account&access_token=${tokkhen}`)
            .then((response) => {
                window.localStorage.setItem("userIn", "influencer");

                console.log("GRAPH API 2 RESPONSE :: --> ", response.data.instagram_business_account.id);

                window.localStorage.setItem("graphApi", true);
                window.localStorage.setItem("isLoggedIn", true);
                window.localStorage.setItem("AccessTokken", tokkhen);
                window.localStorage.setItem("TheGraphId", response.data.instagram_business_account.id);
                localStorage.setItem("UserName", "billy");
                // window.localStorage.setItem("Access-Id", res.data.Id)

                setTimeout(() => {
                    if (response.data.instagram_business_account.id) {
                        navigate(`/Dreabee/Profile/`)
                    } else {
                        swal("Oops!", "Something went wrong.Try again", "info");

                    }
                }, 1000);
            })
            .catch((error) => {
                console.log("Graph Api - 2 - error - ", error)
                swal("Oops!", "Something went wrong.", "error");
            });
    }


    // +++++++++++++++++++++
    const signUps = () => {
        navigate("/signUp")
    }

    // -------------------



    const [logAs, setLogAs] = useState("Brands")
    const [logShowAs, setLogShowAs] = useState(false)
    const finalApiSort = () => {
        if (logAs === "Brands") {
            // alert("Brands Api Hit")
            BrandsLogin();

        } else if (logAs === "Agencies") {
            // alert("Agencies Api Hit")
            AgencyLogin();

        } else {
            // alert("Influencers Api Hit")
            InfluencerLogin();
            // setProfVal(("@miridawn").split("@")[1]);
        }
    }

    return (
        <>
            <div className='conatiner-fluid'>
                {
                    loading ? (
                        <section className='' style={{ "height": "100vh", "width": "100vw", "background": "black" }} >
                            <div className=' d-flex justify-content-center align-items-center  h-100'>

                                <FadeLoader
                                    color={"aqua"}
                                    loading={loading}
                                    // cssOverride={override}
                                    size={50}
                                // aria-label="Loading Spinner"
                                // data-testid="loader"
                                />

                            </div>
                        </section>
                    ) : (
                        <div className='bgLogIn' style={{ "backgroundColor": "" }}>
                            <div className='row '>
                                <div className='col-lg-12 text-center mt-' style={{ "marginTop": "" }}>
                                    <section className='row  d-flex justify-content-center'>


                                        <section className='col-lg-6 ps-5'>
                                            <div className='ps-5 '>

                                                {/* LOGO-SECTION */}
                                                <div className='col-lg-12 mb-2 pt-2 bg-'>
                                                    <img src={logo} alt='hehe' style={{ "width": "120px", }} />
                                                </div>
                                                {/* HEADEING-SECTION  */}
                                                <div>
                                                    <h4 style={{ "fontSize": "42px", "fontWeight": "700", "color": "#FFFFFF", "lineHeight": "60px" }}>Welcome Back , Lets get in ..</h4>
                                                </div>


                                                {/* FORM-SECTION */}
                                                <section className='row d-flex justify-content-center'>
                                                    <section className='col-lg-9 '>
                                                        <div className='py-0'>
                                                            <section >
                                                                <section className='row bg-ifo'>

                                                                    {/* LOG-IN-AS-BUTTONS */}
                                                                    {/* <section className='text-start bg-info'>
                                                                        <h5 className='sub'>Login As</h5>
                                                                        <div className='row'>

                                                                            <div className='col mt-1 logInWithBrands'>
                                                                                <button className='activeButton col-md p-2' style={{ "fontSize": "" }}>Brands</button>
                                                                            </div>

                                                                            <div className='col mt-1 logInWithAgency'>
                                                                                <button
                                                                                    //  onClick={go2}
                                                                                    className='batton2 col-md '>Agency</button>
                                                                            </div>

                                                                            <div className='col mt-1 logInWithInfluencers'>
                                                                                <button
                                                                                    // onClick={go3} 
                                                                                    className='batton2 col-md '>Influencers</button>
                                                                            </div>

                                                                            <div className='col-6 logInWithGoogle'>
                                                                                <button id='google' onClick={() => { glogin() }} className='batton2 col-md mt-3 w-100' style={{ maxWidth: "29em" }} >   <img src={require('../../Imagez/Common/Gologo.png')} width={30} /></button>
                                                                            </div>



                                                                        </div>
                                                                    </section> */}



                                                                    {/* THE-FORM-INPUT-FIELDS */}
                                                                    <section className='text-start'>


                                                                        <span className='LoginAsLoginAs'>
                                                                            <h5 className='sub mt-3'>Login As</h5>
                                                                            <button value={password} name='logShowAs'
                                                                                onClick={() => { setLogShowAs(!logShowAs) }}
                                                                                // onChange={(e) => { setPassword(e.target.value) }} 
                                                                                className='fifi d-flex justify-content-between align-items-center'
                                                                                style={{ position: "relative", borderBottom: "1px solid black", "paddingLeft": "12px", "color": "white", zIndex: "4" }} >

                                                                                <span> {logAs}  </span>
                                                                                <span className='pe-4'>
                                                                                    {
                                                                                        logShowAs
                                                                                            ?
                                                                                            <i class='bx bx-chevron-up'></i>
                                                                                            :
                                                                                            <i class='bx bx-chevron-down'></i>
                                                                                    }

                                                                                </span>

                                                                            </button>

                                                                            {
                                                                                logShowAs ?
                                                                                    <div style={{ height: "0px", width: "0px", position: "relative", top: "0rem", zIndex: "3" }}>
                                                                                        <section className='kiwikiwi px-0' >

                                                                                            <h6 className='subAs  py-2 PX-4' onClick={() => { setLogAs("Brands"); setLogShowAs(false) }} >Brands</h6>

                                                                                            <h6 className='subAs  py-2  PX-4' onClick={() => { setLogAs("Agencies"); setLogShowAs(false) }} >Agencies</h6>

                                                                                            {/* <h6 className='subAs  py-2  PX-4' onClick={() => { setLogAs("Influencers"); setLogShowAs(false) }} >Influencers</h6> */}
                                                                                        </section>
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                                            }

                                                                            {/* {errow && password.length <= 0 ?
                                                                                <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px", "marginTop": "2px" }}>Password cannot be empty. &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                 ""
                                                                            } */}


                                                                        </span>

                                                                        <span className='BusinessEmailBusinessEmail'>
                                                                            <h5 className='sub mt-4'>Business Email</h5>
                                                                            <input type='email' value={email} name='Email' onChange={(e) => { setEmail(e.target.value) }} placeholder='example@examplemail.com' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                                                                            {errow && email.length <= 0 ?
                                                                                <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px", "marginTop": "2px" }}>Email can not be empty. &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                : ""
                                                                            }
                                                                        </span>

                                                                        <span className='PasswordPassword'>
                                                                            <h5 className='sub mt-3'>Password</h5>
                                                                            <input type='email' value={password} name='password' onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                                                                            {errow && password.length <= 0 ?
                                                                                <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px", "marginTop": "2px" }}>Password cannot be empty. &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                : ""
                                                                            }

                                                                            <br />
                                                                            <input className="ms-1 " type="checkbox" checked id="" /><label className='rme ps-2'>Remember Me</label>
                                                                        </span>



                                                                        {/* <br /> */}
                                                                        {/* <label className='rme ' style={{"marginLeft":"4.5rem"}}>Didn't recieved OTP , <span style={{"color":"aqua","fontWeight":"600"}}>Send Again .</span></label> */}



                                                                        {/* LOGO-LOGINS-SOCIAL-ICONS  */}
                                                                        <span className='LogInBtn'>
                                                                            <button className='bigB col-md mt-3' onClick={() => { finalApiSort(); }}>
                                                                                <span className='bigBt'>LOG IN</span>
                                                                            </button>

                                                                            <label className='rme ps-2 py-2'>
                                                                                Didn't Registered yet ?
                                                                                <span onClick={signUps}
                                                                                    style={{ "color": "aqua", "cursor": "pointer" }}>JOIN NOW
                                                                                </span>

                                                                            </label>


                                                                            {/* LOGO-LOGINS-SOCIAL-ICONS  */}
                                                                            <label className='rme ps-2 ms-2  ' style={{ borderLeft: "1px solid #ffffff52" }}>
                                                                                Login using : &nbsp;



                                                                                {/* LIKDIN  */}
                                                                                <span
                                                                                    className={logAs === "Influencers" ? "activeButtonz batton2 p-1" : "nonActiveButtonz batton2"}
                                                                                    style={{ "color": "aqua", "cursor": "pointer", position: "relative", top: "-1px" }}
                                                                                    id='google' onClick={() => { glogin() }} >
                                                                                    <img src={require('../../Imagez/Common/linkdin.png')} width={22} />
                                                                                </span>

                                                                                {/* FB  */}
                                                                                <span>
                                                                                    <FacebookLogin
                                                                                        appId="196789807939487"
                                                                                        onSuccess={(response) => {
                                                                                            // convertaccesstoken(response.accessToken)
                                                                                            console.log("The accesstoken I Got :", response.accessToken)
                                                                                            GraphApi1(response.accessToken)
                                                                                        }}
                                                                                        onFail={(error) => {
                                                                                            console.log('Login Failed!', error);
                                                                                        }}
                                                                                        onProfileSuccess={(response) => {
                                                                                            console.log('Get Profile Success!', response);

                                                                                        }}

                                                                                        render={({ onClick, logout }) => (
                                                                                            <>
                                                                                                <span
                                                                                                    className={logAs === "Influencers" ? "activeButtonz batton2 p-1" : "nonActiveButtonz batton2"}
                                                                                                    style={{ "color": "aqua", "cursor": "pointer", position: "relative", top: "-1px" }}
                                                                                                    id='google' onClick={onClick} onLogoutClick={logout} >
                                                                                                    <img src={require('../../Imagez/Common/fblogo.png')} width={22} />
                                                                                                </span>





                                                                                            </>



                                                                                        )}
                                                                                    />
                                                                                </span>

                                                                                {/* GOOGLER */}
                                                                                <span
                                                                                    className={logAs === "Influencers" ? "activeButtonz batton2 p-1" : "nonActiveButtonz batton2"}
                                                                                    style={{ "color": "aqua", "cursor": "pointer", position: "relative", top: "-1px" }}
                                                                                    id='google' onClick={() => { glogin() }} >
                                                                                    <img src={require('../../Imagez/Common/Gologo.png')} width={22} />
                                                                                </span>

                                                                            </label>

                                                                        </span>


                                                                    </section>
                                                                </section>
                                                            </section>
                                                        </div>
                                                    </section>
                                                </section>
                                            </div>
                                        </section>
 
                                        {/* SIGN-UP-HEADER--&--TRANSPARENT-CARD  */}
                                        <section className='col-lg-6 bg-'>
                                            <div>

                                                {/* SIGN-UP-HEADER  */}
                                                <section className='row bg-'>
                                                    <div className='col-lg-12 mb bg-' >
                                                        <SignUpHead />
                                                    </div>
                                                </section>


                                                {/* TRANSPARENT-CARD */}
                                                <section className='row d-flex justify-content-start'>
                                                    <section className='col-lg-12 text-center bg-inf'>
                                                        <div className='py-3' style={{ border: "" }}>
                                                            <section className='d-flex justify-content-center'>
                                                                <div className=' boxxy Kardz'>

                                                                    <section className='row text-start' >
                                                                        <div className={`fade-in-text ${inView3 ? 'fade-in col-md-6 d-flex flex-column  ' : 'col-md-6 d-flex flex-column '}`} ref={ref3} style={{ "height": "580px" }}>
                                                                            <h4 className='' style={{ "marginTop": "5rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "24px", "lineHeight": "31px", "color": "#FFFFFF" }}>Experience a whole new REALM OF INFLUENCER</h4>
                                                                            <p className='' style={{ "marginTop": "1rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "18px", "lineHeight": "20px", "color": "#FFFFFF" }}>Become a DREABEE Member & Get</p>
                                                                            <ul className='ulli' style={{ "marginTop": "0rem", "marginLeft": "1rem", "fontWeight": "400", "fontSize": "15px", "lineHeight": "20px", "color": "#FFFFFF" }}>
                                                                                <li className=''>300000+ Unique Influencers across Platforms</li>
                                                                                <li className='mt-2'>Super Flexible Search like #tags, bio, profession and more </li>
                                                                                <li className='mt-2'>Robust Scoring system for every Influencer & every Profile</li>
                                                                                <li className='mt-2'>Advanced Search filters with extensive filters</li>
                                                                            </ul>
                                                                            <p id='tangs' style={{ "marginLeft": "3rem", "marginTop": "0.5rem", "fontStyle": "italic", "fontSize": "18px", "fontWeight": "600", "lineHeight": "24px", "color": "#FFFFFF" }}>And best of all ,<br />FREE FOREVER</p>
                                                                        </div>


                                                                        <div className=' col-md-6 d-flex justify-content-center align-items-center ' style={{ "height": "580px" }}>  <img src={phone} className={`fade-in-text ${inView2 ? 'fade-in phone' : 'phone'}`} ref={ref2} style={{ "width": "280px", "zIndex": "" }} alt='logo' /> </div>
                                                                    </section>

                                                                </div>
                                                            </section>
                                                        </div>
                                                    </section>
                                                </section>



                                            </div>
                                        </section>


                                    </section>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default AllInOneLogin




