import React from 'react'
import { useInView } from 'react-intersection-observer';
import img from '../../Imagez/CreatorsLandingPgImg/Why Dreabee Section1.png'
import img2 from '../../Imagez/CreatorsLandingPgImg/Why Dreabee Section2.png'
const Cards3Boxes = () => {


    // REFFF 2------WHYY DReabee
    const [ref2b, inView2b] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 200

    });


    return (
        <>
            <section className='b'>

                <section className='' style={{ "position": "relative", "left": "5rem", top: "" }}>

                    <section>
                        <span style={{ "position": "relative", "left": "", "top": "10rem", "zIndex": "3" }}> <img src={img} className='Kardz' alt='img1' style={{ "width": "16rem", borderRadius: "10px" }} /></span>
                        <span style={{ "position": "relative", "left": "2.5rem", "top": "", "zIndex": "3" }}> <img src={img} className='Kardz' alt='img1' style={{ "width": "16rem", borderRadius: "10px" }} /></span>
                        <span style={{ "position": "relative", "left": "18.5rem", "top": "", "zIndex": "3" }}> <img src={img} className='Kardz' alt='img1' style={{ "width": "16rem", borderRadius: "10px" }} /></span>
                    </section>


                    <section>
                        <span style={{ "position": "relative", "bottom": "13rem", "left": "3rem", "zIndex": "4" }}>   <img className={`pop-in ${inView2b ? 'pop  ' : ' '}`} ref={ref2b} src={img2} alt='img2' style={{ "width": "10rem" }} /></span>
                        <span style={{ "position": "relative", "bottom": "38rem", "left": "2rem", "zIndex": "4" }}>   <img className={`pop-in ${inView2b ? 'pop  ' : ' '}`} ref={ref2b} src={img2} alt='img2' style={{ "width": "10rem" }} /></span>
                        <span style={{ "position": "relative", "bottom": "22rem", "left": "8rem", "zIndex": "4" }}>   <img className={`pop-in ${inView2b ? 'pop  ' : ' '}`} ref={ref2b} src={img2} alt='img2' style={{ "width": "10rem" }} /></span>
                    </section>
                </section>

            </section>
        </>
    )
}

export default Cards3Boxes