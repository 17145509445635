import React from 'react'
import pho1 from '../../../Imagez/LandinPgImg/girl1.png'
import pho2 from '../../../Imagez/LandinPgImg/Shirsha.png'
import { useInView } from 'react-intersection-observer';
import { useRef } from 'react';
const FlowerPhoto2 = () => {
    const textRef = useRef(null);
    // REFFF 1
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view

    });
    return (
        <>
            <span className={`fade-in-text ${inView ? 'fade-in  ' : ''}`} ref={ref} >

                <section>
                    <section className='row '>
                        {/* 1  */}
                        <section className='col-6 '>
                            <span className=' d-flex justify-content-end'
                                // N 
                                style={{ "position": "relative", "top": "2rem", "left": "4rem", "zIndex": "2" }}>
                                <img src={pho2} alt='' className='Kardz' style={{ "border": "2px solid blue", "height": "", "width": "200px", "borderRadius": "35px" }} />

                                <span className='text-light' style={{ "position": "relative", "bottom": "-200px", left: "-180px", height: "0px", width: "0px" }}>
                                    <section style={{ fontWeight: 800, "fontSize": "36px", "lineHeight": "30px" }}>Shirisha</section>
                                    <section style={{ fontWeight: 700, "fontSize": "20px" }}>Fashion</section>
                                </span>
                            </span>
                        </section>

                        {/* 2  */}
                        <section className='col-6 '>
                            <span
                                // E 
                                style={{ "position": "relative", "top": "10rem", "left": "0rem", "zIndex": "3" }}>
                                <img src={pho2} alt='' className='Kardz ' style={{ "border": "2px solid blue", "height": "", "width": "200px", "borderRadius": "35px" }} />

                                <span className='text-light' style={{ "position": "relative", "bottom": "75px", left: "20px", height: "0px", width: "0px" }}>
                                    <section style={{ fontWeight: 800, "fontSize": "36px", "lineHeight": "30px" }}>Shirisha</section>
                                    <section style={{ fontWeight: 700, "fontSize": "20px" }}>Fashion</section>
                                </span>
                            </span>

                        </section>
                    </section>

                    <section className='row'>
                        {/* 3  */}
                        <section className='col-6  '>
                            <span className=' d-flex justify-content-end'
                                // W 
                                style={{ "position": "relative", "top": "-10rem", "left": "-4rem", "zIndex": "1" }}>
                                <img src={pho2} alt='' className='Kardz ' style={{ "border": "2px solid blue", "height": "", "width": "200px", "borderRadius": "35px" }} />

                                <span className='text-light' style={{ "position": "relative", "bottom": "-200px", left: "-180px", height: "0px", width: "0px" }}>
                                    <section style={{ fontWeight: 800, "fontSize": "36px", "lineHeight": "30px" }}>Shirisha</section>
                                    <section style={{ fontWeight: 700, "fontSize": "20px" }}>Fashion</section>
                                </span>
                            </span>

                        </section>
                        {/* 4  */}
                        <section className='col-6 '>
                            <span className='Kardz'
                                // S 
                                style={{ "position": "relative", "top": "-2rem", "left": "-8rem", "zIndex": "4" }}>
                                <img src={pho2} alt='' className='Kardz ' style={{ "border": "2px solid blue", "height": "", "width": "200px", "borderRadius": "35px" }} />
                                <span className='text-light' style={{ "position": "relative", "bottom": "80px", left: "20px", height: "0px", width: "0px" }}>
                                    <section style={{ fontWeight: 800, "fontSize": "36px", "lineHeight": "30px" }}>Shirisha</section>
                                    <section style={{ fontWeight: 700, "fontSize": "20px" }}>Fashion</section>
                                </span>
                            </span>

                        </section>
                    </section>
                </section>





            </span>
        </>
    )
}

export default FlowerPhoto2