import React, { useEffect, useRef, useState } from 'react'
import logo from '../../Imagez/Common/logo.png'

import trapBox from '../../Imagez/signIn_LogIn/Login_pattern2.png'
import FadeLoader from "react-spinners/FadeLoader";
import SignUpHead from '../Brands_Login_SignUp/SignUp/SignUpHead';
import { baseUrl } from '../../Routez/Routey';
import phone from '../../Imagez/LandinPgImg/phone.png'
import { useInView } from 'react-intersection-observer';
import AgencySignUpForm from '../Agency_Login_SignUp/AgencySignUpForm';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';



const AllInOneSignUp = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])


    const textRef = useRef(null);
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view

    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view

    });
    const [ref3, inView3] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.8, // Trigger when at least 50% of the element is in view
        delay: 100
    });


    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [repass, setRePass] = useState("");
    const [errow, setErrow] = useState(false);
    const [errow2, setErrow2] = useState(false);



    // BRANDS
    const [isValid, setIsValid] = useState(false);
    const validateEmail = (e) => {
        // const regex =  /\b\w+@\w+\.\w{2,4}\b/;
        const regex = /\b\w+@\w+\.com\b/;
        const isMatch = regex.test(email);
        setIsValid(isMatch);
    };
    const BrandRegister = () => {
        validateEmail();

        let data = { name, email, password, repass, mobile };
        // console.log("The form data", data)
        if (name.length == 0 || email.length == 0 || password.length == 0 || repass.length == 0 || errow.length == 0 || mobile.length != 10 || mobile.length == 0) {
            setErrow(true)
        } else {

            if (isValid) {
                axios.post(`${baseUrl}api/Brands/BrandRegister`, {
                    admin_name: data.name,
                    email: data.email,
                    password: data.password,
                    repassword: data.repass,
                    phone: data.mobile
                }).then((res) => {
                    navigate("/bfire");
                    swal("You details have been collected ,", "Verify your Phone number !", "success");
                }).catch((error) => {
                    console.log("error", error);
                    swal("Oops!", "Something went wrong! Try Again.", "error");
                })
            }
            else {
                setErrow2(true)
            }
        }
    }
    // BRANDS


    const jump = () => {
        console.log("length - ", name.length);
        navigate("/logIn");
    }

    const GoName = (event) => {
        const value = event.target.value;
        const Name1 = value.replace(/[^A-Z a-z]/g, '');
        setName(Name1);
    };


    // AGENCY 
    const AgencyRegister = () => {
        console.log({ name, email, password, repass, mobile });
        let data = { name, email, password, repass, mobile };

        if (name.length == 0 || email.length == 0 || password.length == 0 || repass.length == 0 || errow.length == 0 || mobile.length != 10 || mobile.length == 0) {
            setErrow(true)
        } else {
            axios.post(`${baseUrl}api/agency/AgencyRegister`, {
                admin_name: data.name,
                email: data.email,
                password: data.password,
                repassword: data.repass,
                phone: data.mobile
            }).then((res) => {
                navigate("/LogIn");
                swal("You are successfully Signed Up", "Try Login", "success");
            }).catch((error) => {
                console.log("error", error);
                swal("Oops!", "Something went wrong! Try Again.", "error");
            })
        }
    }


    // AGENCY 



    const [signUpAs, setSignUpAs] = useState("Brands")
    const [logShowAs, setLogShowAs] = useState(false)



    const finalApiSort = () => {
        if (signUpAs === "Brands") {
            console.log("Brands Api Hit")
            BrandRegister();

        } else {
            console.log("Agencies Api Hit")
            AgencyRegister();


        }
    }
    return (
        <>
            <div className='conatiner-fluid'>
                {
                    loading ? (
                        <section className='' style={{ "height": "100vh", "width": "100vw", "background": "black" }} >
                            <div className=' d-flex justify-content-center align-items-center  h-100'>

                                <FadeLoader
                                    color={"aqua"}
                                    loading={loading}
                                    // cssOverride={override}
                                    size={50}
                                // aria-label="Loading Spinner"
                                // data-testid="loader"
                                />

                            </div>
                        </section>
                    ) : (

                        <>
                            <div className='bgLogIn' style={{ "height": "", "backgroundColor": "", "zIndex": "-5" }}>
                                <div className='row '>
                                    <div className='col-lg-12 text-center mt-' style={{ "marginTop": "" }}>


                                        <section className='row  d-flex justify-content-center'>
                                            {/* lEFT  */}
                                            <section className='col-lg-6 ps-5'>
                                                <div className='ps-5 '>

                                                    {/* LOGO-SECTION */}
                                                    <div className='col-lg-12 mb-2 pt-2 bg-'>
                                                        <img src={logo} alt='hehe' style={{ "width": "120px", }} />
                                                    </div>
                                                    {/* HEADEING-SECTION  */}
                                                    <div>
                                                        <h4 style={{ "fontSize": "42px", "fontWeight": "700", "color": "#FFFFFF", "lineHeight": "60px" }}>Welcome Back , Lets get in ..</h4>
                                                    </div>


                                                    {/* FORM-SECTION */}
                                                    <section className='row d-flex justify-content-center'>
                                                        <section className='col-lg-9 '>
                                                            <div className='py-0 border-light'>
                                                                <section >

                                                                    {/* <AgencySignUpForm /> */}


                                                                    <section className='row'>
                                                                        <section className=' text-start  mb-4'>
                                                                            <section>
                                                                                {/* Name  */}
                                                                                <section>
                                                                                    <h5 className='sub mt-2'>Name</h5>
                                                                                    <input type='text' value={name} name='name' required onChange={GoName} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />

                                                                                    {errow && name.length <= 0 ?
                                                                                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Name can not be empty.  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                        : ""
                                                                                    }
                                                                                </section>

                                                                                {/* BusineEmail  */}
                                                                                <section>
                                                                                    <h5 className='sub mt-4'>Business Email</h5>
                                                                                    <input type='email' value={email} name='email' required onChange={(e) => { setEmail(e.target.value) }} placeholder='example@examplemail.com' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                                                                                    {errow && email.length <= 0 ?
                                                                                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Email cannot be empty  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                        : ""
                                                                                    }

                                                                                </section>

                                                                                {/* Mobile  */}
                                                                                <section>
                                                                                    <h5 className='sub mt-4'>Mobile</h5>
                                                                                    <input type='number' value={mobile} name='mobile' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required onChange={(e) => { setMobile(e.target.value) }} maxLength={10} minLength={10} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                                                                                    {errow && mobile.length <= 0 ?
                                                                                        <> <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Phone number cannot be empty  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label> <br /></>
                                                                                        : ""
                                                                                    }
                                                                                    {errow && mobile.length != 10 ?
                                                                                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Phone number have to be 10 digits  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                        : ""
                                                                                    }


                                                                                </section>
                                                                                {/* LoginAsLoginAs */}
                                                                                <section className='LoginAsLoginAs'>
                                                                                    <h5 className='sub mt-3'>Signup As</h5>
                                                                                    <button value={password} name='logShowAs'
                                                                                        onClick={() => { setLogShowAs(!logShowAs) }}
                                                                                        // onChange={(e) => { setPassword(e.target.value) }} 
                                                                                        className='fifi d-flex justify-content-between align-items-center'
                                                                                        style={{ position: "relative", borderBottom: "1px solid black", "paddingLeft": "12px", "color": "white", zIndex: "4" }} >

                                                                                        <span> {signUpAs}  </span>
                                                                                        <span className='pe-4'>
                                                                                            {
                                                                                                logShowAs
                                                                                                    ?
                                                                                                    <i class='bx bx-chevron-up'></i>
                                                                                                    :
                                                                                                    <i class='bx bx-chevron-down'></i>
                                                                                            }

                                                                                        </span>

                                                                                    </button>

                                                                                    {
                                                                                        logShowAs ?
                                                                                            <div style={{ height: "0px", width: "0px", position: "relative", top: "0rem", zIndex: "3" }}>
                                                                                                <section className='kiwikiwi px-0' >

                                                                                                    <h6 className='subAs  py-2 PX-4' onClick={() => { setSignUpAs("Brands"); setLogShowAs(false) }} >Brands</h6>

                                                                                                    <h6 className='subAs  py-2  PX-4' onClick={() => { setSignUpAs("Agencies"); setLogShowAs(false) }} >Agencies</h6>

                                                                                                    {/* <h6 className='subAs  py-2  PX-4' onClick={() => { setSignUpAs("Influencers"); setLogShowAs(false) }} >Influencers</h6> */}
                                                                                                </section>
                                                                                            </div>
                                                                                            :
                                                                                            null

                                                                                    }




                                                                                </section>

                                                                                {/* Password  */}
                                                                                <section>
                                                                                    <h5 className='sub mt-4'>Password</h5>
                                                                                    <input type='password' value={password} name='password' required onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} />
                                                                                    {errow && password.length <= 0 ?
                                                                                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Password cannot be empty  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                        : ""
                                                                                    }

                                                                                </section>

                                                                                {/*confirm Password  */}
                                                                                <section>
                                                                                    <h5 className='sub mt-4'>Confirm Password</h5>
                                                                                    <input type='text' value={repass} name='repassword' required onChange={(e) => { setRePass(e.target.value) }} placeholder='Enter Here' className='fifi' style={{ "paddingLeft": "12px", "color": "white" }} /><span style={{ "position": "relative", "left": "-6%" }}> <i className="fa-solid fa-eye text-light"></i></span> <br />
                                                                                    <span className='d-flex align-items-center my-1 '>
                                                                                        <input className="ms-1 " type="checkbox" id="" style={{ "backgroundColor": "transparent", "color": "transparent" }} />
                                                                                        &nbsp;
                                                                                        <label className='rme'> Remember Me </label>
                                                                                    </span>
                                                                                    {errow && repass.length <= 0 ?
                                                                                        <label style={{ "color": "red", "fontSize": "16px", "fontWeight": "600", "marginLeft": "5px" }}>Password cannot be empty  &nbsp; <i className="fa-solid fa-circle-exclamation fa-shake" style={{ "color": "#ea0b0b" }} /></label>
                                                                                        : ""
                                                                                    }




                                                                                </section>

                                                                                {/* footer  */}
                                                                                <span>
                                                                                    <button onClick={finalApiSort} className='bigB col-md mb-1'> <span className='bigBt'>SIGN UP</span></button><br />
                                                                                    <label className='rme ps-2'>Already have an account ? <span onClick={() => { navigate("/logIn"); }} style={{ "color": "aqua", "fontWeight": "600", "cursor": 'pointer' }}>LOG IN NOW</span> </label>
                                                                                </span>

                                                                            </section>



                                                                        </section>

                                                                    </section>





                                                                </section>
                                                            </div>
                                                        </section>
                                                    </section>




                                                </div>
                                            </section>



                                            {/* RIGHT  */}

                                            <section className='col-lg-6 '>
                                                <div>

                                                    {/* SIGN-UP-HEADER  */}
                                                    <section className='row bg-'>
                                                        <div className='col-lg-12 mb bg-' >
                                                            <SignUpHead />
                                                        </div>
                                                    </section>


                                                    {/* TRANSPARENT-CARD */}
                                                    <section className='row d-flex justify-content-start'>
                                                        <section className='col-lg-12 text-center bg-inf'>
                                                            <div className='py-3' style={{ border: "" }}>
                                                                <section className='d-flex justify-content-center'>
                                                                    <div className=' boxxy Kardz'>

                                                                        <section className='row text-start' >
                                                                            <div className={`fade-in-text ${inView3 ? 'fade-in col-md-6 d-flex flex-column  ' : 'col-md-6 d-flex flex-column '}`} ref={ref3} style={{ "height": "580px" }}>
                                                                                <h4 className='' style={{ "marginTop": "5rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "24px", "lineHeight": "31px", "color": "#FFFFFF" }}>Experience a whole new REALM OF INFLUENCER</h4>
                                                                                <p className='' style={{ "marginTop": "1rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "18px", "lineHeight": "20px", "color": "#FFFFFF" }}>Become a DREABEE Member & Get</p>
                                                                                <ul className='ulli' style={{ "marginTop": "0rem", "marginLeft": "1rem", "fontWeight": "400", "fontSize": "15px", "lineHeight": "20px", "color": "#FFFFFF" }}>
                                                                                    <li className=''>300000+ Unique Influencers across Platforms</li>
                                                                                    <li className='mt-2'>Super Flexible Search like #tags, bio, profession and more </li>
                                                                                    <li className='mt-2'>Robust Scoring system for every Influencer & every Profile</li>
                                                                                    <li className='mt-2'>Advanced Search filters with extensive filters</li>
                                                                                </ul>
                                                                                <p id='tangs' style={{ "marginLeft": "3rem", "marginTop": "0.5rem", "fontStyle": "italic", "fontSize": "18px", "fontWeight": "600", "lineHeight": "24px", "color": "#FFFFFF" }}>And best of all ,<br />FREE FOREVER</p>
                                                                            </div>


                                                                            <div className=' col-md-6 d-flex justify-content-center align-items-center ' style={{ "height": "580px" }}>  <img src={phone} className={`fade-in-text ${inView2 ? 'fade-in phone' : 'phone'}`} ref={ref2} style={{ "width": "280px", "zIndex": "" }} alt='logo' /> </div>
                                                                        </section>

                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </section>
                                                    </section>



                                                </div>
                                            </section>



                                        </section>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </>
    )
}

export default AllInOneSignUp