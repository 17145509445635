import React from 'react'

const Tosty = () => {
    return (

        <>
            {/* Pugbg slider animation  */}
            {/* <section class="outter option1">
                    <section class="video-container">
                        <video src="https://fugamma.co/wp-content/uploads/2018/02/8863081.mp4" autoplay loop playsinline muted></video>
                        <div class="callout">
                            <h1>Hi, I'm Bosco</h1>
                            <div class="desc">They say every wrinkle is worth a thousand cuddles.  I just need a home and loving family to prove it.</div>
                            <div class="button"><div class="inner">Take Me Home!<div></div>
                            </div>
                            </div>
        
                        </div>
                    </section>
                </section> */}
            <h1>TOSTY</h1>







        </>

    )
}

export default Tosty
