import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommFoot from '../CommonComponents/CommFoot'

import img from '../../Imagez/CreatorsLandingPgImg/Why Dreabee Section1.png'
import img2 from '../../Imagez/CreatorsLandingPgImg/Why Dreabee Section2.png'
import card from '../../Imagez/CreatorsLandingPgImg/card.png'

import bar1 from '../../Imagez/CreatorsLandingPgImg/Bars1.png'
import bar2 from '../../Imagez/CreatorsLandingPgImg/Bars2.png'

import logo1 from '../../Imagez/CreatorsLandingPgImg/Icons/iconamoon_search-light.png'
import logo2 from '../../Imagez/CreatorsLandingPgImg/Icons/solar_user-outline.png'
import logo3 from '../../Imagez/CreatorsLandingPgImg/Icons/solar_eye-scan-linear.png'
import logo4 from '../../Imagez/CreatorsLandingPgImg/Icons/radix-icons_speaker-loud.png'

import img1 from '../../Imagez/CreatorsLandingPgImg/Amazon.png'
import img22 from '../../Imagez/CreatorsLandingPgImg/Starbucks.png'
import img3 from '../../Imagez/CreatorsLandingPgImg/Spectrum.png'
import img4 from '../../Imagez/CreatorsLandingPgImg/Tesla.png'
import img5 from '../../Imagez/CreatorsLandingPgImg/Youtubee.png'
import img6 from '../../Imagez/CreatorsLandingPgImg/Delotte.png'
import photo from '../../Imagez/CreatorsLandingPgImg/pink-line-section.png'
import { useInView } from 'react-intersection-observer'
import Cards3Boxes from './Cards3Boxes'





const CreatorsLandingSec2 = () => {

    const navigate = useNavigate();

    const signIn = () => {
        navigate("/SignUp")
    }
    const InflueReg = () => {
        navigate("/Phone_Verification_Influencers")
    }

    const [message, setMessage] = useState(false);
    const [message1, setMessage1] = useState(false);
    const [message2, setMessage2] = useState(false);
    const [message3, setMessage3] = useState(false);
    const [message4, setMessage4] = useState(false);
    const [message5, setMessage5] = useState(false);





    const textRef = useRef(null);
    // REFFF 1
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF 2------WHYY DReabee
    const [ref2a, inView2a] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 500

    });
    // REFFF 2------WHYY DReabee
    const [ref2b, inView2b] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 2000

    });
    // REFFF 3
    const [ref3, inView3] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.04, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    // REFFF 4
    const [ref4, inView4] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 500

    });
    const [ref4a, inView4a] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.9, // Trigger when at least 50% of the element is in view
        delay: 1000

    });
    const [ref4b, inView4b] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 1000

    });
    // REFFF 5
    const [ref5, inView5] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.05, // Trigger when at least 50% of the element is in view
        delay: 500

    });
    // REFFF 6 ----Up 6b
    const [ref6a, inView6a] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 2000

    });
    const [ref6b, inView6b] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 500

    });
    // REFFF 7 ----Up-side 7b
    const [ref7a, inView7a] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 2000

    });
    const [ref7b, inView7b] = useInView({
        triggerOnce: true, // Only trigger the anixmation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view
        delay: 1000

    });


    return (
        <>
            <section className='row ' style={{ "height": "" }}>
                {/* part 2 */}
                <section className='bgWhytS ' style={{ "height": "45rem", "backgroundColor": "", overflow: "hidden" }}>

                    <div className='row' style={{ "marginTop": "1rem" }}>
                        <div className='col-12 text-center'>
                            <h1 className={`fade-in-text-right  ${inView1 ? 'fade-in-right  ' : ' '}`} ref={ref1} style={{ "fontWeight": "700", "fontSize": "2.4rem", "color": "#C83B93" }}>Why DREABEE ?</h1>
                        </div>
                    </div>

                    <div className='row' style={{ "marginTop": "2rem" }}>


                        <div className='col-6 ' style={{ "zIndex": "4" }}>
                            <Cards3Boxes />
                            <section style={{ "position": "relative", "top": "-60rem" }}>
                                <span><img src={bar2} style={{ "width": "40rem", "position": "relative", "top": "4rem", "left": "-5rem", "zIndex": "1" }} alt='bar2' /></span>
                                <span><img src={bar1} style={{ "width": "40rem", "position": "relative", "top": "8rem", "left": "-12rem", "zIndex": "1", "marginTop": "6rem" }} alt='bar1' /></span>
                            </section>
                        </div>


                        <div className={`fade-in-text ${inView2a ? 'fade-in  col-6 bg-' : ' col-6'}`} ref={ref2a} style={{ "height": "100vh", "zIndex": "3" }}>

                            <section className='mt-5'>
                                <div className='row'>
                                    <div className='col-2 bg- d-flex align-items-center justify-content-center'>
                                        <img src={logo1} style={{ "width": "" }} alt='logo1' />
                                    </div>

                                    <div className='col-10  d-flex align-items-center justify-content-start'>
                                        <section style={{ "width": "34rem" }}>
                                            <h1 className='mb-1' style={{ "fontWeight": "700", "fontSize": "1.2rem", "color": "#C83B93" }}>
                                                Join the #1 destination where brands search Influencers for brand campaigns in India</h1>
                                            <h1 style={{ "fontWeight": "600", "fontSize": "1rem", "color": "#7E7E7E" }}>
                                                Be a part of the community where 6700+ brands search for Influencers every month for campaigns/events.
                                            </h1>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            {/* _______________link up_______________ */}
                            <section className='my-5'>
                                <div className='row'>
                                    <div className='col-2 bg- d-flex align-items-center justify-content-center'>
                                        <img src={logo2} style={{ "width": "" }} alt='logo2' />
                                    </div>

                                    <div className='col-10  d-flex align-items-center justify-content-start'>
                                        <section style={{ "width": "34rem" }}>
                                            <h1 className='mb-1' style={{ "fontWeight": "700", "fontSize": "1.2rem", "color": "#C83B93" }}>
                                                Create a free Profile in a hassle free manner</h1>
                                            <h1 style={{ "fontWeight": "600", "fontSize": "1rem", "color": "#7E7E7E" }}>
                                                By creating your profile on Qoruz the chances of being visible to brands is 3x compared to any other platforms.
                                            </h1>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            <section className='my-5'>
                                <div className='row'>
                                    <div className='col-2 bg- d-flex align-items-center justify-content-center'>
                                        <img src={logo3} style={{ "width": "" }} alt='logo3' />
                                    </div>

                                    <div className='col-10  d-flex align-items-center justify-content-start'>
                                        <section style={{ "width": "34rem" }}>
                                            <h1 className='mb-1' style={{ "fontWeight": "700", "fontSize": "1.2rem", "color": "#C83B93" }}>
                                                Be visible to6700+ Brands throughout the country</h1>
                                            <h1 style={{ "fontWeight": "600", "fontSize": "1rem", "color": "#7E7E7E" }}>
                                                Be on top of the brand searches by creating your profile on Qoruz and work on different brand collaborations in your niche.</h1>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            <section className='my-5'>
                                <div className='row'>
                                    <div className='col-2 bg- d-flex align-items-center justify-content-center'>
                                        <img src={logo4} style={{ "width": "" }} alt='logo4' />
                                    </div>

                                    <div className='col-10  d-flex align-items-center justify-content-start'>
                                        <section style={{ "width": "34rem" }}>
                                            <h1 className='mb-1' style={{ "fontWeight": "700", "fontSize": "1.2rem", "color": "#C83B93" }}>
                                                Get exclusive access to brand campaigns, events etc</h1>
                                            <h1 style={{ "fontWeight": "600", "fontSize": "1rem", "color": "#7E7E7E" }}>
                                                Not just paid collaborations, build strong relationships with the brands and get exclusive access to the brand events and much more. </h1>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            {/* _______________link up_______________ */}
                        </div>
                    </div>

                </section>

                {/* part 3 */}
                <section className='bgWhytS' style={{ "height": "45rem", "backgroundColor": "" }} id='Benifits'>
                    <div className='row' style={{ "marginTop": "2rem" }}>
                        <div className={`fade-in-text-right  ${inView3 ? 'fade-in-right  col-12 text-center' : 'col-12 text-center '}`} ref={ref3}>
                            <h1 className='mb-3' style={{ "fontWeight": "700", "fontSize": "2.4rem", "color": "#C83B93" }}>Get more than just Sponsored Partnerships.</h1>
                            <h1 className='mx-auto' style={{ "fontWeight": "700", "fontSize": "1.2rem", "color": "#7E7E7E", "width": "30rem" }}>Collaborate with more than 6700+ brands in the country, on different types of projects.</h1>
                        </div>
                    </div>


                    <div className='row mt-5'>
                        {/* cards1  */}
                        <div className='col-6'>
                            <section className={`fade-in-text-right  ${inView4b ? 'fade-in-right   ' : ' '}`} ref={ref4b} style={{ "marginLeft": "6.5rem", "marginTop": "" }}>
                                {/* card 1 */}
                                <section className='KardzLandz my-4 ' style={{ "height": "6rem", "width": "35rem", "borderRadius": "20px" }}>
                                    <div className='row d-flex justify-content-center align-items-center h-100'>
                                        <div className='col-2'>
                                            <text style={{ "position": "", "top": "-5rem", "left": "-4rem" }}>
                                                <i class='bx bx-money' style={{ fontSize: "3rem" }}></i>
                                            </text>
                                        </div>

                                        <div className='col-8 '>
                                            <h1 className='mb-1 ' style={{ "fontWeight": "700", "fontSize": "18px", "color": "" }}>
                                                Create a free Profile in a hassle free manner
                                            </h1>
                                            <h1 className='' style={{ "fontWeight": "500", "fontSize": "14px", "color": "" }}>
                                                By creating your profile on Qoruz the chances of being visible to brands is 3x  compared to any other platforms.
                                            </h1>

                                        </div>
                                    </div>
                                </section>


                                {/* card 2 */}
                                <section className='KardzLandz my-5' style={{ "height": "6rem", "width": "35rem", "borderRadius": "20px" }}>
                                    <div className='row d-flex justify-content-center align-items-center h-100'>
                                        <div className='col-2'>
                                            <text style={{ "position": "", "top": "-5rem", "left": "-4rem" }}>
                                                <i class='bx bx-music' style={{ fontSize: "3rem" }}></i>
                                            </text>
                                        </div>

                                        <div className='col-8 '>
                                            <h1 className='mb-1 ' style={{ "fontWeight": "700", "fontSize": "18px", "color": "" }}>
                                                Access to Exclusive events Music
                                            </h1>
                                            <h1 className='' style={{ "fontWeight": "500", "fontSize": "14px", "color": "" }}>
                                                Fest, Brand launches, Invite only meetups and more.
                                            </h1>

                                        </div>
                                    </div>
                                </section>


                                {/* card 3 */}
                                <section className='KardzLandz my-4' style={{ "height": "6rem", "width": "35rem", "borderRadius": "20px" }}>

                                    <div className='row d-flex justify-content-center align-items-center h-100'>
                                        <div className='col-2'>
                                            <text style={{ "position": "", "top": "-5rem", "left": "-4rem" }}>
                                                <i class='bx bx-gift' style={{ fontSize: "3rem" }}></i>
                                            </text>
                                        </div>

                                        <div className='col-8 '>
                                            <h1 className='mb-1 ' style={{ "fontWeight": "700", "fontSize": "18px" }}>
                                                Brand Rewards and Goodies
                                            </h1>
                                            <h1 className='' style={{ "fontWeight": "500", "fontSize": "14px" }}>
                                                Get to try products and experiences ahead of everybody else.
                                            </h1>

                                        </div>
                                    </div>

                                </section>
                            </section>
                        </div>


                        {/* card2  */}
                        <div className='col-6'>
                            <img src={card} alt='card' className={`fade-in-text-right  ${inView4b ? 'fade-in-right   ' : ' '}`} ref={ref4b}
                                style={{ "width": "40rem", "position": "relative", "left": "-6rem", "top": "-2rem", "zIndex": "2" }} />
                        </div>



                    </div>
                    {/* bar  */}
                    <section style={{ "position": "relative", "top": "-62rem" }}>
                        <span><img src={bar2} style={{ "width": "60rem", "position": "relative", "top": "40rem", "left": "70rem", "zIndex": "1 " }} alt='bar2' /></span>

                    </section>
                </section>

                {/* part 4  logos main section*/}
                <section className='bgWhytS' style={{ "height": "35rem" }}>
                    <section className=' h-100 d-flex align-items-center w-100 '>

                        <section className='row w-100'>
                            <section className={`fade-in-text-left  ${inView5 ? 'fade-in-left  col-6 d-flex justify-content-center align-items-center p-2 ' : ' col-6'}`} ref={ref5} style={{ "paddingTop": "0rem", "paddingLeft": "", "width": "", "zIndex": "2" }}>

                                <section className=' ms-4 w-75 px-4'>


                                    <h1 style={{ "position": "", "top": "", "fontSize": "2.1rem", "fontWeight": "700", "color": "#C83B93" }}>Over 6700 Brands are
                                        searching for influencers</h1>
                                    <p style={{ "color": "#7E7E7E", "fontSize": "1.4rem", "fontWeight": "600", "marginTop": "2rem", "marginBottom": "2rem" }}>Top brands from all over the globe are searching for influencers like you on Dreebee.</p>


                                    <button className='btnz' onClick={signIn} style={{ "position": "relative", "left": "0rem", "width": "316px", "borderRadius": "500px", "height": "64px", "backgroundColor": "#C83B93", "color": "#FFFFFF", "fontSize": "24px", "fontWeight": "700", "border": "none", "boxShadow": "#7E7E7E 0px 0px 10px 2px " }}>Create my Profile &nbsp; <i style={{ "color": "#FFFFFF" }} className="fa-solid fa-arrow-trend-up"></i>
                                    </button>
                                </section>


                            </section>
                            <section className={`fade-in-text-left  ${inView5 ? 'fade-in-left  col-6 d-flex justify-content-center' : ' col-6'}`} ref={ref5} style={{ "marginLeft": "-2rem", "marginTop": "0rem", "zIndex": "2" }}>
                                <section>
                                    <div className='row'>
                                        <div className='col-4 p-2'><img src={img1} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img22} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img3} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 p-2'><img src={img6} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img3} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img5} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 p-2'><img src={img4} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img6} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img1} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4 p-2'><img src={img6} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img3} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                        <div className='col-4 p-2'><img src={img22} className='Kardz' alt='img1' style={{ "borderRadius": "10px", "width": "12rem" }} /></div>
                                    </div>
                                </section>

                            </section>
                        </section>

                        {/* back glow  */}
                        {/* <section style={{ "position": "relative", "top": "" }}>
                            <span style={{ "width": "0rem", "position": "relative", "top": "-8rem", "left": "-30rem", "zIndex": "1" }}> <section className='Ellipse4' style={{ "width": "10rem", "height": "10rem", "zIndex": "1" }} />   </span> 
                            <span style={{ "width": "0rem", "position": "relative", "top": "-30rem", "left": "40rem", "zIndex": "1" }}> <section className='Ellipse4' style={{ "width": "10rem", "height": "8rem", "zIndex": "1" }} />   </span> 
                            <span style={{ "width": "20rem", "position": "relative", "top": "-50rem", "left": "10rem", "zIndex": "1" }}> <section className='Ellipse3' style={{ "width": "15rem", "height": "12rem", "zIndex": "1" }} />  </span>    
                            <span style={{ "width": "20rem", "position": "relative", "top": "-40rem", "left": "50rem", "zIndex": "1" }}> <section className='Ellipse3' style={{ "width": "20rem", "height": "20rem", "zIndex": "1" }} />  </span>    
                        </section> */}

                    </section>
                </section>

                {/* part 6 campaign */}
                <section className='bgWhytS' style={{ "paddingTop": "4rem", "paddingBottom": "1rem", "backgroundColor": "", "height": "38rem" }}>
                    <section className='row' style={{ "zIndex": "4" }} >
                        <section className='col-12 ' style={{ "zIndex": "3" }}>
                            <h1 className={`fade-in-text-left  ${inView6a ? 'fade-in-left   text-center' : 'text-center '}`} ref={ref6a} style={{ "fontSize": "2.4rem", "fontWeight": "700", "color": "#C83B93" }}>Some of the brand<br />
                                campaigns you just missed</h1>
                        </section>

                        <section className='col-12  mt-5' style={{ "zIndex": "5" }} >

                            <section className='row justify-content-evenly' style={{ "zIndex": "4" }} >
                                {/* card1 */}
                                <section className={`fade-in-text-up   ${inView6b ? 'fade-in-up    col-3' : ' col-3'}`} ref={ref6b} style={{ "zIndex": "5" }} >
                                    <section className='Kardz d-flex align-items-center ' style={{ "height": "20rem", "width": "20rem", "borderRadius": "10px", "background": "#FFF", "boxShadow": "0px 10px 30px 0px", "zIndex": "4" }}>

                                        <div className=' w-100 mb-4'>
                                            <h5 className='text-center' style={{ "paddingTop": "", "zIndex": "3", fontSize: "16px" }}>Barter Campaign</h5>
                                            <h4 className='text-center mt-2' style={{ "fontWeight": "700", "color": "#7E7E7E", }}>#grandlingeriefestival</h4>
                                            <h4 className='text-center mt-2' style={{ "fontSize": "16px" }}>Style & Fashion | Lifestyle</h4>
                                        </div>

                                    </section>
                                    {/* base line  */}
                                    <section style={{ "height": "10vh", "position": "relative", "top": "-4.5rem", "width": "20rem", "background": "#FFE7E7", "borderRadius": "0 0 10px 10px" }}>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingLeft": "1rem", "paddingTop": "10px" }}>Platform</p>
                                            </div>

                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingTop": "10px", "paddingLeft": "1rem" }}>Cash Reward</p>
                                                <span style={{ "fontSize": "1.2rem", "fontWeight": "500", "position": "relative", "top": "-1rem", "paddingLeft": "1rem" }}>$3000</span><span style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "position": "relative", "top": "-1rem", "paddingLeft": "10px" }}>Onwards</span>
                                            </div>
                                        </div>
                                    </section>
                                </section>

                                {/* card2  */}
                                <section className={`fade-in-text-up   ${inView6b ? 'fade-in-up    col-3' : ' col-3'}`} ref={ref6b} style={{ "zIndex": "5" }} >
                                    <section className='Kardz d-flex align-items-center ' style={{ "height": "20rem", "width": "20rem", "borderRadius": "10px", "background": "#FFFF", "boxShadow": "0px 10px 30px 0px", "zIndex": "4" }}>

                                        <div className='w-100 mb-4'>
                                            <h5 className='text-center' style={{ "paddingTop": "", "zIndex": "3", fontSize: "16px" }}>Barter Campaign</h5>
                                            <h4 className='text-center mt-2' style={{ "fontWeight": "700", "color": "#7E7E7E", }}>#BingoMadholi</h4>
                                            <h4 className='text-center mt-2' style={{ "fontSize": "16px" }}>Food | Lifestyle</h4>
                                        </div>

                                    </section>
                                    {/* base line  */}
                                    <section style={{ "height": "10vh", "position": "relative", "top": "-4.5rem", "width": "20rem", "background": "#FFE7E7", "borderRadius": "0 0 10px 10px" }}>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingLeft": "1rem", "paddingTop": "10px" }}>Platform</p>
                                            </div>

                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingTop": "10px", "paddingLeft": "1rem" }}>Cash Reward</p>
                                                <span style={{ "fontSize": "1.2rem", "fontWeight": "500", "position": "relative", "top": "-1rem", "paddingLeft": "1rem" }}>$3000</span><span style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "position": "relative", "top": "-1rem", "paddingLeft": "10px" }}>Onwards</span>
                                            </div>
                                        </div>
                                    </section>
                                </section>


                                {/* card3  */}
                                <section className={`fade-in-text-up   ${inView6b ? 'fade-in-up    col-3' : ' col-3'}`} ref={ref6b} style={{ "zIndex": "5" }} >
                                    <section className='Kardz d-flex align-items-center ' style={{ "height": "20rem", "width": "20rem", "borderRadius": "10px", "background": "#FFFF", "boxShadow": "0px 10px 30px 0px", "zIndex": "4" }}>

                                        <div className=' w-100 mb-4'>
                                            <h5 className='text-center' style={{ "paddingTop": "", "zIndex": "3", fontSize: "16px" }}>Barter Campaign</h5>
                                            <h4 className='text-center mt-2' style={{ "fontWeight": "700", "color": "#7E7E7E", }}>#BingoMadholi</h4>
                                            <h4 className='text-center mt-2' style={{ "fontSize": "16px" }}>Food</h4>
                                        </div>

                                    </section>
                                    {/* base line  */}
                                    <section style={{ "height": "10vh", "position": "relative", "top": "-4.5rem", "width": "20rem", "background": "#FFE7E7", "borderRadius": "0 0 10px 10px" }}>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingLeft": "1rem", "paddingTop": "10px" }}>Platform</p>
                                            </div>

                                            <div className='col-6'>
                                                <p style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "paddingTop": "10px", "paddingLeft": "1rem" }}>Cash Reward</p>
                                                <span style={{ "fontSize": "1.2rem", "fontWeight": "500", "position": "relative", "top": "-1rem", "paddingLeft": "1rem" }}>$3000</span><span style={{ "fontSize": "0.8rem", "fontWeight": "700", "color": "#7E7E7E", "position": "relative", "top": "-1rem", "paddingLeft": "10px" }}>Onwards</span>
                                            </div>
                                        </div>
                                    </section>
                                </section>

                            </section>


                            <section style={{ "position": "relative", "top": "-40rem", height: "0px", width: "0px" }}>
                                <span><img src={bar2} style={{ "width": "60rem", "position": "absolute", "top": "", "left": "-50rem", "zIndex": "2 " }} alt='bar2' /></span>
                                <span><img src={bar1} style={{ "width": "60rem", "position": "absolute", "top": "-", "left": "1200px", "zIndex": "1", "marginTop": "0rem" }} alt='bar1' /></span>
                            </section>
                        </section>

                    </section>
                    {/* bars  */}

                </section>

                {/* part 7 pink bg line  */}
                <section style={{ "height": "26rem", "backgroundColor": "#C83B93", "paddingTop": "2rem", "paddingBottom": "rem" }}>

                    <div className='row d-flex align-items-center justify-content-center'>
                        {/* text sec  */}
                        <div className={`fade-in-text-left  ${inView7a ? 'fade-in-left   d-flex align-items-center justify-content-center  col-6' : 'col-6 '}`} ref={ref7a} style={{ "paddingLeft": "6rem" }}>
                            <span>
                                <h1 className=' mt-5 pt-5' style={{ "fontSize": "3rem", "fontWeight": "700", "color": "#FFF" }}>
                                    <div>   Be on EVERY Top  </div>
                                    <div> Brand’s phonebook     </div></h1>
                                <button className='mt-4 btnz' onClick={InflueReg} style={{ "position": "relative", "left": "0rem", "width": "316px", "borderRadius": "10px", "height": "64px", "backgroundColor": "#FFFFFF", "color": "#C83B93", "fontSize": "24px", "fontWeight": "700", "border": "none", "boxShadow": "#7E7E7E 0px 0px 10px 2px " }}>Create my Profile &nbsp; <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i>
                                </button>
                            </span>

                        </div>

                        {/* image  */}
                        <div className='col-6 d-flex align-items-center justify-content-center'>
                            <img src={photo} className={`pop-in  ${inView7b ? 'pop   ' : ' '}`} ref={ref7b} alt='photo' style={{ "width": "28rem",position :"relative" , top : "2rem" }} />
                        </div>
                    </div>
                </section>

                {/* part 5-fAQ */}
                <section style={{ "marginTop": "4rem", "paddingBottom": "2rem", "backgroundColor": "", "minHeight": "50rem" }} id="FAQ">
                    <section className='row'>
                        <section className={`fade-in-only ${inView4 ? 'fade-only   col-12' : 'col-12 '}`} ref={ref4} style={{ "paddingBottom": "4rem" }}>
                            <h1 className='text-center' style={{ "fontWeight": "700", "fontSize": "" }}>FAQs</h1>




                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-5 px-5 pt-4 pb-3 Kardz  Faqz' onClick={(e) => { setMessage(!message) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "3rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage(!message) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>
                                        {message
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }
                                    </span>
                                    </h3>
                                    {message ?
                                        <p className='mb-2  mt-4' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-4 px-5 pt-4 pb-3  Kardz Faqz' onClick={(e) => { setMessage1(!message1) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "4.5rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage1(!message1) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>

                                        {message1
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }

                                    </span></h3>
                                    {message1 ?
                                        <p className='mb-2  mt-4 ' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-4 px-5 pt-4 pb-3 Kardz  Faqz' onClick={(e) => { setMessage2(!message2) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "4.5rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage2(!message2) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>

                                        {message2
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }

                                    </span></h3>
                                    {message2 ?
                                        <p className='mb-2  mt-4' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-4 px-5 pt-4 pb-3 Kardz Faqz' onClick={(e) => { setMessage3(!message3) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "4.5rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage3(!message3) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>

                                        {message3
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }

                                    </span></h3>
                                    {message3 ?
                                        <p className='mb-2  mt-4' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-4 px-5 pt-4 pb-3 Kardz Faqz' onClick={(e) => { setMessage4(!message4) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "4.5rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage4(!message4) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>

                                        {message4
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }

                                    </span></h3>
                                    {message4 ?
                                        <p className='mb-2  mt-4' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                            {/* span1  */}
                            <span>
                                <section className='mx-auto mt-4 px-5 pt-4 pb-3 Kardz Faqz' onClick={(e) => { setMessage5(!message5) }} style={{ "border": "0px solid gray", "boxShadow": "0px 0px 10px 1px gray", "width": "50rem", "minHeight": "4.5rem", "backgroundColor": "", "borderRadius": "40px" }}>
                                    <h3 style={{ "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "600" }}>Why should I sign up with DREABEE ?   <span onClick={(e) => { setMessage5(!message5) }} style={{ "position": "relative", "left": "24rem", "top": "5px", "fontWeight": "800", }}>

                                        {message5
                                            ?
                                            <i className="fa-solid fa-angle-up"></i>
                                            :
                                            <i className="fa-solid fa-angle-down"></i>
                                        }

                                    </span></h3>
                                    {message5 ?
                                        <p className='mb-2  mt-4' style={{ "display": "", "position": "", "left": "3rem", "top": "1.8rem", "fontSize": "1.2rem", "fontWeight": "500" }}>Lorem ipsum dolor sit amet, ex cibo velit quaestio cum, idque debet debitis eu mei. Tantas inciderint nam in. Error epicurei ut per, nusquam inimicus cu mea, graecis deleniti placerat an eum. Adhuc vitae usu ei, enim causae elaboraret duo in. Dolores officiis omittantur id cum, est doming aeterno ad. Nec no quodsi nusquam minimum.</p>
                                        : null
                                    }

                                </section>
                            </span>

                        </section>
                    </section>
                </section>


                {/* footer  */}
                <CommFoot />

            </section>
        </>
    )
}

export default CreatorsLandingSec2