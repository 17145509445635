import React from 'react'
import { useInView } from 'react-intersection-observer';
import RangeBar1 from './RangeBar1';
import CommFoot from '../CommonComponents/CommFoot';

const BussLandinFoot = () => {
    const [ref13, inView13] = useInView({
        triggerOnce: true,
        threshold: 0.05,
        delay: 500
    });
    return (
        <>
            <section className=' bgFooter bg-info my-5' style={{ height: "" }} id='Pricing'>
                <div className='row ' style={{ "height": "0px" }}>
                    <div className='col-8 ' style={{ "height": "0px" }}>
                        {/* <Tezt /> */}
                        <section className={`fade-in-text-right ${inView13 ? 'fade-in-right' : ''}`} ref={ref13} style={{ "marginTop": "6rem" }}>
                            <h4 style={{ "position": 'relative', "width": "", "left": "", "top": "-6rem", "fontWeight": "700", "fontSize": "44px", "lineHeight": "70px", "color": "#C83B93" }}>Your search for the perfect<br /> creators ends now. </h4>
                            <p style={{ "position": "relative", "width": "", "left": "", "top": "-6rem", "fontWeight": "600", "fontSize": "24px", "lineHeight": "40px", "color": "#FFFFFF", "marginTop": "2rem" }}>Build brand awareness & long-lasting customer <br />relationships while driving sales with creator <br />collaborations. Get started today.</p>
                        </section>

                    </div>

                    <div className='col-4 text-center ' style={{ "height": "0px" }}>
                        <section className={`fade-in-text-right ${inView13 ? 'fade-in-right' : ''}`} ref={ref13} style={{ "paddingLeft": "10rem", "position": "relative", "top": "-8rem", "left": "-25rem" }}>

                            {/* Buttons  */}
                            <section >
                                <button className='pb-2 btnz' style={{ "width": "630px", "height": "75px", "boxShadow": "#D12C8B 0px 5px 2px 2px ", "backgroundColor": "white", "border": "2px solid #D12C8B", "borderRadius": "500px", "marginTop": "" }}>
                                    <div style={{ "color": "#7E7E7E", "fontSize": "12px" }}>STARTER PLAN</div>
                                    <span style={{ "fontWeight": "700", "paddingBottom": "", "fontSize": "24px", "lineHeight": "0px", "textAlign": "center", "color": "#C83B93", }}>Marketing Planning + Reporting <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i> {/* <i className="fa-solid fa-arrow-up-right-from-square" style={{"color":"white"}}></i> */}</span>
                                </button>
                                <button className='pb-2 mt-5 mb-5 btnz' style={{ "width": "630px", "height": "75px", "boxShadow": "#D12C8B 0px 5px 2px 2px ", "backgroundColor": "white", "border": "2px solid #D12C8B", "borderRadius": "500px", "marginTop": "" }}>
                                    <div style={{ "color": "#7E7E7E", "fontSize": "12px" }}>ENTERPRISE PLAN</div>
                                    <span style={{ "fontWeight": "700", "paddingBottom": "", "fontSize": "24px", "lineHeight": "0px", "textAlign": "center", "color": "#C83B93", }}>Custom Planning + Reporting <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i> {/* <i className="fa-solid fa-arrow-up-right-from-square" style={{"color":"white"}}></i> */}</span>
                                </button>
                            </section>


                            <section>
                                <span >
                                    <RangeBar1 />
                                </span>
                            </section>
                        </section>
                    </div>
                </div>



            </section>
            <CommFoot className="my-5" />
        </>
    )
}

export default BussLandinFoot