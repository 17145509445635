import React, { useEffect } from 'react'
import logo from '../../Imagez/Common/logo.png'
import { useNavigate } from 'react-router-dom'


// import { klik } from '../../../Action/Indexx'
// import state from 'sweetalert/typings/modules/state'
// const myState = useSelector((state)=> state.)
const NavbarCreators = () => {
    const navigate = useNavigate();
    const InflueReg = () => {
        navigate("/Phone_Verification_Influencers")
    }
    const Landed = () => {
        navigate("/")
    }

    return (
        <>
           
            <div className='row'>
                <section className='navBar' style={{ "zIndex": "10" }}>

                    <nav className=''>

                        <div className='row mt-3' >
                            <div className='col-2 d-flex align-items-center justify-content-evenly'> <img src={logo} onClick={Landed} alt='logo' style={{ "width": "6rem", "position": "", "top": "-35%", "cursor": "pointer" }} />   </div>


                            {/* BLOCK ROW  */}
                            <div className='col-5   d-flex align-items-center  justify-content-center'>
                            </div>
                            {/* BLOCK ROW  */}


                            {/* Benifits*/}
                            <div className='col-5 d-flex align-items-center justify-content-evenly ' >
                                {/* 1  */}
                                <span className='d-flex justify-content-center align-items-center  p-1' onClick={(e) => { document.getElementById("Benifits").scrollIntoView({ behavior: "smooth" }) }}>
                                    <text className='navHovText ' style={{ "fontSize": "16px", "fontWeight": "700", "color": "#FFFFFF", "cursor": "pointer" }} > Benefits </text>
                                </span>


                                {/* 2  */}
                                <span onClick={(e) => { document.getElementById("FAQ").scrollIntoView({ behavior: "smooth" }) }}>
                                    <text className=' d-flex justify-content-center align-items-center p-1' style={{ "borderRadius": "600px", "width": "140px", "cursor": "pointer" }}  >
                                        <p className='text1 navHovText' style={{ "fontWeight": "700" }} >FAQs</p>
                                    </text>
                                </span>


                                {/* 3  */}
                                <span onClick={InflueReg}
                                    className='bg-light d-flex justify-content-center align-items-center btnz'
                                    style={{
                                        "border": "2px solid #FFFFFF", "borderRadius": "200px",
                                        "width": "200px", "height": "40px", "cursor": "pointer"
                                    }} >
                                    <text className=' d-flex align-items-center justify-content-center' style={{ "color": "#C83B93", fontWeight: "600" }}>

                                        Post a Campaign Brief &nbsp;<i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i>

                                    </text>
                                </span>

                            </div>
                        </div>
                    </nav>

                </section>
            </div>
        </>
    )
}

export default NavbarCreators