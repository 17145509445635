import React from 'react'
import image from '../../../Imagez/LandinPgImg/photo1.png';
const Kard2 = () => {
    return (
        <>
            <div className='row '>
                {/* <section style={{"marginBottom":"5rem"}}>
                        <h1 className='text-center' style={{ "fontSize": "2rem", "fontWeight": "700", "color": "#D461A8" }}> What Make us special -</h1>
                        <h1 className='text-center' style={{ "fontSize": "2.8rem", "fontWeight": "700", "color": "black", "position": "relative", "top": "" }}>Trusted by All business</h1>
                    </section> */}
                <div className='col-md-12 text-center ' style={{ "zIndex": "2 " }}>
                    <div className='Kardz row d-flex align-items-center' style={{ "width": "45rem", "height": "12rem", "background": "#FFF", "border": "2px solid #D461A8", "borderRadius": "20px", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.4)" }}>


                        <div className='col-md-3 d-flex justify-content-center' ><img src={image} alt='image' style={{ "width": "10rem", "borderRadius": "20px" }} /></div>


                        <div className='col-md-9  d-flex justify-content-center flex-direction-column' style={{ "zIndex": "2" }}>

                            <span>

                                {/* 1 */}
                                <h6 className='text-start  mt-3' style={{ "fontSize": "14px", "fontWeight": "600", color: "rgb(126, 126, 126)" }}>
                                    <i>
                                        " DREABEE has helped us immensely by providing a dashboard of influencers to quickly find the right influencers for our business - saved a lot of
                                        time & effort of scouting influencers manually. Really happy with the god speed of account managers."
                                    </i>
                                </h6>

                                {/* 2 */}
                                <section className='row text-start ' style={{ "position": "", "top": "2rem" }}>




                                    <div className='col-md-11'>
                                        <section >

                                            <div style={{ "fontWeight": "700" }}> ~AKSHIT JAIN</div>

                                            <text style={{ "fontWeight": "700", "fontSize": "14px", color: "rgb(126, 126, 126)", "position": "relative", top: "-10px" }}>
                                                Growth Lead <br />
                                                <span style={{ "position": "relative", top: "-6px" }}>Pep.Live</span> </text>
                                        </section>
                                    </div>
                                </section>
                            </span>



                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Kard2