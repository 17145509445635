import React, { useRef } from 'react'
import LandinNav from './LandinPg_Sub/LandinNav'
import { TransparentCard } from '../CommonComponents/TransparentCard'
import phone from '../../Imagez/LandinPgImg/phone.png'
import { useInView } from 'react-intersection-observer';

import LandingNavbarRe from '../../PagesComponent/Landinpg/LandinPg_Sub/LandingNavbarRe'

const LandinSec1 = () => {





    const textRef = useRef(null);
    const [ref1, inView1] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.5, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.08, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.08, // Trigger when at least 50% of the element is in view
        delay: 500
    });
    const [ref3, inView3] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.08, // Trigger when at least 50% of the element is in view
        delay: 500
    });



    return (
        <>
            {/* <div className='' style={{ "height": "125vh", "backgroundColor": "black", "zIndex": "-5",paddingTop:"70px" }}> */}
            <div className='bgLogIn'
                style={{ paddingTop: "20px", paddingBottom: "8rem" }}>

                <LandingNavbarRe />

                <section className='container-fluid'>
                    <div className='row '>
                        <div className=' col-md-6 d-flex justify-content-center align-items-center ' >


                            <section className={`fade-in-text ${inView ? 'fade-in bg-inf' : ''}`} ref={ref} style={{ "marginLeft": "5rem", "marginBottom": "1rem", "height": "", "width": "45rem" }}>
                                <h2 className='' style={{ "fontSize": "60px", "fontWeight": "700", "lineHeight": "71px", "color": "#C83B93", "marginTop": "6rem" }}> <section className='' style={{ "marginLeft": "1rem" }}> Where Creator Collaborations Happen</section></h2>
                                <span className='' style={{ "lineHeight": "40px", "fontSize": "30px", "fontWeight": "600", "color": "#FFFFFF" }}><section className='' style={{ "marginLeft": "1.5rem", "marginTop": "2rem" }}>DREABEE helps brands to find, connect and <br />collaborate with the most relevant influencers</section></span>
                            </section>
                        </div>



                        {/* TransparentCard */}
                        <div className='col-md-6 d-flex justify-content-center '>

                            <section className='row d-flex justify-content-start'>
                                <section className='col-lg-12 text-center bg-inf'>
                                    <div className='py-3' style={{ border: "" }}>
                                        <section className='d-flex justify-content-center'>
                                            <div className=' boxxy Kardz'>

                                                <section className='row text-start' >
                                                    <div className={`fade-in-text ${inView3 ? 'fade-in col-md-6 d-flex flex-column  ' : 'col-md-6 d-flex flex-column '}`} ref={ref3} style={{ "height": "580px" }}>
                                                        <h4 className='' style={{ "marginTop": "5rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "24px", "lineHeight": "31px", "color": "#FFFFFF" }}>Experience a whole new REALM OF INFLUENCER</h4>
                                                        <p className='' style={{ "marginTop": "1rem", "marginLeft": "2rem", "fontWeight": "600", "fontSize": "18px", "lineHeight": "20px", "color": "#FFFFFF" }}>Become a DREABEE Member & Get</p>
                                                        <ul className='ulli' style={{ "marginTop": "0rem", "marginLeft": "1rem", "fontWeight": "400", "fontSize": "15px", "lineHeight": "20px", "color": "#FFFFFF" }}>
                                                            <li className=''>300000+ Unique Influencers across Platforms</li>
                                                            <li className='mt-2'>Super Flexible Search like #tags, bio, profession and more </li>
                                                            <li className='mt-2'>Robust Scoring system for every Influencer & every Profile</li>
                                                            <li className='mt-2'>Advanced Search filters with extensive filters</li>
                                                        </ul>
                                                        <p id='tangs' style={{ "marginLeft": "3rem", "marginTop": "0.5rem", "fontStyle": "italic", "fontSize": "18px", "fontWeight": "600", "lineHeight": "24px", "color": "#FFFFFF" }}>And best of all ,<br />FREE FOREVER</p>
                                                    </div>


                                                    <div className=' col-md-6 d-flex justify-content-center align-items-center ' style={{ "height": "580px" }}>  <img src={phone} className={`fade-in-text ${inView2 ? 'fade-in phone' : 'phone'}`} ref={ref2} style={{ "width": "280px", "zIndex": "" }} alt='logo' /> </div>
                                                </section>

                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default LandinSec1