import React from 'react'
import NavbarCreators from '../PagesComponent/Creators_LandingPg/NavbarCreators'
import CreatorsLandingSec1 from '../PagesComponent/Creators_LandingPg/CreatorsLandingSec1'
import CreatorsLandingSec2 from '../PagesComponent/Creators_LandingPg/CreatorsLandingSec2'

const CreatorsLandingPage = () => {


  return (
    <>
      <div>

        <CreatorsLandingSec1 />
        <CreatorsLandingSec2 />


      </div>
    </>
  )
}

export default CreatorsLandingPage