import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Invite from "../../../../Imagez/Dashboard/Iconz/AddToList-Pink.png";
import Costz from "../../../../Imagez/Dashboard/Iconz/Cost-gray.png";
import Contactz from "../../../../Imagez/Dashboard/Iconz/Contact-gray.png";



import profiler from "../../../../Imagez/Dashboard/All/Profile.png";
import GRaph from "../../../../Imagez/Dashboard/All/Pie Chart.png";
import Pepsi from "../../../../Imagez/Dashboard/All/Pepsico.png";
import ProfileSumm from "./ProfileSumm";
import { Link } from "react-router-dom";
import ProfilerRou from "../../../../Routez/ProfilerRou";
import axios from "axios";
import { baseUrl2 } from "../../../../Routez/Routey";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const Proff = ({ }) => {
  const location = useLocation();

  // const [influencerData, setInfluencerData] = useState([]);
  const [showOpz, setShowOpz] = useState(false)
  const [profileDataY, setProfileDataY] = useState([]);
  const [profileDataI, setProfileDataI] = useState([]);
  const [ming, setMing] = useState();
  const [showw, setShoww] = useState(false)
  // const [showw, setShoww] = useState(true)
  useEffect(() => {
    // console.log("the location state from the profile is  : ", location.state)
    ProfileDetailsY();
    setMing(localStorage.getItem("UserName"))
    // console.log("the one any oiny : ", localStorage.getItem("UserName"))

    setTimeout(() => {
      InstagramProfile2();
      // console.log("the ratatatat descroiption", profileDataI?.description)
    }, 4000);
  }, []);








  // YOUTUBE 
  const ProfileDetailsY = () => {
    // console.log("the youtube end ", location.state.keyWord)
    axios.post(`${baseUrl2}/youtube/profile-details`, {
      name: localStorage.getItem("UserName")
    }).then((res) => {
      // console.log("THE YOUTUBER API PROFILE DATA -", res.data)
      setProfileDataY(res.data)

      const inputString = res.data.cid;
      const match = inputString.match(/YT:(\S+)/);
      if (match) {
        const extractedValue = match[1];
        // console.log("The Channel Id", extractedValue);
        localStorage.setItem("channelId", extractedValue);
      } else {
        console.log("No match found");
      }

      setShoww(true)
      console.log("proff Yooutube- > ", res.data)
      // localStorage.setItem("channelId", handled);
      // localStorage.setItem("videoId", handled);
    }).catch((err) => {
      console.log("the profile utube page errorr - ", err)
    })
  }


  // INSTAGRAM 
  const ProfileDetailsI = () => {
    // console.log("the insta end ", location.state.keyWord)
    axios.post(`${baseUrl2}/instagram/profile-details`, {
      name: localStorage.getItem("UserName")
    }).then((res) => {
      // console.log("THE Instagram API PROFILE DATA -", res.data)
      setProfileDataI(res.data)
    }).catch((err) => {
      console.log("the profile insta page errorr - ", err)
    })
  }

  const flag1 = () => {
    document.getElementById("icon1").style.color = "#C83B93";
    document.getElementById("txt1").style.color = "#C83B93"

    document.getElementById("icon2").style.color = "black";
    document.getElementById("txt2").style.color = "black";
    document.getElementById("icon3").style.color = "black";
    document.getElementById("txt3").style.color = "black";
  };
  const flag2 = () => {
    document.getElementById("icon2").style.color = "#C83B93";
    document.getElementById("txt2").style.color = "#C83B93";
    setInstaPfp("https://shiftart.com/wp-content/uploads/2017/04/RC-Profile-Square.jpg")


    document.getElementById("icon1").style.color = "black";
    document.getElementById("txt1").style.color = "black";
    document.getElementById("icon3").style.color = "black";
    document.getElementById("txt3").style.color = "black";
  };
  const flag3 = () => {
    document.getElementById("icon3").style.color = "#C83B93";
    document.getElementById("txt3").style.color = "#C83B93";
    setInstaPfp("https://images.unsplash.com/photo-1581824043583-6904b080a19c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Mnw3NjA4Mjc3NHx8ZW58MHx8fHx8")

    document.getElementById("icon1").style.color = "black";
    document.getElementById("txt1").style.color = "black";
    document.getElementById("icon2").style.color = "black";
    document.getElementById("txt2").style.color = "black";
  };


  // ---------------------
  // --------LOCAL APIS STARTS--------------
  // ---------------------
  const [averageDataInstagram, setAverageDataInstagram] = useState([]);
  const [profileDataInstagram, setProfileDataInstagram] = useState([]);
  const [instaPfp, setInstaPfp] = useState("https://images.unsplash.com/photo-1581824043583-6904b080a19c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Mnw3NjA4Mjc3NHx8ZW58MHx8fHx8")

  const InstagramProfile2 = () => {
    axios.post(`${baseUrl2}/Influencer/influencerBigData`, {
      username: localStorage.getItem("UserInstaName")
    }).then((res) => {
      console.log("THE IG Local API PROFILE DATA  2 : -", res.data)
      // console.log("Api 2nd response :-", res.data.AvgData[0].avgLikes)
      // // console.log("Api 3nd response LENGTH:-", res.data.instagram.length)
      // console.log("Api 4nd response LENGTH:-", res.data.profileData.length)
      if (res.data.AvgData.length === 0) {
        localStorage.setItem("InstagramProfile3Api", "0");
      } else {
        setAverageDataInstagram(res.data.AvgData)
        setShoww(true)

      }

      if (res.data.profileData.length === 0) {
        localStorage.setItem("InstagramProfile4Api", "0");
      } else {
        setProfileDataInstagram(res.data.profileData)
        setShoww(true)
      }


      // if (res.data.instagram.length === 0) {
      //     // InstagramProfile4();
      //     localStorage.setItem("InstagramProfile4Api", "0");
      // } else {
      //     setInstaDataInstagram(res.data.instagram)
      // }



      // else if (res.data.profileData.length) {

      // }
    }).catch((err) => {
      console.log("THE IG Local API PROFILE DATA  2  ERROR - ", err)
    })
  }


  const owwl = () => {
    // console.log("OWWL",InstaPfp )
    // console.log("TYPE OF OWWL",typeof(InstaPfp) )
  }
  // const desc = profileDataY?.description.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")
  return (
    <>

      <div className="container-fluid">
        <div className="row">
          <div
            className="col StickTop "
            style={{
              background: "white",
              borderRadius: " 10px",
              // border: "1px solid black",
              boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
              height: "190px",
              width: "90.5vw",
            }}
          >
            {/* ROW ! - PROFILE ONLY  */}
            {/* profileDataY &&   profileDataInstagram != "0" */}

            {
              showw
                ?
                <div className="row bg-">
                  {/* IMAGES  */}
                  <div className="col-2 bg- mt-3 text-center   ps-4">
                    {/* <img src={influencerData?.image} alt="profiler" style={{ width: "8rem" }} /> */}
                    {/* <img src={require("../../../../Imagez/Common/Rock.jpg")} alt="profiler" width={135} style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}

                    {/* <img src={profileDataY?.image} alt="profiler" width={150} className="border border-3 border-info" style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}
                    <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={150} height={150} />
                    {/* <img src={instaPfp} alt="profiler" width={150} className="border border-3 border-info" style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}

                    {/* <img src={profileDataInstagram[0].profile_pic_url_hd} alt="profiler" width={150} className="border border-3 border-info" style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}


                    {/* <img src={InstaPfp} alt="profiler" width={150} className="border border-3 border-info" style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}
                    {/* <img src={InstaPfp} alt="Instagram Image" /> */}
                    {/* <img src={profileDataInstagram[0].profile_pic_url_hd} alt="Instagram Image" /> */}
                  </div>

                  {/* TEXTS */}
                  <div className="col-5 p-2 pt-4 mt-2 bg- d-flex flex-column ">
                    <div className="" style={{ fontSize: "14px", fontWeight: "600" }}>
                      <h5 className="pt" style={{ fontWeight: "700", fontSize: "24px" }}>
                        {profileDataY?.name}
                        &nbsp;
                      </h5>


                      <section className="my-2" style={{ lineHeight: "25px", textTransform: "capitalize" }}>
                        {" "}
                        <i class="fa-solid fa-location-dot"></i>
                        &nbsp;
                        {profileDataY?.country}  &nbsp;   {profileDataY?.city}
                      </section>
                    </div>


                    {/* ROW 2 - SOCIAL  HANDELS  */}
                    <div className="row bg d-flex ms-5  pt-3 justify-content-start "
                      style={{ marginTop: "0rem", marginBottom: "3rem", height: "4rem", }} >
                      {/* <div className="col-2 bg-success"></div> */}

                      {/* Button 1  */}
                      <div className="col-4  text-start">
                        {/* <Link to={`Summary/${"@" + ming}`}> */}
                        <Link to={`Summary`}>
                          {" "}
                          <button
                            onClick={flag1}
                            style={{ background: "transparent", border: "none" }}
                          >
                            <i class='bx bx-user'
                              id="icon1"
                              style={{
                                fontSize: "26px",
                                fontWeight: "600",
                                color: "#C83B93",
                              }}
                            ></i>
                            <section
                              className=""
                              id="txt1"
                              style={{
                                marginTop: "2px",
                                fontWeight: "600",
                                color: "#C83B93",
                              }}
                            >
                              Profile Summary
                            </section>
                          </button>{" "}
                        </Link>
                      </div>

                      {/* Button 2  */}
                      <div className="col-4  text-start">
                        {/* <Link to={`Instagram/${"@" + ming}`}> */}
                        <Link to={`Instagram`}>
                          {" "}
                          <button
                            onClick={flag2}
                            style={{ background: "transparent", border: "none" }}
                          >
                            {/* <i class='bx bxl-instagram'
                              id="icon2"
                              style={{ fontSize: "28px", fontWeight: "600" }}
                            ></i> */}


                            <img width="35" height="35" id="icon2" src="https://img.icons8.com/3d-fluency/94/instagram-new.png" alt="instagram-new" />
                            {/* <section id="txt2" style={{ fontWeight: "600" }}>
                              <Skeleton height={20} width={100} />
                            </section> */}


                            <section id="txt2" style={{ fontWeight: "600" }}>
                              {/* {influencerData?.Instagram_detail[0]?.Instagram_username} */}
                              {/* @therock */}
                              {/* @{profileDataI?.screenName} */}
                              {profileDataY?.screenName}
                            </section>
                          </button>
                        </Link>
                      </div>

                      {/* Button 3 */}
                      <div className="col-4  text-start"> 
                        {/* <Link to={`Youtube/${"@" + ming}`}> */}
                        <Link to={`Youtube`}>
                          {" "}
                          <button
                            onClick={flag3}
                            style={{ background: "transparent", border: "none" }}
                          >
                            {/* <i
                              className="bx bxl-youtube"
                              id="icon3"
                              style={{ fontSize: "30px", fontWeight: "600", color: "" }}
                            ></i> */}

                            <img width="35" height="35" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" id="icon3" alt="youtube-play" />

                            {/* <section id="txt2" style={{ fontWeight: "600" }}>
                              <Skeleton height={20} width={100} />
                            </section> */}



                            <section id="txt3" style={{ fontWeight: "600", color: "" }}>
                              {/* @{influencerData?.Youtube_detail[0]?.youtube_username } */}
                              {profileDataY?.screenName}
                              {/* @therock */}
                            </section>
                          </button>
                        </Link>
                      </div>
                      {/* <div className='col-6 bg-info'>hi</div> */}
                    </div>
                  </div>

                  {/* BUTTONS  */}
                  <div className={localStorage.getItem("userIn") != "influencer" ? "showLinks col-5 pt-4" : "hideLinks"}>
                    <div className="row bg-">
                      {/* btn1 */}
                      <div className="col bg-">
                        <button
                          className="text-start p-1 px-3  "
                          style={{
                            width: "8rem",
                            height: "2.5rem",
                            fontWeight: "600",
                            fontSize: "0.8rem",
                            borderRadius: "10px",
                            color: "#7E7E7E",
                            backgroundColor: "white",
                            border: "2px solid #C83B93",
                            marginLeft: "0rem",
                          }}>
                          <img
                            src={Contactz}
                            alt="add"
                            style={{
                              width: "1.8rem",
                              border: "2px solid white",
                              borderRadius: "10px",
                            }}
                          />{" "}
                          &nbsp;
                          <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                            {" "}
                            Contacts{" "}
                          </span>
                        </button>

                      </div>
                      {/* btn2 */}
                      <div className="col bg-">
                        <button
                          className="text-start px-3 p-1  "
                          style={{
                            width: "8rem",
                            height: "2.5rem",
                            fontWeight: "600",
                            fontSize: "0.8rem",
                            borderRadius: "10px",
                            color: "#7E7E7E",
                            backgroundColor: "white",
                            border: "2px solid #C83B93",
                            marginLeft: "0rem",
                          }}
                        >
                          <img
                            src={Costz}
                            alt="add"
                            style={{
                              width: "1.8rem",
                              border: "2px solid white",
                              borderRadius: "10px",
                            }}
                          />


                          {" "}


                          &nbsp;
                          <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                            {" "}
                            Cost{" "}
                          </span>
                        </button>

                      </div>

                      {/* btn3 */}
                      <div className="col text-end  bg-">
                        <button
                          className="text-center p-1  "
                          style={{
                            width: "8rem",
                            height: "2.5rem",
                            fontWeight: "600",
                            fontSize: "0.8rem",
                            borderRadius: "10px",
                            color: "#7E7E7E",
                            backgroundColor: "white",
                            border: "2px solid #C83B93",
                            marginLeft: "0rem",
                          }}
                        >
                          <img
                            src={Invite}
                            alt="add"
                            style={{
                              width: "1.8rem",
                              border: "2px solid white",
                              borderRadius: "10px",
                            }}
                          />{" "}
                          &nbsp;
                          <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                            {" "}
                            Add to list{" "}
                          </span>
                        </button>
                      </div>


                      <div className="col">
                        <button
                          className="text-center d-flex align-items-center justify-content-center  "
                          style={{
                            width: "38px",
                            height: "38px",
                            fontWeight: "600",
                            fontSize: "0.8rem",
                            borderRadius: "10px",
                            color: "#7E7E7E",
                            backgroundColor: "#C83B93",
                            border: "none",
                            marginLeft: "1rem",
                          }}
                        >

                          <i class='bx bx-refresh' style={{ color: "white", fontSize: "24px" }}></i>

                        </button>





                      </div>
                    </div>
                  </div>



                </div>
                :
                <SkeletonTheme baseColor="#d3d3d39b" highlightColor="#f6cbe59d" borderRadius={20}>
                  <div className="row bg-">
                    {/* IMAGES  */}
                    <div className="col-2 text-center py-2  ">

                      {/* <img src={profileDataY?.image} alt="profiler" width={150} className="border border-3 border-info" style={{ borderRadius: "50%", border: "1px solid #c83b93" }} /> */}

                      <span style={{ width: "0px" }} >
                        <Skeleton circle={true} className=" rounded-circle border-info border-1 img-fluid my-2" width={150} height={150} />
                      </span>

                    </div>

                    {/* TEXTS */}
                    <div className="col-5 p-2 pt-4 mt-2 bg- d-flex flex-column ">
                      <div className="" style={{ fontSize: "14px", fontWeight: "600" }}>
                        <Skeleton height={34} width={150} />

                        <section className="my-1" style={{ lineHeight: "25px", textTransform: "capitalize" }}>
                          <Skeleton height={20} width={150} />
                        </section>
                      </div>


                      {/* ROW 2 - SOCIAL  HANDELS  */}
                      <div className="row bg- d-flex ms-5  pt-3 justify-content-start "
                        style={{ marginTop: "0rem", marginBottom: "3rem", height: "4rem", }} >
                        {/* <div className="col-2 bg-success"></div> */}

                        {/* Button 1  */}
                        <div className="col-4  text-start">
                          {/* <Link to={`Summary/${"@" + ming}`}> */}
                          <Link to={`Summary`}>
                            {" "}
                            <button
                              onClick={flag1}
                              style={{ background: "transparent", border: "none" }}
                            >
                              <i class='bx bx-user'
                                id="icon1"
                                style={{
                                  fontSize: "26px",
                                  fontWeight: "600",
                                  color: "#C83B93",
                                }}
                              ></i>
                              <section
                                className=""
                                id="txt1"
                                style={{
                                  marginTop: "2px",
                                  fontWeight: "600",
                                  color: "#C83B93",
                                }}
                              >
                                Profile Summary
                              </section>
                            </button>{" "}
                          </Link>
                        </div>

                        {/* Button 2  */}
                        <div className="col-4  text-start">
                          {/* <Link to={`Instagram/${"@" + ming}`}> */}
                          <Link to={`Instagram`}>
                            {" "}
                            <button
                              onClick={flag2}
                              style={{ background: "transparent", border: "none" }}
                            >
                              {/* <i class='bx bxl-instagram'
                                id="icon2"
                                style={{ fontSize: "28px", fontWeight: "600" }}
                              ></i> */}
                              <img width="35" height="35" id="icon2" src="https://img.icons8.com/3d-fluency/94/instagram-new.png" alt="instagram-new" />

                              <section id="txt2" style={{ fontWeight: "600" }}>
                                <Skeleton height={20} width={100} />
                              </section>
                            </button>
                          </Link>
                        </div>

                        {/* Button 3 */}
                        <div className="col-4  text-start">
                          <Link to={`Youtube`}>
                            {" "}
                            <button onClick={flag3} style={{ background: "transparent", border: "none" }} >
                            
                              <img width="35" height="35" src="https://img.icons8.com/3d-fluency/100/youtube-play.png" id="icon3" alt="youtube-play" />

                              <section id="txt3" style={{ fontWeight: "600", color: "" }}>
                                <Skeleton height={20} width={100} />
                              </section>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>


                    {/* BUTTONS  */}
                    <div className={localStorage.getItem("userIn") != "influencer" ? "showLinks col-5 pt-4" : "hideLinks"}>
                      <div className="row bg-">
                        <div className="col-4">

                        </div>
                        <div className="col-5 text-end ms-5 ">
                          <button
                            className="text-center p-1  "
                            style={{
                              width: "8rem",
                              height: "2.5rem",
                              fontWeight: "600",
                              fontSize: "0.8rem",
                              borderRadius: "10px",
                              color: "#7E7E7E",
                              backgroundColor: "white",
                              border: "2px solid #C83B93",
                              marginLeft: "0rem",
                            }}
                          >
                            <img
                              src={Invite}
                              alt="add"
                              style={{
                                width: "1.8rem",
                                border: "2px solid white",
                                borderRadius: "10px",
                              }}
                            />{" "}
                            &nbsp;
                            <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                              {" "}
                              Add to list{" "}
                            </span>
                          </button>
                        </div>


                        <div className="col-1 ms-2">
                          <button
                            className="text-center p-1  "
                            style={{
                              width: "38px",
                              height: "38px",
                              fontWeight: "600",
                              fontSize: "0.8rem",
                              borderRadius: "10px",
                              color: "#7E7E7E",
                              backgroundColor: "#C83B93",
                              border: "none",
                              marginLeft: "0rem",
                            }}
                            onClick={() => { setShowOpz(!showOpz) }}

                          >

                            {
                              showOpz ?
                                <i class="fa-solid fa-xmark" style={{ color: "white" }}></i>
                                :
                                <i class='bx bx-dots-vertical-rounded' style={{ color: "white" }}></i>

                            }

                          </button>


                          {
                            showOpz ?
                              <section className="mt-3" style={{ height: "0px", position: "relative", "right": "10rem" }}>
                                <div className="bg-  row" style={{ height: "100px", width: "100px" }} >
                                  <div className="col-lg">
                                    <button
                                      className="text-start p-1 px-3  "
                                      style={{
                                        width: "8rem",
                                        height: "2.5rem",
                                        fontWeight: "600",
                                        fontSize: "0.8rem",
                                        borderRadius: "10px",
                                        color: "#7E7E7E",
                                        backgroundColor: "white",
                                        border: "2px solid #C83B93",
                                        marginLeft: "0rem",
                                      }}
                                    >
                                      <img
                                        src={Contactz}
                                        alt="add"
                                        style={{
                                          width: "1.8rem",
                                          border: "2px solid white",
                                          borderRadius: "10px",
                                        }}
                                      />{" "}
                                      &nbsp;
                                      <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                                        {" "}
                                        Contacts{" "}
                                      </span>
                                    </button>





                                  </div>
                                  <div className="col-lg">
                                    <button
                                      className="text-start px-3 p-1  "
                                      style={{
                                        width: "8rem",
                                        height: "2.5rem",
                                        fontWeight: "600",
                                        fontSize: "0.8rem",
                                        borderRadius: "10px",
                                        color: "#7E7E7E",
                                        backgroundColor: "white",
                                        border: "2px solid #C83B93",
                                        marginLeft: "0rem",
                                      }}
                                    >
                                      <img
                                        src={Costz}
                                        alt="add"
                                        style={{
                                          width: "1.8rem",
                                          border: "2px solid white",
                                          borderRadius: "10px",
                                        }}
                                      />


                                      {" "}


                                      &nbsp;
                                      <span style={{ position: "relative", top: "2px", color: "#C83B93" }}>
                                        {" "}
                                        Cost{" "}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </section>
                              :
                              null
                          }


                        </div>
                      </div>
                    </div>
                  </div>
                </SkeletonTheme>
            }

          </div>

          {/* _________________________________________________ */}

          {/* <ProfileSumm /> */}
          <ProfilerRou influencerData={profileDataY} />
        </div>
      </div>


    </>

  );
};

export default Proff;
