import React from 'react'

import LandinSec1 from '../PagesComponent/Landinpg/LandinSec1'
import LandinSec2 from '../PagesComponent/Landinpg/LandinSec2'
import LandinFoot from '../PagesComponent/Landinpg/LandinFoot'
import LandinNav from '../PagesComponent/Landinpg/LandinPg_Sub/LandinNav'
import LandingNavbarRe from '../PagesComponent/Landinpg/LandinPg_Sub/LandingNavbarRe'
export const LandinPage = () => {
    return (
        <>

            <div className='conatiner-fluid' style={{ "width": "102vw" }}>
           
                {/* <LandingNavbarRe /> */}
              
                <LandinSec1 />
                <LandinSec2 />
                <LandinFoot />
            </div>

        </>
    )
}
