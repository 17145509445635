import React from 'react'
import LandinFoot from '../Landinpg/LandinFoot'
import CommFoot from './CommFoot'

const PrivacyPolicy = () => {
    return (
        <>
            <div className='bgLogIn' >
                <div className='row text-light'>
                    <div className='col-12 p-4 my-2 mb-4'>
                        <div className='text-center'>
                            <h1> WELCOME TO DREABEE PRIVACY NOTICE.  </h1>
                        </div>

                        <div className='mx-4'>



                            <div className='my-4'>
                                This Privacy notice is incorporated by reference into the DREABEE Terms of Service (the “Terms”) and the DREABEE Privacy Policy.
                                This Privacy notice applies to both Registered and Unregistered Influencer (“Influencer’’ ‘’you’’ “your”) whose Personal Information has been processed by DREABEE ("DREABEE", “we”, “us”, “our”) in the course of our business. <br /><br />
                                DREABEE is a web-based platform that helps you to connect with the brands, companies and marketing agencies, to monetise your digital media presence commercially. This platform provides you an opportunity to endorse and comment on the brands/ companies you prefer. To serve that purpose, we collect your Personal Information from a variety of public sources. After collation, we combine and couple your information and data with powerful tools to create an influencer Profile and then enable our Users to connect with you. <br />
                                We respect your privacy and are committed to protect your personal data. This privacy notice describes how we collect, use, process, and disclose your information, including Personal Data (as defined below), in conjunction with your access to and use of our Services, and it applies to all of the Services offered by DREABEE and its affiliates including but not limited to all DREABEE Apps, Sites, APIs (including exports), and Integrations. <br />
                                It is important that you read this privacy policy together with the privacy policy narrated here, and any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your personal data. <br />
                                This privacy notice supplements the other notices/policy and is not intended to supersede them.

                            </div>



                            {/* INFORMATION WE COLLECT */}
                            {/* I. REGISTERED INFLUENCERS */}
                            {/* --A. INFORMATION YOU PROVIDE TO US- */}
                            {/* B. AUTOMATICALLY COLLECTED INFORMATION */}
                            {/* C. INFORMATION COLLECTED FROM THIRD PARTIES */}



                            <div >
                                <h2>INFORMATION WE COLLECT
                                </h2>
                                <p>
                                    To provide the services, we collect, store, process and share Personal information of both registered as well as unregistered influencers.<br />
                                    ‘’Registered Influencer’’ shall mean those who have volunteered to provide their personal details into include in DREABEE database and registered their name as an influencer.<br />
                                    Unregistered influencers shall mean influencer identified by DREABEE from its own research whose contact and coordinates are extracted from various public sources.<br />
                                    The type of Personal Information we may collect (directly from you or from Third-Party sources) and our privacy practices depend on the nature of the relationship you have with DREABEE and the requirements of applicable law.<br />
                                    Below are the ways on what information we collect from Registered influencers.

                                </p>

                            </div>
                            <div >
                                <h2>
                                    I. REGISTERED INFLUENCERS

                                </h2>
                                <p>
                                    By registering and setting up DREABEE profile, you give us explicit consent to collect, store, process and share your Personal information that is provided by you and we collect about you from various internet sources.<br />
                                    “Personal data, or Personal Information” that we collect includes:<br />
                                    <ul>
                                        <li>
                                            name
                                        </li>
                                        <li>
                                            email id
                                        </li>
                                        <li>
                                            phone number
                                        </li>
                                        <li>
                                            location
                                        </li>
                                        <li>
                                            gender
                                        </li>
                                        <li>
                                            profile picture
                                        </li>


                                        <li>
                                            social media handles and public profile information of the followers for the influencer and for a particular post
                                        </li>


                                        <li>
                                            social media handles and public profile information of who the influencer is following.
                                        </li>


                                        <li>
                                            Text/image or video URL posted or uploaded by the influencer or the followers/friends of the influencer.
                                        </li>


                                        <li>
                                            Pages, social accounts, hashtags and groups that the influencer is connected with.
                                        </li>


                                        <li>
                                            Comments or posts by the influencer and/or friends/followers of influencer.
                                        </li>


                                        <li>
                                            Information about individuals who reacted(like/dislike), commented or shared the post and location data of the post
                                        </li>
                                        <li>
                                            Address
                                        </li>
                                        <li>
                                            Internet Protocol (IP) address, browser type and operating system
                                        </li>

                                    </ul>
                                    {/* 
                                    Some of the personal information we collect may belong to special categories. However, such information we collect, and process are those which are manifestly made public by you.<br />
                                    Some of the ways that DREABEE may collect Personal Information include: */}


                                </p>

                            </div>



                            {/*                             
                            <div >
                                <h4>
                                    <u>
                                        A. INFORMATION YOU PROVIDE TO US
                                    </u>

                                </h4>
                                <p>
                                    When you create a profile and you provide your basic details including your full name, email address, phone number Gender, Age, Location, area of interest and we may also ask you to connect to your social account. Your display name and username are always public.<br />
                                    We collect every information that you provide in the DREABEE platform including your name, email address, phone number, Gender, Age, Location, area of interest, the content you create, upload, or receive from others when using our services, , documents and spreadsheets you create, comments you make, and the comments others make about you on various social media platforms.<br />
                                    We use your contact information, such as your email address or phone number, to authenticate your profile and keep it - and our services - secure, and to help prevent spam, fraud, and abuse. We also use your information to personalize our services, enable certain features and send you information about our services. If you provide us with your phone number and email id, you agree to receive text messages and emails from DREABEE<br />
                                    If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.<br />
                                    From time to time, we may contact you to participate in online surveys. If you do decide to participate, you may be asked to provide certain information which may include Personal Information. All information collected from your participation in our surveys is provided by you voluntarily. We may use such information to improve our products and/or Services and in any manner consistent with the policies provided herein.<br />


                                </p>

                            </div>

                            <div >


                                <h4>
                                    <u>
                                        B. AUTOMATICALLY COLLECTED INFORMATION
                                    </u>

                                </h4>
                                <p>

                                    In addition to the information that you provide us, we also use technology like Application Programming Interfaces (APIs) to make your personal information available in DREABEE database. <br />

                                    <ol type='a'>
                                        <li>
                                            Usage and Log Information To make our Site and Services more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time of your visit. We use this information to examine our traffic and to view how our customers use the Platform. This type of information will not allow you to be personally identified although we might be able to associate it with your profile. For example, we use “cookies,” which contain only certain statistical information. For more information on cookies please find more information below.

                                        </li>
                                        <li>
                                            Local Information We may collect information about your signup and current location, which we get from signals such as your IP address or device settings, to securely and reliably set up and maintain your account and to provide our services to you.

                                        </li>
                                        <li>
                                            Links We also collect information about the people, Pages, social accounts, hashtags and groups you are connected to and how you interact with them across our product and Services such as type of content that you view or engage with, the features you use, the actions you take, the people or accounts you interact with and the time, frequency and duration of your activities. For example, we log when you are using and have last used Services and what posts, videos and other contents that you have viewed. We also collect information about how you use our features.

                                        </li>
                                        <li>
                                            Cookies We use cookies and URL information to gather information regarding the date and time of your visit and the information for which you searched and which you viewed. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing a web site. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. Persistent Cookies can be removed by following Internet browser help file directions. <br />

                                        </li>
                                    </ol>


                                    Our uses of cookies, web beacons, or other similar technologies fall into the following general categories:
                                    <ol type="i">
                                        <li>
                                            Operationally Necessary. We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our Services. This includes technologies that allow you access to our Services; that are required to identify irregular site behaviour, prevent fraudulent activity and improve security; or that allow you to make use of our functions such as, product information, saved search, or similar functions;

                                        </li>
                                        <li>
                                            Performance Related. We may use cookies, web beacons, or other similar technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use our Services, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our Services’ content, applications, services, or tools;

                                        </li>
                                        <li>
                                            Functionality Related. We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed so that we may enhance the presentation of content on our Services;


                                        </li>
                                        <li>
                                            Advertising or Targeting Related. We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests, on our Services or on Third Party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.

                                        </li>
                                    </ol>
                                    If you would like to opt-out of the Technologies we employ on our sites, services, applications, or tools, you may do so by blocking, deleting, or disabling them as your browser or device permits.


                                </p>

                            </div>

                            <div >
                                <h4>
                                    <u>
                                        C. INFORMATION COLLECTED FROM THIRD PARTIES
                                    </u>

                                </h4>
                                <p>
                                    We collect your Personal Information that you post and makes manifestly public on various social media platforms, that are commonly used for self-promotion, marketing and publicity purposes. If information is located behind a paywall or a password or have setting that are other than “public”, we will not collect it. <br />
                                    We collect the information about you and other individuals (including those in your End User Contact Data) from the following third-party sources: <br />

                                    <ol type='a'>
                                        <li>
                                            Third parties who license, sell or otherwise provide data they have collected;
                                            Social Sign-In. Our Site incorporate functionality enabling you to sign-on using your login information from a social networking platform such as Facebook, Instagram in lieu of creating a new login account specifically for the Site. If we incorporate such functionality and you use it to sign into any of our Sites, we may collect information about you from the social networking platform, such as your list of friends, “likes,” and interests, as well as your contact information.  a.  Usage and Log Information To make our Site and Services more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time of your visit. We use this information to examine our traffic and to view how our customers use the Platform. This type of information will not allow you to be personally identified although we might be able to associate it with your profile. For example, we use “cookies,” which contain only certain statistical information. For more information on cookies please find more information below.
                                        </li>
                                        <li>
                                            Local Information We may collect information about your signup and current location, which we get from signals such as your IP address or device settings, to securely and reliably set up and maintain your account and to provide our services to you.
                                        </li>

                                        <li>

                                            Links We also collect information about the people, Pages, social accounts, hashtags and groups you are connected to and how you interact with them across our product and Services such as type of content that you view or engage with, the features you use, the actions you take, the people or accounts you interact with and the time, frequency and duration of your activities. For example, we log when you are using and have last used Services and what posts, videos and other contents that you have viewed. We also collect information about how you use our features.
                                        </li>
                                        <li>
                                            Cookies We use cookies and URL information to gather information regarding the date and time of your visit and the information for which you searched and which you viewed. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing a web site. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. Persistent Cookies can be removed by following Internet browser help file directions. <br />

                                        </li>
                                    </ol>
                                    <br />

                                    Below are some of the platforms from where we extract and collect your personal information:  a.  Usage and Log Information To make our Site and Services more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time of your visit. We use this information to examine our traffic and to view how our customers use the Platform. This type of information will not allow you to be personally identified although we might be able to associate it with your profile. For example, we use “cookies,” which contain only certain statistical information. For more information on cookies please find more information below. <br />
                                    <ol type='i'>

                                        <li>
                                            YouTube We may collect and use your publicly available information shared by you in YouTube through APIs and other means, to understand your trend and nature of your interest of our services.
                                        </li>

                                        <li>
                                            Facebook and Instagram We may use certain tools offered by Facebook, Inc. (“Facebook”) that enable us to collect or receive information about actions users take on: (a) our Site and elsewhere on the internet through use of technologies; or (b) through Facebook API..
                                        </li>

                                        <li>
                                            Google Analytics We may use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behaviour and visitor demographics on some of our Services, and to develop website content. This analytics data is not tied to any Personal Information.
                                        </li>
                                    </ol>

                                </p>

                            </div> */}




                            {/* <h2>II. UNREGISTERED INFLUENCERS</h2> */}
                            {/* A. INFORMATION COLLECTED FROM SOCIAL MEDIA PLATFORMS */}
                            {/* B.  <u>AUTOMATICALLY COLLECTED INFORMATION</u> */}
                            {/* HOW WE USE YOUR PERSONAL DATA */}


                            <div >
                                <h2>II. UNREGISTERED INFLUENCERS</h2>
                                <p>
                                    Along with Registered influencer, we also collect, store, process and share Personal information of Unregistered influencers as well.
                                    “Personal data, or Personal Information” that we collect includes: <br />
                                    <ul>
                                        <li>name</li>
                                        <li> email id</li>
                                        <li>phone number</li>
                                        <li> location</li>
                                        <li>gender</li>
                                        <li>profile picture</li>
                                        <li>social media handles and public profile information of the followers for the influencer and for a particular post</li>
                                        <li>social media handles and public profile information of who the influencer is following.</li>
                                        <li>Text/image or video URL posted or uploaded by the influencer or the followers/friends of the influencer.</li>
                                        <li>Pages, social accounts, hashtags and groups that the influencer is connected with.</li>
                                        <li>Comments or posts by the influencer and/or friends/followers of influencer.</li>
                                        <li>Information about individuals who reacted(like/dislike), commented or shared the post and location data of the post</li>
                                        <li>Address</li>
                                        <li>Internet Protocol (IP) address, browser type and operating system</li>
                                    </ul>

                                    Some of the personal information we collect may belong to special categories. However, such information we collect, and process are those which are manifestly made public by you.We believe that our collection and processing of your personal information is not harmful to you.
                                    We collect and process your information on as it is basis and with the legitimate interest of providing you the platform to connect with the brands, companies and marketing agencies, thereby giving you a platform to monetise your social media presence and influencing ability.


                                </p>

                            </div>

                            {/*
                            

                            <div >
                                <h4>
                                    A. <u>INFORMATION COLLECTED FROM SOCIAL MEDIA PLATFORMS
                                    </u>

                                </h4>
                                <p>
                                    We collect those Personal Information that are manifestly made public by you or third parties on publicly available sources, such as via the Internet and social networks. If the information is located behind a paywall or a password or have setting that are other than “public”, we will not collect it.
                                    We collect your Personal Information from various social platforms including the following. However, it is pertinent to note that the below list is only an indication of social media companies and the same is not an exhaustive list.
                                    We constantly search for other sources to make the influencer profile more accurate and accordingly we collect your Personal Information from all available sources in the internet.

                                    <ol type='a'>
                                        <li>YouTube We may collect and use your publicly available information shared by you in YouTube through APIs and other means, to understand your trend and nature of your interest of our services.</li>
                                        <li>Facebook and Instagram We may use certain tools offered by Facebook, Inc. (“Facebook”) that enable us to collect or receive information about actions users take on: (a) our Site and elsewhere on the internet through use of technologies; or (b) through Facebook API.</li>
                                        <li>Twitter We may use the Twitter API to integrate Twitter Content, to collect and use your twitter shared information.</li>
                                        <li>LinkedIn We may collect and use your publicly available information shared by you in LinkedIn through APIs and other means, to understand your professional background, networking and nature of your interest.</li>
                                        <li>Google Analytics We may use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behaviour and visitor demographics on some of our Services, and to develop website content. This analytics data is not tied to any Personal Information.</li>
                                    </ol>
                                </p>

                            </div>

                            <div >
                                <h4>
                                    B.  <u>AUTOMATICALLY COLLECTED INFORMATION</u>
                                </h4>

                                <p>
                                    In addition to the information that you provide us, we also use technology like Application Programming Interfaces (APIs) to make your personal information available in DREABEE database.

                                    <ol type='a'>
                                        <li>Server Information When you visit or browse our Site, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time of your visit. We use this information to examine our traffic and to view how our customers use the Platform. This type of information will not allow you to be personally identified although we might be able to associate it with your profile.</li>
                                        <li>Local Information We may collect information on your current location, which we get from signals such as your IP address or device settings.</li>
                                        <li>Cookies We use cookies and URL information to gather information regarding the date and time of your visit and the information for which you searched and which you viewed. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing a web site. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. Persistent Cookies can be removed by following Internet browser help file directions.
                                            Our uses of cookies, web beacons, or other similar technologies fall into the following general categories:

                                            <ol type='i'>
                                                <li>Operationally Necessary.. We may use cookies, web beacons, or other similar technologies that are necessary to the operation of our Services. This includes technologies that allow you access to our Services; that are required to identify irregular site behaviour, prevent fraudulent activity and improve security; or that allow you to make use of our functions such as, product information, saved search, or similar functions.</li>
                                                <li> Performance Related. We may use cookies, web beacons, or other similar technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use our Services, determine if you have interacted with our messaging, determine whether you have viewed an item or link, or to improve our Services’ content, applications, services, or tools;</li>
                                                <li>Functionality Related. We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed so that we may enhance the presentation of content on our Services;</li>
                                                <li>Advertising or Targeting Related. We may use first-party or third-party cookies and web beacons to deliver content, including ads relevant to your interests, on our Services or on Third Party sites. This includes using technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.</li>
                                            </ol>

                                        </li>
                                    </ol>



                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>  HOW WE USE YOUR PERSONAL DATA </u>
                                </h2>

                                <p>
                                    In case of Registered Influencer, we process your data on the basis of your explicit consent. In all instances, we use the influencer personal information that is manifestly made public, with a legitimate interest of providing the platform to connect with the brands, companies and marketing agencies, to enable those companies to utilize your presence in the influencer marketing ecosystem and to commercially utilize your subject-matter expertise.
                                    Additionally, the platform offers a greater public benefit by facilitating the growth and development of, and insight into, the wider influencer marketing ecosystem.
                                    We believe that such use of your personal information does not override your fundamental rights with that of legitimate interests.
                                    We process your data we collected directly/indirectly and do a profiling by: <br />

                                    <ol type='a'>
                                        <li>Use A.I powered search engine in order to understand your expertise category, topics and brand mentions with the help of A.I powered Search Engine</li>
                                        <li>As a part of our Services, we use Smart Filters to shortlist and prioritise you based on specific campaign objectives such as reach, engagement and geo-targeting</li>
                                        <li>
                                            We use our proprietary Scoring algorithm to understand your platform wise clout and engagement with audience.
                                            By processing, we create a profile of the influencers which contain:
                                            <ul>
                                                <li>Basic identifying information of influencer from their public profile</li>
                                                <li>Contact information for the influencer (to the extent listed by them or in their name)</li>
                                                <li>Compiled and aggregated information about particular areas and populations with whom the influencer is most influential</li>
                                            </ul>
                                        </li>
                                    </ol>


                                    We create influencer profile primarily upon the decision of automated processing i.e., by using AI power engine, smart filters and proprietary Scoring Algorithm. However, the same is vetted by our human resource personnel as well to ensure accuracy.
                                    If you are a Registered Influencer, we may need to request specific information from you to help us confirm your identity. This is a security measure to ensure that your personal data is not managed by any person, who has no right to edit and manage your information. We may also contact you to ask you for further information in relation to your request to speed up our response.
                                    We may use the email address to respond to their inquiries, questions, and/or other requests. If User joins our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
                                    As a part of our services, we implement appropriate safeguards to protect the rights of influencers. For example, influencers can control information we use and share about them as set forth in the [Privacy Rights] section of our Privacy Notice. If you require further information about the balancing test that DREABEE has undertaken to justify its reliance on the legitimate interest legal basis under the GDPR, please contact us for further details at the address mentioned below.


                                </p>

                            </div>  */}




                            {/* ACCESS TO YOUR PERSONAL INFORMATION */}
                            {/* <h2>SHARING OF YOUR PERSONAL INFORMATION */}
                            {/* THIRD PARTY WEBSITES
                            DISCLAIMER
                            DATA SECURITY
                            DATA RETENTION
                            YOUR PRIVACY RIGHTS */}


                            {/* 
                            <div >
                                <h2>
                                    <u>
                                        ACCESS TO YOUR PERSONAL INFORMATION
                                    </u>
                                </h2>

                                <p>
                                    As a part of our legitimate interest, we provide access of your Personal Information [including your digital media presence, your profile, your social media use and activities] to our Registered Platform Users.
                                    We provide our Registered Users the opportunity to identify you, and help them to analyse your abilities and expertise. If they are convinced with your influencing skills, they might approach you by connecting you either through E-mails as well as Direct Message through Instagram, Facebook or Twitter.

                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>
                                        SHARING OF YOUR PERSONAL INFORMATION
                                    </u>


                                </h2>
                                <p>
                                    In certain situations, we may disclose your Personal Information in the following scenarios:
                                    Third Party Service Providers. We may share your Personal Data with third party service providers to provide with the IT, system administration and other Services. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes assigned and in accordance with our documented instructions.
                                    Other Disclosures. Regardless of your rights that you have regarding your Personal Data (as described below), DREABEE may disclose Personal Data to third parties including but not limited to lawyers, government officials etc. if we believe in good faith that such disclosure is necessary:
                                    •  in connection with any legal investigation
                                    •  to comply with relevant laws or to respond to subpoenas or warrants served on DREABEE;
                                    •  to protect or defend the rights or property of DREABEE or users of the Services; and/or
                                    •  to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or Terms of Use.


                                </p>

                            </div>
                            
                            <div >
                                <h2>
                                    <u>
                                        THIRD PARTY WEBSITES
                                    </u>


                                </h2>
                                <p>
                                    Influencers may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on the third-party websites and are not responsible for the practices employed by websites linked to or from our Site.
                                    We encourage you to read the privacy policies of each and every website and application with which they interact. We do not endorse, screen or approve, and are not responsible for the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.
                                    The Site and our other Services may also contain links and interactive features with various Social Networks (e.g., widgets). If you already use these platforms, their cookies may be set on your device when using our Services. You should be aware that Personal Information which you voluntarily include and transmit online through such Social Networks may be accessible by the providers of such Social Networks and, if posted publicly, may be viewed and used by others without any restrictions. We are unable to control such uses of your information when interacting with a Social Network, and by using such services you assume the risk that the Personal Information provided by you may be viewed and used by third parties for any number of purposes.


                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>
                                        DISCLAIMER
                                    </u>


                                </h2>
                                <p>
                                    1.  When we collect Personal Information, we will not consider your nationality or privacy rights granted to you by the concerned authorities. We are an Indian Company and our privacy policy are based on Indian law.
                                    2.  We store our data including Personal Information that we collect in our digital Cloud Servers hosted by third party service providers.We believe that the security measures adopted by [DigitalOcean-Singapore , AWS-Singapore ] are up to the required standard. However, we are not responsible for any failures from such third-party vendors.
                                    3.  Influencer profiling, we create and categorize are based on our best intelligence and algorithm process and are vetted by our human resource personnel. However, since our operation of the services including profiling are mostly by using automated means, we do not give any assurance or guarantee regarding the accuracy, suitability or quality of the influencer profile we create.

                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>
                                        DATA SECURITY
                                    </u>



                                </h2>
                                <p>
                                    We have put in place appropriate safeguards with the intend to protect your Personal data and as a part of that we have given you adequate privacy control regarding the information we collect, use and store. [It is more explained in the Section ‘'Privacy Rights’' below.]
                                    In addition, as a part of our security measure, we limit access to your personal data to only those Users, employees, and other third parties who have a business need to know. Processors of your personal data acting on our documented instructions will only process your personal data in accordance with our instructions and are subject to a duty of confidentiality.
                                    We have also taken Data Protection Impact Assessment prior to processing of your data in order to assess the impact of processing your data. Our impact assessment explains the nature and purpose of processing your data, risk involved in processing, measures, safeguards and mechanisms envisaged for mitigating that risk and thereby we ensure the legal obligations. For more details about our Impact Assessment, please approach our Data Protection Officer in the address mentioned below.

                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>
                                        DATA RETENTION
                                    </u>



                                </h2>
                                <p>
                                    We retain your personal data with the legitimate interest of providing you a platform that helps you to monetise your digital media presence.
                                    We retain your personal data for as long as necessary to fulfil the purpose for which it is intended. However, you can request us to remove your Personal Information from our Platform. The retention policy that describes how DREABEE retains data we collect is explained under our Terms of Services.
                                    In order to facilitate the most effective campaign opportunities for our Users and the influencers, we take measures designed to ensure that the information we collect and the analysis, we do are accurate and up to date. To this end:
                                    •  We continuously check each influencer’s source websites and update the profiles on our system.
                                    •  We apply consistent quality analysis and testing on samples of posts and followers that we use for our analysis.
                                    •  We employee a team of data scientists that applies algorithms designed to ensure the accuracy of the information.
                                    •  If an influencer contacts us to correct inaccurate information about them, we assess the request and amend the information where appropriate.

                                </p>

                            </div>

                            <div >
                                <h2>
                                    <u>
                                        YOUR PRIVACY RIGHTS
                                    </u>



                                </h2>
                                <p>
                                    You can certainly have control over the information we collect and how it's used in the following ways:



                                    <ol type='A'>

                                        <li>Right to manage, review, and update your information
                                            You have every right to:

                                            <ol type='a'>
                                                <li>Request to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                                                <li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of any new data you provide to us.</li>
                                                <li>If you’re a registered influencer, you can always review and update information by visiting the services you use. Our site is designed to help you manage specific types of content you’ve saved with Dreabee.</li>
                                                <li>You can decide what types of activity you’d like saved in your account.</li>
                                                <li>Manage your preferences about the ads shown to you on Dreabee and on sites and apps that partner with Dreabee to show ads.</li>
                                                <li>You can modify your interests, choose whether your personal information is used to make ads more relevant to you, and turn on or off certain advertising services.</li>
                                                <li>Control what others see about you across DREABEE services.</li>
                                                <li>Control whom you share information with through your account.</li>
                                                <li>Your Profile allows you to review and control data that’s created when you use DREABEE services, like searches you’ve done. You can browse by date and by topic and delete part or all of your activity.</li>
                                                <li>We also allow you to manage information associated with your Profile. You can manage your contact information, such as your name, email, and phone number.</li>
                                            </ol>

                                            Unregistered influencer can also manage information associated with your browser or device. You are free to manage your preferences about the ads shown to you on Dreabee and on sites and third-party links.</li>

                                        <li>Right to remove & delete your information<br />
                                            You are free to ask us to delete or remove personal data from our Site, where there is no good reason for us continuing to process it.

                                            <ol type='a'>
                                                <li>Delete your content from specific Dreabee services.</li>
                                                <li>Search for and then delete specific items from your Profile/Account.</li>
                                                <li>Delete specific information or activities contained in your profile.</li>
                                                <li> Delete your entire Profile.</li>
                                            </ol>

                                            Unregistered influencers can also delete information associated with you/your personal data by writing to us if there is no good reason for us continuing to process it.
                                            However, it is pertinent to note that we may not always be able to comply with your request for erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>

                                        <li>Right to object
                                            <ol type='a'>
                                                <li>You have every right to object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds [like, to comply with legal obligation or to meet national or security measures] to process your information which override your rights and freedoms. </li>
                                                <li> You can request us to restrict the processing of your personal data when you feel that such processing of information impacts on your fundamental rights and freedoms.</li>
                                            </ol></li>

                                        <li>Right to access and data portability
                                            You have every right to receive your personal information that we have collected and processed in a structured, commonly used, and machine-readable format. Also, you can certainly request us to transfer your personal data to third parties as well where it is technically feasible.
                                        </li>

                                        <li>Right to Opt out :
                                            You are always free to opt out of our Services or third-party Services to stop processing your personal data. To do this, reach out to us at
                                            &nbsp;          <a href="adarsh@digitaladarsh.com">

                                                <u>
                                                    adarsh@digitaladarsh.com
                                                </u>
                                            </a>
                                            <br />
                                            Also, if at any time the Registered influencer would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
                                            However, please note that DREABEE reserves the right to refuse any request to exercise such rights to the extent permitted by applicable law.</li>

                                        <li>Right to Change how cookies collect your information <br />
                                            On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. Please note that if you disable cookies, certain features of our Services will not be available. Cookies may be deleted by following the directions in the “Cookies” section above.
                                            For the Chrome web browser, please visit this page from Google.
                                            For any other web browser, please visit your web browser’s official web pages.</li>

                                        <li>Right to change your preferences for Social Media Integration<br />
                                            You can revoke permission for our Services to receive information from your social networking profile in your social networking service user settings.
                                            Please note that DREABEE reserves the right to refuse any request to exercise such rights to the extent permitted by applicable law.</li>
                                    </ol>



                                </p>

                            </div> */}





                            {/* INFORMATION ABOUT CHILDREN
                            TIME LIMIT TO RESPOND
                            CHANGES OF PURPOSE
                            ADDITIONAL INFORMATION
                            CHANGES TO OUR PRIVACY POLICY */}



                            {/* <div >
                                <h2>
                                    <u>
                                        INFORMATION ABOUT CHILDREN
                                    </u>



                                </h2>
                                <p>
                                    The influencers whose information we collect are generally over the age of 18. The websites we use to collect information about influencers and followers are generally limited to individuals over the age of 18. Accordingly, we do not proactively collect or share information about children. If we become aware that we have collected personal information children under the age of 18 without parental or guardian consent, we will delete this information.
                                    If you have reason to believe that a child under the age of 18 has provided personal information to us, please contact us using the information below.

                                </p>

                            </div>
                           
                            <div >
                                <h2>
                                    <u>
                                        TIME LIMIT TO RESPOND
                                    </u>



                                </h2>
                                <p>
                                    We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In that case, we will notify you and keep you updated.

                                </p>

                            </div>
                           
                            <div >
                                <h2>
                                    <u>
                                        CHANGES OF PURPOSE
                                    </u>



                                </h2>
                                <p>
                                    We will only use your personal data for the purposes for which it was obtained, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal data for an unrelated purpose, we will update this privacy notice and explain the legal basis which allows us to do so.
                                    Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.

                                </p>

                            </div> */}

                            {/* <div >
                                <h2>
                                    <u>
                                        ADDITIONAL INFORMATION
                                    </u>



                                </h2>
                                <p>
                                    For any thoughts or queries about this Privacy Policy, Please let us know by contacting us here or writing to us at the appropriate address below.
                                    The data controller shall be responsible for your personal data, and without prejudice to any other rights you may have), you have the right to do so with our Data Protection Officer or Supervisory authority with an address of:<br />
                                    Data Protection Officer<br />
                                    ADARSH ,<br />
                                    <a href="adarsh@digitaladarsh.com">
                                        <u>
                                            adarsh@digitaladarsh.com
                                        </u>
                                    </a>

                                </p>

                            </div> */}

                            {/* <div >
                                <h2>
                                    <u>
                                        CHANGES TO OUR PRIVACY POLICY
                                    </u>
                                </h2>
                                <p>
                                    Any changes we make to this privacy notice in the future will be posted to our website. Please check back frequently to see any updates or changes to this privacy policy. <br />
                                    Effective as on 02/11/23

                                </p>

                            </div> */}




                            <div >
                                <h2>All the YouTube's policies</h2>

                                <p>
                                    By registering and setting up DREABEE profile, you give us explicit consent to collect, store, process and share your Personal information that is provided by you and we collect about you from various internet sources.<br />
                                    “Personal data, or Personal Information” that we collect includes:<br />




                                    <ul>



                                        <li>
                                            Dreabee is fully compliant with the policy, as it ensures that each API Client is associated with exactly one (1) API Project for creating API Credentials. This guarantees that the credentials are used exclusively by the designated API Client, maintaining adherence to the policy's requirements.
                                        </li>






                                        <li>
                                            Dreabee seamlessly adheres to the policy by ensuring that API Clients prominently display a direct link to YouTube's Terms of Service (<a href='https://www.youtube.com/t/terms'>https://www.youtube.com/t/terms</a>) . Furthermore, Dreabee's own terms of use explicitly state that users agree to be bound by YouTube's Terms of Service upon using the platform, thus demonstrating full compliance with the policy requirements
                                        </li>






                                        <li>
                                            Dreabee ensures strict adherence to the policy by requiring users to agree to a comprehensive privacy policy before accessing its features and functionalities. This privacy policy prominently references and links to the Google Privacy Policy at <a href='http://www.google.com/policies/privacy'>http://www.google.com/policies/privacy</a>, meeting the first requirement. Additionally, for API Clients accessing or using Authorized Data, Dreabee's privacy policy explains that users can revoke the API Client's access to their data via the Google security settings page at <a href='https://security.google.com/settings/security/permissions'>https://security.google.com/settings/security/permissions</a>, thus fulfilling the second requirement of the policy. Dreabee stands as a prime example of compliance with these stipulated guidelines."
                                        </li>






                                        <li>
                                            Dreabee stands in strict compliance with the policy requirements by adhering to the Requirements for Minimum Functionality for YouTube API Services (RMF) without imposing any limitations on the YouTube functionality mandated by the RMF. For instance, if Dreabee facilitates users in uploading videos to YouTube, it ensures that users can set a title for each uploaded video, meeting the RMF criteria. Dreabee refrains from imposing shorter maximum lengths on fields such as the video title, in alignment with YouTube's specifications. This dedication to fulfilling the RMF guidelines showcases Dreabee's commitment to compliance and user experience."
                                        </li>






                                        <li>
                                            Dreabee is fully compliant with the policy stipulations regarding API Client features related to YouTube resources. Each feature within Dreabee that initiates a user action on a YouTube resource is clearly and unmistakably identified as a YouTube action, ensuring transparency and user awareness. These features are distinct from Dreabee's own functionality, maintaining clarity and separation between YouTube actions and the platform's capabilities. Additionally, user-initiated actions are clearly facilitated, emphasizing user control and consent. Dreabee's adherence to these requirements ensures a seamless and compliant user experience, aligning perfectly with the specified policy guidelines."
                                        </li>



                                        <li>
                                            Dreabee ensures compliance with YouTube API Services policies by utilizing the Google Developers Console to create API Credentials for each API Project. These credentials are used exclusively for their designated API Client, preventing any misrepresentation of identity or access. Dreabee strictly adheres to policies prohibiting the masking or misrepresentation of its identity or API Client's identity when accessing or using YouTube API Services, thereby maintaining integrity and compliance."
                                        </li>
                                        <li>
                                            Dreabee demonstrates strong compliance with YouTube's Authentication policies by strictly adhering to the guidelines outlined. It ensures that no user-provided information, including login credentials, is obtained or stored during authentication processes. Moreover, Dreabee obtains user consent in line with applicable laws, only requesting access to authorization scopes that are currently utilized and providing direct benefits to users. By following the principle of incremental authorization, Dreabee ensures transparency and clarity regarding the data access it requests from users, promoting trust and compliance with YouTube's policies.
                                        </li>
                                        <li>
                                            Dreabee wholeheartedly embraces and implements the principles outlined in YouTube's Authentication and Revocation policies. We prioritize user privacy and consent by ensuring that no user-provided information is stored during authentication processes. Our platform strictly adheres to obtaining user consent in accordance with applicable laws, requesting access only to authorization scopes that are actively utilized, and providing transparent benefits to users. We facilitate user understanding by requesting data access in context through incremental authorization. Moreover, Dreabee diligently provides users with a straightforward method to revoke authorization consent, promptly revoking tokens and deleting Authorized Data in compliance with YouTube's guidelines. We are committed to upholding these standards to safeguard user privacy and maintain trust in our platform."
                                        </li>
                                        <li>
                                            Dreabee strictly adheres to the Data Aggregation policies set forth by YouTube. We do not aggregate API Data unless expressly permitted, such as aggregating data from YouTube channels under the same content owner as recognized by YouTube pursuant to content licensing agreements. Any aggregated data is only accessible to the respective content owner. Furthermore, we do not use API Data or YouTube API Services to gain insights into YouTube's usage, revenue, or any other aspects of YouTube's business. Dreabee remains committed to maintaining data integrity and upholding the guidelines outlined in YouTube's policies.
                                        </li>


                                        <li>
                                        Dreabee is fully committed to adhering to the Authorized Data Usage policies outlined by YouTube. We prioritize honesty and transparency regarding the collection, storage, sharing, and usage of user data. Authorized Data is accessed, collected, and utilized strictly in accordance with our privacy policy and user consent obtained through transparent procedures. We ensure that users are promptly notified and prompted to re-accept our privacy policy if any changes occur in the way data is accessed or used. Dreabee clearly identifies any actions taken on behalf of the user and ensures that users expressly consent to such actions before execution. Additionally, we transparently identify the YouTube channel or content owner associated with any authorized request. Our platform also clearly presents any modifications to content visibility settings, ensuring that existing settings are not altered without the user's explicit consent. Dreabee's commitment to these practices underscores our dedication to user trust and data integrity."
                                        </li>

                                        <li>
                                        Dreabee strictly adheres to YouTube's policies regarding Refreshing, Storing, and Displaying API Data. We ensure that authorization tokens and Authorized Data are stored for only as long as necessary and used in accordance with user consent. Data is periodically verified to maintain authorization status and consistency with YouTube's current data. Non-Authorized Data is stored for a limited time, and users have the option to request deletion of stored data, which we promptly fulfill within 7 calendar days. Our platform consistently displays the most updated API Data to users, with accurate presentation of historical data when applicable. Dreabee prioritizes data integrity, user privacy, and compliance with YouTube's policies in all data-related processes."  
                                        </li>



                                        <li>
                                        Dreabee strictly adheres to YouTube's policy regarding the use and display of API Data. We refrain from replacing API Data with independently calculated data or creating new metrics using API Data. Any additional information or metrics displayed alongside API Data are clearly disclosed as not being from YouTube and are part of our own product. Dreabee ensures transparency and accuracy in presenting data to users, maintaining compliance with YouTube's guidelines."
                                        </li>



                                        <li>
                                        Dreabee strictly adheres to YouTube's policy by refraining from collecting or retrieving YouTube data from unauthorized sources. We solely rely on the YouTube API Services for accessing and retrieving YouTube data, ensuring compliance with YouTube's terms and guidelines.
                                        </li>



                                        <li>
                                        Dreabee strictly adheres to YouTube's policy by refraining from offering any API service that allows users to access data or functionality provided by the YouTube API Services directly or indirectly. We prioritize treating members of the YouTube community honestly and respectfully while respecting the integrity of YouTube's services, content, and interests."
                                        </li>



                                    </ul>
                                </p>




                            </div>




                        </div>
                    </div>

                </div>

            </div>
            {/* <CommFoot /> */}

        </>
    )
}

export default PrivacyPolicy