import $ from 'jquery'
import '../../../../Styles/van.css'
import React, { useEffect, useState } from 'react'
import log from '../../../../Imagez/Common/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
// import pik1 from '../../../../Imagez/Common/TayTay.jpg'
import pik1 from '../../../../Imagez/Dashboard/All/wat/profLOgo.jpg'
import { googleLogout } from '@react-oauth/google'
import { baseUrl } from '../../../../Routez/Routey'
import Invite from "../../../../Imagez/Dashboard/All/Invite.png";
import Linee from "../../../../Imagez/Dashboard/All/Line.png";
const Navbhar = () => {
  const boom = () => {
    let slide = document.getElementById("close");
    slide.classList.toggle("open");
  }



  //BRAND ANALYSIS
  const [showBrandz, setShowBrandz] = useState(false);
  const Branz = () => {
    setShow(false);
    setShowNoty(false);
    setShowBrandz(!showBrandz);
  }


  // PROFILE 
  const [show, setShow] = useState(false);
  const now = () => {
    setShow(!show);
    setProShow(false)
    setShowNoty(false)
    setShowBrandz(false);


    // document.getElementById("overlay").style.display = "none";
  }
  const [proShow, setProShow] = useState(false);
  const ProfUp = () => {
    // document.getElementById("overlay").style.display = "block";
    setShow(false)
    setProShow(true)
  }


  // PROFILE 

  const Cross = () => {
    // document.getElementById("overlay").style.display = "none";
    setProShow(false)
    setShowNoty(false)
    setShow(false);
    setShowBrandz(false);
  }

  // NOTIFICATION 
  // ______________________________________________________________________ 

  const [showNoty, setShowNoty] = useState(false);
  const nowty = () => {
    setShow(false);
    setShowNoty(!showNoty);
    setShowBrandz(false);


  }
  // NOTIFICATION

  // CHAT WITH BOT 
  const Pop = () => {
    swal("UNDER MAINTAINANCE ", "✂️", "info");
    setTimeout(() => {
      swal.close()
    }, 3000);

  }

  // ______________________________________________________________________ 

  // Cross Button 
  const Glow = () => {
    document.getElementById("tada").style.color = "#C83B93";
  }
  const GlowEnd = () => {
    document.getElementById("tada").style.color = "#C83B93";
  }
  // Profile Upload 
  const Glow1 = () => {
    document.getElementById("tada1").style.border = "#C83B93 3.5px solid";
    document.getElementById("tada1").style.boxShadow = "0px 15px 25px 0px rgba(0, 0, 0, 0.30)";
  }
  const GlowEnd1 = () => {
    document.getElementById("tada1").style.border = " aqua 3px solid";
    document.getElementById("tada1").style.boxShadow = "0px 10px 20px 0px rgba(0, 0, 0, 0.20)";
  }


  // __________________________________________________________________________
  //  PROFILE PICTURE GET AND PREVIEW 

  const [noImg, setNoImg] = useState(false);
  const PreviewImage = () => {
    var oFReader = new FileReader();
    oFReader.readAsDataURL(document.getElementById("uploadImage").files[0]);

    oFReader.onload = function (oFREvent) {
      document.getElementById("uploadPreview").src = oFREvent.target.result;
    }

  }

  // ______________________________________________________________________ 

  const [ulr, setulr] = useState("")
  // +++++++++++Api2++++++++++++++
  // Details 

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const BrandName = "";
  const Updayte = () => {

    let data = { name, email, phone, ulr };
    axios.post(`${baseUrl}api/Brands//UpdatePage/${BrandName}`, {
      email: data.email,
      phone: data.phone,
      name: data.name,
      photo: data.ulr

    }).then((resp) => {
      swal("You are successfully logged in", "", "success");
    }).catch((error) => {
      console.log("Login Api Error - ", error);
      swal("Oops!", "Maybe a Wrong Entry ,Try Again !! ", "warning");
    })

  }



  // +++++++++++Api3++++++++++++++
  // Password 
  const id = 7522323232;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const RestPress = () => {

    let data = { oldPassword, newPassword, rePassword };
    // console.warn(data.id);

    // axios.patch(`${baseUrl}/api/Admin/resetPasswordAdmin/${identityNo}`, {
    axios.patch(`${baseUrl}api/Brands/resetpassword/${id} `, {
      old: data.oldPassword,
      neew: data.newPassword,
      cpassword: data.rePassword
    }).then((res) => {
      swal("Updated", "Successfully Updated", "success");
      // navigate("/admin/dashboard/");
    }).catch((error) => {
      console.log("error", error);
      swal("Oops!", "Something went wrong! Try Again.", "error");
    })

  }


  // +++++++++++++++++++++++++
  // LOGOUT 
  const navigate = useNavigate();

  const LogOut = () => {
    window.localStorage.removeItem("isLoggedIn");
    // window.localStorage.removeItem("userIn");
    window.localStorage.setItem("userIn", "");
    window.localStorage.removeItem("Access-Id");
    window.localStorage.removeItem("Gmail-Auth");
    googleLogout();

    swal("Logged out!", "You have logged out Successfully, Login Again.", "success");
    navigate("/LogIn");
  }
  // ______________________________________________________________________ 


  const [userz, setUserz] = useState("");
  useEffect(() => {
    setUserz(localStorage.getItem("userIn"))
    console.log(localStorage.getItem("userIn"))

  }, [])



  // const [showTalktoExpert, setShowTalktoExpert] = useState(false);
  // const [hideTalkToExpert, setHideTalkToExpert] = useState("DashHome");


  return (





    <section style={{ zIndex: "1500" }}>

      {/* {
        hideTalkToExpert === "DashHome" ?
          <div className="d-flex justify-content-center" style={{ "position": "fixed", bottom: "2rem", right: "2rem", zIndex: "15" }}>
            <div className='circle grow d-flex align-items-center justify-content-center' onClick={() => setShowTalktoExpert(true)}>


              <i class='bx bx-chat' style={{ fontSize: "26px" }}></i>
              &nbsp;
              <text className="goneText" style={{ fontWeight: "500", fontSize: "20px" }}> Talk to Experts </text>

            </div>
          </div>
          
          
      } */}



      {/* PROFILE UPDATE FORM */}
      {/* ______________________________________________________________________  */}





      {/* PROFILE UPDATE FORM */}
      {/* ______________________________________________________________________  */}
      {
        proShow ?
          <>
            <div id="overlay" style={{
              "position": "fixed", "width": "100%", "height": "100%", "zIndex": "3",
              "top": "", "left": "0", "right": "0", "bottom": "0", "backgroundColor": "rgba(0,0,0,0.5)", "cursor": "default"
            }} >


              <span>
                <section className='py-4 pt-2 ' style={{ "height": "100vh", "width": "24vw", "background": "whitesmoke", "zIndex": "600", "position": "relative", "left": "6.5rem", "top": "0", borderRadius: "5px", "overflow": "scroll", "overflowX": "hidden" }}>
                  {/* HEADER  */}
                  <div className='row mb-1' >
                    <div className='col-lg-10  d-flex align-items-center'>
                      <div className='ps-4 ' style={{ "fontSize": "24px", "fontWeight": "600" }}>Update Profile</div>

                    </div>

                    {/* cross  */}
                    <div className='col-lg-2 d-flex justify-content-start   '>

                      <button className='  ms-0' style={{ "background": "none", "border": "none" }} onClick={Cross} >
                        <i className='bx bx-x-circle  ' id='tada' style={{ "fontSize": "38px", "color": "gray" }} />
                      </button>

                    </div>
                  </div>


                  <hr className='my-0' style={{ "color": "gray", "fontWeight": "700", opacity: "0.18" }} />

                  {/* FORM SECTION   */}
                  <section className=''>

                    {/* Profile picture  */}
                    <section className='mt-3  ps-4 d-flex justify-content-start'>

                      {/* img show  */}
                      <img alt='profpik' src={pik1} id="uploadPreview" style={{ "height": "10rem", "width": "10rem", "border": "3px solid aqua", "borderRadius": "50%", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      {/*icon jump  */}
                      <span style={{ "height": "0px", width: "0px" }}>
                        <i class='bx bxs-camera-plus' onClick={() => { $('#uploadImage').click() }} style={{ "background": "gray", "border": "aqua 3px solid", "color": "white", "cursor": "pointer", "padding": "10px", "borderRadius": "50%", "fontSize": "1.4rem", "position": "relative", "right": "3rem", "top": "7rem", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} id='tada1' onMouseOver={Glow1} onMouseLeave={GlowEnd1}  ></i>
                      </span>
                      {/* reffered input  */}
                      <input id="uploadImage" hidden name="image" type="file" accept=".jpg,.jpeg,.png" onChange={PreviewImage} placeholder="profile_img" />
                    </section>

                    {/* Name / Phone / Email /Photo __ */}
                    <section className='px-2 mt-3' >
                      <h4 className='ps-1 mb-3' style={{ "color": "#C83B93", "fontSize": "22px" }}> Personal Details</h4>


                      {/* Name __ */}
                      <div className='my-2'>
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>Name</section>
                        <input type='text' onChange={(e) => { setName(e.target.value) }} value={name} className='w-100 ps-3' placeholder='username' style={{ "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px", "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      </div>
                      {/* Phone __ */}
                      <div className='my-3' >
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>Phone No.</section>
                        <input type='tel' onChange={(e) => { setPhone(e.target.value) }} value={phone} className='w-100 ps-3' placeholder='+91-28-2928-29' style={{ "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px", "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      </div>
                      {/* Email __ */}
                      <div className='my-2 '>
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>Email</section>
                        <input type='email' onChange={(e) => { setEmail(e.target.value) }} value={email} className='w-100 ps-3' placeholder='useremail@gmail.com' style={{
                          "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px",
                          "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
                        }} />
                      </div>

                      {/* button __ */}
                      <button className='my-2 w-100' style={{
                        "fontSize": "16px", "fontWeight": "600", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px",
                        "border": "0.2px solid whitesmoke", "background": "#C83B93", "color": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
                      }} onClick={Updayte} >Save Changes</button>

                    </section>

                    {/* Password Reset  __ */}
                    <section className='mt-5 ps-2 mb-2' >
                      <h4 className='ps-1 mb-4' style={{ "color": "#C83B93", "fontSize": "22px" }}> Password Setting</h4>
                      {/* Old Password __ */}
                      <div className='my-1 '>
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>Old Password</section>
                        <input type='text' onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} className='w-100 ps-3' placeholder='monkey luffy' style={{ "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px", "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      </div>
                      {/* New Password __ */}
                      <div className='my-3 '>
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>New Password</section>
                        <input type='text' onChange={(e) => { setNewPassword(e.target.value) }} value={newPassword} className='w-100 ps-3' placeholder='monkey d luffy' style={{ "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px", "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      </div>
                      {/* New Re-Password __ */}
                      <div className='my-2 '>
                        <section className='ms-3' style={{ "fontWeight": "500", "fontSize": "15px", "color": "gray" }}>New Re-Password</section>
                        <input type='text' onChange={(e) => { setRePassword(e.target.value) }} value={rePassword} className='w-100 ps-3' placeholder='monkey d luffy' style={{ "fontSize": "16px", "fontWeight": "400", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px", "border": "0.2px solid whitesmoke", "background": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)" }} />
                      </div>
                      {/* button __ */}
                      <button className='my-2 w-100' style={{
                        "fontSize": "16px", "fontWeight": "600", "maxWidth": "21rem", "height": "2.6rem", "borderRadius": "20px",
                        "border": "0.2px solid whitesmoke", "background": "#C83B93", "color": "white", "boxShadow": "0px 10px 20px 0px rgba(0, 0, 0, 0.20)"
                      }} onClick={RestPress} >Update Password</button>



                    </section>


                  </section>


                </section>
              </span>
            </div>
          </>
          : null
      }




      {/* NOTIFIICATION SECTION */}
      {/* ______________________________________________________________________  */}
      {
        showNoty ?
          <>
            <div id="overlay" onClick={Cross} style={{
              "position": "fixed", "width": "100%", "height": "100%", "zIndex": "2",
              "top": "0", "left": "0", "right": "0", "bottom": "0", "backgroundColor": "rgba(0,0,0,0.5)", "cursor": "default"
            }}    >

              <div>
                {/* Update Section  */}
                <section style={{ "height": "100vh", "width": "40vw", "background": "whitesmoke", "zIndex": "600", "position": "relative", "left": "6.5rem", "top": "0rem", "overflow": "scroll", "overflowX": "hidden" }}>

                  <section className='row  my-2'>
                    {/* Notification  */}
                    <div className='col-3 '>
                      <span className='ps-3' style={{ "fontSize": "26px", "fontWeight": "600" }}>Notification</span>
                    </div>

                    {/* unread */}
                    <div className='col-4 text-end ' style={{ "borderRight": "gray solid  1px  ", "cursor": "pointer" }}>
                      <span style={{ "fontSize": "14px", "color": "#666666", "fontWeight": "600" }}>
                        Only show unread
                        &nbsp;
                        <span className='' style={{ "position": "relative", "top": "6px" }}><i class='bx bx-toggle-left' style={{ "fontSize": "26px" }} />
                        </span>
                      </span>
                    </div>

                    {/* All read  */}
                    <div className='col-3 text-start  d-flex align-items-center' >
                      <span style={{ "fontSize": "14px", "color": "#666666", "fontWeight": "600", "cursor": "pointer" }}>Mark all as read</span>
                    </div>

                    {/* X-Button */}
                    <div className='col-2   text-end'  >
                      <span style={{ "height": "0px", "width": "0px" }}>
                        <button className=' me-2 ' style={{ "background": "none", "border": "none" }} onClick={Cross} >
                          <i class='bx bx-x-circle ' id='tada' onMouseOver={Glow} onMouseLeave={GlowEnd} style={{ "fontSize": "38px", "color": "gray" }} />
                        </button>

                      </span>


                    </div>

                  </section>

                  <hr className='my-0' style={{ "color": "gray", "fontWeight": "700" }} />

                  <section style={{ "height": "80vh" }}>

                    <section className='row h-100 w-100 d-flex align-items-center  justify-content-center'>
                      <section className=' d-flex align-items-center  justify-content-center flex-column'>
                        <section><i class='bx bx-bell' style={{ "fontSize": "10rem", "color": "#6666" }}></i></section>
                        <h5 style={{ "color": "#6666" }}>You don’t have any notification yet.</h5>
                        {/* <i class='bx bxs-camera-plus' style={{ "background": "gray", "color": "#C83B93", "padding": "10px", "borderRadius": "50%", "fontSize": "1.4rem", "position": "", "left": "-3rem", "top": "-1.2rem" }} ></i> */}

                      </section>
                    </section>

                  </section>
                </section>

              </div>

            </div>
          </>
          : null
      }
      {/* ____________________BRAND ANALYSISIs__________________________________________________  */}

      {/* BRAND ANALYSIS POP-UP OPTIONS */}
      {
        showBrandz ?
          <>
            {/* ______________________________________________________________________ */}

            <div id="overlay" onClick={Cross} style={{
              "position": "fixed", "width": "100%", "height": "100%", "zIndex": "2",
              "top": "0", "left": "0", "right": "0", "bottom": "0", "backgroundColor": "rgba(0,0,0,0.3)", "cursor": "default"
            }} >


              <section className='py-2 px-3 ' style={{ "position": "relative", "borderRadius": "20px", "border": "#6666 2px solid", "top": "24rem", "left": "6.5rem", "maxHeight": "9rem", "maxWidth": "16rem", "background": "white" }}>

                {/* Brands  */}
                <span className='row w-100 pb-3 ps-1' style={{ "height": "18px", "cursor": "pointer" }}>
                  <section className='col-12 '>
                    <Link className=' w-100 d-flex bg- align-items-center  justify-content-start ' onClick={Cross} to="BrandSites" style={{ "textDecoration": "none" }} >
                      <i class='bx bx-loader-circle' style={{ "color": "black", "fontSize": "18px" }} />
                      &nbsp;
                      <text style={{ "fontSize": "18px", "fontWeight": "600", "color": "#C83B93" }}>Brands</text>
                    </Link>
                  </section>
                </span>

                <hr />
                {/* Comp Research  */}
                <span className='row w-100 pb-3 ps-1' style={{ "height": "16px", "cursor": "pointer" }}>
                  <section className='col-12'>
                    <Link className=' w-100 d-flex bg- align-items-center  justify-content-start h-100' to="BrandCompetitor" onClick={Cross} style={{ "textDecoration": "none" }} >
                      <i class='bx bx-pie-chart-alt-2 bg-' style={{ "color": "black", "fontSize": "18px", }}></i>
                      &nbsp;
                      <text style={{ "fontSize": "18px", "fontWeight": "600", "color": "#C83B93" }}>Competitor Research</text>
                    </Link>
                  </section>
                </span>

                <hr />
                {/* Games  */}
                <span className='row w-100  ps-1' style={{ "height": "16px", "cursor": "pointer" }}>
                  <section className='col-12  '>
                    <Link className=' w-100 d-flex bg- align-items-center  justify-content-start h-100' to="BrandGames" onClick={Cross} style={{ "textDecoration": "none" }} >
                      <i class='bx bx-game' style={{ "color": "black", "fontSize": "18px" }}></i>
                      &nbsp;
                      <text style={{ "fontSize": "18px", "fontWeight": "600", "color": "#C83B93" }}>
                        Games
                      </text>
                    </Link>
                  </section>
                </span>
                {/* cross  */}
                <span className='' style={{ height: "0px", "width": "0px" }}>
                  <button className=' ' style={{ "background": "none", "border": "none", "zIndex": "25", "height": "0px", "position": "relative", "top": "-7.5rem", "left": "12.8rem" }} onClick={Cross} > <i class='bx bx-x ' id='tada' onMouseOver={Glow} onMouseLeave={(e) => { document.getElementById("tada").style.color = "#6666"; }} style={{ "fontSize": "26px", "color": "black" }} /> </button>
                </span>
              </section>
            </div>
          </> : null
      }



      <div className="sidebar close" style={{ "zIndex": "2", display: "flex", flexDirection: "column", justifyContent: "space-between", "alignContent": "center" }} id='close'>
        <section>
          {/* LOGO  */}
          <div className="logo-details">
            <img src={log} style={{ "width": "3rem", "paddingTop": "2rem", "marginLeft": "8px" }} alt='img' />
          </div>


          <hr />


          {/* UPPER SECTION NAVBAR  */}
          <div>
            <ul className='nav-list'>

              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} >
                <Link to="Home" >
                  {/* <i class='bx bx-home bx-sm'></i> */}
                  <img src={require('../../../../Imagez/Dashboard/All/home.png')} style={{ width: '30px', height: '20px', margin: '15px -3px', marginTop: "20px" }} />
                  <span className="links_name ">ashboard</span>
                </Link>
                <div className="tooltip" style={{ zIndex: "999" }}>Dashboard</div>
              </li>

              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} >
                <Link to="Search" >
                  {/* <i className='bx bx-search-alt' ></i> */}
                  <img src={require('../../../../Imagez/Dashboard/All/search.png')} style={{ width: '30px', height: '20px', margin: '15px -3px' }} />
                  <span className="links_name">Search</span>
                </Link>
                <span className="tooltip">Search</span>
              </li>

              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} >
                <Link to="Plans" >
                  {/* <i className="bx bx-pie-chart-alt-2"></i> */}
                  <img src={require('../../../../Imagez/Dashboard/All/plan_icon.png')} style={{ width: '20px', height: '20px', margin: '15px -3px' }} />

                </Link>
                <span className="tooltip">Plan</span>
              </li>

              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} >
                <Link to="Report" >
                  {/* <i className='bx bx-task'></i>  */}
                  <img src={require('../../../../Imagez/Dashboard/All/report.png')} style={{ width: '20px', height: '20px', margin: '15px -3px' }} />
                </Link>
                <span className="tooltip">Reports</span>

              </li>

              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} >
                <Link to="Campagin"  >
                  {/* <i className='bx bx-pin'></i> */}
                  <img src={require('../../../../Imagez/Dashboard/All/fluent_megaphone-loud-24-regular.png')} style={{ width: '20px', height: '40px', margin: '5px 0' }} />
                </Link>
                <span className="tooltip">Campaigns</span>
              </li>

              <Link to="BrandCompetitor" >
                <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} style={{ "cursor": "pointer" }}
                  // onClick={() => { Branz();}} 
                  onClick={Cross}
                >
                  <img src={require('../../../../Imagez/Dashboard/All/pie.png')} style={{ width: '20px', height: '25px', margin: '15px -3px' }} />
                  <span className="tooltip">Brand Analysis</span>
                </li>
              </Link>

            </ul>
          </div>
        </section>


        {/* ICONS */}
        {/* <div className='  ' style={{'height': "85%","display": "flex", "flexWrap": "wrap", justifyContent: "center", flexWrap: "wrap", alignItems: "center"}}></div> */}




        {/* LOWER SECTION NAVBAR  */}
        <section>
          {/* notifications */}
          <div className=''>
            <ul className='nav-list'>
              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} style={{ "marginTop": "2rem", "cursor": "pointer" }}>
                <i className='bx bx-bell' onClick={nowty}></i>
                <span className="tooltip">Notifications</span>
              </li>

              {/* chat  */}
              <li className={localStorage.getItem("userIn") != "influencer" ? "showLinks" : "hideLinks"} onClick={Pop}>
                {/* <i className='bx bx-conversation'></i> */}
                <img src={require('../../../../Imagez/Dashboard/All/chat.png')} style={{ width: '20px', height: '35px', margin: '5px 0' }} />
                <span className="tooltip">Chat with Us</span>
              </li>


              {/* profile  */}
              {
                localStorage.getItem("userIn") != "influencer"
                  ?
                  <li style={{ "cursor": "pointer" }}>
                    <i class='bx bx-user' onClick={now}></i>
                    <span className="tooltip">Profile</span>
                  </li>

                  :
                  <>
                    <li style={{ "cursor": "pointer" }}>
                      <i class='bx bx-log-out-circle' onClick={LogOut} style={{ "fontSize": "24px", position: "relative", left: "-5px" }}></i>
                      <span className="tooltip">Logout</span>
                    </li>
                  </>
              }


              {/* PROFILE POP-UP  */}
              {
                show ?
                  <>

                    <section className='p-3 ps-4 ms-2 Kardz' style={{ "position": "relative", "borderRadius": "10px", "border": "#6666 2px solid", "top": "-9.8rem", "left": "1.9rem", "height": "11.5rem", "width": "20rem", "background": "white" }}>

                      <span className={localStorage.getItem("userIn") != "influencer" ? "showLinks  row" : "hideLinks row"} style={{ "height": "40px" }}>
                        <section className='col-2 pt-3' > <i class='bx bx-user' style={{ "color": "black", "fontSize": "24px", "lineHeight": "0px" }} /> </section>
                        <section className='col-8' >
                          <section style={{ "color": "#C83B93", "fontSize": "17px", "marginBottom": "-8px" }}>Monkey D Luffy</section>
                          <span style={{ "fontSize": "13px", "marginTop": "", "fontWeight": "600", "color": "gray" }}>KingofPirates@gmail.com</span>

                        </section>
                      </span>

                      <hr />
                      {/* <Link to="ProfileUp" style={{"textDecoration":"none","color":"black"}}> */}
                      <span className={localStorage.getItem("userIn") != "influencer" ? "showLinks  row" : "hideLinks row"} onClick={ProfUp} style={{ "height": "20px", "cursor": "pointer" }}>
                        <section className='col-2 pt-1' > <i class='bx bx-loader-circle' style={{ "color": "black", "fontSize": "24px", "lineHeight": "0px" }} /> </section>
                        <section className='col-10 '>
                          <h5 style={{ "fontSize": "16px", "fontWeight": "400" }}>Update profile</h5>
                        </section>
                      </span>
                      {/* </Link> */}

                      <hr />
                      <span className='row ' onClick={LogOut} style={{ "height": "30px", "cursor": "pointer" }}>
                        <section className='col-2' style={{ "paddingTop": "2px" }} >  <i class='bx bx-log-out-circle' style={{ "color": "black", "fontSize": "24px", "lineHeight": "0px" }} /> </section>
                        <section className='col-10 '>
                          <h5 style={{ "fontSize": "16px", "fontWeight": "400" }}>Logout</h5>
                        </section>
                      </span>


                    </section>
                    <button style={{ "background": "none", "border": "none", "zIndex": "25", "position": "relative", "top": "-22rem", "left": "20rem" }} onClick={Cross} > <i class='bx bx-x ' id='tada' onMouseOver={Glow} onMouseLeave={(e) => { document.getElementById("tada").style.color = "#6666"; }} style={{ "fontSize": "26px", "color": "black" }} /> </button>

                  </>
                  :
                  null
              }
            </ul>
          </div>
        </section>


      </div>
    </section>
  )

}

export default Navbhar