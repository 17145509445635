import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Invite from "../../../../../Imagez/Dashboard/Iconz/AddToList-Pink.png";
import Costz from "../../../../../Imagez/Dashboard/Iconz/Cost-gray.png";
import Contactz from "../../../../../Imagez/Dashboard/Iconz/Contact-gray.png";



import log from '../../../../../Imagez/Common/logo.png'
// import log from '../../../../../Imagez/Common/jasonderulo.jpg'
import profiler from "../../../../../Imagez/Dashboard/All/Profile.png";
import GRaph from "../../../../../Imagez/Dashboard/All/Pie Chart.png";
import Pepsi from "../../../../../Imagez/Dashboard/All/Pepsico.png";
// import ProfileSumm from "./ProfileSumm";
import { Link } from "react-router-dom";
// import ProfilerRou from "../../../../Routez/ProfilerRou";
import axios from "axios";
import { baseUrl2 } from "../../../../../Routez/Routey";
import ProfileSumm from "../../Profile_Profile/ProfileSumm";
import ProfilerRou from "../../../../../Routez/ProfilerRou";
import InfluencerProfileSummary from "./InfluencerProfileSummary";
import InfluencerInstagramSummary from "./InfluencerInstagramSummary";
import InfluencerYoutubeSummary from "./InfluencerYoutubeSummary";
import swal from "sweetalert";
import { FadeLoader } from "react-spinners";
const InfluencerProfile = ({ }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const [influencerData, setInfluencerData] = useState([]);
    const [showOpz, setShowOpz] = useState(false)
    const [profileDataY, setProfileDataY] = useState([]);
    const [profileDataI, setProfileDataI] = useState([]);
    const [ming, setMing] = useState("");
    const [graphApi, setrGaphApi] = useState();

    useEffect(() => {
        // console.log("the location state from the profile is  : ", location.state)
        // console.log("the one any oiny : ", localStorage.getItem("UserName"))
        // ProfileDetailsY();
        setLoadering(false)
        setMing(localStorage.getItem("UserName"))
        setrGaphApi(localStorage.getItem("graphApi"))
        console.log("innn")


        setTimeout(() => {
            setLoadering(true)
        }, 2000);
        setTimeout(() => {
            // window.location.reload(false);
        }, 200);



        // setTimeout(() => {
        //     ProfileDetailsI();
        // }, 4000);


        setTimeout(() => {
            Api3();
        }, 5000);
    }, []);








    // YOUTUBE 
    const ProfileDetailsY = () => {
        // console.log("the youtube end ", location.state.keyWord)
        axios.post(`${baseUrl2}/youtube/profile-details`, {
            name: localStorage.getItem("UserName")
        }).then((res) => {
            // console.log("THE YOUTUBER API PROFILE DATA -", res.data)
            setProfileDataY(res.data)

            const inputString = res.data.cid;
            const match = inputString.match(/YT:(\S+)/);
            if (match) {
                const extractedValue = match[1];
                // console.log("The Channel Id", extractedValue);
                localStorage.setItem("channelId", extractedValue);
            } else {
                console.log("No match found");
            }

            console.log("proff Yooutube- > ", res.data)
            // localStorage.setItem("channelId", handled);
            // localStorage.setItem("videoId", handled);
        }).catch((err) => {
            console.log("the profile utube page errorr - ", err)
        })
    }


    // INSTAGRAM 
    const ProfileDetailsI = () => {
        // console.log("the insta end ", location.state.keyWord)
        axios.post(`${baseUrl2}/instagram/profile-details`, {
            name: localStorage.getItem("UserName")
        }).then((res) => {
            // console.log("THE Instagram API PROFILE DATA -", res.data)
            setProfileDataI(res.data)
        }).catch((err) => {
            console.log("the profile insta page errorr - ", err)
        })
    }


    const [page, setPage] = useState("Profile")

    // const desc = profileDataY?.description.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")

    const LogOut = () => {
        window.localStorage.removeItem("isLoggedIn");
        window.localStorage.removeItem("graphApi");
        window.localStorage.removeItem("userIn");
        window.localStorage.removeItem("Access-Id");
        window.localStorage.removeItem("UserName");
        window.localStorage.removeItem("TheGraphId");
        window.localStorage.removeItem("AccessTokken");

        swal("Logged out!", "You have logged out Successfully, Login Again.", "success");
        navigate("/LogIn");
    }


    // --------------Graph Apis---------------
    const [profileData3, setProfileData3] = useState([])
    const Api3 = () => {
        axios.get('https://graph.facebook.com/v19.0/17841433401221135? fields=name%2Cfollows_count%2Cfollowers_count%2Cbiography%2Cid%2Cusername%2Cwebsite%2Cmedia_count%2Cprofile_picture_url&access_token=EAACyZBrKYZC58BO2wGk8ZBEL4ZAbPHliXgNLCQmLy7TdBCyyvKhEeStiakPwRzS0Sn8Gi1KLSZByajpmHSFZCZBBaIhXBZCZBZAbCDZBvp0ZC9gCZC12mq6S8rYL2hQZA62Hj9uhk7lgjyttTXA0ktupp4BDZAsYyDZCT9CDtSQU3Qs9gzjMMxxZC31hwCGlr9V9fsB8ODPJ4PGyonYAZC')
            .then((response) => {
                // console.log("THE RESPONSE INSIDE API #3", response.data);
                console.log("Profile section passed")
                setProfileData3(response.data)

                setTimeout(() => {
                    // console.log("WEHOOO HOOO", profileData3)
                }, 5000);
            }).catch((error) => {
                console.log("The Inside Api 3 error - ", error)
            });

    }


    const showww = () => {
        console.log("the dtata i want  : ", profileData3.profile_picture_url)
    }

    const [loadering, setLoadering] = useState(false);

    return (
        <>

            {
                loadering
                    ?
                    <div className="container-fluid  " style={{ "height": "100vh", overflow: "hidden" }}>
                        <div className="row d- justify-content " style={{ float: "none", margin: "auto" }}>
                            <div className="col-12  my-2  rounded" style={{ backgroundColor: "black", borderRadius: "30px" }}>

                                <div className=" bg- rounded w-100 py-2 ">
                                    <section className="row mx-1 ">
                                        <div className="col-6 bg-">

                                            <div className="logo-details">
                                                <img src={log} className="rounded-circle" style={{ "width": "3rem", "paddingTop": "0rem", "marginLeft": "0px" }} alt='img' />
                                            </div>

                                        </div>

                                        <div className="col-6 ">

                                            <section className=" d-flex justify-content-end align-items-center"
                                                onClick={LogOut}
                                                style={{ cursor: "pointer" }}>

                                                <i class='bx bx-log-out-circle outlog-btn' />
                                                &nbsp;
                                                <span className="outlog-btn-txt  text-light">Logout</span>
                                            </section>


                                        </div>
                                    </section>
                                </div>


                            </div>
                        </div>

                        <div className="row bg-">
                            <div className="col mx-2 my-4 mt-2 mb-0 pb-0 "
                                style={{ background: "white", borderRadius: " 10px", boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)", minHeight: "12vw", width: "90.5vw" }}  >


                                {/* ROW ! - PROFILE ONLY  */}
                                <div className="row ">
                                    {/* IMAGES  */}
                                    <div className="col-2  mt-2 mt-lg-3  text-end " onClick={() => { showww() }}>

                                        {
                                            graphApi
                                                ?
                                                <img className="img-fluid" src={profileData3?.profile_picture_url} alt="profiler" style={{ borderRadius: "50%", border: "1px solid #c83b93", width: "10vw" }} />
                                                :
                                                <img className="img-fluid" src={require("../../../../../Imagez/Common/jasonderulo.jpg")} alt="profiler" style={{ borderRadius: "50%", border: "1px solid #c83b93", width: "10vw" }} />
                                        }
                                    </div>


                                    {/* TEXTS */}
                                    <div className="col-5 p-2  pb-0 pt-4 mt-2   d-flex flex-column ">

                                        <div className="" >
                                            {/* LINE 1 */}
                                            <section className="d-flex  align-items-center">
                                                <h6 className="" style={{ fontWeight: "700", fontSize: "24px" }}>
                                                    {
                                                        graphApi
                                                            ?
                                                            <>
                                                                {profileData3?.name}
                                                            </>
                                                            :
                                                            <>
                                                                jasonderulo
                                                            </>
                                                    }
                                                    {/* {ming} */}
                                                    {/* {influencerData?.name} */}
                                                    {/* {profileDataY?.name} */}
                                                </h6>

                                                {
                                                    graphApi
                                                        ?
                                                        null
                                                        :
                                                        <>
                                                            <span className="py-1 me-2 px-2 mx-2 border" style={{ fontWeight: "600", fontSize: "13px", background: "#F5F5F5", borderRadius: " 5px", textTransform: "capitalize" }}> Entertainment   </span>
                                                            <span className="py-1 me-2 px-2 mx-2 border" style={{ fontWeight: "600", fontSize: "13px", background: "#F5F5F5", borderRadius: " 5px", textTransform: "capitalize" }}>  Influencer  </span>

                                                        </>
                                                }

                                            </section>

                                            {/* LINE 2 */}
                                            <section className="my-2" style={{ fontSize: "14px", fontWeight: "600", lineHeight: "25px", textTransform: "capitalize" }}>
                                                {" "}
                                                {/* <i className="bx bx-current-location"></i> */}
                                                <i class="fa-solid fa-location-dot"></i>
                                                &nbsp;
                                                {/* {profileDataY?.country}                                          {profileDataY?.city}  */}
                                                {
                                                    graphApi
                                                        ?
                                                        <>
                                                            {profileData3?.biography}
                                                        </>
                                                        :
                                                        <>
                                                            Florida  &nbsp; United States
                                                        </>
                                                }


                                            </section>
                                        </div>


                                        {/* ROW 2 - SOCIAL  HANDELS  */}
                                        <div className="row  ms-5   " >


                                            {/* Button 1  */}
                                            <div className={page === "Profile" ? "col-4 pb-2 mt-1 btn-on text-center" : "col-4 pb-2 mt-1 btn-off  text-center"} >
                                                {/* <Link to={`Summary/${"@" + ming}`} id="btn_1" >
                                            {" "}
                                        </Link> */}
                                                <button
                                                    className="btn_1"
                                                    // onClick={flag1}
                                                    onClick={() => { setPage("Profile") }}
                                                    style={{ background: "transparent", border: "none" }}>
                                                    <i class='bx bx-user'
                                                        id={page === "Profile" ? "icon-on" : "icon-off"}
                                                        style={{
                                                            fontSize: "26px",
                                                            fontWeight: "600",
                                                            // color: "#C83B93",
                                                        }}
                                                    ></i>
                                                    <section
                                                        className="d-none d-md-block"
                                                        id={page === "Profile" ? "txt-on" : "txt-off"}
                                                        style={{
                                                            marginTop: "2px",
                                                            fontWeight: "600",
                                                            // color: "#C83B93",
                                                        }}
                                                    >
                                                        Profile Summary
                                                    </section>
                                                </button>{" "}
                                            </div>


                                            {/* Button 2  */}
                                            <div className={page === "InstaProfile" ? "col-4 btn-on  pb-2 mt-1 text-center" : "col-4 btn-off   pb-2 mt-1 text-center"}  >
                                                {/* <Link to={`Instagram/${"@" + ming}`}>
                                            {" "}
                                        </Link> */}
                                                <button
                                                    // onClick={flag2}
                                                    onClick={() => { setPage("InstaProfile") }}

                                                    style={{ background: "transparent", border: "none" }} >
                                                    {/* <i class='bx bxl-instagram' id={page === "InstaProfile" ? "icon-on" : "icon-off"} style={{ fontSize: "28px", fontWeight: "600" }} ></i> */}

                                                    <img width="30" height="30" id={page === "InstaProfile" ? "icon-on" : "icon-off"} src="https://img.icons8.com/3d-fluency/94/instagram-new.png" alt="instagram-new" />
                                                    <section id={page === "InstaProfile" ? "txt-on" : "txt-off"} style={{ fontWeight: "600" }}>
                                                        @ {
                                                            graphApi
                                                                ?
                                                                <>
                                                                    {profileData3?.username}
                                                                </>
                                                                :
                                                                <>
                                                                    jasonderulo
                                                                </>
                                                        }
                                                    </section>
                                                </button>
                                            </div>


                                            {/* Button 3 */}
                                            <div className={page === "UtubeProfile" ? "col-4 btn-on pb-2 mt-1 text-center" : "col-4 btn-off  pb-2 mt-1 text-center"}  >
                                                {/* <Link to={`Youtube/${"@" + ming}`}>
                                            {" "}
                                        </Link> */}
                                                <button
                                                    // onClick={flag3}
                                                    onClick={() => { setPage("UtubeProfile") }}
                                                    style={{ background: "transparent", border: "none" }}
                                                >
                                                    {/* <i
                                                        className="bx bxl-youtube"

                                                        style={{ fontSize: "30px", fontWeight: "600", color: "" }}
                                                    ></i> */}
                                                    <img width="30" height="30" id={page === "UtubeProfile" ? "icon-on" : "icon-off"} src="https://img.icons8.com/3d-fluency/100/youtube-play.png" alt="youtube-play" />
                                                    <section id={page === "UtubeProfile" ? "txt-on" : "txt-off"} style={{ fontWeight: "600", color: "" }}>
                                                        @{
                                                            graphApi
                                                                ?
                                                                <>
                                                                    {profileData3?.username}
                                                                </>
                                                                :
                                                                <>
                                                                    jasonderulo
                                                                </>
                                                        }


                                                    </section>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            {/* _________________________________________________ */}
                            {/* <ProfileSumm /> */}
                            {/* <ProfilerRou influencerData={profileDataY}/> */}

                        </div>

                        {

                            page === "Profile"
                                ?
                                <>
                                    <InfluencerProfileSummary />
                                </>
                                :
                                <>
                                    {
                                        page === "InstaProfile"
                                            ?
                                            <InfluencerInstagramSummary />
                                            :
                                            <InfluencerYoutubeSummary />
                                    }
                                </>



                        }

                    </div>

                    :
                    <>
                        (
                        <section className='' style={{ "height": "100vh", "width": "100vw", "background": "whitesmoke" }} >
                            <div className=' d-flex justify-content-center align-items-center  h-100'>

                                <FadeLoader
                                    color={"#C83B93"}
                                    size={140}
                                />
                            </div>
                        </section>
                        )
                    </>
            }


        </>

    );
};


export default InfluencerProfile