import React, { useRef } from 'react'
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

const FlowerPhoto1 = () => {
    const navigate = useNavigate();
    const Go = () => {
        navigate("/SignUp")
    }
    const Logyn = () => {
        navigate("/LogIn")
    }
    const textRef = useRef(null);
    // REFFF 1
    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger the animation once
        threshold: 0.4, // Trigger when at least 50% of the element is in view

    });
    return (
        <>
            <section className={`fade-in-text ${inView ? 'fade-in d-flex justify-content-end ' : ''}`} ref={ref}>

                <span className='pt-5' style={{"width":"600px", position:"" , top : "30px"}}>
                    <h4 style={{ "position": '', "width": "", "left": "", "top": "-6rem", "fontWeight": "700", "fontSize": "40px", "lineHeight": "40px", "color": "#C83B93" }}>
                        Your search for the perfect creators ends now. </h4>
                    <p style={{ "position": "", "width": "", "left": "", "top": "-6rem", "fontWeight": "600", "fontSize": "20px", "lineHeight": "30px", "color": "#FFFFFF", "marginTop": "1rem" }}>
                        Build brand awareness & long-lasting customer relationships while driving sales with creator collaborations.Get started today.</p>



                    <span style={{ "position": "", "top": "-4rem" }}>

                        <button className=' revCol' onClick={Go} style={{ "width": "419px", "height": "64px", "backgroundColor": "transparent", "border": "2px solid #D12C8B", "borderRadius": "500px", "marginTop": "" }}>
                            <span className='revColText' style={{ "fontWeight": "700", "fontSize": "24px", "lineHeight": "34px", "textAlign": "center", "color": "#C83B93", }}>Start now to Get Free Access <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i> {/* <i className="fa-solid fa-arrow-up-right-from-square" style={{"color":"white"}}></i> */}</span>
                        </button>

                        
                        <button className='btnz' onclick={() => { navigate("/LogIn") }} style={{ "width": "348px", "height": "64px", "backgroundColor": "white", "border": "2px solid #D12C8B", "borderRadius": "500px", "marginTop": "2rem" }}>
                            <span onclick={Go} style={{ "fontWeight": "700", "fontSize": "24px", "lineHeight": "34px", "textAlign": "center", "color": "#C83B93" }}>Post a Campaign Brief <i style={{ "color": "#C83B93" }} className="fa-solid fa-arrow-trend-up"></i> {/* <i className="fa-solid fa-arrow-up-right-from-square" style={{"color":"white"}}></i> */} </span>
                        </button>
                    </span>

                    
                </span>

            </section>
        </>
    )
}

export default FlowerPhoto1






