import React, { useState } from 'react'

export default function Video_analysis() {

    const [filfil, setFilfil] = useState(false)

    return (
        <div className='bg-light rounded px-3 ps-5 pt-3 mt-3 mb-5 ' style={{ "paddingBottom": "5rem" }}>
            {/* search box */}
            <div className='d-flex flex-content-between align-items-center w-100'>
                <div className='brand_search'>
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type='text' />
                </div>

                <div className='mx-3'>

                    <button onClick={() => { setFilfil(!filfil) }} className='brand_filter_btn   d-flex align-items-center justify-content-evenly py-3'>
                        Filters
                    </button>

                </div>
            </div>

            {/* Filter section */}
            <div className='d-flex filter_select_option mt-3 w-100'>
                <select className='active_search_filter'>
                    <option>
                        Views
                    </option>
                </select>

                <select>
                    <option>
                        Subscribers
                    </option>
                </select>

                <select>
                    <option>
                        Publication Date
                    </option>
                </select>

                <select>
                    <option>
                        Price
                    </option>

                </select>

                <select>
                    <option>
                        Influencer's Country
                    </option>
                </select>

                <select>
                    <option>
                        Broadcast Language
                    </option>
                </select>

                <select>
                    <option>
                        Video Type
                    </option>
                </select>
            </div>

            {/* Cards Row Section */}
            {/* ____1___ */}
            <div className=' row mt-4 text-center'>
                <div className='col-lg-3'>
                    <div className=''>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>

                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


            </div>
            {/* ---2--- */}
            <div className=' row mt-4 text-center'>
                <div className='col-lg-3'>
                    <div className=''>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>

                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


            </div>
            {/* --3--  */}
            <div className=' row mt-4 text-center'>
                <div className='col-lg-3'>
                    <div className=''>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" style={{ "color": "#6c757d" }} />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>

                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>



                <div className='col-lg-3'>
                    <div>
                        <img src={require('../../../../Imagez/Dashboard/All/blank.png')} style={{ "borderRadius": "15px" }} width={280} />
                    </div>
                    <div>
                        Always Take Calculated Risk | Sandeep Maheshwari | Hindi
                    </div>

                    <div>
                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>

                                {/* <i class="fa fa-play-circle" aria-hidden="true"></i> */}
                                <img width="18" height="18" src="https://img.icons8.com/ios/50/play--v1.png" alt="play--v1" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            <span className='d-flex align-items-center'>
                                {/* <i class="fa fa-thumbs-up"></i> */}
                                <img width="20" height="20" src="https://img.icons8.com/windows/32/thumb-up.png" alt="thumb-up" />
                                {""}
                                1.2 M
                            </span>
                        </button>

                        <button className='btn text-secondary'>
                            {/* <i class="fa fa-comment" aria-hidden="true"></i> */}
                            <img width="18" height="18" src="https://img.icons8.com/ios/50/comments--v1.png" alt="comments--v1" />
                            &nbsp;
                            7.9 K
                        </button>
                    </div>
                </div>


            </div>

        </div>
    )
}
