import React from 'react'
const Kard1 = () => {
  return (
    <>

      <section className='py-2 px-2 Kardz' style={{ "minHeight": "15rem ", "width": "22rem", "borderRadius": "40px", "backgroundColor": "white", border: "1px solid aqua", "boxShadow": "0 4px 30px rgba(0, 0, 0, 0.4)" }}>
        <section className='w-100 text-center'>
          <section className='mulaHex2 text-center '></section>

        </section>


        <section className='text-start px-3' style={{ "marginLeft": "", "position": "", "top": "-0.8rem" }}>
          <h7 style={{ "fontWeight": "500", "fontSize": "14px" }}>Agency</h7>
          <h5 style={{ "fontWeight": "700", "fontSize": "18px" }}>Find relevant Influencers faster</h5>
          <p style={{ "color": "#7E7E7E", "fontSize": "13px", "fontWeight": "600", "width": "", flexWrap: "wrap" }}>Find influencers from your niche faster. Don’t compromise time by googling the list of influencers or waiting for the influencer list from an external agency.</p>


          <section style={{ "position": "relative", "top": "-0.5rem" }}>
            <span><u style={{ "color": "#D461A8", "fontWeight": "500", "fontSize": "14px" }}>Read More</u></span>
            <button style={{ "width": "7rem", "height": "2rem", "fontSize": "14px", "color": "#D461A8", "backgroundColor": "", "fontWeight": "500", "border": "1px red", "borderRadius": "2rem", "position": "relative", "left": "7.5rem", "boxShadow": "0px 0px 0px 1px #D461A8" }}>Use this flow</button>
          </section>

        </section>


      </section>
    </>
  )
}

export default Kard1